import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { useHorizontalScroll } from "domains/commons/hooks/useHorizontalScroll";
import { useScrollbarSize } from "domains/commons/hooks/useScrollbarSize";
import { extraTheme } from "domains/theme";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { VariableSizeList } from "react-window";

import {
  Box,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  VStack,
} from "@chakra-ui/react";

export const IMAGE_HEIGHT = 80;
export const GAP = 6;

export interface PreviewModalImageProps {
  isOpen: boolean;
  imageSrc?: string;
  images?: string[];
  onClose: () => void;
  onChange?: (index: number) => void;
}

export default function PreviewModalImage({
  isOpen,
  imageSrc,
  images,
  onClose,
  onChange,
}: PreviewModalImageProps) {
  const [elementWidth, setElementWidth] = useState<number>();
  const [scrolledTo, setScrolledTo] = useState<number>();
  const [hasRendered, setHasRendered] = useState(false);
  const horizontalScrollRef = useHorizontalScroll();
  const scrollbarSize = useScrollbarSize();
  const listRef = useRef<VariableSizeList>(null);
  const widthHelperRef = useRef<HTMLDivElement>(null);

  const elementHeight = IMAGE_HEIGHT + GAP * 2 + scrollbarSize.height;
  const imageCount = images?.length ?? 0;
  const imageIndex = useMemo(
    () => (images ? images.findIndex((item) => item === imageSrc) : -1),
    [images, imageSrc]
  );

  // ----------------------------------

  const getItemSize = useCallback(
    (index: number) => {
      return IMAGE_HEIGHT + GAP + (index === imageCount - 1 ? GAP : 0);
    },
    [imageCount]
  );

  const switchImage = useCallback(
    (direction: "next" | "previous") => {
      if (
        !onChange ||
        imageIndex === -1 ||
        (imageIndex === 0 && direction === "previous") ||
        (imageIndex === imageCount - 1 && direction === "next")
      ) {
        return;
      }

      const targetIndex = imageIndex + (direction === "previous" ? -1 : 1);
      onChange(targetIndex);
    },
    [onChange, imageIndex, imageCount]
  );

  // ----------------------------------

  const handleBottomLoad = useCallback(() => {
    setHasRendered(true);
    if (widthHelperRef?.current) {
      setElementWidth(widthHelperRef.current.clientWidth);
    }
  }, [setHasRendered, setElementWidth]);

  // ----------------------------------

  useEffect(() => {
    if (!widthHelperRef?.current) return;
    setElementWidth(widthHelperRef.current?.clientWidth);
    const resizeObserver = new ResizeObserver((entries) => {
      setElementWidth(entries[0].contentRect.width);
    });
    resizeObserver.observe(widthHelperRef.current);
    return () => resizeObserver.disconnect();
  }, [setElementWidth]);

  useEffect(() => {
    if (!hasRendered || imageIndex === -1 || imageIndex === scrolledTo) {
      return;
    }

    listRef?.current?.scrollToItem(imageIndex, "center");
    setScrolledTo(imageIndex);
  }, [setScrolledTo, hasRendered, imageIndex, scrolledTo]);

  useHotkeys("ArrowLeft", () => switchImage("previous"), [switchImage]);
  useHotkeys("ArrowRight", () => switchImage("next"), [switchImage]);

  // ----------------------------------

  const renderImage = useCallback(
    ({
      index,
      style,
      data: { list: listImages, selectedIndex },
    }: {
      index: number;
      style: any;
      data: {
        list: string[];
        selectedIndex: number;
      };
    }) => {
      const imageItemSrc: string | undefined =
        index > listImages.length - 1 ? undefined : listImages[index];
      const isSelected = selectedIndex === index;
      const isLast = index === listImages.length - 1;
      return (
        <Box
          w={`${IMAGE_HEIGHT + GAP + (isLast ? GAP : 0)}px`}
          h="100%"
          onClick={onChange ? () => onChange(index) : undefined}
          style={style}
        >
          <Box
            pos="absolute"
            top={`${GAP}px`}
            left={`${GAP}px`}
            w={`${IMAGE_HEIGHT}px`}
            h={`${IMAGE_HEIGHT}px`}
          >
            <Image
              w="100%"
              h="100%"
              objectFit="cover"
              alt="image preview"
              fallback={
                <Center h="100%">
                  <Spinner />
                </Center>
              }
              src={imageItemSrc}
            />

            {isSelected && (
              <Box
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                borderWidth={2}
                borderColor="primary.500"
              />
            )}
          </Box>
        </Box>
      );
    },
    [onChange]
  );

  return (
    <Modal
      isOpen={isOpen && !!imageSrc}
      onClose={onClose}
      size="5xl"
      variant="modern"
    >
      <ModalOverlay />
      <ModalContent h="90%" p={0} pt={12} bgColor="background.500">
        <ModalCloseButton />
        <ModalBody w="100%" h="100%">
          <VStack align="stretch" w="100%" h="100%" spacing={0}>
            <Box pos="relative" flex={1}>
              <Box
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                pb={12}
                px={10}
              >
                <Image
                  w="100%"
                  h="100%"
                  mx="auto"
                  objectFit="contain"
                  alt="image preview"
                  fallback={
                    <Center h="100%">
                      <Spinner />
                    </Center>
                  }
                  src={imageSrc}
                />
              </Box>

              {imageCount > 1 && (
                <>
                  <Button
                    variant="secondaryAlt"
                    position="absolute"
                    top={0}
                    bottom={0}
                    margin="auto"
                    left={5}
                    onClick={() => switchImage("previous")}
                  >
                    <Icon id="Ui/ChevronLeft" />
                  </Button>

                  <Button
                    variant="secondaryAlt"
                    position="absolute"
                    top={0}
                    bottom={0}
                    margin="auto"
                    right={5}
                    onClick={() => switchImage("next")}
                  >
                    <Icon id="Ui/ChevronRight" />
                  </Button>
                </>
              )}
            </Box>

            {imageCount > 1 && (
              <Box
                pos="relative"
                h={`${elementHeight + 1}px`}
                borderColor="border.500"
                borderTopWidth={1}
                bgColor="backgroundSecondary.500"
                onLoad={handleBottomLoad}
              >
                <Box ref={widthHelperRef} w="100%" h={0} />

                <Box pos="absolute" top={0} left={0} w="100%" h="100%">
                  <VariableSizeList
                    outerRef={horizontalScrollRef}
                    width={elementWidth || 9_999}
                    height={`${elementHeight}px`}
                    itemData={{
                      list: images ?? [],
                      selectedIndex: imageIndex,
                    }}
                    itemCount={imageCount}
                    itemSize={getItemSize}
                    layout="horizontal"
                    ref={listRef}
                    style={{
                      transition: extraTheme.transitions.fast,
                    }}
                  >
                    {renderImage}
                  </VariableSizeList>
                </Box>
              </Box>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
