import { useCallback, useState } from "react";
import { MAX_BASE64_UPLOAD_SIZE } from "domains/assets/constants/upload";
import useGetUploadedAsset from "domains/assets/hooks/useGetUploadedAsset";
import { getBase64Mime } from "domains/assets/utils";
import { compressImageForUpload } from "domains/assets/utils/compressImage";
import formatDate from "domains/canvas/utils/formatDate";
import { getMultipartChunkCount } from "domains/commons/misc";
import { mapAssetsToImagesFiles } from "domains/file-manager/interfaces";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Track from "infra/analytics/Track";
import {
  usePostAssetMutation,
  usePostUploadsActionByTeamIdMutation,
  usePostUploadsMutation,
} from "infra/api/generated/api";

export function useAssetUpload() {
  const { errorToast } = useScenarioToast();
  const { selectedTeam } = useTeamContext();
  const [postAssetTrigger] = usePostAssetMutation();
  const getUploadedAsset = useGetUploadedAsset();
  const [uploadFileTrigger] = usePostUploadsMutation();
  const [runActionOnUploadTrigger] = usePostUploadsActionByTeamIdMutation();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState<number | undefined>(undefined);

  const uploadImage = useCallback(
    async ({
      imageData,
      collectionIds = [],
      name,
      resizeError = {
        title: "Error uploading image",
        description:
          "There was an error uploading the image. Please try again.",
      },
      trackingEvent,
      trackingProperties = {},
      apiErrorHandler,
      hide,
      parentAssetId,
      preventCompression = false,
    }: {
      imageData: string;
      collectionIds?: string[];
      name?: string;
      resizeError?: { title: string; description: string };
      trackingEvent?: string;
      trackingProperties?: { [key: string]: any };
      apiErrorHandler?: (error: any) => void;
      hide?: boolean;
      parentAssetId?: string;
      preventCompression?: boolean;
    }): Promise<FmFileImage | undefined> => {
      setIsUploading(true);
      setProgress(undefined);

      const imageArrBuffer = await (await fetch(imageData)).arrayBuffer();
      const imageBase64Size = imageArrBuffer.byteLength;
      const imageBase64Mime = getBase64Mime(imageData) ?? "image/jpg";

      if (preventCompression && imageBase64Size >= MAX_BASE64_UPLOAD_SIZE) {
        const chunksCount = getMultipartChunkCount(imageBase64Size);
        const chunkSize = Math.ceil(imageBase64Size / chunksCount);
        const uploadInfo = await uploadFileTrigger({
          teamId: selectedTeam.id,
          body: {
            kind: "asset",
            contentType: imageBase64Mime,
            parts: chunksCount,
          },
        }).unwrap();

        if (!uploadInfo.upload.parts) {
          throw new Error("Invalid upload response");
        }

        for (let i = 0; i < chunksCount; i++) {
          const chunk = imageArrBuffer.slice(
            i * chunkSize,
            chunkSize * (i + 1)
          );
          const chunkUrl = (uploadInfo.upload.parts ?? [])[i].url;

          await fetch(chunkUrl, { method: "PUT", body: chunk });
          setProgress(Math.round(((i + 1) / chunksCount) * 80));
        }

        await runActionOnUploadTrigger({
          teamId: selectedTeam.id,
          uploadId: uploadInfo.upload.id,
          body: {
            action: "complete",
          },
        }).unwrap();

        const response = await getUploadedAsset(uploadInfo.upload.id);
        return mapAssetsToImagesFiles([response.asset])[0];
      } else {
        if (trackingEvent) {
          Track(trackingEvent, {
            size: imageBase64Size,
            resize: imageBase64Size > MAX_BASE64_UPLOAD_SIZE,
            ...trackingProperties,
          });
        }

        const resizedImage = await compressImageForUpload(imageData);
        if (!resizedImage) {
          errorToast(resizeError);
          return;
        }

        try {
          const response = await postAssetTrigger({
            teamId: selectedTeam.id,
            body: {
              image: resizedImage.data,
              name: name ?? "upload-" + formatDate(Date.now()),
              collectionIds,
              hide,
              parentId: parentAssetId,
            },
          }).unwrap();
          return mapAssetsToImagesFiles([response.asset])[0];
        } catch (error) {
          apiErrorHandler?.(error);
        } finally {
          setIsUploading(false);
        }
      }
    },
    [
      errorToast,
      getUploadedAsset,
      postAssetTrigger,
      runActionOnUploadTrigger,
      selectedTeam.id,
      uploadFileTrigger,
    ]
  );

  return {
    isUploading,
    uploadProgress: progress,
    uploadImage,
  };
}
