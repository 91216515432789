import { useMemo } from "react";
import ButtonSingleChoice, {
  ButtonSingleChoiceProps,
} from "domains/ui/components/Menu/ButtonSingleChoice";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import MenuTitle from "domains/ui/components/Menu/MenuTitle";

import { Menu, MenuDivider, MenuList } from "@chakra-ui/react";

type OptionsProps<T> =
  | {
      value: T;
      label: string;
      dataTestId?: string;
      isDivider?: never;
    }
  | {
      isDivider: true;
      value?: never;
      label?: never;
      dataTestId?: never;
    };

export type MenuListSingleChoiceProps<T> = {
  placeholder: string;
  options: OptionsProps<T>[];
  dataTestId?: string;
  topText: string;
  variant?: ButtonSingleChoiceProps["variant"];
  isPrimary?: boolean;
  isButtonLoading?: boolean;
} & (
  | {
      cannotBeUndefined: true;
      selectedValue: T;
      setSelectedValue: (value: T) => void;
    }
  | {
      cannotBeUndefined?: false;
      selectedValue: T | undefined;
      setSelectedValue: (value: T | undefined) => void;
    }
);

export default function MenuListSingleChoice<T extends string>({
  placeholder,
  options,
  setSelectedValue,
  selectedValue,
  dataTestId,
  topText,
  variant,
  cannotBeUndefined,
  isButtonLoading = false,
  isPrimary = false,
}: MenuListSingleChoiceProps<T>) {
  const mapValueLabel = useMemo(() => {
    return options.reduce((acc, option) => {
      if (!option.isDivider) {
        acc[option.value] = option.label;
      }
      return acc;
    }, {} as Record<string, string>);
  }, [options]);

  return (
    <Menu isLazy variant="blurred">
      <ButtonSingleChoice
        asMenuButton
        text={selectedValue ? mapValueLabel[selectedValue] : placeholder}
        onRemove={
          !cannotBeUndefined ? () => setSelectedValue(undefined) : undefined
        }
        hasValue={!!selectedValue}
        dataTestId={dataTestId}
        variant={variant}
        isLoading={isButtonLoading}
        {...(isPrimary
          ? {
              textColor: "primary.500",
              bgColor: "primary.800",
              borderWidth: selectedValue ? 0 : undefined,
              _hover: {
                textColor: "primary.500",
                bgColor: "primary.700",
              },
            }
          : {})}
      />

      <MenuList rootProps={{ maxWidth: "100%", minWidth: "unset" }}>
        <MenuTitle variant="blurred" text={topText.toUpperCase()} />

        {options.map((option, idx) => {
          if (option.isDivider) {
            return <MenuDivider key={idx} mx={1} />;
          } else {
            return (
              <MenuItem
                key={option.value}
                check="icon"
                text={option.label}
                isSelected={selectedValue === option.value}
                onClick={() => setSelectedValue(option.value)}
                dataTestId={option.dataTestId}
              />
            );
          }
        })}
      </MenuList>
    </Menu>
  );
}

export function MenuListSingleChoiceNeverUndefined<T extends string>({
  placeholder,
  options,
  setSelectedValue,
  selectedValue,
  dataTestId,
  topText,
  variant,
  isButtonLoading,
}: Omit<
  MenuListSingleChoiceProps<T>,
  "cannotBeUndefined" | "setSelectedValue" | "selectedValue"
> & {
  setSelectedValue: (value: T) => void;
  selectedValue: T;
}) {
  return (
    <MenuListSingleChoice
      placeholder={placeholder}
      options={options}
      setSelectedValue={(value) => {
        if (value === undefined) {
          return;
        }
        setSelectedValue(value);
      }}
      selectedValue={selectedValue}
      dataTestId={dataTestId}
      topText={topText}
      variant={variant}
      isButtonLoading={isButtonLoading}
      cannotBeUndefined
    />
  );
}
