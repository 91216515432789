import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useAssetJobFilterAuthor from "domains/assets/hooks/useAssetAuthor";
import useAssetFilterType from "domains/assets/hooks/useAssetFilterType";
import { useFileCanvasHandler } from "domains/canvas/hooks/useFileCanvasHandler";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import FileManager from "domains/file-manager/components/FileManager";
import FileManagerImage from "domains/file-manager/components/FileManagerImage";
import HeaderFilterAssetType from "domains/file-manager/components/FileManagerImage/HeaderFilterAssetType";
import HeaderFilterAuthor from "domains/file-manager/components/FileManagerImage/HeaderFilterAuthor";
import HeaderFilterDate, {
  HeaderFilterDateProps,
} from "domains/file-manager/components/FileManagerImage/HeaderFilterDate";
import FmHeaderColumn from "domains/file-manager/components/FileManagerV2/FmHeaderColumn";
import FmHeaderView from "domains/file-manager/components/FileManagerV2/FmHeaderView";
import {
  GridViewKey,
  gridViewKeys,
} from "domains/file-manager/constants/GridView";
import { useFileModelHandler } from "domains/file-manager/hooks/useFileModelHandler";
import { FileCanvasType, FileModelType } from "domains/file-manager/interfaces";
import { FmFile } from "domains/file-manager/interfacesV2";
import SearchBar from "domains/search/components/SearchBar";
import {
  useAssetsSearch,
  useCanvasesSearch,
  useModelsSearch,
} from "domains/search/hooks/useSearch";
import { useAutocompleteSearch } from "domains/search/hooks/useSearch";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button, { IconButton } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "domains/ui/components/Tabs";
import _ from "lodash";
import { CardActions as ImageCardActions } from "pages/images";
import { CardActions as SkyboxCardActions } from "pages/skyboxes";
import { CardActions as TextureCardActions } from "pages/textures";

import {
  Box,
  Collapse,
  Divider,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Skeleton,
  SkeletonText,
  Spinner,
  Text,
  useBreakpoint,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

interface SearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  query: string;
  setQuery: (query: string) => void;
  assetId: string | undefined;
  setAssetId: (assetId: string | undefined) => void;
  aiBoost: boolean;
  setAiBoost: (aiBoost: boolean) => void;
  tabId: string;
  setTabId: (tabId: string) => void;
}

export default function SearchModal({
  isOpen,
  onClose,
  query,
  setQuery,
  assetId,
  setAssetId,
  aiBoost,
  setAiBoost,
  tabId,
  setTabId,
}: SearchModalProps) {
  const isMac = navigator.userAgent.indexOf("Mac") !== -1;
  const { selectedTeam } = useTeamContext();
  const breakpoint = useBreakpoint({ ssr: true });
  const [canScrollTabsRight, setCanScrollTabsRight] = useState(false);
  const [canScrollTabsLeft, setCanScrollTabsLeft] = useState(false);
  const {
    isOpen: isFilterOpen,
    onToggle: onToggleFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const lastIsOpenRef = useRef<boolean>(isOpen);
  const modalSearchBarRef = useRef<HTMLInputElement>(null);
  const tabListRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [recentOptions, setRecentOptions] = usePersistedState<
    {
      assetId: string;
      teamId: string; // we need the team id because if it doesn't match the selected team, we can't do the search by assetId
      query: string;
    }[]
  >(PersistedStateKey.RECENT_SEARCHES, { defaultValue: [] });
  const focusedRecentOptionRef = useRef<number | undefined>(undefined);

  const initialColumnCount =
    {
      base: 3,
      xs: 3,
      sm: 4,
      md: 5,
      lg: 6,
    }[breakpoint] ?? 6;
  const [columnsCount, setColumnsCount] = useState<number>(initialColumnCount);
  const [view, setView] = useState<GridViewKey>("fill");

  const initialSkyboxColumnCount =
    {
      base: 1,
      xs: 1,
      sm: 2,
      md: 2,
      lg: 3,
    }[breakpoint] ?? 3;
  const [skyboxColumnsCount, setSkyboxColumnsCount] = useState<number>(
    initialSkyboxColumnCount
  );

  const [dateRange, setDateRange] = useState<
    HeaderFilterDateProps["value"] | undefined
  >();
  const searchDateArgs = useMemo(
    () => (dateRange?.start ? { dateRange } : {}),
    [dateRange]
  );
  const fmHeaderFilterDateProps = useMemo(
    () => ({ value: dateRange, onChange: setDateRange }),
    [dateRange, setDateRange]
  );

  const {
    authorQueryArgs,
    fmHeaderFilterAuthorProps,
    reset: resetAuthor,
  } = useAssetJobFilterAuthor({ avoidPersistedState: true });

  const {
    allAssetsTypeArgs: imageTypesQueryArgs,
    fmHeaderFilterAssetProps: imageFmHeaderFilterAssetProps,
    reset: resetImageTypes,
  } = useAssetFilterType({
    assetType: "image",
    showVectorization: true,
    avoidPersistedState: true,
  });

  const {
    allAssetsTypeArgs: skyboxTypesQueryArgs,
    fmHeaderFilterAssetProps: skyboxFmHeaderFilterAssetProps,
    reset: resetSkyboxTypes,
  } = useAssetFilterType({
    assetType: "skybox",
    avoidPersistedState: true,
  });

  const {
    allAssetsTypeArgs: textureTypesQueryArgs,
    fmHeaderFilterAssetProps: textureFmHeaderFilterAssetProps,
    reset: resetTextureTypes,
  } = useAssetFilterType({
    assetType: "texture",
    avoidPersistedState: true,
  });

  const isInSearch = !!(query || assetId);
  const isRecentDisplayed = !isInSearch && !!recentOptions.length;

  const { propositions, isLoading: isLoadingAutocomplete } =
    useAutocompleteSearch({ query: isOpen ? query : "" });

  const debouncedQuery = useDebounce(query, 500);
  const isLoadingGlobal = debouncedQuery !== query && debouncedQuery === "";

  // ----------------------------------

  const {
    files: images,
    resultCount: imagesCount,
    isLoading: isLoadingImages,
    setDeletedIds: setDeletedImageIds,
    loadMore: loadMoreImages,
    hasMore: hasMoreImages,
  } = useAssetsSearch({
    query: isOpen ? debouncedQuery : undefined,
    assetId: isOpen ? assetId : undefined,
    isHighResThumbnailRequired: tabId === "image",
    ...imageTypesQueryArgs,
    ...authorQueryArgs,
    ...searchDateArgs,
    aiBoost,
  });

  const {
    files: skyboxes,
    resultCount: skyboxesCount,
    isLoading: isLoadingSkyboxes,
    setDeletedIds: setDeletedSkyboxIds,
    loadMore: loadMoreSkyboxes,
    hasMore: hasMoreSkyboxes,
  } = useAssetsSearch({
    query: isOpen ? debouncedQuery : undefined,
    assetId: isOpen ? assetId : undefined,
    isHighResThumbnailRequired: tabId === "skybox",
    ...skyboxTypesQueryArgs,
    ...authorQueryArgs,
    ...searchDateArgs,
    aiBoost,
  });

  const {
    files: textures,
    resultCount: texturesCount,
    isLoading: isLoadingTextures,
    setDeletedIds: setDeletedTextureIds,
    loadMore: loadMoreTextures,
    hasMore: hasMoreTextures,
  } = useAssetsSearch({
    query: isOpen ? debouncedQuery : undefined,
    assetId: isOpen ? assetId : undefined,
    isHighResThumbnailRequired: tabId === "texture",
    ...textureTypesQueryArgs,
    ...authorQueryArgs,
    ...searchDateArgs,
    aiBoost,
  });

  const {
    files: models,
    resultCount: modelsCount,
    isLoading: isLoadingModels,
    setDeletedIds: setDeletedModelIds,
    loadMore: loadMoreModels,
    hasMore: hasMoreModels,
  } = useModelsSearch({
    query: isOpen ? debouncedQuery : undefined,
    assetId: isOpen ? assetId : undefined,
    ...authorQueryArgs,
    ...searchDateArgs,
    aiBoost,
  });

  const {
    files: canvas,
    resultCount: canvasCount,
    isLoading: isLoadingCanvas,
    setDeletedIds: setDeletedCanvasIds,
    loadMore: loadMoreCanvases,
    hasMore: hasMoreCanvases,
  } = useCanvasesSearch({
    query: isOpen ? debouncedQuery : undefined,
    assetId: isOpen ? assetId : undefined,
    ...authorQueryArgs,
    ...searchDateArgs,
    aiBoost,
  });

  const modelFileHandler = useFileModelHandler({
    emptyState: <EmptyState />,
    onOpen: onClose,
    onDelete: (files) => handleDelete(files, setDeletedModelIds),
    onUndoDelete: (files) => handleUndoDelete(files, setDeletedModelIds),
  });

  const canvasFileHandler = useFileCanvasHandler({
    emptyState: <EmptyState />,
    onOpen: onClose,
    onDelete: (files) => handleDelete(files, setDeletedCanvasIds),
    onUndoDelete: (files) => handleUndoDelete(files, setDeletedCanvasIds),
  });

  // ----------------------------------

  const setFocusedRecentOption = useCallback((index: number) => {
    focusedRecentOptionRef.current = index;
    (
      document.getElementById("recent-search-list")?.children[index] as
        | HTMLElement
        | undefined
    )?.focus();
  }, []);

  // ----------------------------------

  const handleRecentSearchClick = useCallback(
    (option: (typeof recentOptions)[number]) => {
      setQuery(option.query ?? "");
      setAssetId(option.assetId);
      modalSearchBarRef.current?.focus();
    },
    [setQuery, setAssetId]
  );

  const handleTabChange = useCallback(
    (idx: number) => {
      const tab = tabListRef.current?.children[idx];
      if (!tab) return;
      const tabId = tab.getAttribute("data-id") ?? "";
      setTabId(tabId);
      tab.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    [setTabId]
  );

  const handleRemoveRecentOptionClick = useCallback(
    (e: MouseEvent, option: (typeof recentOptions)[number]) => {
      e.preventDefault();
      e.stopPropagation();
      setRecentOptions((options) => _.without(options, option));
      focusedRecentOptionRef.current = undefined;
    },
    [setRecentOptions]
  );

  const handleActionClick = useCallback(
    (type: string) => {
      if (type === "searchSimilar" || type === "more") return;
      onClose();
    },
    [onClose]
  );

  const handleDelete = useCallback(
    (
      files: (FmFile<"image"> | FileModelType | FileCanvasType)[],
      setDeletedIds: Dispatch<SetStateAction<string[]>>
    ) => {
      setDeletedIds((prev) => [...prev, ...files.map((f) => f.id)]);
    },
    []
  );

  const handleUndoDelete = useCallback(
    (
      files: (FmFile<"image"> | FileModelType | FileCanvasType)[],
      setDeletedIds: Dispatch<SetStateAction<string[]>>
    ) => {
      setDeletedIds((prev) =>
        prev.filter((id) => !files.map((f) => f.id).includes(id))
      );
    },
    []
  );

  // ----------------------------------

  useEffect(() => {
    if (!isOpen && lastIsOpenRef.current) {
      onCloseFilter();
      setDateRange(undefined);
      setColumnsCount(initialColumnCount);
      setSkyboxColumnsCount(initialSkyboxColumnCount);
      resetImageTypes();
      resetSkyboxTypes();
      resetTextureTypes();
      resetAuthor();
    }
    lastIsOpenRef.current = isOpen;
  }, [
    initialColumnCount,
    initialSkyboxColumnCount,
    isOpen,
    onCloseFilter,
    resetAuthor,
    resetImageTypes,
    resetSkyboxTypes,
    resetTextureTypes,
    setColumnsCount,
    setSkyboxColumnsCount,
  ]);

  useEffect(() => {
    if (!query && !assetId) {
      return;
    }

    const newOption = {
      query: query ?? "",
      ...(assetId
        ? { assetId, teamId: selectedTeam.id }
        : {
            assetId: "",
            teamId: "",
          }),
    };
    const timeout = setTimeout(() => {
      setRecentOptions((options) => {
        return [
          newOption,
          ...options.filter((o) => !_.isEqual(o, newOption)),
        ].slice(0, 10);
      });
    }, 1_000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query, assetId, setRecentOptions, selectedTeam]);

  useEffect(() => {
    if (!tabListRef.current) return;
    const listener = () => {
      if (!tabListRef.current) return;
      setCanScrollTabsRight(
        tabListRef.current.scrollWidth -
          Math.ceil(tabListRef.current.scrollLeft + 10) >
          tabListRef.current.clientWidth
      );
      setCanScrollTabsLeft(tabListRef.current.scrollLeft > 0);
    };
    listener();
    const tabList = tabListRef.current;
    tabList.addEventListener("scroll", listener);
    window.addEventListener("resize", listener);
    return () => {
      tabList.removeEventListener("scroll", listener);
      window.removeEventListener("resize", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabListRef.current]);

  useEffect(() => {
    if (!tabListRef.current) return;
    const listener = (e: WheelEvent) => {
      if (!tabListRef.current) return;
      tabListRef.current.scrollTo({
        left: tabListRef.current.scrollLeft + e.deltaY,
        behavior: "smooth",
      });
    };
    const tabList = tabListRef.current;
    tabList.addEventListener("wheel", listener);
    return () => {
      tabList.removeEventListener("wheel", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabListRef.current]);

  useEffect(() => {
    const resetFocusedRecentOption = (e: FocusEvent) => {
      if (
        !e.target ||
        !("closest" in e.target) ||
        !(e.target as HTMLElement).closest("#recent-search-list")
      ) {
        focusedRecentOptionRef.current = undefined;
      }
    };
    window.addEventListener("focus", resetFocusedRecentOption, true);
    return () => {
      window.removeEventListener("focus", resetFocusedRecentOption, true);
    };
  }, []);

  useHotkeys(
    "ArrowUp",
    () => {
      if (!recentOptions.length) return;
      if (focusedRecentOptionRef.current === undefined) {
        setFocusedRecentOption(recentOptions.length - 1);
      } else {
        setFocusedRecentOption(
          (((focusedRecentOptionRef.current - 1) % recentOptions.length) +
            recentOptions.length) %
            recentOptions.length
        );
      }
    },
    {
      enableOnContentEditable: true,
      enableOnFormTags: true,
      preventDefault: true,
    },
    [recentOptions]
  );

  useHotkeys(
    "ArrowDown",
    () => {
      if (!recentOptions.length) return;
      if (focusedRecentOptionRef.current === undefined) {
        setFocusedRecentOption(0);
      } else {
        setFocusedRecentOption(
          (focusedRecentOptionRef.current + 1) % recentOptions.length
        );
      }
    },
    {
      enableOnContentEditable: true,
      enableOnFormTags: true,
      preventDefault: true,
    },
    [recentOptions]
  );

  useHotkeys(
    "Enter",
    () => {
      if (focusedRecentOptionRef.current === undefined) return;
      handleRecentSearchClick(recentOptions[focusedRecentOptionRef.current]);
    },
    [recentOptions]
  );

  // ----------------------------------

  const cardActionsProps = useMemo(() => ({}), []);

  // ----------------------------------

  return (
    <Modal
      closeOnEsc
      closeOnOverlayClick
      initialFocusRef={modalSearchBarRef}
      isOpen={isOpen}
      onClose={onClose}
      variant="search"
    >
      <ModalOverlay />
      <ModalContent
        overflow="hidden"
        w="min(90vw, 1500px)"
        h={isInSearch ? "90vh" : "auto"}
        maxH="90vh"
        mt="5vh"
        mb={0}
        p={2}
        pb={0}
        borderRadius="20px"
        bgColor="background.500"
        containerProps={{
          onDragEnter: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        }}
      >
        <ModalBody flexDir="column" flex={1} display="flex" overflow="hidden">
          <VStack align="stretch" flex={1} h="100%" spacing={0}>
            <SearchBar
              searchBarRef={modalSearchBarRef}
              query={query}
              setQuery={setQuery}
              assetId={assetId}
              setAssetId={setAssetId}
              size="lg"
              color="textPrimary"
              borderWidth={0}
              searchOn={["enter"]}
              aiBoost={aiBoost}
              setAiBoost={setAiBoost}
              isAiBoostDisabled={!!assetId}
            />

            {isRecentDisplayed && (
              <VStack align="stretch" pt={4} pb={2} spacing={2}>
                <Text pl={2} size="body.lg">
                  Recent Searches
                </Text>

                <VStack align="stretch" id="recent-search-list" spacing={0}>
                  {recentOptions.map((option, idx) => {
                    if (option.assetId && option.teamId !== selectedTeam.id) {
                      return null;
                    }
                    return (
                      <Button
                        key={`${option}-${idx}`}
                        size="sm"
                        variant="menuItem"
                        leftIcon={<Icon id="Ui/Search" h="12px" />}
                        data-group
                        rightIcon={
                          <IconButton
                            w="24px"
                            minW={0}
                            h="24px"
                            mx={-1}
                            borderRadius="full"
                            _hover={{
                              bgColor: "whiteAlpha.300",
                            }}
                            _groupHover={{
                              visibility: "visible",
                            }}
                            visibility="hidden"
                            aria-label="remove"
                            colorScheme="white"
                            onClick={(e) =>
                              handleRemoveRecentOptionClick(e, option)
                            }
                            variant="ghost"
                          >
                            <Icon id="Ui/Cross" h="8px" />
                          </IconButton>
                        }
                        onClick={() => handleRecentSearchClick(option)}
                      >
                        {option.assetId && (
                          <Image
                            h="100%"
                            borderRadius="base"
                            objectFit="contain"
                            alt="image recent search"
                            aspectRatio="1"
                            fallback={<Skeleton h="100%" aspectRatio="1" />}
                            src={`${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/${option.assetId}`}
                          />
                        )}
                        <Box flex={1} textAlign="left">
                          {option.query}
                        </Box>
                      </Button>
                    );
                  })}
                </VStack>
              </VStack>
            )}

            {((!!propositions && propositions.propositions.length > 0) ||
              isLoadingAutocomplete) && (
              <>
                <HStack
                  pos="relative"
                  justify="start"
                  flexShrink={0}
                  overflow="hidden"
                  h="56px"
                  px={1}
                  py={2.5}
                  flexFlow="wrap"
                  spacing={0}
                >
                  {(!!propositions && propositions.propositions
                    ? propositions.propositions
                    : ["placeholder", "placeholder", "placeholder"]
                  ).map((option, idx) => (
                    <Button
                      key={`${option}-${idx}`}
                      size="sm"
                      width="auto"
                      variant="menuItem"
                      pointerEvents={isLoadingAutocomplete ? "none" : "auto"}
                      leftIcon={<Icon id="Ui/Search" h="12px" />}
                      onClick={() => {
                        if (!isLoadingAutocomplete) {
                          setQuery(option);
                        }
                      }}
                      fontSize="16px"
                    >
                      {propositions ? (
                        <HStack spacing={0}>
                          <Box as="span" color="textSecondary" whiteSpace="pre">
                            {propositions.query}
                          </Box>
                          <Box as="span" color="textPrimary" whiteSpace="pre">
                            {option.replace(propositions.query, "")}
                          </Box>
                        </HStack>
                      ) : (
                        <SkeletonText
                          w="60px"
                          noOfLines={1}
                          skeletonHeight="20px"
                        />
                      )}
                    </Button>
                  ))}
                  <Box pos="absolute" top="46px" left={0} w="100%" h="100px" />
                </HStack>

                <Divider color="border.500" opacity={1} />
              </>
            )}

            {isInSearch && (
              <Flex direction="column" flex={1} overflow="hidden">
                <Tabs
                  variant="light"
                  isLazy
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  overflow="hidden"
                  borderRadius="xl"
                  onChange={handleTabChange}
                >
                  <HStack
                    pos="relative"
                    justify="space-between"
                    w="100%"
                    borderBottomWidth={1}
                    borderBottomColor="border.500"
                    spacing={2}
                  >
                    <TabList
                      ref={tabListRef}
                      py={1}
                      alignItems="center"
                      flexShrink={1}
                      overflow="auto"
                      borderBottomWidth={0}
                      sx={{
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        "-ms-overflow-style": "none",
                        "scrollbar-width": "none",
                      }}
                    >
                      <ModalTab
                        id="image"
                        title="Images"
                        isLoading={isLoadingGlobal || isLoadingImages}
                        count={imagesCount}
                      />

                      <ModalTab
                        id="model"
                        title="Models"
                        isLoading={isLoadingGlobal || isLoadingModels}
                        count={modelsCount}
                      />

                      <ModalTab
                        id="skybox"
                        title="Skyboxes"
                        isLoading={isLoadingGlobal || isLoadingSkyboxes}
                        count={skyboxesCount}
                      />

                      <ModalTab
                        id="texture"
                        title="Textures"
                        isLoading={isLoadingGlobal || isLoadingTextures}
                        count={texturesCount}
                      />

                      <ModalTab
                        id="canvas"
                        title="Canvas"
                        isLoading={isLoadingGlobal || isLoadingCanvas}
                        count={canvasCount}
                      />

                      <TabIndicator
                        key={[
                          isLoadingImages,
                          images.length,
                          isLoadingSkyboxes,
                          skyboxes.length,
                          isLoadingTextures,
                          textures.length,
                          isLoadingCanvas,
                          canvas.length,
                          isLoadingModels,
                          models.length,
                          isLoadingGlobal,
                        ].join("-")}
                        bg="white"
                      />
                    </TabList>

                    {canScrollTabsRight && (
                      <IconButton
                        variant="blurred"
                        pos="absolute"
                        left={(tabListRef.current?.clientWidth ?? 0) - 36}
                        aria-label="Scroll Right"
                        icon={<Icon id="Ui/ChevronRight" />}
                        borderRadius="full"
                        onClick={() => {
                          tabListRef.current?.scrollTo({
                            left: tabListRef.current.scrollLeft + 100,
                            behavior: "smooth",
                          });
                        }}
                      />
                    )}

                    {canScrollTabsLeft && (
                      <IconButton
                        variant="blurred"
                        pos="absolute"
                        left={0}
                        aria-label="Scroll Left"
                        icon={<Icon id="Ui/ChevronLeft" />}
                        borderRadius="full"
                        onClick={() => {
                          tabListRef.current?.scrollTo({
                            left: tabListRef.current.scrollLeft - 100,
                            behavior: "smooth",
                          });
                        }}
                      />
                    )}

                    <HStack mr={3}>
                      <Button
                        size="sm"
                        variant="secondary"
                        leftIcon={<Icon id="Ui/Filter" h="16px" />}
                        onClick={onToggleFilter}
                      >
                        Filters
                      </Button>
                    </HStack>
                  </HStack>

                  <Box pos="relative" zIndex="popover">
                    <Collapse animateOpacity={false} in={isFilterOpen}>
                      <HStack w="100%" pt={4} pb={3} px={3}>
                        {tabId === "image" &&
                          !!imageFmHeaderFilterAssetProps && (
                            <HeaderFilterAssetType
                              {...imageFmHeaderFilterAssetProps}
                              variant="secondaryAlt"
                            />
                          )}

                        {tabId === "skybox" &&
                          !!skyboxFmHeaderFilterAssetProps && (
                            <HeaderFilterAssetType
                              {...skyboxFmHeaderFilterAssetProps}
                              variant="secondaryAlt"
                            />
                          )}

                        {tabId === "texture" &&
                          !!textureFmHeaderFilterAssetProps && (
                            <HeaderFilterAssetType
                              {...textureFmHeaderFilterAssetProps}
                              variant="secondaryAlt"
                            />
                          )}

                        {!!fmHeaderFilterAuthorProps && (
                          <HeaderFilterAuthor
                            {...fmHeaderFilterAuthorProps}
                            variant="secondary"
                          />
                        )}

                        {!!fmHeaderFilterDateProps && (
                          <HeaderFilterDate
                            {...fmHeaderFilterDateProps}
                            variant="secondaryAlt"
                          />
                        )}

                        <Box flex={1} />

                        <FmHeaderColumn
                          value={
                            tabId === "skybox"
                              ? skyboxColumnsCount
                              : columnsCount
                          }
                          onChange={
                            tabId === "skybox"
                              ? setSkyboxColumnsCount
                              : setColumnsCount
                          }
                        />

                        {tabId === "image" && (
                          <FmHeaderView
                            value={view}
                            options={gridViewKeys.filter(
                              (key) => key !== "jobs"
                            )}
                            onChange={setView}
                          />
                        )}
                      </HStack>
                    </Collapse>
                  </Box>

                  <TabPanels flex={1} overflow="auto" pt={0} ref={scrollRef}>
                    <TabPanel h="100%">
                      <FileManagerImage
                        scrollRef={scrollRef}
                        files={images}
                        isLoading={isLoadingGlobal || isLoadingImages}
                        view={view}
                        columnsCount={columnsCount}
                        isSelectable
                        isWithoutHeader
                        onActionClick={handleActionClick}
                        CardActionsComponent={ImageCardActions}
                        cardActionsProps={cardActionsProps}
                        EmptyStateComponent={EmptyState}
                        onDelete={(files) =>
                          handleDelete(files, setDeletedImageIds)
                        }
                        onUndoDelete={(files) =>
                          handleUndoDelete(files, setDeletedImageIds)
                        }
                        onEndReached={loadMoreImages}
                        hasMore={hasMoreImages}
                        assetZoomPriorityLevel={1}
                      />
                    </TabPanel>

                    <TabPanel h="100%">
                      <FileManager
                        styleProps={{
                          topbar: {
                            display: "none",
                          },
                        }}
                        files={models}
                        isLoading={isLoadingGlobal || isLoadingModels}
                        options={{
                          canSelect: true,
                          canChangeView: false,
                          gridView: "fill",
                          canChangeNumberOfColumns: false,
                          numberOfColumns: columnsCount,
                          showFileNames: "always",
                        }}
                        fileHandlers={{
                          model: modelFileHandler,
                        }}
                        scrollRef={scrollRef}
                        loadMore={loadMoreModels}
                        hasMore={hasMoreModels}
                      />
                    </TabPanel>

                    <TabPanel>
                      <FileManagerImage
                        variant="skybox"
                        scrollRef={scrollRef}
                        files={skyboxes}
                        isLoading={isLoadingGlobal || isLoadingSkyboxes}
                        view="masonry"
                        columnsCount={skyboxColumnsCount}
                        isWithoutHeader
                        isSelectable
                        onActionClick={handleActionClick}
                        CardActionsComponent={SkyboxCardActions}
                        cardActionsProps={cardActionsProps}
                        EmptyStateComponent={EmptyState}
                        onDelete={(files) =>
                          handleDelete(files, setDeletedSkyboxIds)
                        }
                        onUndoDelete={(files) =>
                          handleUndoDelete(files, setDeletedSkyboxIds)
                        }
                        onEndReached={loadMoreSkyboxes}
                        hasMore={hasMoreSkyboxes}
                        assetZoomPriorityLevel={1}
                      />
                    </TabPanel>

                    <TabPanel>
                      <FileManagerImage
                        variant="texture"
                        scrollRef={scrollRef}
                        files={textures}
                        isLoading={isLoadingGlobal || isLoadingTextures}
                        view="fill"
                        columnsCount={columnsCount}
                        isWithoutHeader
                        isSelectable
                        onActionClick={handleActionClick}
                        CardActionsComponent={TextureCardActions}
                        cardActionsProps={cardActionsProps}
                        EmptyStateComponent={EmptyState}
                        onDelete={(files) =>
                          handleDelete(files, setDeletedTextureIds)
                        }
                        onUndoDelete={(files) =>
                          handleUndoDelete(files, setDeletedTextureIds)
                        }
                        onEndReached={loadMoreTextures}
                        hasMore={hasMoreTextures}
                        assetZoomPriorityLevel={1}
                      />
                    </TabPanel>

                    <TabPanel>
                      <FileManager
                        styleProps={{
                          topbar: {
                            display: "none",
                          },
                        }}
                        files={canvas}
                        isLoading={isLoadingGlobal || isLoadingCanvas}
                        options={{
                          canSelect: true,
                          canChangeView: false,
                          gridView: "fill",
                          canChangeNumberOfColumns: false,
                          numberOfColumns: columnsCount,
                          showFileNames: "always",
                        }}
                        fileHandlers={{
                          canvas: canvasFileHandler,
                        }}
                        scrollRef={scrollRef}
                        loadMore={loadMoreCanvases}
                        hasMore={hasMoreCanvases}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            )}

            {!isInSearch && (
              <HStack
                align="center"
                justify="start"
                mt={isRecentDisplayed ? 0 : 2}
                mx={-2}
                px={4}
                py={2}
                borderTopWidth={1}
                spacing={8}
              >
                <HStack align="center" spacing={2}>
                  <Icon id="Ui/UpDownArrows" color="textPrimary" h="16px" />
                  <Text color="textSecondary" size="body.md">
                    Select
                  </Text>
                </HStack>
                <HStack align="center" spacing={2}>
                  <Text color="textSecondary" size="body.bold.md">
                    {isMac ? "⌘" : "Ctrl"} F
                  </Text>
                  <Text color="textSecondary" size="body.md">
                    Quick Search
                  </Text>
                </HStack>
              </HStack>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

// ------------------------------------

function EmptyState() {
  return (
    <VStack justify="center" py={20} spacing={2}>
      <Icon id="Ui/Search" h="16px" color="textPrimary" />
      <Text color="textPrimary" size="body.md">
        Sorry, no results found
      </Text>
      <Text color="textTertiary" size="body.sm">
        You can search models, images, prompts, tags...
      </Text>
    </VStack>
  );
}

// ------------------------------------

interface ModalTabProps {
  id: string;
  title: React.ReactNode;
  isLoading?: boolean;
  count?: number;
}

function ModalTab({ id, title, isLoading = false, count }: ModalTabProps) {
  return (
    <Tab data-id={id}>
      <HStack spacing={1}>
        <span>{title}</span>
        {isLoading && count === undefined ? (
          <Spinner size="sm" />
        ) : (
          <span>{`(${count ?? 0})`}</span>
        )}
      </HStack>
    </Tab>
  );
}
