import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { GuideKeys } from "domains/guide/constants/guides";
import { useAssetUpload } from "domains/image/hooks/useAssetUpload";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import ButtonSectionTopIcon from "domains/inference/components/InferenceGenerator/Sidebar/ButtonSectionTopIcon";
import SidebarSection from "domains/inference/components/InferenceGenerator/Sidebar/Section";
import { getIsBaseModel } from "domains/models/utils";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { Shortcut } from "domains/shortcuts/components/Shorcut";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { sizes } from "domains/theme/components/text";
import Button from "domains/ui/components/Button";
import CompatibleImageUploader, {
  ImageListType,
} from "domains/ui/components/CompatibleImageUploader";
import Icon from "domains/ui/components/Icon";
import { useHover } from "domains/ui/hooks/useHover";
import { useHandleApiError } from "infra/api/error";
import { removeCuFromType } from "infra/api/ExludeCU";
import {
  GetModelsByModelIdApiResponse,
  usePostCaptionInferencesMutation,
  usePostPromptInferencesMutation,
  usePostTranslateInferencesMutation,
} from "infra/api/generated/api";
import _ from "lodash";

import {
  Box,
  Flex,
  HStack,
  keyframes,
  Spinner,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";

const MAGIC_PROMPT_MAX_LENGTH = 512;
const TRANSLATION_MAX_LENGTH = 512;
const MAX_PROMPT_LENGTH = 2_048;

interface SidebarSectionPromptProps {
  id: string;
  title?: string;
  placeholder?: string;
  prompt: string;
  setPrompt: (prompt: string) => void;
  magicPromptModelCallback?: () => Promise<
    GetModelsByModelIdApiResponse["model"] | undefined
  >;
  guide?: GuideKeys;
  compact?: boolean;
  withRandom?: boolean;
  withMagic?: boolean;
  withAssetUpload?: boolean;
  minH?: number;
  withShortcuts?: boolean;
  isPromptEmbedding?: boolean;
  referenceAssetId?: string;
}

export default function SidebarSectionPrompt({
  id,
  title = "Prompt",
  placeholder = "Describe your scene or subject, and let prompt tools generate, complete, or translate it.",
  prompt,
  setPrompt,
  magicPromptModelCallback,
  guide,
  compact: isCompact,
  withRandom = true,
  withMagic = true,
  withAssetUpload = true,
  minH = 100,
  withShortcuts = true,
  isPromptEmbedding = false,
  referenceAssetId,
}: SidebarSectionPromptProps) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const handleApiError = useHandleApiError();
  const { errorToast, infoToast } = useScenarioToast();
  const [magicPromptTrigger] = usePostPromptInferencesMutation();
  const [isMagicPromptLoading, setIsMagicPromptLoading] = useState(false);
  const [isRandomPromptLoading, setIsRandomPromptLoading] = useState(false);
  const [translateTrigger] = usePostTranslateInferencesMutation();
  const [isLoadingTranslation, setIsLoadingTranslation] = useState(false);
  const { selectedTeam } = useTeamContext();
  const { showLimitModal } = usePlanContext();
  const [isActive, setIsActive] = useState(false);
  const [undoList, setUndoList] = useState<string[]>([]);
  const [redoList, setRedoList] = useState<string[]>([]);
  const [containerRef, isHoveringContainer] = useHover<HTMLDivElement>();
  const [magicPromptButtonRef, isHoveringMagicPromptButton] =
    useHover<HTMLButtonElement>();
  const [translationButtonRef, isHoveringTranslationButton] =
    useHover<HTMLButtonElement>();
  const [isLoadingCaption, setIsLoadingCaption] = useState(false);
  const { uploadImage } = useAssetUpload();
  const [postCaptionTrigger] = usePostCaptionInferencesMutation();
  const timeoutChangeRef = useRef<NodeJS.Timeout | undefined>();

  const {
    isDraggingHover: isDraggingHoverDropZone,
    dragFunctions,
    onDrop,
  } = useImageUploadDragDrop({
    onImageDrop: async (imageUrl) => {
      const assetId = imageUrl.split("/")?.pop()?.split("?")[0];
      if (
        assetId &&
        (imageUrl.includes(".scenario.com/assets") ||
          imageUrl.includes(".scenario-labs.io/assets"))
      ) {
        void setPromptFromAssetId(assetId);
      }
    },
  });

  const isTooShortForTranslate = useMemo(() => {
    const value = prompt.replace(/[0-9!@#$%^&*)(+=._\-/\\]/g, "");
    if (/^[a-zA-Z\s]*$/.test(value)) {
      return value.length < 3;
    }
    return value.length < 1;
  }, [prompt]);
  const isTooShortForMagic = useMemo(() => {
    return prompt.length < 1;
  }, [prompt]);

  const isLoading = useMemo(
    () =>
      isLoadingTranslation ||
      isMagicPromptLoading ||
      isRandomPromptLoading ||
      isLoadingCaption,
    [
      isLoadingTranslation,
      isMagicPromptLoading,
      isLoadingCaption,
      isRandomPromptLoading,
    ]
  );

  const loadingAnimation = useMemo(() => {
    if (isLoadingTranslation || isMagicPromptLoading) {
      return "breathing";
    } else if (isLoading) {
      return "spinner";
    }
    return undefined;
  }, [isLoadingTranslation, isMagicPromptLoading, isLoading]);

  const shouldShowTooLong = useMemo(() => {
    if (
      isHoveringMagicPromptButton &&
      prompt.length > MAGIC_PROMPT_MAX_LENGTH
    ) {
      return "magic";
    } else if (
      isHoveringTranslationButton &&
      prompt.length > TRANSLATION_MAX_LENGTH
    ) {
      return "translate";
    } else {
      return undefined;
    }
  }, [isHoveringMagicPromptButton, isHoveringTranslationButton, prompt]);

  // ----------------------------------

  const setPromptFromAssetId = useCallback(
    async (assetId: string) => {
      setIsLoadingCaption(true);

      const model = magicPromptModelCallback
        ? await magicPromptModelCallback()
        : undefined;
      try {
        const captionResponse = await postCaptionTrigger({
          teamId: selectedTeam.id,
          body: {
            modelId: model?.id,
            detailsLevel: getIsBaseModel(model) ? "action+style" : undefined,
            assetIds: [assetId],
          },
        })
          .unwrap()
          .then(removeCuFromType);
        if (captionResponse.captions?.[0]) {
          setPrompt(captionResponse.captions[0]);
        }
      } catch (error) {
        handleApiError(error, "Error generating caption");
      } finally {
        setIsLoadingCaption(false);
      }
    },
    [
      magicPromptModelCallback,
      postCaptionTrigger,
      selectedTeam.id,
      setPrompt,
      handleApiError,
    ]
  );

  // ----------------------------------

  const handleInputChange = useCallback<
    ChangeEventHandler<HTMLTextAreaElement>
  >(
    (e) => {
      let newValue = e.target.value ?? "";
      if (newValue.length > MAX_PROMPT_LENGTH) {
        newValue = newValue.slice(0, MAX_PROMPT_LENGTH);
        infoToast({
          description: `Your have reached the maximum length of ${MAX_PROMPT_LENGTH} characters.`,
        });
      }

      if (timeoutChangeRef.current) {
        clearTimeout(timeoutChangeRef.current);
      }

      setPrompt(newValue);

      timeoutChangeRef.current = setTimeout(() => {
        setUndoList((undo) => {
          if (undo.length && undo[0] === prompt) {
            return undo;
          }
          return [prompt, ...undo];
        });
      }, 500);
    },
    [prompt, setPrompt, infoToast]
  );

  const handleImageDrop = useCallback(
    async (imageList: ImageListType) => {
      if (imageList.length === 0) {
        return;
      }

      const image = imageList[0].dataURL;
      if (!image) {
        return;
      }

      setIsLoadingCaption(true);
      const uploadedAsset = await uploadImage({
        imageData: image,
        name: "Prompt Image",
      });
      if (!uploadedAsset) {
        setIsLoadingCaption(false);
        return;
      }

      void setPromptFromAssetId(uploadedAsset.id);
    },
    [uploadImage, setPromptFromAssetId]
  );

  const handlePromptSpark = useCallback(
    async (isRandom = false) => {
      if (!isRandom && isTooShortForMagic) {
        return;
      }
      if (isRandom) {
        setIsRandomPromptLoading(true);
      } else {
        setIsMagicPromptLoading(true);
      }
      const model = magicPromptModelCallback
        ? await magicPromptModelCallback()
        : undefined;
      try {
        const { prompts } = await magicPromptTrigger({
          teamId: selectedTeam.id,
          body: {
            modelId: model?.id,
            prompt: isRandom ? "" : prompt.slice(0, MAGIC_PROMPT_MAX_LENGTH),
            numResults: 1,
            mode: isRandom
              ? "inventive"
              : prompt.length < 100
              ? "structured"
              : "completion",
          },
        })
          .unwrap()
          .then(removeCuFromType);
        if (prompts?.[0]) {
          setUndoList((undo) => [prompt, ...undo]);
          setPrompt(prompts[0]);
          setRedoList([]);
        }
      } catch (error: any) {
        handleApiError(
          error,
          "There was an error generating the prompt. Please try again.",
          {
            quota: () => {
              if (_.get(error, "data.details.remainingSeconds")) {
                showLimitModal("planCooldown", {
                  timeout: error.data.details.remainingSeconds,
                  type: "magicPrompt",
                });
              } else {
                showLimitModal("planMagicPromptLimit");
              }
            },
          }
        );
      } finally {
        setIsMagicPromptLoading(false);
        setIsRandomPromptLoading(false);
      }
    },
    [
      isTooShortForMagic,
      magicPromptModelCallback,
      magicPromptTrigger,
      selectedTeam.id,
      prompt,
      setPrompt,
      handleApiError,
      showLimitModal,
    ]
  );

  const handleTranslation = useCallback(async () => {
    if (isTooShortForTranslate) {
      return;
    }

    setIsLoadingTranslation(true);

    try {
      const translationData = await translateTrigger({
        teamId: selectedTeam.id,
        body: {
          prompt: prompt.slice(0, TRANSLATION_MAX_LENGTH),
        },
      })
        .unwrap()
        .then(removeCuFromType);
      if (
        translationData &&
        translationData.job.status === "success" &&
        translationData.translation
      ) {
        setUndoList((undo) => [prompt, ...undo]);
        setPrompt(translationData.translation);
        setRedoList([]);
      } else {
        errorToast({
          title: "Translation failed",
        });
      }
    } catch (error) {
    } finally {
      setIsLoadingTranslation(false);
    }
  }, [
    errorToast,
    prompt,
    selectedTeam.id,
    setPrompt,
    translateTrigger,
    isTooShortForTranslate,
  ]);

  const handleReferenceClick = useCallback(() => {
    if (!referenceAssetId) return;
    void setPromptFromAssetId(referenceAssetId);
  }, [referenceAssetId, setPromptFromAssetId]);

  const handleUndo = useCallback(() => {
    if (undoList.length === 0) {
      return;
    }
    setRedoList((redo) => [prompt, ...redo]);
    setPrompt(undoList[0]);
    setUndoList((undo) => undo.slice(1));
  }, [prompt, setPrompt, undoList]);

  const handleRedo = useCallback(() => {
    if (redoList.length === 0) {
      return;
    }
    setUndoList((undo) => [prompt, ...undo]);
    setPrompt(redoList[0]);
    setRedoList((redo) => redo.slice(1));
  }, [prompt, setPrompt, redoList]);

  const handleDelete = useCallback(() => {
    setUndoList((undo) => [prompt, ...undo]);
    setPrompt("");
    setRedoList([]);
  }, [prompt, setPrompt]);

  const handleBlur = useCallback(() => setIsActive(false), []);
  const handleFocus = useCallback(() => setIsActive(true), []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (
        ((navigator.userAgent.indexOf("Mac") === -1 && e.ctrlKey) ||
          (navigator.userAgent.indexOf("Mac") !== -1 && e.metaKey)) &&
        (e.key === "z" || e.key === "y")
      ) {
        e.preventDefault();
        void (e.key === "z" && !e.shiftKey ? handleUndo() : handleRedo());
      }
    },
    [handleUndo, handleRedo]
  );

  // ----------------------------------

  useAutosizeTextArea(ref.current, prompt);

  useHotkeys(
    appShortcuts.prompting.shortcuts.generatePrompt.shortcut,
    () => handlePromptSpark(true),
    { enabled: withShortcuts && withRandom },
    [handlePromptSpark]
  );

  useHotkeys(
    appShortcuts.prompting.shortcuts.rewritePrompt.shortcut,
    () => handlePromptSpark(),
    { enabled: withShortcuts && withMagic },
    [handlePromptSpark]
  );

  useHotkeys(
    appShortcuts.prompting.shortcuts.translate.shortcut,
    () => handleTranslation(),
    { enabled: withShortcuts },
    [handleTranslation]
  );
  useHotkeys(
    appShortcuts.prompting.shortcuts.undo.shortcut,
    () => handleUndo(),
    { enabled: isActive }
  );
  useHotkeys(
    appShortcuts.prompting.shortcuts.redo.shortcut,
    () => handleRedo(),
    { enabled: isActive }
  );

  // ----------------------------------

  return (
    <SidebarSection
      ref={containerRef}
      compact={isCompact}
      headerProps={{
        title,
        id,
        guide,
        topRight: (
          <HStack
            align="center"
            opacity={isHoveringContainer ? 1 : 0}
            spacing={2}
          >
            <ButtonSectionTopIcon
              onClick={handleUndo}
              isDisabled={undoList.length === 0}
            >
              <Icon id="Domains/Canvas/Undo" h="14px" />
            </ButtonSectionTopIcon>
            <ButtonSectionTopIcon
              onClick={handleRedo}
              isDisabled={redoList.length === 0}
            >
              <Icon id="Domains/Canvas/Redo" h="14px" />
            </ButtonSectionTopIcon>
            <ButtonSectionTopIcon
              onClick={handleDelete}
              isDisabled={prompt.length === 0}
            >
              <Icon id="Ui/Trash" h="14px" />
            </ButtonSectionTopIcon>
          </HStack>
        ),
        noCollapse: isPromptEmbedding,
        style: isPromptEmbedding ? { pt: 0, px: 0, pb: 2, h: "auto" } : {},
      }}
      contentBoxStyle={
        isPromptEmbedding
          ? {
              py: 0,
              px: 0,
            }
          : {}
      }
    >
      <CompatibleImageUploader
        value={[]}
        onChange={handleImageDrop}
        allowNonImageType={false}
        multiple={false}
      >
        {({ onImageUpload, dragProps: { onDrop: dragPropsOnDrop } }) => {
          return (
            <Flex
              pos="relative"
              {...(withAssetUpload && {
                onDrop: (event) => onDrop(event, dragPropsOnDrop),
                ...dragFunctions,
              })}
              direction={"column"}
              overflow="hidden"
              borderWidth={1}
              borderColor={
                isDraggingHoverDropZone || isActive
                  ? "primary.500"
                  : "border.500"
              }
              borderRadius={"lg"}
              shadow={isActive ? "0px 0px 0px 2px #00334D" : undefined}
              _hover={{
                borderColor: isActive ? undefined : "borderSecondary.500",
              }}
              bgColor={"background.500"}
            >
              <Box pos="relative">
                <Textarea
                  ref={ref}
                  minH={`${minH}px`}
                  pt={2}
                  border="none"
                  transition="none"
                  data-testid="inference-builder-prompt-textarea"
                  id={`inference-builder-prompt-${id}`}
                  isDisabled={isLoading && loadingAnimation !== "breathing"}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onInput={handleInputChange}
                  value={prompt}
                  {...(((isLoading && loadingAnimation !== "breathing") ||
                    shouldShowTooLong) && {
                    pointerEvents: "none",
                    opacity: "0 !important",
                  })}
                  {...(isLoading &&
                    loadingAnimation === "breathing" && {
                      onInput: undefined,
                      pointerEvents: "none",
                      animation: `${colorTransition} 1.5s ease-in forwards, ${pulsingAnimation}  1.5s ease-in-out infinite 1.5s`,
                    })}
                  onKeyDown={handleKeyDown}
                />

                {isLoading && loadingAnimation === "spinner" && (
                  <Spinner pos="absolute" top={2} left={3} size="sm" />
                )}

                {shouldShowTooLong && (
                  <Text
                    pos="absolute"
                    top={0}
                    left={0}
                    maxW="100%"
                    px={3}
                    py={2}
                    color="textPrimary"
                    fontSize={sizes["body.md"].fontSize}
                    fontWeight={sizes["body.md"].fontWeight}
                    lineHeight="short"
                  >
                    <Text
                      as="span"
                      dangerouslySetInnerHTML={{
                        __html: prompt
                          .slice(
                            0,
                            shouldShowTooLong === "magic"
                              ? MAGIC_PROMPT_MAX_LENGTH
                              : TRANSLATION_MAX_LENGTH
                          )
                          .replace(/[\r\n]/g, "<br>"),
                      }}
                    />
                    <Text
                      as="span"
                      color="textTertiary"
                      dangerouslySetInnerHTML={{
                        __html: prompt
                          .slice(
                            shouldShowTooLong === "magic"
                              ? MAGIC_PROMPT_MAX_LENGTH
                              : TRANSLATION_MAX_LENGTH
                          )
                          .replace(/[\r\n]/g, "<br>"),
                      }}
                    />
                  </Text>
                )}

                {(withAssetUpload || referenceAssetId) &&
                  !prompt &&
                  !isLoading && (
                    <Text
                      pos="absolute"
                      zIndex={3}
                      top={0}
                      left={0}
                      maxW="100%"
                      px={3}
                      py={2}
                      color="textTertiary"
                      fontSize={sizes["body.md"].fontSize}
                      fontWeight={sizes["body.md"].fontWeight}
                      lineHeight="short"
                      pointerEvents="none"
                    >
                      {placeholder}{" "}
                      <Text
                        as="span"
                        color="primary.500"
                        _hover={{
                          opacity: 0.9,
                        }}
                        pointerEvents="all"
                        cursor="pointer"
                        onClick={
                          referenceAssetId
                            ? handleReferenceClick
                            : onImageUpload
                        }
                      >
                        {referenceAssetId
                          ? "Turn your reference image into a prompt."
                          : "Upload an image"}
                      </Text>
                      {!referenceAssetId && " to turn it into a prompt."}
                    </Text>
                  )}
              </Box>

              <HStack
                sx={{
                  // we need this because the tooltips are adding a div around the buttons
                  "& div": {
                    flex: 1,
                  },
                }}
                justify="stretch"
                pt={2}
                pb={2}
                px={3}
                spacing={2}
              >
                <Button
                  w="full"
                  variant="secondary"
                  onClick={() => handlePromptSpark(true)}
                  leftIcon={<Icon id="Ui/Dices" h="12px" />}
                  isDisabled={isLoading}
                  {...(!withRandom && {
                    opacity: "0 !important",
                    pointerEvents: "none",
                  })}
                  tooltip={
                    <Shortcut
                      shortcut={
                        withShortcuts
                          ? appShortcuts.prompting.shortcuts.generatePrompt
                              .shortcut
                          : undefined
                      }
                      description={
                        appShortcuts.prompting.shortcuts.generatePrompt
                          .description
                      }
                    />
                  }
                  tooltipProps={{
                    isDisabled: !withRandom,
                  }}
                />

                <Button
                  w="full"
                  variant="secondary"
                  onClick={() => handlePromptSpark()}
                  leftIcon={<Icon id="Ui/ThreeStars" h="12px" />}
                  isDisabled={isTooShortForMagic || isLoading}
                  ref={magicPromptButtonRef}
                  {...(!withMagic && {
                    opacity: "0 !important",
                    pointerEvents: "none",
                  })}
                  tooltip={
                    <Shortcut
                      shortcut={
                        withShortcuts
                          ? appShortcuts.prompting.shortcuts.rewritePrompt
                              .shortcut
                          : undefined
                      }
                      description={
                        appShortcuts.prompting.shortcuts.rewritePrompt
                          .description
                      }
                    />
                  }
                  tooltipProps={{
                    isDisabled: !withMagic,
                  }}
                />

                <Button
                  w="full"
                  variant="secondary"
                  onClick={handleTranslation}
                  leftIcon={<Icon id="Ui/Translate" h="12px" />}
                  isDisabled={isTooShortForTranslate || isLoading}
                  ref={translationButtonRef}
                  tooltip={
                    <Shortcut
                      shortcut={
                        withShortcuts
                          ? appShortcuts.prompting.shortcuts.translate.shortcut
                          : undefined
                      }
                      description={
                        appShortcuts.prompting.shortcuts.translate.description
                      }
                    />
                  }
                />
              </HStack>

              {isDraggingHoverDropZone && (
                <VStack
                  pos="absolute"
                  zIndex="docked"
                  align="center"
                  justify="center"
                  w="full"
                  h="full"
                  bg="rgba(0, 136, 204, 0.33)"
                  backdropFilter="blur(4px)"
                >
                  <Icon
                    id="Domains/Canvas/Import"
                    color="textPrimary"
                    h="38px"
                  />
                  <Text color="textPrimary" size="body.bold.md">
                    Image to prompt
                  </Text>
                </VStack>
              )}
            </Flex>
          );
        }}
      </CompatibleImageUploader>
    </SidebarSection>
  );
}

// ------------------------------------

const colorTransition = keyframes`
0% {
  color: var(--chakra-colors-textPrimary);
}
100% {
  color: var(--chakra-colors-textTertiary);
}
`;

const pulsingAnimation = keyframes`
0%, 100% {
  color: var(--chakra-colors-textTertiary);
}
50% {
  color: var(--chakra-colors-textSecondary);
}
`;

// ------------------------------------

function useAutosizeTextArea(
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) {
  useEffect(() => {
    if (textAreaRef) {
      const newTextArea = textAreaRef.cloneNode(true) as HTMLTextAreaElement;
      newTextArea.value = value;
      newTextArea.style.position = "absolute";
      newTextArea.style.top = "-9999px";
      newTextArea.style.left = "-9999px";
      newTextArea.style.height = "auto";
      newTextArea.style.width = "316px";
      document.body.appendChild(newTextArea);
      textAreaRef.style.height = `${newTextArea.scrollHeight}px`;
      document.body.removeChild(newTextArea);
    }
  }, [textAreaRef, value]);
}
