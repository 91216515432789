import React from "react";
import Icon from "domains/ui/components/Icon";

import { HStack, Switch, Text, Tooltip, VStack } from "@chakra-ui/react";

interface ControlSwitchProps {
  title: string;
  description?: string | React.ReactNode;
  tooltip?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  setIsChecked: (isChecked: boolean) => void;
}

export default function ControlSwitch({
  title,
  description,
  tooltip,
  isChecked,
  isDisabled,
  setIsChecked,
}: ControlSwitchProps) {
  return (
    <HStack
      justify="space-between"
      w="100%"
      color="textSecondary"
      _hover={{ color: "textPrimary" }}
      cursor={!isDisabled ? "pointer" : undefined}
      data-group
      onClick={!isDisabled ? () => setIsChecked(!isChecked) : undefined}
    >
      <VStack align="start" spacing={0}>
        <Tooltip
          color="inherit"
          isDisabled={!tooltip}
          label={tooltip}
          placement="bottom-start"
        >
          <HStack minH="28px" spacing={0}>
            <Text color="inherit" size="body.md">
              {title}
            </Text>

            {tooltip && (
              <Icon
                id="Ui/Help"
                ml={2}
                color={"textTertiary"}
                opacity={0}
                _groupHover={{ opacity: 1 }}
              />
            )}
          </HStack>
        </Tooltip>

        {description &&
          (typeof description === "string" ? (
            <Text color="textTertiary" size="body.sm">
              {description}
            </Text>
          ) : (
            description
          ))}
      </VStack>

      <Switch
        pointerEvents="none"
        isChecked={isChecked}
        isDisabled={isDisabled}
      />
    </HStack>
  );
}
