import React, { useCallback, useMemo, useState } from "react";
import formatDate from "domains/canvas/utils/formatDate";
import ModalLibraryAssetSelect from "domains/image/components/ModalLibraryAssetSelect";
import { useAssetUpload } from "domains/image/hooks/useAssetUpload";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import { ReferenceImage } from "domains/inference/interfaces/InferenceGenerator";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import CompatibleImageUploader, {
  handleBlurFocusOnUploadClick,
  ImageListType,
} from "domains/ui/components/CompatibleImageUploader";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import ModalCrop from "domains/ui/components/ModalCrop";
import { useLazyGetAssetsByAssetIdQuery } from "infra/api/generated/api";

import {
  Box,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  SimpleGrid,
  Skeleton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

interface SidebarFragmentReferenceImagesProps {
  images: ReferenceImage[];
  setImages: (images: ReferenceImage[]) => void;
  maxImages: number;
  trackingEvent: string;
  trackingProperties: Record<string, unknown>;
}

export default function SidebarFragmentReferenceImages({
  images,
  setImages: __setImages,
  maxImages,
  trackingEvent,
  trackingProperties,
}: SidebarFragmentReferenceImagesProps) {
  const { selectedTeam } = useTeamContext();
  const {
    isOpen: isSelectImageModalOpen,
    onOpen: onOpenSelectImageModal,
    onClose: onCloseSelectImageModal,
  } = useDisclosure();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [imageIndexToCrop, setImageIndexToCrop] = useState<
    number | undefined
  >();
  const { uploadImage } = useAssetUpload();

  const canAddImage = useMemo(
    () => images.length < maxImages,
    [images.length, maxImages]
  );

  const setImages = useCallback(
    (images: ReferenceImage[]) => {
      // remove duplicates asset id except if it's empty
      const newImages = [];
      for (const image of images) {
        if (image.isLoading) {
          newImages.push(image);
          continue;
        }
        if (newImages.some((newImage) => newImage.assetId === image.assetId)) {
          continue;
        }
        newImages.push(image);
      }
      __setImages(newImages);
    },
    [__setImages]
  );

  const removeAtIndex = useCallback(
    (index: number) => {
      const tmpImages = [...images];
      tmpImages.splice(index, 1);
      setImages(tmpImages);
    },
    [images, setImages]
  );

  const setAtIndex = useCallback(
    (image: ReferenceImage, index: number) => {
      const tmpImages = [...images];
      tmpImages[index] = image;
      setImages(tmpImages);
    },
    [images, setImages]
  );

  const replaceImage = useCallback(
    async ({
      src,
      assetId,
      index,
    }: {
      src: string;
      assetId?: string;
      index: number;
    }) => {
      if (assetId) {
        setAtIndex(
          {
            src,
            assetId,
          },
          index
        );
        return;
      }

      setAtIndex(
        {
          src: "",
          assetId: "",
          isLoading: true,
        },
        index
      );
      const asset = await uploadImage({
        imageData: src,
        name: "style-image-" + formatDate(Date.now()),
        trackingEvent,
        trackingProperties,
      });
      if (asset) {
        setAtIndex(
          {
            src: asset.thumbnail,
            assetId: asset.id,
            isLoading: false,
          },
          index
        );
      } else {
        removeAtIndex(index);
      }
    },
    [removeAtIndex, setAtIndex, trackingEvent, trackingProperties, uploadImage]
  );

  const addImages = useCallback(
    async (
      items: {
        src: string;
        assetId?: string;
      }[]
    ) => {
      const ogImages = images;
      setImages([
        ...ogImages,
        ...Array(items.length).fill({
          src: "",
          assetId: "",
          isLoading: true,
        }),
      ]);
      const newImages = await Promise.all(
        items.map(async (item) => {
          if (item.assetId) {
            return {
              src: item.src,
              assetId: item.assetId,
            };
          }

          const asset = await uploadImage({
            imageData: item.src,
            name: "style-image-" + formatDate(Date.now()),
            trackingEvent,
            trackingProperties,
          });
          if (asset) {
            return {
              src: asset.thumbnail,
              assetId: asset.id,
            };
          }
        })
      );
      setImages([
        ...ogImages,
        ...(newImages.filter((image) => !!image) as ReferenceImage[]),
      ]);
    },
    [images, setImages, trackingEvent, trackingProperties, uploadImage]
  );

  const [getAssetById] = useLazyGetAssetsByAssetIdQuery();
  const { isDraggingHover, dragFunctions, onDrop } = useImageUploadDragDrop({
    onImageDrop: async (imageUrl) => {
      const assetId = imageUrl.split("/")?.pop()?.split("?")[0];
      if (
        assetId &&
        (imageUrl.includes(".scenario.com/assets") ||
          imageUrl.includes(".scenario-labs.io/assets"))
      ) {
        const ogImages = images;
        setImages([
          ...ogImages,
          {
            src: "",
            assetId: "",
            isLoading: true,
          },
        ]);
        const getAssetData = await getAssetById({
          teamId: selectedTeam.id,
          assetId,
        });
        if (getAssetData.data?.asset) {
          setImages([
            ...ogImages,
            {
              src: getAssetData.data.asset.url,
              assetId: getAssetData.data.asset.id,
            },
          ]);
        } else {
          void removeAtIndex(images.length);
        }
      }
    },
  });

  const handleUploadInputChange = useCallback(
    async (imageList: ImageListType) => {
      const dataUrls = [];

      for (const image of imageList) {
        if (image.dataURL === undefined) {
          continue;
        }
        dataUrls.push(image.dataURL);
      }

      void addImages(dataUrls.map((src) => ({ src })));
      onCloseSelectImageModal();
    },
    [addImages, onCloseSelectImageModal]
  );

  return (
    <CompatibleImageUploader
      value={[]}
      onChange={handleUploadInputChange}
      allowNonImageType={false}
      maxNumber={maxImages - images.length}
      multiple={true}
    >
      {({ onImageUpload, dragProps: { onDrop: dragPropsOnDrop } }) => {
        return (
          <VStack pt="10px" spacing={2}>
            {/*this somehow fixes the display bug on safari*/}
            <Flex w="100%">
              <SimpleGrid w="100%" columns={3} spacing={2}>
                {images.map((image, index) => (
                  <Box
                    key={`style-image-${index}`}
                    pos="relative"
                    w="100%"
                    aspectRatio="1"
                  >
                    <Box
                      overflow="hidden"
                      w="100%"
                      h="100%"
                      borderWidth={1}
                      borderRadius="md"
                    >
                      <Image
                        w="100%"
                        h="100%"
                        objectFit="contain"
                        alt={`Style Image ${index + 1}`}
                        fallback={<Skeleton w="100%" h="100%" />}
                        src={image.src}
                      />
                    </Box>

                    <Box
                      pos="absolute"
                      top={0}
                      right={0}
                      transform={"translate(50%, -50%)"}
                    >
                      <Menu isLazy placement="bottom-end">
                        <Center
                          as={MenuButton}
                          w="20px"
                          h="20px"
                          bg="white"
                          borderWidth={1}
                          borderRadius="full"
                          bgColor="black"
                        >
                          <Icon id="Ui/Dots" w="12px" h="unset" />
                        </Center>
                        <Portal>
                          <MenuList zIndex="tooltip">
                            <MenuItem
                              text="Remove"
                              onClick={() => removeAtIndex(index)}
                            />
                            {!image.isLoading && (
                              <MenuItem
                                text="Crop"
                                onClick={() => setImageIndexToCrop(index)}
                              />
                            )}
                          </MenuList>
                        </Portal>
                      </Menu>
                    </Box>
                  </Box>
                ))}

                {canAddImage && (
                  <Center
                    onDrop={(e) => onDrop(e, dragPropsOnDrop)}
                    {...dragFunctions}
                    overflow="hidden"
                    w="100%"
                    borderWidth={1}
                    borderColor={isDraggingHover ? "primary.500" : undefined}
                    borderRadius="md"
                    _hover={{
                      opacity: 0.75,
                    }}
                    cursor="pointer"
                    aspectRatio="1"
                    onClick={onOpenSelectImageModal}
                  >
                    <Icon
                      id="Ui/Plus"
                      h="25%"
                      color={isDraggingHover ? "primary.500" : undefined}
                    />
                  </Center>
                )}
              </SimpleGrid>
            </Flex>

            <ModalLibraryAssetSelect
              isOpen={isSelectImageModalOpen}
              selectionMax={maxImages - images.length}
              onClose={onCloseSelectImageModal}
              onMultiSelect={(assets) => {
                void addImages(
                  assets.splice(0, maxImages - images.length).map((asset) => ({
                    src: asset.meta.url,
                    assetId: asset.id,
                  }))
                );
                onCloseSelectImageModal();
              }}
              buttonDroppableUploadProps={{
                onPaste: (asset) => {
                  void addImages([
                    {
                      src: asset.meta.url,
                      assetId: asset.id,
                    },
                  ]);
                },
                isLoading: isUploading,
                isDraggingHover,
                onClick: () =>
                  handleBlurFocusOnUploadClick(onImageUpload, setIsUploading),
                onDrop: (event) => onDrop(event, dragPropsOnDrop),
                ...dragFunctions,
              }}
            />

            {imageIndexToCrop !== undefined && (
              <ModalCrop
                imageToCrop={{
                  assetId: images[imageIndexToCrop].assetId,
                  src: images[imageIndexToCrop].src,
                }}
                onSubmit={async (asset) => {
                  void replaceImage({
                    src: asset.url,
                    assetId: asset.id,
                    index: imageIndexToCrop,
                  });
                  setImageIndexToCrop(undefined);
                }}
                onCancel={() => setImageIndexToCrop(undefined)}
              />
            )}
          </VStack>
        );
      }}
    </CompatibleImageUploader>
  );
}
