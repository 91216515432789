import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Mutex } from "async-mutex";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { removeCuFromType } from "infra/api/ExludeCU";
import { usePostEmbedInferencesMutation } from "infra/api/generated/api";

import {
  instantMeiliSearch,
  InstantMeiliSearchObject,
} from "@meilisearch/instant-meilisearch";

const mutex = new Mutex();

interface SearchProviderProps {
  getIndexes: (params: {
    type: "asset" | "model";
    withPublic?: boolean;
  }) => string[];
  searchClient: InstantMeiliSearchObject["searchClient"] | undefined;
  getQueryEmbedding: (query: string) => Promise<
    | {
        query: string;
        embedding: number[];
      }
    | undefined
  >;
  clearCache: () => void;
}

export const SearchContext = createContext<SearchProviderProps>({
  getIndexes: () => [],
  searchClient: undefined,
  getQueryEmbedding: async () => undefined,
  clearCache: () => {},
});

export function SearchProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const { selectedTeamDetails, selectedTeam } = useTeamContext();
  const [searchClient, setSearchClient] = useState<
    InstantMeiliSearchObject["searchClient"] | undefined
  >();
  const queryEmbeddingsRef = useRef<{
    [key: string]: number[];
  }>({});
  const [getEmbeddingTrigger] = usePostEmbedInferencesMutation();

  useEffect(() => {
    if (!selectedTeamDetails?.tenantToken) return;
    const { searchClient } = instantMeiliSearch(
      process.env.NEXT_PUBLIC_MEILISEARCH_URL ?? "",
      selectedTeamDetails.tenantToken
    );
    setSearchClient(searchClient);
  }, [selectedTeamDetails]);

  const getIndexes = useCallback(
    ({
      type,
      withPublic = true,
    }: {
      type: "asset" | "model";
      withPublic?: boolean;
    }) => {
      if (type === "model") {
        return [
          selectedTeamDetails?.searchIndexes?.models?.private,
          withPublic
            ? selectedTeamDetails?.searchIndexes?.models?.public
            : undefined,
        ].filter((value) => value !== undefined);
      }
      return [
        selectedTeamDetails?.searchIndexes?.assets?.private,
        withPublic
          ? selectedTeamDetails?.searchIndexes?.assets?.public
          : undefined,
      ].filter((value) => value !== undefined);
    },
    [selectedTeamDetails]
  );

  const getQueryEmbedding = useCallback(
    async (query: string) => {
      await mutex.acquire();
      if (queryEmbeddingsRef.current[query]) {
        mutex.release();
        return {
          query,
          embedding: queryEmbeddingsRef.current[query],
        };
      }
      try {
        const data = await getEmbeddingTrigger({
          teamId: selectedTeam.id,
          body: {
            text: query,
          },
        })
          .unwrap()
          .then(removeCuFromType);
        queryEmbeddingsRef.current[query] = data.embedding;
      } catch (_) {
      } finally {
        mutex.release();
      }
      return {
        query,
        embedding: queryEmbeddingsRef.current[query],
      };
    },
    [getEmbeddingTrigger, selectedTeam]
  );

  const clearCache = useCallback(() => {
    if (searchClient) {
      searchClient.clearCache();
    }
  }, [searchClient]);

  return (
    <SearchContext.Provider
      value={{
        getIndexes,
        searchClient,
        getQueryEmbedding,
        clearCache,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export function useSearchContext() {
  return useContext<SearchProviderProps>(SearchContext);
}
