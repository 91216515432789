import { useCallback, useMemo, useState } from "react";
import { getImageDimensions } from "domains/commons/utils/getImageDimensions";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { ExcludeCu, removeCuFromType } from "infra/api/ExludeCU";
import {
  GetAssetsByAssetIdApiResponse,
  PostRemoveBackgroundInferencesApiArg,
  PostRemoveBackgroundInferencesApiResponse,
  useLazyGetAssetsByAssetIdQuery,
  usePostRemoveBackgroundInferencesMutation,
} from "infra/api/generated/api";
import _ from "lodash";

export interface RemoveBackgroundForm {
  backgroundColor: string | undefined;
  reference:
    | {
        src: string;
        assetId?: string;
        dimensions?: { width: number; height: number };
      }
    | undefined;
}

export interface UseAssetRemoveBackgroundReturnValue {
  form: RemoveBackgroundForm;
  setValue: <K extends keyof RemoveBackgroundForm>(
    key: K,
    value: RemoveBackgroundForm[K]
  ) => void;
  setReference: (
    image: string,
    assetId?: string,
    assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
  ) => void;
  resetForm: () => void;
  handleRemoveBackgroundJob: (props: {
    assetId?: string;
    trackingExtraParams: Record<string, unknown>;
    withToast?: boolean;
    isBulk?: boolean;
  }) => Promise<
    ExcludeCu<PostRemoveBackgroundInferencesApiResponse>["job"] | undefined
  >;
  handleRemoveBackgroundAsset: (props: {
    assetId?: string;
    trackingExtraParams: Record<string, unknown>;
    withToast?: boolean;
    isBulk?: boolean;
  }) => Promise<GetAssetsByAssetIdApiResponse["asset"] | undefined>;
  isRemoveBackgroundLoading: boolean;
}

export default function useAssetRemoveBackground(): UseAssetRemoveBackgroundReturnValue {
  const { selectedTeam } = useTeamContext();
  const [getAssetByAssetId] = useLazyGetAssetsByAssetIdQuery();
  const [triggerImageRemoveBackground] =
    usePostRemoveBackgroundInferencesMutation();
  const { infoToast, successToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();
  const [form, setForm] = useState<RemoveBackgroundForm>({
    backgroundColor: undefined,
    reference: undefined,
  });
  const [isRemoveBackgroundLoading, setIsRemoveBackgroundLoading] =
    useState<boolean>(false);

  // ----------------------------------

  const handleRemoveBackground = useCallback(
    async ({
      assetId: initialAssetId,
      trackingExtraParams,
      withToast = true,
      isBulk = false,
    }: {
      assetId?: string;
      trackingExtraParams: Record<string, unknown>;
      withToast?: boolean;
      isBulk?: boolean;
    }) => {
      try {
        if (withToast) {
          infoToast({
            title: "Removing background",
          });
        }

        const assetId = initialAssetId ?? form.reference?.assetId;
        if (!assetId) {
          throw new Error("No asset");
        }

        const removeBackgroundParams: ExcludeCu<PostRemoveBackgroundInferencesApiArg> & {
          isBulk: boolean;
        } = {
          teamId: selectedTeam.id,
          isBulk,
          originalAssets: "true",
          body: {
            image: assetId,
            format: "png",
            ...(form.backgroundColor
              ? {
                  backgroundColor: form.backgroundColor,
                }
              : {}),
          },
        };

        const removeBackgroundResponse = await triggerImageRemoveBackground(
          removeBackgroundParams
        )
          .unwrap()
          .then(removeCuFromType);

        Track(AnalyticsEvents.ImageLibrary.RemovedBackground, {
          assetId,
          backgroundColor: form.backgroundColor ?? "transparent",
          ...trackingExtraParams,
        });

        return removeBackgroundResponse;
      } catch (error: any) {
        handleApiError(
          error,
          "Error removing background",
          {
            quota: () => {
              if (_.get(error, "data.details.remainingSeconds")) {
                showLimitModal("planCooldown", {
                  timeout: error.data.details.remainingSeconds,
                  type: "removeBackground",
                });
              } else if (
                _.get(error, "data.reason").includes(
                  "You have reached your plan's limit."
                )
              ) {
                showLimitModal("notEnoughCreativeUnits");
              } else {
                errorToast({
                  title: "Error generating images",
                  description: _.get(error, "data.reason"),
                });
              }
            },
          },
          withToast
        );
      }
    },
    [
      form,
      errorToast,
      handleApiError,
      infoToast,
      selectedTeam.id,
      showLimitModal,
      triggerImageRemoveBackground,
    ]
  );

  const handleRemoveBackgroundJob = useCallback(
    async ({
      assetId: initialAssetId,
      trackingExtraParams,
      withToast = true,
      isBulk = false,
    }: {
      assetId?: string;
      trackingExtraParams: Record<string, unknown>;
      withToast?: boolean;
      isBulk?: boolean;
    }) => {
      setIsRemoveBackgroundLoading(true);
      const removeBackgroundResponse = await handleRemoveBackground({
        assetId: initialAssetId,
        trackingExtraParams,
        withToast,
        isBulk,
      });
      setIsRemoveBackgroundLoading(false);
      return removeBackgroundResponse?.job;
    },
    [handleRemoveBackground]
  );

  const handleRemoveBackgroundAsset = useCallback(
    async ({
      assetId: initialAssetId,
      trackingExtraParams,
      withToast = true,
      isBulk = false,
    }: {
      assetId?: string;
      trackingExtraParams: Record<string, unknown>;
      withToast?: boolean;
      isBulk?: boolean;
    }) => {
      setIsRemoveBackgroundLoading(true);

      const removeBackgroundResponse = await handleRemoveBackground({
        assetId: initialAssetId,
        trackingExtraParams,
        withToast,
        isBulk,
      });

      if (!removeBackgroundResponse?.asset) {
        return;
      }

      let removedBackgroundAsset: GetAssetsByAssetIdApiResponse | undefined =
        await getAssetByAssetId({
          teamId: selectedTeam.id,
          originalAssets: "true",
          assetId: removeBackgroundResponse.asset.id,
        }).unwrap();

      while (
        removedBackgroundAsset?.asset &&
        removedBackgroundAsset.asset.status === "pending"
      ) {
        await new Promise((resolve) => setTimeout(resolve, 2_000));
        try {
          removedBackgroundAsset = await getAssetByAssetId({
            teamId: selectedTeam.id,
            originalAssets: "true",
            assetId: removedBackgroundAsset.asset.id,
          })
            .unwrap()
            .then(removeCuFromType);
        } catch (_) {
          removedBackgroundAsset = undefined;
        }
      }

      if (
        !removedBackgroundAsset?.asset ||
        removedBackgroundAsset.asset.status !== "success"
      ) {
        errorToast({
          title: "Error removing background",
        });
      }

      if (withToast) {
        successToast({
          title: "Background removed",
        });
      }
      setIsRemoveBackgroundLoading(false);

      return removedBackgroundAsset?.asset;
    },
    [
      errorToast,
      getAssetByAssetId,
      handleRemoveBackground,
      selectedTeam.id,
      successToast,
    ]
  );

  const setValue = useCallback(
    <K extends keyof RemoveBackgroundForm>(
      key: K,
      value: RemoveBackgroundForm[K]
    ) => {
      setForm((form) => ({
        ...form,
        [key]: value,
      }));
    },
    [setForm]
  );

  const setReference = useCallback(
    async (
      image: string,
      assetId?: string,
      assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
    ) => {
      const { width, height } = await (async () => {
        if (assetMeta?.metadata.width && assetMeta?.metadata.height) {
          return {
            width: assetMeta.metadata.width,
            height: assetMeta.metadata.height,
          };
        } else if (image) {
          return await getImageDimensions(image);
        } else {
          return { width: undefined, height: undefined };
        }
      })();

      setForm((form) => ({
        ...form,
        reference: {
          src: image,
          assetId,
          dimensions: width ? { width, height } : undefined,
        },
      }));
    },
    [setForm]
  );

  const resetForm = useCallback(() => {
    setForm({ reference: undefined, backgroundColor: undefined });
  }, []);

  // ----------------------------------

  return useMemo(
    () => ({
      form,
      setValue,
      setReference,
      resetForm,
      handleRemoveBackgroundJob,
      handleRemoveBackgroundAsset,
      isRemoveBackgroundLoading,
    }),
    [
      form,
      setValue,
      setReference,
      resetForm,
      handleRemoveBackgroundJob,
      handleRemoveBackgroundAsset,
      isRemoveBackgroundLoading,
    ]
  );
}

// ----------------------------------
