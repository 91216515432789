import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";

export const appShortcuts = {
  global: {
    title: "Global",
    shortcuts: {
      shortcutHelp: {
        shortcut: "h",
        description: "Display shortcut help",
      },
      launchGeneration: {
        shortcut: ["mod+enter"],
        description: "Launch generation",
      },
      search: {
        shortcut: "mod+f",
        description: "Search",
      },
      toggleMenu: {
        shortcut: "m",
        description: "Toggle menu",
      },
    },
  },
  prompting: {
    title: "Prompting",
    shortcuts: {
      generatePrompt: {
        shortcut: "g",
        description: "Generate a new prompt",
      },
      rewritePrompt: {
        shortcut: "r",
        description: "Rewrite your prompt",
      },
      translate: {
        shortcut: "t",
        description: "Translate to English",
      },
      undo: {
        shortcut: "mod+z",
        description: "Undo",
      },
      redo: {
        shortcut: ["mod+shift+z", "mod+y"],
        description: "Redo",
      },
    },
  },
  gallery: {
    title: "Gallery",
    shortcuts: {
      selectMultiple: {
        // used in shortcuts modal
        shortcut: "shift+click",
        description: "Select multiple items",
      },
      zoomIn: {
        shortcut: ["+", "="],
        description: "Zoom in",
      },
      zoomOut: {
        shortcut: ["-"],
        description: "Zoom out",
      },
    },
  },
  assetPage: {
    title: "Asset Page",
    shortcuts: {
      fullscreen: {
        shortcut: "f",
        description: "Toggle fullscreen",
      },
      info: {
        shortcut: "i",
        description: "Show/hide info panel",
      },
      close: {
        shortcut: "esc",
        description: "Close",
      },
      previousItem: {
        shortcut: "left",
        description: "Previous item",
      },
      nextItem: {
        shortcut: "right",
        description: "Next item",
      },
      resetZoom: {
        shortcut: "r",
        description: "Reset zoom",
      },
      zoomIn: {
        shortcut: "mouse wheel up",
        description: "Zoom in",
      },
      zoomOut: {
        shortcut: "mouse wheel down",
        description: "Zoom out",
      },
      compare: {
        shortcut: "c",
        description: "Toggle comparison slider",
      },
      zoomInTextureViewer: {
        shortcut: "ArrowUp",
        description: "Zoom In (Texture)",
      },
      zoomOutTextureViewer: {
        shortcut: "ArrowDown",
        description: "Zoom Out (Texture)",
      },
      enterTags: {
        shortcut: "t",
        description: "Tag",
      },
      download: {
        shortcut: "d",
        description: "Download",
      },
      delete: {
        shortcut: ["delete", "backspace"],
        description: "Delete",
      },
      enhance: {
        shortcut: "e",
        description: "Enhance",
      },
    },
  },
} satisfies Record<string, AppShortcutCategory | ShortcutItem>;

export type AppShortcuts = typeof appShortcuts;

export type ShortcutItem = {
  shortcut: string | string[];
  description: string;
  action?: () => void;
  options?: Parameters<typeof useHotkeys>[2];
};

export type AppShortcutCategory = {
  title: string;
  shortcuts: {
    [key: string]: ShortcutItem;
  };
};
