import { useCallback, useEffect, useMemo, useState } from "react";
import { mapAssetsToJobs } from "domains/assets/utils/mapAssetsToJobs";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import useDeepMemo from "domains/commons/hooks/useDeepMemo";
import { mapAssetsToImagesFiles } from "domains/file-manager/interfaces";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetAssetsApiArg,
  GetAssetsByAssetIdApiResponse,
  useGetAssetsQuery,
} from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export type UseAllAssetsArgs = {
  modelId?: string;
  collectionId?: string;
  types: GetAssetsByAssetIdApiResponse["asset"]["metadata"]["type"][];
  inferenceId?: string;
  parent?: GetAssetsByAssetIdApiResponse["asset"];
  sortBy?: GetAssetsApiArg["sortBy"];
  sortDirection?: GetAssetsApiArg["sortDirection"];
  authorId?: string;
  createdBefore?: string;
};

export default function useAllAssets(props: UseAllAssetsArgs | undefined) {
  const {
    modelId,
    collectionId,
    types,
    inferenceId,
    parent,
    sortBy,
    sortDirection,
    authorId,
    createdBefore,
  } = props ?? {};
  const canIncludeType =
    !modelId && !collectionId && !inferenceId && !parent && !authorId;
  const { selectedTeam } = useTeamContext();
  const [paginationToken, setPaginationToken] = useState<string | undefined>();

  const partialRequestArgs = useDeepMemo(
    props
      ? {
          teamId: selectedTeam.id,
          pageSize: "100",
          modelId,
          collectionId,
          inferenceId,
          types: canIncludeType && types?.length ? types : undefined,
          parentAssetId: parent?.id,
          sortBy,
          sortDirection,
          authorId,
          createdBefore,
        }
      : skipToken
  );

  const debouncedRequestArgs = useDebounce<GetAssetsApiArg | typeof skipToken>(
    partialRequestArgs === skipToken
      ? skipToken
      : {
          ...partialRequestArgs,
          paginationToken,
        },
    100,
    {
      preventFor: (value) => value === undefined,
    }
  );

  useEffect(() => {
    setPaginationToken(undefined);
  }, [partialRequestArgs]);

  const {
    currentData: data,
    isLoading,
    isFetching,
  } = useGetAssetsQuery(debouncedRequestArgs);

  const loadMore = useCallback(() => {
    if (data?.nextPaginationToken) {
      setPaginationToken(data?.nextPaginationToken);
    }
  }, [data?.nextPaginationToken]);

  const { files, jobs } = useMemo(() => {
    let assets = data?.assets ?? [];
    if (!canIncludeType && types?.length) {
      assets = assets.filter((asset) => types.includes(asset.metadata.type));
      if (!assets.length && data?.nextPaginationToken) {
        setPaginationToken(data?.nextPaginationToken);
      }
    }

    const files = mapAssetsToImagesFiles(
      assets.filter((item) => item.metadata.type !== "canvas")
    );
    const jobs = mapAssetsToJobs(files);
    return { files, jobs };
  }, [data?.assets, data?.nextPaginationToken, canIncludeType, types]);

  return partialRequestArgs === skipToken || debouncedRequestArgs === skipToken
    ? {
        files: [],
        jobs: [],
        loadMore: () => {},
        hasMore: false,
        isLoading: partialRequestArgs !== skipToken,
      }
    : {
        files,
        jobs,
        loadMore,
        hasMore: !!data?.nextPaginationToken,
        isLoading:
          isLoading ||
          (isFetching && paginationToken === undefined) ||
          (!files.length && !!data?.nextPaginationToken),
      };
}
