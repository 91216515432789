import React from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import { MenuButton } from "@chakra-ui/react";

export interface ButtonSingleChoiceProps extends ButtonProps {
  text: string;
  onRemove?: () => void;
  dataTestId?: string;
  hasValue: boolean;
  variant?: "secondary" | "secondaryAlt" | "ghost";
  asMenuButton?: boolean;
}

const ButtonSingleChoise = React.forwardRef(function ButtonSingleChoice(
  {
    text,
    onRemove,
    dataTestId,
    hasValue,
    variant = "secondary",
    asMenuButton = false,
    ...props
  }: ButtonSingleChoiceProps,
  ref
) {
  const showRemove = hasValue && onRemove;
  const ButtonComponent = asMenuButton ? MenuButton : Button;

  return (
    <ButtonComponent
      ref={ref}
      as={asMenuButton ? Button : undefined}
      pr={showRemove ? 1 : 3}
      pl={3}
      color={variant === "secondaryAlt" ? undefined : "textSecondary"}
      textColor={variant === "secondaryAlt" ? undefined : "textSecondary"}
      borderWidth={variant === "secondaryAlt" ? undefined : 0}
      _hover={
        variant === "secondaryAlt"
          ? undefined
          : {
              textColor: "textPrimary",
              color: "textPrimary",
            }
      }
      data-testid={dataTestId}
      rightIcon={
        !showRemove ? (
          <Icon id="Ui/ChevronDownSm" color="inherit" />
        ) : (
          <Button
            leftIcon={<Icon id="Ui/CrossSm" color="inherit" />}
            onClick={(e) => {
              e.stopPropagation();
              onRemove?.();
            }}
            variant="ghost"
            colorScheme="white"
            _hover={{
              bg: "whiteAlpha.300",
              color: "textPrimary",
            }}
            p={2}
            ml={-1}
            borderRadius="base"
            color="inherit"
            minW="unset"
            h="unset"
          />
        )
      }
      size="sm"
      variant="secondary"
      {...(variant === "ghost"
        ? {
            bgColor: "transparent",
          }
        : {})}
      {...props}
    >
      {text}
    </ButtonComponent>
  );
});

export default ButtonSingleChoise;
