import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetFilterAuthor from "domains/assets/hooks/useAssetAuthor";
import useAssetFilterType from "domains/assets/hooks/useAssetFilterType";
import { useAssetsByIds } from "domains/assets/hooks/useAssetsByIds";
import useAssetView from "domains/assets/hooks/useAssetView";
import { mapAssetTypeToJobType } from "domains/assets/utils/mapAssetsToJobs";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import { FileManagerImageProps } from "domains/file-manager/components/FileManagerImage";
import { FilterAuthor } from "domains/file-manager/components/FileManagerImage/HeaderFilterAuthor";
import { FilterAssetTypeKey } from "domains/file-manager/constants/AssetFilter";
import {
  getPlaceholderImageFilesFromJob,
  mapAssetsToImagesFiles,
} from "domains/file-manager/interfaces";
import { FmFileImage, FmJobImage } from "domains/file-manager/interfacesV2";
import { getAssetNumberFromJob, getSizeFromJob } from "domains/jobs/utils";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import useWebsocketMessage from "domains/websocket/hooks/useWebsocketMessage";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
  useLazyGetJobIdQuery,
  usePostJobActionByJobIdMutation,
} from "infra/api/generated/api";
import _ from "lodash";
import moment from "moment";

type JobSessionContextValue = {
  files: FmFileImage[] | undefined;
  jobs: FmJobImage[] | undefined;
  isLoading: boolean;
  cancelJob: (jobId: string) => void;
  addJob: (job: GetJobIdApiResponse["job"]) => void;
  fileManagerProps: Pick<
    FileManagerImageProps,
    | "view"
    | "onViewChange"
    | "headerFilterAssetProps"
    | "headerFilterAuthorProps"
    | "headerOnClearSession"
    | "isLoading"
    | "hasMore"
    | "onEndReached"
    | "isVaryEnabled"
    | "onDelete"
    | "onUndoDelete"
  >;
};

export const JobSessionContext = createContext<JobSessionContextValue>({
  files: undefined,
  jobs: undefined,
  isLoading: false,
  cancelJob: () => {},
  addJob: () => {},
  fileManagerProps: {
    view: "jobs",
  },
});

// ------------------------------------

const getIsJobCompleted = (job: GetJobIdApiResponse["job"]) =>
  ["canceled", "failure", "success"].includes(job.status);

// ------------------------------------

interface JobSessionProviderProps extends PropsWithChildren {
  forcedFilterTypeOptions?: FilterAssetTypeKey[];
  forcedFilterTypeValues?: FilterAssetTypeKey[];
  forcedFilterAuthorValue?: FilterAuthor;
  isEmptySessionStart?: boolean;
}

export function JobSessionProvider({
  children,
  forcedFilterTypeOptions,
  forcedFilterTypeValues,
  forcedFilterAuthorValue,
  isEmptySessionStart = false,
}: JobSessionProviderProps) {
  const { selectedTeam } = useTeamContext();
  const [runActionOnJobTrigger] = usePostJobActionByJobIdMutation();
  const handleApiError = useHandleApiError();
  const { successToast } = useScenarioToast();
  const [addedJobs, setAddedJobs] = useState<GetJobIdApiResponse["job"][]>([]);
  const [assetsFromWs, setAssetsFromWs] = useState<{
    [key: string]: GetAssetsByAssetIdApiResponse["asset"];
  }>({});
  const intervals = useRef<{ [key: string]: any }>({});
  const [getJobTrigger] = useLazyGetJobIdQuery();
  const [assetIdsForBulk, setAssetIdsForBulk] = useState<string[]>([]);
  const [deletedAssetIds, setDeletedAssetIds] = useState<string[]>([]);
  const [hasClearedSession, setHasClearedSession] =
    useState(isEmptySessionStart);
  const [createdBefore] = useState<string>(moment().format());

  const { assetsByIds } = useAssetsByIds({
    assetIds: assetIdsForBulk,
    originalAssets: false,
  });

  const { view, onViewChange } = useAssetView();
  const { allAssetsTypeArgs, fmHeaderFilterAssetProps } = useAssetFilterType({
    forcedOptions: forcedFilterTypeValues
      ? undefined
      : forcedFilterTypeOptions ?? ["image:inference"],
    forcedValues: forcedFilterTypeValues,
  });
  const { authorQueryArgs, fmHeaderFilterAuthorProps } = useAssetFilterAuthor({
    forcedValue: forcedFilterAuthorValue,
  });

  const { files, jobs, loadMore, hasMore, isLoading } = useAllAssets(
    hasClearedSession
      ? undefined
      : {
          ...allAssetsTypeArgs,
          ...authorQueryArgs,
          createdBefore,
        }
  );

  const onDelete = useCallback((files: FmFileImage[]) => {
    setDeletedAssetIds((ids) => [...ids, ...files.map((file) => file.id)]);
  }, []);
  const onUndoDelete = useCallback((files: FmFileImage[]) => {
    setDeletedAssetIds((ids) =>
      ids.filter((id) => !files.map((file) => file.id).includes(id))
    );
  }, []);

  const onClearSession = useCallback(() => {
    setHasClearedSession(true);
    setAddedJobs([]);
    setAssetsFromWs({});
    for (const interval of Object.values(intervals.current)) {
      clearInterval(interval);
    }
    intervals.current = {};
    setDeletedAssetIds([]);
    setAssetIdsForBulk([]);
  }, []);

  const handleWebSocketMessage = useCallback(async (message: any) => {
    const { asset } = message.payload;
    if (!asset) return;

    setAssetsFromWs((assets) => ({
      ...assets,
      [asset.id]: asset,
    }));
  }, []);
  useWebsocketMessage(`asset.created`, handleWebSocketMessage);

  // ----------------------------------

  const cancelJob = useCallback(
    async (jobId: string) => {
      try {
        await runActionOnJobTrigger({
          teamId: selectedTeam.id,
          jobId,
          body: {
            action: "cancel",
          },
        }).unwrap();
        setAddedJobs((jobs) => jobs.filter((job) => job.jobId !== jobId));
        successToast({ title: "Generation has been canceled" });
      } catch (error) {
        handleApiError(error, "Unable to cancel generation");
      }
    },
    [
      handleApiError,
      runActionOnJobTrigger,
      selectedTeam.id,
      successToast,
      setAddedJobs,
    ]
  );

  // ----------------------------------

  const addJob = useCallback(
    (job: GetJobIdApiResponse["job"]) => {
      setAddedJobs((jobs) => [job, ...jobs]);
      if (getIsJobCompleted(job)) {
        setAssetIdsForBulk((ids) => [...ids, ...(job.metadata.assetIds ?? [])]);
      }
      if (
        authorQueryArgs.authorId !== undefined &&
        job.authorId !== authorQueryArgs.authorId
      ) {
        fmHeaderFilterAuthorProps?.onChange("me");
      }
    },
    [setAddedJobs, fmHeaderFilterAuthorProps, authorQueryArgs]
  );

  // it's debounced in useAllAssets so we'll debounce it here too
  const debouncedFilterTypes = useDebounce<typeof allAssetsTypeArgs>(
    allAssetsTypeArgs,
    100
  );
  const jobsWeWantToAdd = useMemo(() => {
    return addedJobs
      .filter(
        (job) =>
          (!debouncedFilterTypes.types.length ||
            debouncedFilterTypes.types.some((type) => {
              const jobType = mapAssetTypeToJobType(type);
              return Array.isArray(jobType)
                ? jobType.includes(job.jobType)
                : jobType === job.jobType;
            })) &&
          (authorQueryArgs.authorId === undefined ||
            authorQueryArgs.authorId === job.authorId)
      )
      .map((job) => ({
        id: job.jobId,
        title: (job.metadata.input as any)?.prompt ?? "",
        job,
        files: _.compact(
          _.range(getAssetNumberFromJob(job)).map((idx) => {
            const id = (job.metadata.assetIds ?? [])[idx];
            if (deletedAssetIds.includes(id)) return undefined;

            return (
              (id && assetsByIds[id]) ||
              (id &&
                _.keys(assetsFromWs).includes(id) &&
                mapAssetsToImagesFiles([assetsFromWs[id]])[0]) ||
              getPlaceholderImageFilesFromJob({
                job,
                amount: 1 + idx,
                ...getSizeFromJob(job),
              })[idx]
            );
          })
        ).sort(
          (a, b) => Date.parse(a.meta.createdAt) - Date.parse(b.meta.createdAt)
        ),
      }))
      .filter((job) => job.files.length !== 0);
  }, [
    addedJobs,
    assetsByIds,
    assetsFromWs,
    deletedAssetIds,
    debouncedFilterTypes,
    authorQueryArgs,
  ]);

  useEffect(() => {
    const processingJobs = addedJobs.filter((file) => !getIsJobCompleted(file));
    if (processingJobs.length === 0) return;

    for (const job of processingJobs) {
      if (intervals.current[job.jobId] !== undefined) return;
      intervals.current[job.jobId] = setInterval(async () => {
        const { data: jobData } = await getJobTrigger({
          teamId: selectedTeam.id,
          jobId: job.jobId,
        });
        const updatedJob = jobData?.job;
        if (!updatedJob) return;

        setAddedJobs((jobs) =>
          jobs.map((j) => (j.jobId === updatedJob.jobId ? updatedJob : j))
        );

        if (getIsJobCompleted(updatedJob)) {
          clearInterval(intervals.current[job.jobId]);
          delete intervals.current[job.jobId];
          setAssetIdsForBulk((ids) => [
            ...ids,
            ...(updatedJob.metadata.assetIds ?? []),
          ]);
        }
      }, 3_000);
    }
  }, [addedJobs, getJobTrigger, selectedTeam.id]);

  // ----------------------------------

  const fileManagerProps = useMemo<JobSessionContextValue["fileManagerProps"]>(
    () => ({
      view,
      onViewChange,
      headerFilterAssetProps: fmHeaderFilterAssetProps,
      headerFilterAuthorProps: fmHeaderFilterAuthorProps,
      headerOnClearSession: {
        onClear: onClearSession,
        isDisabled: !(files.length || jobsWeWantToAdd.length),
      },
      isLoading,
      hasMore,
      onEndReached: loadMore,
      onDelete,
      onUndoDelete,
      isVaryEnabled: true,
    }),
    [
      fmHeaderFilterAssetProps,
      fmHeaderFilterAuthorProps,
      onViewChange,
      view,
      isLoading,
      hasMore,
      loadMore,
      onDelete,
      onUndoDelete,
      onClearSession,
      files,
      jobsWeWantToAdd,
    ]
  );

  const contextValue = useMemo(
    () => ({
      files:
        view === "jobs"
          ? undefined
          : [
              ...jobsWeWantToAdd.reduce(
                (acc, job) => [...acc, ...job.files],
                [] as FmFileImage[]
              ),
              ...files,
            ],
      jobs: view === "jobs" ? [...jobsWeWantToAdd, ...jobs] : undefined,
      isLoading,
      cancelJob,
      addJob,
      fileManagerProps,
    }),
    [
      view,
      files,
      jobsWeWantToAdd,
      jobs,
      isLoading,
      cancelJob,
      addJob,
      fileManagerProps,
    ]
  );

  return (
    <JobSessionContext.Provider value={contextValue}>
      {children}
    </JobSessionContext.Provider>
  );
}

export function useJobSessionContext() {
  return useContext<JobSessionContextValue>(JobSessionContext);
}
