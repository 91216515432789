import { TOP_BAR_HEIGHT } from "domains/navigation/constants/TopBar";

import { Flex, FlexProps, HStack, Spinner, Text } from "@chakra-ui/react";

import FmHeaderColumn from "./FmHeaderColumn";

export type FmHeaderProps = {
  title?: React.ReactNode;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  styleProps?: FlexProps;
  isLoading?: boolean;

  columnsCount?: number;
  onColumnsCountChange?: (columnsCount: number) => void;
};

export default function FmHeader({
  title,
  leftContent,
  rightContent,
  styleProps,
  isLoading,

  columnsCount,
  onColumnsCountChange,
}: FmHeaderProps) {
  return (
    <Flex
      pos="sticky"
      zIndex="sticky"
      top={`${TOP_BAR_HEIGHT}px`}
      align="center"
      justify="space-between"
      bg="background.500"
      {...styleProps}
    >
      <HStack flex={1} spacing={2}>
        {!!title && <Text size="body.bold.lg">{title}</Text>}

        {leftContent}
      </HStack>

      <HStack my={2} spacing={1}>
        {isLoading && <Spinner size="sm" />}

        {onColumnsCountChange && (
          <FmHeaderColumn
            value={columnsCount ?? 3}
            onChange={onColumnsCountChange}
          />
        )}

        {rightContent}
      </HStack>
    </Flex>
  );
}
