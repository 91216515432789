import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import useDeepMemo from "domains/commons/hooks/useDeepMemo";
import { FmListProps } from "domains/file-manager/components/FileManagerV2/FmList";
import { useAvoidFlicker } from "domains/file-manager/hooks/useAvoidFlicker";
import { useOptimizedAssetUrl } from "domains/file-manager/hooks/useOptimizedAssetUrl";
import { FmFileImage, FmJobImage } from "domains/file-manager/interfacesV2";
import { getShouldHaveNsfwBlur } from "domains/file-manager/utils/getShouldHaveNsfwBlur";
import { getTypeLabelFromJob } from "domains/jobs/utils";
import { useHover } from "domains/ui/hooks/useHover";
import { useWindowSize } from "domains/ui/hooks/useWindowSize";
import { useUserContext } from "domains/user/contexts/UserProvider";
import _ from "lodash";
import {
  useContainerPosition,
  useInfiniteLoader,
  useMasonry,
  usePositioner,
  useResizeObserver,
} from "masonic";
import { useScroller } from "mini-virtual-list";
import moment from "moment";

import {
  Box,
  HStack,
  SimpleGrid,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

import Card from "../Card";
import { ActionType, FmImageCardActionsProps } from "../Card/Actions";
import CardContainer from "../Card/Container";
import { CardContentProps } from "../Card/Content";

import { FmImageListActionsProps } from "./Actions";
import CancelButton from "./CancelButton";

export type ListCustomProps<
  L extends object = object,
  C extends object = object
> = {
  variant?: "image" | "skybox" | "texture";
  onMoreClick?: (key: string, value: any) => void;
  isVaryEnabled?: boolean;

  listActionsProps: L;
  ListActionsComponent?: React.FunctionComponent<FmImageListActionsProps<L>>;

  pinnedFileIds?: string[];
  onCardPinClick?: CardContentProps["onPinClick"];

  isCardWithoutActions?: boolean;
  cardActionsProps: C;
  CardActionsComponent?: React.FunctionComponent<FmImageCardActionsProps<C>>;
  onActionClick?: (type: ActionType) => void;
};

export type ListProps<
  L extends object = object,
  C extends object = object
> = FmListProps<"image", ListCustomProps<L, C>>;

export default function List<
  L extends object = object,
  C extends object = object
>({
  jobs,
  variant = "image",
  onMoreClick,
  isVaryEnabled = false,
  onEndReached,
  scrollRef,
  containerRef,
  ..._listItemProps
}: ListProps<L, C>) {
  const listItemProps = useDeepMemo(_listItemProps);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [initialCardWidth, setInitialCardWidth] = useState<
    number | undefined
  >();
  const [cardMaxWidth, setCardMaxWidth] = useState<number | undefined>();
  const { offset, width: containerWidth } = useContainerPosition(containerRef, [
    windowWidth,
    windowHeight,
  ]);
  const { scrollTop } = useScroller(scrollRef ?? window, {
    offset,
  });

  // check if files[0] has changed
  const fileZero = jobs[0]?.files[0];
  const [firstFile, setFirstFile] = useState(fileZero);
  const willChangeOnlyIfFirstFileChanged = useMemo(() => {
    if (fileZero.id !== firstFile.id) {
      return {};
    }
    setFirstFile(fileZero);
    // NOTE: ignoring firstFile to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // firstFile
    fileZero,
  ]);

  const [jobsLength, setJobsLength] = useState(jobs.length);
  /** Used to reset positions of already existing files, when we delete files, but not when new files are loaded */
  const willChangeOnlyIfLessFilesThanBefore = useMemo(() => {
    if (jobs.length < jobsLength) {
      return {};
    }
    setJobsLength(jobs.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs.length]);

  const positioner = usePositioner(
    {
      width: containerWidth,
      rowGutter: 40,
      columnCount: 1,
    },
    [
      containerWidth,
      listItemProps.columnsCount,
      willChangeOnlyIfLessFilesThanBefore,
      willChangeOnlyIfFirstFileChanged,
    ]
  );
  const resizeObserver = useResizeObserver(positioner);

  const columnWidth = useMemo(
    () =>
      containerWidth
        ? Math.ceil(
            (containerWidth - (listItemProps.columnsCount - 1) * 8) /
              listItemProps.columnsCount
          )
        : 0,
    [containerWidth, listItemProps.columnsCount]
  );

  // ----------------------------------

  const handleEndReachCheck = useInfiniteLoader(async () => onEndReached?.(), {
    isItemLoaded: (index, items) => !!items[index],
  });

  // ----------------------------------

  useEffect(() => {
    if (columnWidth < 40) return;

    if (!cardMaxWidth || columnWidth > cardMaxWidth) {
      setCardMaxWidth(columnWidth);
    }

    if (!initialCardWidth) {
      setInitialCardWidth(columnWidth);
    }
  }, [columnWidth, initialCardWidth, cardMaxWidth]);

  // ----------------------------------

  const ListItemWithProps = useCallback(
    (props: Omit<ListItemProps<L, C>, keyof ListItemCommonProps<L, C>>) => {
      const file = props.data.files[0];
      const cardHeight =
        (file.height ?? 512) * (columnWidth / (file.width ?? 512));
      const rowCount = Math.ceil(
        props.data.files.length / listItemProps.columnsCount
      );
      return (
        <ListItem
          key={props.data.id}
          variant={variant}
          isVaryEnabled={isVaryEnabled}
          columnWidth={columnWidth}
          cardHeight={!columnWidth ? 0 : cardHeight}
          cardMaxWidth={
            initialCardWidth && cardMaxWidth ? cardMaxWidth : undefined
          }
          itemHeight={
            !columnWidth ? 0 : rowCount * cardHeight + (rowCount - 1) * 8 + 52
          }
          containerRef={containerRef}
          onMoreClick={onMoreClick}
          {...listItemProps}
          {...props}
        />
      );
    },
    [
      columnWidth,
      listItemProps,
      variant,
      isVaryEnabled,
      initialCardWidth,
      cardMaxWidth,
      containerRef,
      onMoreClick,
    ]
  );

  const masonry = useAvoidFlicker(
    useMasonry({
      scrollTop,
      positioner,
      resizeObserver,
      items: jobs,
      onRender: handleEndReachCheck,
      className: "masonic",
      itemKey: (data) => data.id,
      overscanBy: 2,
      height: windowHeight || 0,
      render: ListItemWithProps,
    })
  );

  return <Box sx={{ ".masonic": { outline: "none" } }}>{masonry}</Box>;
}

// ------------------------------------

type ListItemCommonProps<
  L extends object = object,
  C extends object = object
> = Omit<ListProps<L, C>, "jobs" | "scrollRef"> & {
  variant: "image" | "skybox" | "texture";
  isVaryEnabled?: boolean;
  columnWidth: number;
  cardMaxWidth: number | undefined;
  cardHeight: number;
  itemHeight: number;
};

type ListItemProps<
  L extends object = object,
  C extends object = object
> = ListItemCommonProps<L, C> & {
  index: number;
  width: number;
  data: FmJobImage & {
    files: FmFileImage[];
  };
};

function ListItemWithoutMemo<
  L extends object = object,
  C extends object = object
>({
  variant,
  isVaryEnabled = false,
  columnsCount,
  columnWidth,
  cardHeight,
  cardMaxWidth,
  itemHeight,
  containerRef,
  isSelectionForced,
  isSelectionMaxReached,
  selection,
  onSelect,
  onCardClick,
  listActionsProps,
  ListActionsComponent,
  pinnedFileIds,
  onCardPinClick,
  isCardWithoutActions,
  cardActionsProps,
  CardActionsComponent,
  onActionClick,
  data: { id, title, job, files },
}: ListItemProps<L, C>) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const now = Math.round(Date.now() / 1_000);
  const timeStartAt = useMemo(
    () => Math.round(new Date(job.createdAt).getTime() / 1_000),
    [job.createdAt]
  );
  const diffFromNow = useMemo(
    () => moment.utc(moment(now * 1_000).diff(moment(timeStartAt * 1_000))),
    [timeStartAt, now]
  );

  const firstAsset = files[0]?.meta;
  const type = getTypeLabelFromJob(job);
  const isWaiting = ["warming-up", "queued"].includes(job.status);
  const isWarmingUp = job.status === "warming-up";
  const isQueued = job.status === "queued";
  const queuePosition =
    job.status === "queued"
      ? ((job.metadata as any)?.queue?.approximatePosition as number)
      : undefined;
  const waitingTime = useMemo(
    () => diffFromNow.format("HH:mm:ss").replace(/^00:/, ""),
    [diffFromNow]
  );
  const isActionsDisplayed = ["success", "failure"].includes(job?.status ?? "");

  // ----------------------------------

  useEffect(() => {
    if (!isWaiting) return;

    const interval = setInterval(() => forceUpdate(), 1_000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isWaiting]);

  // ----------------------------------

  return (
    <Box key={id} pos="relative" h={`${itemHeight || 500}px`}>
      <VStack pos="relative" align="stretch" h="full" spacing={4}>
        <HStack spacing={10}>
          <HStack flex={1} overflow="hidden" spacing={2}>
            <Box>
              <Tag
                whiteSpace="nowrap"
                cursor="default"
                colorScheme="primary"
                data-testid="job-tag"
              >
                {type}
              </Tag>
            </Box>

            {isWarmingUp && (
              <Box whiteSpace="nowrap">
                <Tooltip label="Please wait while the model is being activated.">
                  <Tag cursor="default" colorScheme="warning">
                    {`Warming Up ${waitingTime}`}
                  </Tag>
                </Tooltip>
              </Box>
            )}

            {isQueued && (
              <Box whiteSpace="nowrap">
                <Tag cursor="default" colorScheme="primary">
                  {_.compact([
                    `Queued ${waitingTime}`,
                    queuePosition !== undefined && `(${queuePosition} ahead)`,
                  ]).join(" ")}
                </Tag>
              </Box>
            )}

            <Tooltip
              isDisabled={title.length < 60}
              label={title}
              placement="bottom-start"
            >
              <Text isTruncated size="body.md">
                {title}
              </Text>
            </Tooltip>
          </HStack>

          <HStack minH="36px">
            {!!(ListActionsComponent && isActionsDisplayed) && (
              <ListActionsComponent
                job={job}
                firstAsset={firstAsset}
                {...listActionsProps}
              />
            )}
            <CancelButton job={job} />
          </HStack>
        </HStack>

        <SimpleGrid gap={2} columns={columnsCount}>
          {files.map((file) => (
            <MemoizedListItemCard
              key={file.id}
              file={file}
              variant={variant}
              isVaryEnabled={isVaryEnabled}
              cardWidth={columnWidth}
              cardHeight={cardHeight}
              cardMaxWidth={cardMaxWidth}
              isSelectable
              isSelectionForced={isSelectionForced}
              isSelectionMaxReached={isSelectionMaxReached}
              selection={selection}
              onSelect={onSelect}
              onCardClick={onCardClick}
              containerRef={containerRef}
              isPinned={pinnedFileIds?.includes(file.id)}
              onPinClick={onCardPinClick}
              isWithoutActions={isCardWithoutActions}
              actionsProps={cardActionsProps}
              ActionsComponent={CardActionsComponent}
              onActionClick={onActionClick}
            />
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
}

const ListItem = React.memo(ListItemWithoutMemo) as typeof ListItemWithoutMemo;

// ------------------------------------

type ListItemCardProps<C extends object = object> = Pick<
  ListItemProps,
  | "variant"
  | "isVaryEnabled"
  | "isSelectable"
  | "isSelectionForced"
  | "isSelectionMaxReached"
  | "selection"
  | "onSelect"
  | "onCardClick"
  | "containerRef"
> & {
  file: FmFileImage;
  cardWidth: number;
  cardMaxWidth?: number;
  cardHeight: number;
  isPinned?: boolean;
  onPinClick?: CardContentProps["onPinClick"];
  isWithoutActions?: boolean;
  actionsProps: C;
  ActionsComponent?: React.FunctionComponent<FmImageCardActionsProps<C>>;
  onActionClick?: (type: ActionType) => void;
};

function ListItemCard<C extends object = object>({
  file,
  variant,
  isVaryEnabled = false,
  cardWidth,
  cardHeight,
  cardMaxWidth,
  isSelectable = false,
  isSelectionForced = false,
  isSelectionMaxReached = false,
  selection,
  onSelect,
  onCardClick,
  isPinned = false,
  onPinClick,
  containerRef,
  isWithoutActions = false,
  actionsProps,
  ActionsComponent,
  onActionClick,
}: ListItemCardProps<C>) {
  const { nsfwFilteredTypes, nsfwRevealedAssetIds } = useUserContext();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const isSuccess = file.status === "success";

  const { url: maxResUrl } = useOptimizedAssetUrl({
    imageFile: file,
    cardWidth: cardMaxWidth ?? cardWidth,
  });

  const thumbnailUrl = (isSuccess && maxResUrl) || "";
  const lowResThumbnailUrl = isSuccess
    ? `${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/${file.id}`
    : undefined;
  const isSelected = selection.includes(file.id);
  const isDisabled = !!isSelectionMaxReached && !selection.includes(file.id);
  const isBlurred =
    getShouldHaveNsfwBlur({
      nsfwFilteredTypes,
      nsfw: file.meta.nsfw,
    }) && !nsfwRevealedAssetIds.includes(file.id);

  return (
    <Box ref={hoverRef} pos="relative">
      <CardContainer
        variant={variant}
        file={file}
        thumbnail={thumbnailUrl}
        lowResThumbnail={lowResThumbnailUrl}
        isHovered={isHovered}
        isDisabled={isDisabled}
        isBlurred={isBlurred}
        isSelectable={isSuccess && isSelectable && !isDisabled}
        isShiftSelectable={isSelectionForced || !!selection.length}
        isSelected={isSelected}
        onSelect={isDisabled ? () => {} : onSelect}
        onClick={isSuccess ? onCardClick : undefined}
        containerProps={{
          minH: "30px",
          ...(cardHeight
            ? { h: `${cardHeight}px`, pt: 0 }
            : {
                h: "auto",
                pt: "100%",
              }),
        }}
      >
        <Card
          file={file}
          variant={variant}
          isVaryEnabled={isVaryEnabled}
          cardWidth={cardWidth}
          containerRef={containerRef}
          isBlurred={isBlurred}
          isHovered={isHovered}
          isPinned={isPinned}
          onClick={isSuccess ? onCardClick : undefined}
          onPinClick={onPinClick}
          isWithoutActions={isWithoutActions}
          actionsProps={actionsProps}
          ActionsComponent={ActionsComponent}
          onActionClick={onActionClick}
        />
      </CardContainer>
    </Box>
  );
}

const MemoizedListItemCard = React.memo(ListItemCard) as typeof ListItemCard;
