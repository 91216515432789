import { useMemo, useState } from "react";
import {
  gridSorts,
  GridSortValue,
} from "domains/file-manager/constants/GridSort";

export const ASSET_SORT_OPTIONS: GridSortValue[] = [
  "createdDesc",
  "createdAsc",
];

export default function useAssetSort() {
  const [sort, setSort] = useState<(typeof ASSET_SORT_OPTIONS)[number]>(
    ASSET_SORT_OPTIONS[0]
  );

  const fmHeaderSortProps = useMemo(
    () => ({
      value: sort,
      onChange: setSort,
    }),
    [sort, setSort]
  );

  return {
    allAssetsSortArgs: {
      sortBy: gridSorts[sort].value[0],
      sortDirection: gridSorts[sort].value[1],
    },
    assetFileManagerSortOptions: {
      sort,
      sortOptions: ASSET_SORT_OPTIONS,
      onSortChange: setSort,
    },
    sortQueryArgs: {
      sortBy: gridSorts[sort].value[0],
      sortDirection: gridSorts[sort].value[1],
    },
    fmHeaderSortProps,
  };
}
