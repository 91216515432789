import { useMemo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { reverseStrength } from "domains/assets/utils";
import { isAssetSkybox } from "domains/assets/utils/isType";
import { getImageThumbnail } from "domains/file-manager/interfaces";
import { getShouldHaveNsfwBlur } from "domains/file-manager/utils/getShouldHaveNsfwBlur";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  AsideList,
  AsideListItem,
  AsideSection,
} from "domains/ui/components/Aside";
import { useUserContext } from "domains/user/contexts/UserProvider";
import {
  GetAssetsApiResponse,
  GetAssetsByAssetIdApiResponse,
} from "infra/api/generated/api";
import { useGetAssetsBulkQuery } from "infra/store/apiSlice";
import _ from "lodash";

import { Box, Center, Image, SimpleGrid } from "@chakra-ui/react";

export interface AsideSectionRefsProps {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function AsideSectionRefs({ asset }: AsideSectionRefsProps) {
  const { selectedTeam } = useTeamContext();

  const structureAssetId = asset?.metadata?.structureImage;
  const cannyStructureAssetId = asset?.metadata?.cannyStructureImage;
  const depthAssetId = asset?.metadata?.depthImage;
  const img2imgAssetId = asset?.metadata?.image;
  const styleAssetIds = useMemo(
    () => asset?.metadata?.styleImages ?? [],
    [asset?.metadata?.styleImages]
  );
  const secondaryRefAssetIds = useMemo(
    () => asset?.metadata?.secondaryRefImages ?? [],
    [asset?.metadata?.secondaryRefImages]
  );

  const refImageIds = useMemo(() => {
    return _.compact([
      structureAssetId,
      cannyStructureAssetId,
      depthAssetId,
      img2imgAssetId,
      ...styleAssetIds,
      ...secondaryRefAssetIds,
    ]);
  }, [
    structureAssetId,
    cannyStructureAssetId,
    depthAssetId,
    img2imgAssetId,
    styleAssetIds,
    secondaryRefAssetIds,
  ]);

  const { data: refAssets } = useGetAssetsBulkQuery({
    teamId: selectedTeam.id,
    body: {
      assetIds: refImageIds,
    },
  });

  const structureAsset = useMemo(() => {
    return refAssets
      ? refAssets.assets.find((refAsset) => refAsset.id === structureAssetId)
      : undefined;
  }, [refAssets, structureAssetId]);
  const cannyStructureAsset = useMemo(() => {
    return refAssets
      ? refAssets.assets.find(
          (refAsset) => refAsset.id === cannyStructureAssetId
        )
      : undefined;
  }, [refAssets, cannyStructureAssetId]);
  const structureOrCannyAsset = structureAsset || cannyStructureAsset;

  const depthAsset = useMemo(() => {
    return refAssets
      ? refAssets.assets.find((refAsset) => refAsset.id === depthAssetId)
      : undefined;
  }, [refAssets, depthAssetId]);

  const img2imgAsset = useMemo(() => {
    return refAssets
      ? refAssets.assets.find((refAsset) => refAsset.id === img2imgAssetId)
      : undefined;
  }, [refAssets, img2imgAssetId]);

  const styleAssets = useMemo(() => {
    return refAssets
      ? refAssets.assets.filter((refAsset) =>
          styleAssetIds.includes(refAsset.id)
        )
      : [];
  }, [refAssets, styleAssetIds]);

  const secondaryRefAssets = useMemo(() => {
    return refAssets
      ? refAssets.assets.filter((refAsset) =>
          secondaryRefAssetIds.includes(refAsset.id)
        )
      : [];
  }, [refAssets, secondaryRefAssetIds]);

  return (
    <>
      {!!styleAssets.length && (
        <AsideSection id="styleImages" title="Style Images">
          <AsideList>
            <MultipleRefImages assets={styleAssets} />

            {asset?.metadata.type === "upscale" && (
              <AsideListItem
                label="Style Fidelity"
                value={
                  asset?.metadata.styleImagesFidelity !== undefined
                    ? Math.round(asset?.metadata.styleImagesFidelity)
                    : "-"
                }
              />
            )}

            {asset?.metadata.type === "restyle" && (
              <AsideListItem
                label="Style Fidelity"
                value={
                  asset?.metadata.styleFidelity !== undefined
                    ? Math.round(asset?.metadata.styleFidelity)
                    : "-"
                }
              />
            )}

            {asset?.metadata.type === "upscale" && (
              <AsideListItem
                label="Style Tiling"
                value={asset.metadata.tileStyle ? "Yes" : "No"}
              />
            )}

            {(asset?.metadata.type === "skybox-base-360" ||
              asset?.metadata.type === "upscale-skybox") && (
              <AsideListItem
                label="Style Fidelity"
                value={
                  asset?.metadata.styleFidelity !== undefined
                    ? Math.round(asset?.metadata.styleFidelity)
                    : "-"
                }
              />
            )}
          </AsideList>
        </AsideSection>
      )}

      {!!secondaryRefAssets.length && (
        <AsideSection id="secondaryRefImages" title="Secondary References">
          <AsideList>
            <MultipleRefImages assets={secondaryRefAssets} />

            {asset?.metadata.type === "restyle" && (
              <AsideListItem
                label="Style Fidelity"
                value={
                  asset?.metadata.secondaryRefFidelity !== undefined
                    ? Math.round(asset?.metadata.secondaryRefFidelity)
                    : "-"
                }
              />
            )}
          </AsideList>
        </AsideSection>
      )}

      {!!structureOrCannyAsset && isAssetSkybox(asset) && (
        <AsideSection id="structure" title="Reference">
          <AsideList>
            <SingleRefImage asset={structureOrCannyAsset} />
            <AsideListItem label="Type" value="Structure" />
            <AsideListItem
              label="Influence"
              value={asset?.metadata.structureFidelity ?? "-"}
              copyable
            />
          </AsideList>
        </AsideSection>
      )}

      {!!depthAsset && isAssetSkybox(asset) && (
        <AsideSection id="depth" title="Reference">
          <AsideList>
            <SingleRefImage asset={depthAsset} />
            <AsideListItem label="Type" value="Depth" />
            <AsideListItem
              label="Influence"
              value={asset?.metadata.depthFidelity ?? "-"}
              copyable
            />
          </AsideList>
        </AsideSection>
      )}

      {!!img2imgAsset && isAssetSkybox(asset) && (
        <AsideSection id="img2img" title="Reference">
          <AsideList>
            <SingleRefImage asset={img2imgAsset} />
            <AsideListItem label="Type" value="Image to Image" />
            <AsideListItem
              label="Influence"
              value={
                asset?.metadata.strength
                  ? Math.round(reverseStrength(asset.metadata.strength) * 100)
                  : "-"
              }
              copyable
            />
          </AsideList>
        </AsideSection>
      )}
    </>
  );
}

// ------------------------------------

interface SingleRefImageProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  withoutLink?: boolean;
}

function SingleRefImage({ asset, withoutLink }: SingleRefImageProps) {
  const router = useRouter();
  const { nsfwFilteredTypes } = useUserContext();

  const imgSrc = getImageThumbnail({
    url: asset.url,
    type: asset.metadata.type,
  });
  const internalLink = !withoutLink
    ? {
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: asset.id,
        },
      }
    : undefined;
  const isBlurred = getShouldHaveNsfwBlur({
    nsfwFilteredTypes,
    nsfw: asset.nsfw,
  });

  return (
    <SimpleGrid gap={2} columns={2}>
      <Center
        as={internalLink ? Link : undefined}
        pos="relative"
        overflow="hidden"
        w="100%"
        borderRadius="md"
        bgColor="backgroundQuaternary.500"
        href={internalLink}
      >
        <Image
          maxH="160px"
          borderRadius="md"
          alt="Image"
          fallback={<Box w="100%" h="160px" />}
          filter={isBlurred ? "blur(20px)" : undefined}
          src={imgSrc + "&width=256"}
        />
      </Center>
    </SimpleGrid>
  );
}

// ------------------------------------

interface MultipleRefImagesProps {
  assets: GetAssetsApiResponse["assets"];
}

function MultipleRefImages({ assets }: MultipleRefImagesProps) {
  const router = useRouter();
  const { nsfwFilteredTypes } = useUserContext();

  return (
    <SimpleGrid gap={2} columns={3}>
      {assets.map((asset) => (
        <Box key={asset.id}>
          <Link
            style={{
              width: "100%",
            }}
            href={{
              pathname: router.pathname,
              query: {
                ...router.query,
                openAssetId: asset.id,
              },
            }}
          >
            <Box
              pos="relative"
              overflow="hidden"
              w="100%"
              pt="100%"
              borderRadius="md"
            >
              <Image
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                objectFit="cover"
                alt="derived image"
                fallback={
                  <Box
                    pos="absolute"
                    top={0}
                    left={0}
                    w="100%"
                    h="100%"
                    borderRadius="md"
                    bgColor="background.500"
                  />
                }
                filter={
                  getShouldHaveNsfwBlur({
                    nsfwFilteredTypes,
                    nsfw: asset.nsfw,
                  })
                    ? "blur(20px)"
                    : undefined
                }
                src={
                  getImageThumbnail({
                    url: asset.url,
                    type: asset.metadata.type,
                  }) + "&width=128"
                }
              />
            </Box>
          </Link>
        </Box>
      ))}
    </SimpleGrid>
  );
}
