import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetFilterCollection from "domains/assets/hooks/useAssetFilterCollection";
import useAssetFilterType, {
  UseAssetFilterTypeWithAssetTypeArgs,
  UseAssetFilterTypeWithForcedOptionsArgs,
} from "domains/assets/hooks/useAssetFilterType";
import useAssetSort from "domains/assets/hooks/useAssetSort";
import useAssetView from "domains/assets/hooks/useAssetView";
import formatDate from "domains/canvas/utils/formatDate";
import { useClipboardContext } from "domains/commons/contexts/ClipboardProvider";
import { PersistedStateKey } from "domains/commons/hooks/usePersistedState";
import FileManagerImage from "domains/file-manager/components/FileManagerImage";
import SelectionBarSectionLeft from "domains/file-manager/components/SelectionBar/SectionLeft";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import ButtonDroppableUpload, {
  ButtonDroppableUploadProps,
} from "domains/image/components/ButtonDroppableUpload";
import { useAssetUpload } from "domains/image/hooks/useAssetUpload";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import SearchBar, {
  SearchQueryData,
} from "domains/search/components/SearchBar";
import { useAssetsSearch } from "domains/search/hooks/useSearch";
import Button from "domains/ui/components/Button";
import { AnalyticsEvents } from "infra/analytics/constants/Events";

import {
  Box,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";

export interface ModalLibraryAssetSelectProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect?: (asset: FmFileImage) => void;
  onMultiSelect?: (assets: FmFileImage[]) => void;
  modelId?: string;
  buttonDroppableUploadProps?: ButtonDroppableUploadProps & {
    onPaste: (image: FmFileImage) => void;
  };
  selectionMax?: number;
  libraryProps?:
    | UseAssetFilterTypeWithAssetTypeArgs
    | UseAssetFilterTypeWithForcedOptionsArgs;
}

export default function ModalLibraryAssetSelect({
  isOpen,
  onClose,
  onSelect,
  onMultiSelect,
  modelId,
  buttonDroppableUploadProps,
  selectionMax,
  libraryProps,
}: ModalLibraryAssetSelectProps) {
  const { addImagePasteListener, removeImagePasteListener } =
    useClipboardContext();
  const { uploadImage, isUploading } = useAssetUpload();
  const { successToast, errorToast } = useScenarioToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [assetSelection, setAssetSelection] = useState<FmFileImage[]>([]);
  const [searchAssetId, setSearchAssetId] = useState<string | undefined>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [searchQueryData, setSearchQueryData] = useState<SearchQueryData>({
    query: "",
    assetId: undefined,
  });

  const isWithCollectionFilter = modelId === undefined;
  const isMultiSelectable = !!onMultiSelect;
  const isInSearch = searchQueryData.query || searchQueryData.assetId;

  const fmViewProps = useAssetView({ options: ["masonry", "fill", "fit"] });
  const { sortQueryArgs, fmHeaderSortProps } = useAssetSort();
  const assetFilterCollection = useAssetFilterCollection();
  const { allAssetsCollectionArgs } = assetFilterCollection;
  const fmHeaderCollectionProps = useMemo(
    () =>
      isInSearch || !isWithCollectionFilter
        ? {}
        : assetFilterCollection.fmHeaderCollectionProps,
    [
      assetFilterCollection.fmHeaderCollectionProps,
      isInSearch,
      isWithCollectionFilter,
    ]
  );
  const { allAssetsTypeArgs, fmHeaderFilterAssetProps } = useAssetFilterType(
    libraryProps ?? {
      assetType: "image",
    }
  );

  const listData = useAllAssets({
    ...sortQueryArgs,
    ...allAssetsTypeArgs,
    ...(isWithCollectionFilter ? allAssetsCollectionArgs : {}),
    modelId,
  });
  const searchData = useAssetsSearch({
    ...searchQueryData,
    ...allAssetsTypeArgs,
    modelId,
  });

  const files = isInSearch ? searchData.files : listData.files;
  const isLoading = isInSearch ? searchData.isLoading : listData.isLoading;
  const loadMore = isInSearch ? searchData.loadMore : listData.loadMore;
  const hasMore = isInSearch ? searchData.hasMore : listData.hasMore;
  const loadingText = isInSearch ? "Searching..." : "Loading...";

  // ----------------------------------

  const handleSearch = useCallback(
    (searchData: SearchQueryData) => {
      setSearchQueryData(searchData);
    },
    [setSearchQueryData]
  );

  const handleSubmit = useCallback(() => {
    onClose();
    onMultiSelect?.(assetSelection);
  }, [onClose, onMultiSelect, assetSelection]);

  const handleSelectionChange = useCallback(
    (assets: FmFileImage[]) => {
      setAssetSelection(assets);
    },
    [setAssetSelection]
  );

  // ----------------------------------

  useEffect(() => {
    if (!buttonDroppableUploadProps || !isOpen) {
      return;
    }
    const onImagePastedCallback = async (images: string[]) => {
      if (images.length > 0) {
        const uploadedImage = await uploadImage({
          imageData: images[0],
          name: "reference-image-" + formatDate(Date.now()),
          trackingEvent: AnalyticsEvents.ImageLibrary.UploadedImage,
          apiErrorHandler: () => {
            errorToast({
              title: "Error importing image",
              description:
                "There was an error importing the image. Please try again.",
            });
          },
        });

        if (uploadedImage) {
          buttonDroppableUploadProps.onPaste(uploadedImage);
          onClose();
          successToast({
            title: "Image imported from clipboard",
          });
        }
      }
    };

    addImagePasteListener(onImagePastedCallback);
    return () => {
      removeImagePasteListener(onImagePastedCallback);
    };
  }, [
    addImagePasteListener,
    removeImagePasteListener,
    uploadImage,
    successToast,
    errorToast,
    buttonDroppableUploadProps,
    isOpen,
    onSelect,
    onClose,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="modern">
      <ModalOverlay />
      <ModalContent overflow="hidden" w="90vw" maxW="unset" h="80vh" p={0}>
        <ModalCloseButton />
        <ModalBody w="100%" id="pickImageModal">
          <VStack w="100%" h="80vh" spacing={0}>
            <Box w="100%" pr="80%" px={5} py={3} borderBottomWidth={1}>
              <Box w="100%" maxW="500px">
                <SearchBar
                  query={searchQuery}
                  setQuery={setSearchQuery}
                  assetId={searchAssetId}
                  setAssetId={setSearchAssetId}
                  onSearch={handleSearch}
                />
              </Box>
            </Box>

            <Box ref={scrollRef} flex={1} overflow="auto" w="100%" px={5}>
              <Box>
                {buttonDroppableUploadProps && (
                  <Box w="100%" mt={2}>
                    <ButtonDroppableUpload
                      {...buttonDroppableUploadProps}
                      isLoading={
                        buttonDroppableUploadProps.isLoading || isUploading
                      }
                    />
                  </Box>
                )}

                <FileManagerImage
                  scrollRef={scrollRef}
                  settingsCacheKey={
                    PersistedStateKey.FILE_MANAGER_IMAGE_SELECTION
                  }
                  files={files}
                  isLoading={isLoading}
                  hasMore={hasMore}
                  loadingText={loadingText}
                  onEndReached={loadMore}
                  isWithoutAssetZoom
                  isWithoutSelectionBar
                  isCardWithoutActions
                  isSelectable={isMultiSelectable}
                  isSelectionForced={isMultiSelectable}
                  selectionMax={selectionMax}
                  onSelectionChange={handleSelectionChange}
                  onCardClick={onSelect}
                  {...fmViewProps}
                  {...fmHeaderCollectionProps}
                  headerSortProps={!isInSearch ? fmHeaderSortProps : undefined}
                  headerFilterAssetProps={fmHeaderFilterAssetProps}
                  EmptyStateComponent={EmptyMessage}
                  styleProps={{
                    header: {
                      top: `0px`,
                      bg: "secondary.900",
                    },
                  }}
                />
              </Box>
            </Box>
          </VStack>
        </ModalBody>

        {isMultiSelectable && (
          <ModalFooter
            pos="absolute"
            bottom={0}
            left={0}
            w="100%"
            px={5}
            py={3}
            borderColor="border.500"
            borderTopWidth={1}
            bgColor="backgroundTertiary.500"
          >
            <HStack justify="space-between" w="100%" spacing={2}>
              <SelectionBarSectionLeft
                count={assetSelection.length}
                max={selectionMax}
              />

              <HStack spacing={2}>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  isDisabled={!assetSelection.length}
                  onClick={handleSubmit}
                >
                  Confirm
                </Button>
              </HStack>
            </HStack>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

function EmptyMessage() {
  return <Heading size="md">No results</Heading>;
}
