import { useCallback, useRef } from "react";

import { useDisclosure } from "@chakra-ui/react";

export default function useDisclosureDebounce(s: number) {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      onClose();
    }, s);
  }, [onClose, s]);

  const handleOpen = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    onOpen();
  }, [onOpen]);

  return { isOpen, onOpen: handleOpen, onClose: handleClose };
}
