import { ASSET_SORT_OPTIONS } from "domains/assets/hooks/useAssetSort";
import FmHeaderSort, {
  FmHeaderSortProps,
} from "domains/file-manager/components/FileManagerV2/FmHeaderSort";

export type HeaderSortProps = Omit<
  FmHeaderSortProps<typeof ASSET_SORT_OPTIONS>,
  "options"
>;

export default function HeaderSort(props: HeaderSortProps) {
  return <FmHeaderSort options={ASSET_SORT_OPTIONS} {...props} />;
}
