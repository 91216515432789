import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useRouter } from "next/router";
import ModelCollection from "domains/collections/components/ModelCollection";
import DefaultFilePreview, {
  FilePreviewProps,
} from "domains/file-manager/components/FilePreview";
import {
  Action,
  FileHandler,
  FileImageType,
  FileModelType,
  mapAssetsToImagesFiles,
} from "domains/file-manager/interfaces";
import BlendAddButton from "domains/models/components/Blend/BlendCreation/BlendAddButton";
import useModelTagsManager from "domains/models/hooks/useModelTagsManager";
import { DEFAULT_MODEL_THUMBNAIL } from "domains/models/utils";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import SelectionBarTags from "domains/tags/components/SelectionBarTags";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import ButtonWithModal from "domains/ui/components/ButtonWithModal";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  useDeleteModelsByModelIdMutation,
  useGetModelsByModelIdQuery,
  usePostAssetGetBulkMutation,
} from "infra/api/generated/api";
import { intersection } from "lodash";

import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/query";

import { useOptimizedAssetUrl } from "./useOptimizedAssetUrl";

interface FileModelHandlerArgs {
  emptyState?: JSX.Element;
  onAdd?: (file: FileModelType) => void;
  onUndoAdd?: (file: FileModelType) => void;
  conceptIdsWithoutAdd?: string[];
  disableNotReady?: boolean;
  onOpen?: () => void;
  onDelete?: (files: FileModelType[]) => void;
  onUndoDelete?: (files: FileModelType[]) => void;
}

export function useFileModelHandler({
  emptyState,
  onAdd,
  onUndoAdd,
  conceptIdsWithoutAdd,
  disableNotReady,
  onOpen,
  onDelete,
  onUndoDelete,
}: FileModelHandlerArgs): FileHandler<FileModelType> {
  const router = useRouter();
  const { successToast } = useScenarioToast();
  const { selectedTeam } = useTeamContext();
  const handleApiError = useHandleApiError();
  const [triggerDeleteModel, { isLoading: isDeleteModelLoading }] =
    useDeleteModelsByModelIdMutation();

  const { updateTags } = useModelTagsManager();

  const handleDelete = useCallback(
    async (files: FileModelType[]) => {
      try {
        onDelete?.(files);
        await Promise.all(
          files.map((file) => {
            void triggerDeleteModel({
              teamId: selectedTeam.id,
              modelId: file.id,
            });
            Track(AnalyticsEvents.Model.Deleted, {
              modelId: file.id,
            });
          })
        );
        successToast({
          title: "Model(s) deleted",
        });
      } catch (error) {
        onUndoDelete?.(files);
        handleApiError(error, "There was an error deleting the model(s)");
      }
    },
    [
      handleApiError,
      triggerDeleteModel,
      successToast,
      selectedTeam.id,
      onDelete,
      onUndoDelete,
    ]
  );

  // ----------------------------------

  const deleteAction = useMemo<Action<FileModelType>>(
    () => ({
      kind: ["selectionBar"],
      label: "Delete",
      onAction: handleDelete,
      Component: ({ files, onAction }) => (
        <ButtonWithModal
          variant="secondary"
          leftIcon={<Icon id="Ui/Trash" />}
          onConfirm={() => onAction(files)}
          modalHeader="Delete models"
          modalBody="Are you sure you want delete these models?"
          modalColorScheme="danger"
          isLoading={isDeleteModelLoading}
          isModalConfirmButtonLoading={isDeleteModelLoading}
          isDisabled={isDeleteModelLoading}
        >
          Delete
        </ButtonWithModal>
      ),
    }),
    [handleDelete, isDeleteModelLoading]
  );

  const actions = useMemo<Action<FileModelType>[]>(
    () => [
      deleteAction,
      {
        kind: ["selectionBar"],
        label: "Tags",
        onAction: (_) => {},
        Component: ({ files }) => {
          const tags = intersection(...files.map((file) => file.meta.tags));

          return (
            <SelectionBarTags
              compact
              tags={tags}
              files={files}
              updateTags={(fileId: string, newTags: string[]) => {
                const model = files.find((file) => file.id === fileId)?.meta!;
                void updateTags(model, newTags, false);
              }}
            />
          );
        },
      },
      {
        kind: ["selectionBar"],
        label: "Collections",
        onAction: (_) => {},
        Component: ({ files }) => (
          <ModelCollection
            models={files.map((item) => item.meta)}
            menuPlacement="top"
          />
        ),
      },
    ],
    [deleteAction, updateTags]
  );

  const FilePreview = useCallback(
    (props: FilePreviewProps<FileModelType>) => (
      <FileModelPreview
        {...props}
        onAdd={
          conceptIdsWithoutAdd?.includes(props.file.id) ? undefined : onAdd
        }
        onUndoAdd={
          conceptIdsWithoutAdd?.includes(props.file.id) ? onUndoAdd : undefined
        }
        disableNotReady={disableNotReady}
      />
    ),
    [onAdd, onUndoAdd, conceptIdsWithoutAdd, disableNotReady]
  );

  // ----------------------------------

  return {
    EmptyState: emptyState,
    actions,
    FilePreview,
    onOpen: (file) => {
      onOpen?.();
      void router.push({
        pathname: "/models/[id]",
        query: { id: file.id },
      });
    },
  };
}

type FileModelPreviewProps = FilePreviewProps<FileModelType> & {
  onAdd?: (file: FileModelType) => void;
  onUndoAdd?: (file: FileModelType) => void;
  disableNotReady?: boolean;
};

function FileModelPreview(props: FileModelPreviewProps) {
  const { selectedTeam } = useTeamContext();

  const id = props.file.id;
  const trainingImages = props.file.meta.trainingImages;
  const isCopying = props.file.meta.status === "copying";
  const isNonTrained = props.file.meta.status !== "trained";
  const isTraining = props.file.meta.status === "training";
  const isFeatured = props.file.meta.tags.includes("sc:featured");
  const isCompact = props.cardWidth < 120;

  useGetModelsByModelIdQuery(
    trainingImages === undefined &&
      props.file.thumbnail === DEFAULT_MODEL_THUMBNAIL
      ? { teamId: selectedTeam.id, modelId: id }
      : skipToken,
    {
      selectFromResult: ({ data }) => {
        return {
          actualTrainingImages: data?.model?.trainingImages ?? [],
        };
      },
    }
  );

  //// We can fix performance issues by removing ModelWithCarousel when scrolling (will be fix with the next FileManager implementation)

  return (
    <DefaultFilePreview
      {...(props as any)}
      isDisabled={props.disableNotReady && (isCopying || isNonTrained)}
    >
      {props.file.meta.exampleAssetIds.length > 0 && !isCompact && (
        <ModelWithCarousel
          file={props.file}
          cardWidth={props.cardWidth}
          cardMaxWidth={props.cardMaxWidth}
        />
      )}

      {(() => {
        if (isCompact || props.onAdd || props.onUndoAdd) return null;
        if (isCopying) return <HighlightTag>Copying...</HighlightTag>;
        if (isTraining) return <HighlightTag>Training</HighlightTag>;
        if (isNonTrained) return <HighlightTag>Draft</HighlightTag>;
        if (isFeatured) return <HighlightTag>Featured</HighlightTag>;
      })()}

      {(props.onAdd || props.onUndoAdd) && (
        <BlendAddButton
          isAdded={!!props.onUndoAdd}
          onUndoAdd={(e) => {
            e.stopPropagation();
            props.onUndoAdd?.(props.file);
          }}
          onAdd={(e) => {
            e.stopPropagation();
            props.onAdd?.(props.file);
          }}
        />
      )}
    </DefaultFilePreview>
  );
}

// ------------------------------------

interface ModelWithCarouselProps {
  file: FileModelType;
  cardWidth: number;
  cardMaxWidth?: number;
}

function ModelWithCarousel({
  file,
  cardWidth,
  cardMaxWidth,
}: ModelWithCarouselProps) {
  const { selectedTeam } = useTeamContext();
  const [scrollIndex, setScrollIndex] = useState<number>(0);
  const [exampleAssets, setExampleAssets] = useState<{
    [key: string]: FileImageType;
  }>({});
  const [getExampleAssetsTrigger] = usePostAssetGetBulkMutation();

  const { url: thumbnailUrl } = useOptimizedAssetUrl({
    file,
    cardWidth: cardMaxWidth ?? cardWidth,
  });

  const exampleAssetIds = useMemo(
    () => file.meta.exampleAssetIds ?? [],
    [file.meta.exampleAssetIds]
  );

  // ----------------------------------

  const loadExampleAssets = useCallback(async () => {
    if (Object.keys(exampleAssets).length > 0) return;
    const info = await getExampleAssetsTrigger({
      teamId: selectedTeam.id,
      body: {
        assetIds: exampleAssetIds,
      },
    }).unwrap();
    const assets = info.assets.reduce<{
      [key: string]: FileImageType;
    }>((memo, asset) => {
      memo[asset.id] = mapAssetsToImagesFiles([asset])[0];
      return memo;
    }, {});
    setExampleAssets(assets);
  }, [
    setExampleAssets,
    getExampleAssetsTrigger,
    exampleAssets,
    exampleAssetIds,
    selectedTeam.id,
  ]);

  const scrollExampleTo = useCallback(
    (index: number, e: React.MouseEvent<HTMLDivElement>) => {
      void loadExampleAssets();
      setScrollIndex(Math.max(0, Math.min(index, exampleAssetIds.length)));
      e.stopPropagation();
    },
    [exampleAssetIds.length, loadExampleAssets]
  );

  const scrollExampleDirection = useCallback(
    (direction: "prev" | "next", e: React.MouseEvent<HTMLDivElement>) => {
      void loadExampleAssets();
      if (direction === "prev" && scrollIndex > 0) {
        setScrollIndex(scrollIndex - 1);
      }
      if (direction === "next" && scrollIndex < exampleAssetIds.length) {
        setScrollIndex(scrollIndex + 1);
      }
      e.stopPropagation();
    },
    [exampleAssetIds.length, loadExampleAssets, scrollIndex]
  );

  // ----------------------------------

  return (
    <Box
      pos="absolute"
      top={0}
      right={0}
      left={0}
      overflow="hidden"
      pt="100%"
      borderTopRadius="md"
      bgColor="backgroundTertiary.500"
      data-group
    >
      <Box pos="absolute" top={0} right={0} bottom={0} left={0}>
        <Flex
          align="stretch"
          transform={`translateX(calc(-${scrollIndex * 100}%))`}
          transition="transform 0.5s"
        >
          <Box pos="relative" flex="0 0 100%" pt="100%">
            <Image
              pos="absolute"
              top={0}
              left={0}
              w="100%"
              h="100%"
              objectFit="cover"
              alt="thumbnail"
              src={thumbnailUrl}
            />
          </Box>

          {exampleAssetIds.map((id) => (
            <ExampleImage
              key={id}
              file={exampleAssets[id]}
              cardWidth={cardWidth}
              cardMaxWidth={cardMaxWidth}
            />
          ))}
        </Flex>
      </Box>

      <HStack
        pos="absolute"
        zIndex="docked"
        right={0}
        bottom={1}
        left={0}
        justify="center"
        p={1}
        onClick={(e) => e.stopPropagation()}
        spacing={1.5}
      >
        {[{ id: "initial" }, ...exampleAssetIds]
          .slice(0, 8)
          .map((id, index) => (
            <Box
              key={`${id}`}
              py={2}
              cursor="pointer"
              onClick={(e) => scrollExampleTo(index, e)}
            >
              <Box
                w="8px"
                h="8px"
                borderRadius="full"
                _hover={{ bgColor: "textPrimary" }}
                bgColor={
                  Math.min(scrollIndex, 7) === index
                    ? "textPrimary"
                    : "whiteAlpha.600"
                }
              />
            </Box>
          ))}
      </HStack>

      <PrevNextButton
        direction="prev"
        onClick={(e) => scrollExampleDirection("prev", e)}
        isDisabled={scrollIndex === 0}
      />
      <PrevNextButton
        direction="next"
        onClick={(e) => scrollExampleDirection("next", e)}
        isDisabled={scrollIndex === exampleAssetIds.length}
      />
    </Box>
  );
}

interface ExampleImageProps {
  file: FileImageType | undefined;
  cardWidth: number;
  cardMaxWidth?: number;
}

function ExampleImage({ file, cardWidth, cardMaxWidth }: ExampleImageProps) {
  const isSuccess = file?.status === "success";
  const { url: thumbnailUrl } = useOptimizedAssetUrl({
    file,
    cardWidth: cardMaxWidth ?? cardWidth,
  });
  const lowResThumbnailUrl = isSuccess
    ? `${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/${file.id}`
    : undefined;

  return (
    <Box pos="relative" flex="0 0 100%" pt="100%">
      <Image
        pos="absolute"
        top={0}
        left={0}
        w="100%"
        h="100%"
        objectFit="cover"
        alt="example"
        fallback={
          <Skeleton pos="absolute" top={0} left={0} w="100%" h="100%" />
        }
        fallbackSrc={lowResThumbnailUrl}
        src={thumbnailUrl}
      />
    </Box>
  );
}

// ------------------------------------

function HighlightTag({ children }: PropsWithChildren) {
  return (
    <Center
      pos="absolute"
      zIndex="docked"
      top={3}
      right={3}
      h="28px"
      px={2}
      borderRadius="full"
      bgColor="white"
    >
      <Text color="black" size="body.bold.md">
        {children}
      </Text>
    </Center>
  );
}

// ------------------------------------

interface PrevNextButtonProps {
  direction: "prev" | "next";
  isDisabled?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

function PrevNextButton({
  direction,
  isDisabled,
  onClick,
}: PrevNextButtonProps) {
  return (
    <Box
      pos="absolute"
      zIndex="docked"
      top="50%"
      right={direction === "next" ? 2 : undefined}
      left={direction === "prev" ? 2 : undefined}
      _groupHover={{ visibility: "visible" }}
      visibility="hidden"
      onClick={(e) => e.stopPropagation()}
    >
      <Center
        w="28px"
        h="28px"
        mt="-14px"
        borderRadius="full"
        _hover={!isDisabled ? { bgColor: "whiteAlpha.800" } : undefined}
        bgColor={!isDisabled ? "whiteAlpha.600" : "whiteAlpha.400"}
        onClick={onClick}
      >
        <Icon
          id={
            (
              {
                prev: "Ui/ChevronLeft",
                next: "Ui/ChevronRight",
              } as const
            )[direction]
          }
          color={!isDisabled ? "background.500" : "background.400"}
        />
      </Center>
    </Box>
  );
}
