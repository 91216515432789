import React from "react";
import Link from "next/link";
import { useAssetRefineLink } from "domains/assets/hooks/useAssetLink";
import {
  isAssetInferenceTexture,
  isAssetSkybox,
  isAssetUpscaledTexture,
} from "domains/assets/utils/isType";
import { getIsModelFluxPro, getIsModelFluxPro1_1 } from "domains/models/utils";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
} from "infra/api/generated/api";

export interface ButtonRefineProps {
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

export default function ButtonRefine({ model, asset }: ButtonRefineProps) {
  const linkRefine = useAssetRefineLink(asset, { modelId: model?.id });

  if (
    isAssetSkybox(asset) ||
    getIsModelFluxPro1_1(model) ||
    getIsModelFluxPro(model)
  ) {
    return null;
  }

  const isTexture =
    isAssetInferenceTexture(asset) || isAssetUpscaledTexture(asset);

  const tooltip = isTexture
    ? "Generate new iterations using this albedo as a reference image"
    : "Generate new iterations using this image as a reference image";

  return (
    <Link
      href={linkRefine ?? "/"}
      style={{ pointerEvents: linkRefine ? "auto" : "none" }}
    >
      <Button
        data-testid="edit-image-refine"
        leftIcon={<Icon id="Ui/Image" h="14px" />}
        variant="secondary"
        size="sm"
        isDisabled={!linkRefine}
        tooltip={tooltip}
      >
        Use as Reference
      </Button>
    </Link>
  );
}
