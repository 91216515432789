import { useCallback, useState } from "react";
import formatDate from "domains/canvas/utils/formatDate";
import { useAssetUpload } from "domains/image/hooks/useAssetUpload";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useHandleApiError } from "infra/api/error";
import { removeCuFromType } from "infra/api/ExludeCU";
import {
  GetAssetsByAssetIdApiResponse,
  usePostPatchInferencesMutation,
} from "infra/api/generated/api";

export function useAssetCrop() {
  const [putImagePatchTrigger] = usePostPatchInferencesMutation();
  const { uploadImage } = useAssetUpload();
  const [isCropping, setIsCropping] = useState(false);
  const { selectedTeam } = useTeamContext();
  const handleApiError = useHandleApiError();

  const cropImage = useCallback(
    async ({
      asset,
      cropData,
      backgroundColor = "#000000",
      preventCompression = false,
    }: {
      asset: {
        assetId: string | undefined;
        src: string;
      };
      cropData: {
        width: number;
        height: number;
        x: number;
        y: number;
      };
      backgroundColor?: string;
      preventCompression?: boolean;
    }): Promise<GetAssetsByAssetIdApiResponse["asset"] | undefined> => {
      setIsCropping(true);

      let assetId = asset.assetId;
      if (!assetId) {
        const uploadedAsset = await uploadImage({
          imageData: asset.src,
          name: "cropping-" + formatDate(Date.now()),
          preventCompression,
        });
        if (!uploadedAsset) {
          return;
        }
        assetId = uploadedAsset.id;
      }

      const width = Math.floor(cropData.width);
      const height = Math.floor(cropData.height);
      const top = Math.floor(cropData.y);
      const left = Math.floor(cropData.x);

      try {
        const newAsset = await putImagePatchTrigger({
          teamId: selectedTeam.id,
          body: {
            image: assetId,
            crop: {
              height,
              width,
              top,
              left,
              backgroundColor,
            },
          },
        })
          .unwrap()
          .then(removeCuFromType);
        return newAsset.asset;
      } catch (error) {
        handleApiError(error, "Failed to crop image");
      } finally {
        setIsCropping(false);
      }
    },
    [handleApiError, putImagePatchTrigger, selectedTeam.id, uploadImage]
  );

  return {
    isCropping,
    cropImage,
  };
}
