import { useCallback, useEffect, useMemo, useState } from "react";
import { UseAllAssetsArgs } from "domains/assets/hooks/useAllAssets";
import { useAssetsByIds } from "domains/assets/hooks/useAssetsByIds";
import { mapAssetsToJobs } from "domains/assets/utils/mapAssetsToJobs";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import {
  mapAssetsToCanvasFiles,
  mapAssetsToImagesFiles,
  mapModelsToFiles,
} from "domains/file-manager/interfaces";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useSearchContext } from "domains/search/contexts/SearchProvider";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  useGetAssetsByAssetIdQuery,
} from "infra/api/generated/api";
import _ from "lodash";
import moment from "moment";

import { skipToken } from "@reduxjs/toolkit/query";

interface UseSearchArgs {
  query?: string;
  assetId?: string;
  filters?: string;
  authorId?: string;
  dateRange?: { start: string; end: string | undefined };
  isHighResThumbnailRequired?: boolean;
  withPublic?: boolean;
  aiBoost?: boolean;
  allowEmptySearch?: boolean;
}

interface UseAssetsSearchArgs extends Omit<UseSearchArgs, "filters"> {
  types?: UseAllAssetsArgs["types"];
  modelId?: UseAllAssetsArgs["modelId"];
}

interface UseModelsSearchArgs extends Omit<UseSearchArgs, "filters"> {}

interface UseCanvasesSearchArgs extends Omit<UseSearchArgs, "filters"> {}

const PAGE_SIZE = 50;

export function useAssetsSearch({
  modelId,
  types,
  ...props
}: UseAssetsSearchArgs) {
  const filters = useMemo(() => {
    return [
      ...(types?.length
        ? [
            "(" +
              types.map((type) => `metadata.type = ${type}`).join(" OR ") +
              ")",
          ]
        : []),
      ...(modelId ? [`metadata.modelId = ${modelId}`] : []),
    ].join(" AND ");
  }, [types, modelId]);

  const { results, ...rest } = useSearch<
    GetAssetsByAssetIdApiResponse["asset"]
  >({
    ...props,
    filters,
    type: "asset",
  });

  const { files, jobs } = useMemo(() => {
    const files = mapAssetsToImagesFiles(results);
    const jobs = mapAssetsToJobs(files);
    return { files, jobs };
  }, [results]);

  return {
    files,
    jobs,
    ...rest,
  };
}

export function useModelsSearch(props: UseModelsSearchArgs) {
  const { results, ...rest } = useSearch<
    GetModelsByModelIdApiResponse["model"]
  >({
    ...props,
    type: "model",
  });

  const files = useMemo(() => {
    return mapModelsToFiles(results);
  }, [results]);

  return {
    files,
    ...rest,
  };
}

export function useCanvasesSearch(props: UseCanvasesSearchArgs) {
  const filters = useMemo(() => {
    return `metadata.type = canvas`;
  }, []);

  const { results, ...rest } = useSearch<
    GetAssetsByAssetIdApiResponse["asset"]
  >({
    ...props,
    filters,
    type: "asset",
  });

  const files = useMemo(() => {
    return mapAssetsToCanvasFiles(results);
  }, [results]);

  return {
    files,
    ...rest,
  };
}

function useSearch<
  T extends
    | GetAssetsByAssetIdApiResponse["asset"]
    | GetModelsByModelIdApiResponse["model"]
>({
  query: untrimmedQuery,
  assetId,
  filters,
  type,
  dateRange,
  authorId,
  isHighResThumbnailRequired = true,
  withPublic = true,
  aiBoost,
  allowEmptySearch = false,
}: UseSearchArgs & {
  type: T extends GetAssetsByAssetIdApiResponse["asset"] ? "asset" : "model";
}) {
  const { getIndexes, searchClient, getQueryEmbedding } = useSearchContext();

  const { selectedTeam } = useTeamContext();
  const { errorToast } = useScenarioToast();
  const [results, setResults] = useState<T[]>([]);
  const [resultCount, setResultCount] = useState<number | undefined>();
  const [stopPaginationAt, setStopPaginationAt] = useState<
    | {
        [indexName: string]: number;
      }
    | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lastPageLoaded, setLastPageLoaded] = useState<number | undefined>(
    undefined
  );
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [queryEmbedding, setQueryEmbedding] = useState<
    | {
        query: string;
        embedding: number[];
      }
    | undefined
  >(undefined);

  const indexes = useMemo(
    () => getIndexes({ type, withPublic }),
    [type, getIndexes, withPublic]
  );

  const { currentData: asset, error: assetError } = useGetAssetsByAssetIdQuery(
    assetId
      ? {
          teamId: selectedTeam.id,
          assetId,
          withEmbedding: "true",
        }
      : skipToken
  );
  const query = useMemo(() => {
    return untrimmedQuery ? untrimmedQuery.trim() : untrimmedQuery;
  }, [untrimmedQuery]);

  useEffect(() => {
    setStopPaginationAt(undefined);
    setCurrentPage(0);
    setLastPageLoaded(undefined);
  }, [
    query,
    asset,
    filters,
    dateRange,
    indexes,
    authorId,
    queryEmbedding,
    allowEmptySearch,
  ]);

  useEffect(() => {
    if (!aiBoost || !query) {
      setQueryEmbedding(undefined);
      return;
    }

    if (query === queryEmbedding?.query) {
      return;
    }

    void getQueryEmbedding(query).then((embedding) => {
      setQueryEmbedding(embedding);
    });
  }, [query, getQueryEmbedding, queryEmbedding, aiBoost]);

  const assetIds = useMemo(() => {
    if (!isHighResThumbnailRequired) return [];

    if (type === "asset") {
      return (results as GetAssetsByAssetIdApiResponse["asset"][]).map(
        (result) => result.id
      );
    }
    return (results as GetModelsByModelIdApiResponse["model"][])
      .map((result) => result.thumbnail?.assetId)
      .filter((value) => value !== undefined);
  }, [results, type, isHighResThumbnailRequired]);
  const { assetsByIds } = useAssetsByIds({
    assetIds,
    originalAssets: false,
  });

  useEffect(() => {
    if (!searchClient || currentPage === lastPageLoaded) return;

    if (!!assetId && asset?.asset.id !== assetId) {
      return;
    }

    if (aiBoost && query && query !== queryEmbedding?.query) {
      return;
    }

    if (!query && !asset?.asset.embedding && !allowEmptySearch) {
      setResults([]);
      setResultCount(undefined);
      return;
    }

    const search = async () => {
      const searchQueries = indexes
        .map((indexName) => {
          if (
            indexName &&
            stopPaginationAt &&
            stopPaginationAt[indexName] <= currentPage
          ) {
            return undefined;
          }

          const withQueryEmbedding = aiBoost && queryEmbedding?.embedding;

          return {
            indexName,
            params: {
              query: withQueryEmbedding ? undefined : query ?? "",
              filters: _.compact([
                filters,
                authorId ? `authorId = "${authorId}"` : "",
                dateRange?.start
                  ? `createdAtTS >= ${moment(
                      dateRange.start,
                      "YYYY-MM-DD"
                    ).unix()} AND createdAtTS <= ${moment(
                      dateRange.end || dateRange.start,
                      "YYYY-MM-DD"
                    )
                      .endOf("day")
                      .unix()}`
                  : "",
              ]).join(" AND "),
              page: currentPage,
              hitsPerPage: PAGE_SIZE,
              ...(asset?.asset.embedding
                ? {
                    meiliSearchParams: {
                      vector: asset.asset.embedding,
                      hybrid: {
                        embedder: "jina-clip-v2-1024",
                        semanticRatio: 1,
                      },
                    },
                  }
                : {}),
              ...(withQueryEmbedding
                ? {
                    meiliSearchParams: {
                      vector: queryEmbedding.embedding,
                      hybrid: {
                        embedder: "jina-clip-v2-1024",
                        semanticRatio: 0.75,
                      },
                    },
                  }
                : {}),
            },
          };
        })
        .filter(Boolean);

      if (searchQueries.length === 0) return;

      let results: {
        index: string;
        hits: T[];
        nbPages: number;
        nbHits: number;
      }[] = [];
      try {
        const response = await searchClient.search(searchQueries);
        results = response.results;
      } catch (error) {
        errorToast({
          title: "Error fetching search results",
          description: "Please try again later",
        });
        return;
      }

      setStopPaginationAt((prevStopPaginationAt) => ({
        ...prevStopPaginationAt,
        ...results.reduce((acc, result) => {
          acc[result.index] = result.nbPages;
          return acc;
        }, {} as NonNullable<typeof stopPaginationAt>),
      }));
      setResultCount(
        results.reduce((acc, result) => {
          return acc + result.nbHits;
        }, 0)
      );
      setResults((prevResults) =>
        _.uniqBy(
          [
            ...(currentPage === 0 ? [] : prevResults),
            ...results
              .map((result) =>
                result.hits.map((hit) => ({
                  ...hit,
                  ...(type === "model"
                    ? {
                        thumbnail:
                          "thumbnail" in hit && hit.thumbnail
                            ? {
                                assetId: hit.thumbnail.assetId,
                                url:
                                  assetsByIds[hit.thumbnail.assetId]?.meta
                                    .url ??
                                  `${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/${hit.thumbnail.assetId}`,
                              }
                            : undefined,
                        exampleAssetIds: [],
                        status: "trained",
                        trainingImages: [],
                      }
                    : {}),
                  ...(type === "asset"
                    ? assetsByIds[hit.id]
                      ? assetsByIds[hit.id]!.meta
                      : {
                          status: "success",
                          url: `${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/${hit.id}`,
                        }
                    : {}),
                }))
              )
              .flat(),
          ],
          "id"
        )
      );
      setLastPageLoaded(currentPage);
    };

    void search();
  }, [
    indexes,
    query,
    searchClient,
    stopPaginationAt,
    currentPage,
    lastPageLoaded,
    filters,
    authorId,
    type,
    dateRange,
    asset,
    assetId,
    assetsByIds,
    aiBoost,
    queryEmbedding,
    errorToast,
    allowEmptySearch,
  ]);

  const hasMore = useMemo(() => {
    if (!stopPaginationAt) {
      return true;
    }
    return Object.values(stopPaginationAt).some(
      (page) => page !== undefined && page > currentPage
    );
  }, [stopPaginationAt, currentPage]);

  const loadMore = useCallback(() => {
    if (!hasMore) return;
    setCurrentPage((prevPage) => prevPage + 1);
  }, [hasMore]);

  useEffect(() => {
    setResults((prevResults) =>
      prevResults.map((result) => ({
        ...result,
        ...("thumbnail" in result && result.thumbnail
          ? {
              thumbnail: {
                assetId: result.thumbnail.assetId,
                url: assetsByIds[result.thumbnail.assetId]?.meta.url,
              },
            }
          : {}),
        ...(type === "asset" && assetsByIds[result.id]
          ? assetsByIds[result.id]!.meta
          : {}),
      }))
    );
  }, [assetsByIds, type]);

  const filteredResults = useMemo(() => {
    return results.filter((result) => !deletedIds.includes(result.id));
  }, [results, deletedIds]);

  return {
    results: filteredResults,
    isLoading:
      lastPageLoaded === undefined &&
      !!(
        query ||
        asset?.asset.embedding ||
        (assetId && !assetError) ||
        allowEmptySearch
      ),
    hasMore,
    loadMore,
    resultCount,
    setDeletedIds,
  };
}

// ------------------------------------

function isValidKey(key: string) {
  return (
    !key.endsWith("Id") &&
    !key.endsWith("Ids") &&
    key !== "id" &&
    key !== "createdAt" &&
    key !== "updatedAt" &&
    key !== "createdAtTS" &&
    key !== "updatedAtTS"
  );
}

export function useAutocompleteSearch({ query }: { query: string }) {
  const { getIndexes, searchClient } = useSearchContext();
  const [propositions, setPropositions] = useState<
    | {
        query: string;
        propositions: string[];
      }
    | undefined
  >();

  const indexes = useMemo(
    () => [...getIndexes({ type: "asset" }), ...getIndexes({ type: "model" })],
    [getIndexes]
  );

  const debouncedQuery = useDebounce(query, 100);
  const trimmedQuery = useMemo(() => {
    return debouncedQuery.trim();
  }, [debouncedQuery]);

  useEffect(() => {
    if (!searchClient) return;

    if (!trimmedQuery) {
      setPropositions(undefined);
      return;
    }

    const search = async () => {
      const {
        results,
      }: {
        results: {
          hits: {
            _highlightResult: {
              [key: string]:
                | {
                    value?: string;
                  }
                | {
                    [key: string]: {
                      value?: string;
                    };
                  }
                | (
                    | string
                    | {
                        value?: string;
                      }
                  )[];
            };
          }[];
        }[];
      } = await searchClient.search(
        indexes.map((indexName) => {
          return {
            indexName,
            params: {
              query: trimmedQuery,
              hitsPerPage: 5,
              highlightPreTag: "",
              highlightPostTag: "",
            },
          };
        })
      );

      const regex = new RegExp(
        `\\b${_.escapeRegExp(trimmedQuery)}\\w*\\b`,
        "gi"
      );
      const newPropositions: string[] = [];
      results.forEach((result) => {
        result.hits.forEach((hit) => {
          Object.keys(hit._highlightResult).forEach((key) => {
            if (!isValidKey(key)) return;
            if (Array.isArray(hit._highlightResult[key])) {
              hit._highlightResult[key].forEach((x) => {
                if (!x) return;
                const value = typeof x === "object" ? x.value : x;
                if (!value) return;
                const matches = value.match(regex);
                if (matches) {
                  newPropositions.push(...matches);
                }
              });
              return;
            }
            const item = hit._highlightResult[key];
            if (!item.value && key !== "metadata") return;
            Object.keys(item).forEach((key2) => {
              if (!isValidKey(key2)) return;
              const x = item[key2 as keyof typeof item];
              if (!x) return;
              const value = typeof x === "object" ? x.value : x;
              if (!value) return;
              const matches = value.match(regex);
              if (matches) {
                newPropositions.push(...matches);
              }
            });
          });
        });
      });
      setPropositions({
        query: trimmedQuery,
        propositions: _.uniq(
          newPropositions.map((x) => x.toLowerCase())
        ).filter((x) => x.length > 1 && x !== trimmedQuery),
      });
    };

    void search();
  }, [trimmedQuery, searchClient, indexes]);

  return {
    propositions,
    isLoading: propositions === undefined && trimmedQuery.length > 0,
  };
}
