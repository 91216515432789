import { ComponentStyleConfig, defineStyleConfig } from "@chakra-ui/react";

export const Tabs: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    root: {
      position: "relative",
    },
    tab: {
      _selected: {
        color: "textPrimary",
      },
      color: "textSecondary",
      fontWeight: 500,
    },
    tablist: {
      borderBottomColor: "border.500",
      borderBottomWidth: "1px",
    },
    tabpanels: {
      paddingTop: 10,
    },
    tabpanel: {
      padding: 0,
    },
  },
  variants: {
    unstyled: {},
    light: {
      tablist: {
        pos: "relative",
        px: 3,
        gap: 6,
        "& > .chakra-tabs__tab-indicator": {
          bottom: 0,
        },
      },
      tab: {
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
        _hover: {
          color: "textPrimary",
        },
      },
    },
  },
  sizes: {
    sm: {
      tab: {
        py: 3,
        paddingInlineStart: 2,
        paddingInlineEnd: 2,
      },
      tablist: {
        gap: 3,
      },
      tabpanels: {
        pt: 2,
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: "unstyled",
  },
});
