import { useMemo } from "react";
import { capitalizeFirstWord } from "domains/commons/misc";
import {
  FilterJobType,
  FilterJobTypeKey,
} from "domains/file-manager/constants/JobFilter";
import MenuListMultipleChoice from "domains/ui/components/Menu/MenuListMultipleChoice";
import _ from "lodash";

export interface HeaderFilterJobTypeProps {
  options: {
    [key in FilterJobTypeKey]?: FilterJobType;
  };
  values: FilterJobTypeKey[];
  onChange: (value: FilterJobTypeKey[] | undefined) => void;
}

export default function HeaderFilterJobType({
  options,
  values,
  onChange,
}: HeaderFilterJobTypeProps) {
  const sortedOptions: {
    value: FilterJobTypeKey;
    label: string;
    isDivider?: boolean;
    dataTestId?: string;
  }[] = useMemo(() => {
    const keys = Object.keys(options) as (keyof typeof options)[];
    const assetTypes = _.uniq(keys.map((key) => key.split(":")[0])) as string[];
    if (assetTypes.length === 1) {
      return keys.map((key) => ({
        value: key,
        label: options[key]?.label ?? "",
        dataTestId: `file-manager-asset-change-type-${key}`,
      }));
    }
    return assetTypes
      .map((assetType) => [
        {
          value: "inference" as FilterJobTypeKey,
          label: capitalizeFirstWord(assetType),
          isDivider: true,
        },
        ...keys
          .filter((key) => key.startsWith(`${assetType}:`))
          .map((key) => ({
            value: key,
            label: options[key]?.label ?? "",
            dataTestId: `file-manager-asset-change-type-${key}`,
          })),
      ])
      .flat();
  }, [options]);

  return (
    <MenuListMultipleChoice
      placeholder="Type"
      options={sortedOptions}
      setSelectedValues={onChange}
      selectedValues={values}
      dataTestId="file-manager-asset-change-type-dropdown"
      withoutSearch
      variant="secondaryAlt"
    />
  );
}
