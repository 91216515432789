import React from "react";
import Link from "next/link";
import { useAssetReloadParametersLink } from "domains/assets/hooks/useAssetLink";
import {
  isAssetInferenceTexture,
  isAssetUpscaledTexture,
} from "domains/assets/utils/isType";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";

export interface ButtonReloadParametersProps {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  model: GetModelsByModelIdApiResponse["model"] | undefined;
}

export default function ButtonReloadParameters({
  asset,
  inference,
  model,
}: ButtonReloadParametersProps) {
  const link = useAssetReloadParametersLink(asset, {
    modelId: model?.id,
    inferenceId: inference?.id,
  });
  const isTextureInference = isAssetInferenceTexture(asset);
  const isTextureUpscale = isAssetUpscaledTexture(asset);
  const isInference =
    asset?.metadata.type.includes("inference") && !isTextureInference;
  const isUpscale = asset?.metadata.type === "upscale";
  const isSkybox = asset?.metadata.type === "skybox-base-360";
  const isSkyboxUpscale = asset?.metadata.type === "upscale-skybox";
  const isRestyle = asset?.metadata.type === "restyle";
  const isExpand = asset?.metadata.type === "reframe";

  const isDisabled =
    isInference || isTextureInference ? !inference || !model : false;

  if (!link) {
    return null;
  }

  return (
    <Link
      href={link}
      style={{
        pointerEvents: isDisabled ? "none" : "auto",
      }}
    >
      <Button
        leftIcon={<Icon id="Ui/Refresh" h="14px" />}
        variant="secondary"
        size="sm"
        isDisabled={isDisabled}
        tooltip={
          (isSkybox &&
            "Reload the exact parameters used to generate this skybox") ||
          (isSkyboxUpscale &&
            "Reload the exact parameters used to enhance this skybox") ||
          (isInference &&
            "Reload the exact parameters used to generate this image") ||
          (isUpscale &&
            "Reload the exact parameters used to enhance this image") ||
          (isTextureInference &&
            "Reload the exact parameters used to generate this texture") ||
          (isTextureUpscale &&
            "Reload the exact parameters used to enhance this texture") ||
          (isRestyle &&
            "Reload the exact parameters used to generate this image") ||
          (isExpand &&
            "Reload the exact parameters used to expand this image") ||
          undefined
        }
      >
        Reload Parameters
      </Button>
    </Link>
  );
}
