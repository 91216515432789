import React from "react";
import Link from "next/link";
import PlanCooldown, {
  PlanCooldownProps,
} from "domains/teams/components/ModalPlanQuotaLimit/PlanCooldown";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { PlanQuotaLimit } from "domains/teams/interfaces/Pricing";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import {
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

export type ContentProps = PlanCooldownProps;

type ButtonType = "upgrade" | "close" | "sales";

type ModalData = {
  [key: string]: {
    title: string;
    ContentComponent?: React.ComponentType<ContentProps>;
    label?: (isAdmin: boolean, plan?: string) => string;
    buttonType: (isAdmin: boolean) => ButtonType | ButtonType[];
  };
};

const MODAL_DATA: ModalData = {
  planModelMinuteTrainingRateLimit: {
    title: "Rate Limit Exceeded",
    label: () =>
      "You've exceeded the rate limit for training models within a minute. Please wait for a moment and try again.",
    buttonType: () => "close",
  },
  planModelHourlyTrainingRateLimit: {
    title: "Rate Limit Exceeded",
    label: () =>
      "You've exceeded the hourly rate limit for training models. Please wait for your hourly quota to reset.",
    buttonType: () => "close",
  },
  planModelDailyTrainingRateLimit: {
    title: "Rate Limit Exceeded",
    label: (isAdmin: boolean) =>
      "Daily rate limit for training models exceeded." +
      `${
        isAdmin
          ? "To continue training, consider upgrading your plan or wait for your daily quota to reset."
          : "To continue training, ask an admin to upgrade the organization plan or wait for your daily quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  notEnoughCreativeUnits: {
    title: "Insufficient balance",
    label: (isAdmin: boolean) =>
      "You don't have enough creative units to run this task. " +
      `${
        isAdmin
          ? "Consider upgrading your plan to increase your creative units allowance, get faster support, quicker generation times, or enable multi-user collaboration."
          : "To get more creative units, ask an admin to upgrade the organization plan."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planCooldown: {
    title: "Hold on!",
    ContentComponent: PlanCooldown,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planImageGenerationsRateLimit: {
    title: "Rate Limit Exceeded",
    label: () =>
      "You've exceeded the rate limit for generating images. Wait for a moment and try again. Please reach out to increase your rate limit.",
    buttonType: () => "close",
  },
  planMagicPromptLimit: {
    title: "Prompt Spark is currently rate-limited",
    label: () =>
      "Please wait a few moments before attempting to generate more prompts",
    buttonType: () => "close",
  },
  planTeamMembers: {
    title: "Seat Limit Reached",
    label: (isAdmin: boolean) =>
      "You’ve used all the seats available in your current plan. " +
      `${
        isAdmin
          ? "You can either upgrade your plan, add a seat, remove an existing user, or contact us for custom solutions."
          : "Ask an admin to either upgrade your plan, add a seat, remove an existing user, or contact us for custom solutions."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planCollections: {
    title: "You're at Full Capacity!",
    label: (isAdmin: boolean) =>
      "It looks like you've maxed out the number of collections allowed under your current plan. To create more collections, " +
      `${
        isAdmin
          ? `please consider upgrading your plan.`
          : `have an admin upgrade your plan, or delete some existing collections.`
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planCanvasLayers: {
    title: "Max Layers Reached!",
    label: (isAdmin: boolean) =>
      "You've reached the maximum number of layers for a single canvas project on your current plan. Any additional changes won't be saved. To add more layers to your masterpiece, " +
      `${
        isAdmin
          ? `please consider upgrading your plan.`
          : `have an admin upgrade your plan, or clear out some previously created layers`
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planCanvasProjects: {
    title: "All Filled Up on Projects!",
    label: (isAdmin: boolean) =>
      "You've maxed out the number of projects under your current plan. To embark on more creative journeys, " +
      `${
        isAdmin
          ? `please consider upgrading your plan.`
          : `have an admin upgrade your plan, or free up some space by deleting existing canvas projects.`
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planDetections: {
    title: "Detection Limit Reached",
    label: (isAdmin: boolean) =>
      "You have reached the maximum number of detections allowed by your plan. " +
      `${
        isAdmin
          ? "Consider upgrading your plan or wait for your monthly quota to reset."
          : "Ask an admin to upgrade the organization plan or wait for your monthly quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  detectionRateLimit: {
    title: "Rate Limit Exceeded",
    label: () =>
      "You've exceeded the rate limit for detections. Please wait for a moment and try again.",
    buttonType: () => "close",
  },
  freeTeams: {
    title: "Free Organizations Limit Reached",
    label: (isAdmin: boolean) =>
      "You are only allowed one Organization under the Starter (free) Plan. " +
      `${
        isAdmin
          ? "Please upgrade your subscription to keep using this feature."
          : "Ask an admin to upgrade your plan to continue using this feature."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  parallelInferences: {
    title: "Parallel Inference Limit",
    label: (isAdmin: boolean) =>
      "You've hit the maximum number of simultaneous generations allowed. To increase your parallel inference capacity, " +
      `${
        isAdmin
          ? "upgrade your plan or contact us to set up a custom limit."
          : "ask an admin to upgrade your plan or to contact us to set up a custom limit."
      }`,
    buttonType: (isAdmin: boolean) =>
      isAdmin ? ["upgrade", "sales"] : "close",
  },
  parallelTrainings: {
    title: "Concurrent Training Limit",
    label: (isAdmin: boolean) =>
      "You've hit the maximum number of simultaneous trainings allowed. To increase your concurrent trainings capacity, " +
      `${
        isAdmin
          ? "upgrade your plan or contact us to set up a custom limit."
          : "ask an admin to upgrade your plan or to contact us to set up a custom limit."
      }`,
    buttonType: (isAdmin: boolean) =>
      isAdmin ? ["upgrade", "sales"] : "close",
  },
};

interface ModalPlanQuotaLimitProps {
  isOpen: boolean;
  id?: PlanQuotaLimit;
  contentProps?: ContentProps;
  onClose: () => void;
}

export default function ModalPlanQuotaLimit({
  isOpen,
  id,
  contentProps,
  onClose,
}: ModalPlanQuotaLimitProps) {
  const { selectedTeam } = useTeamContext();
  const isAdmin = selectedTeam.userRole === "admin";
  const modalData = id ? MODAL_DATA[id] : undefined;
  const isReady = !!modalData;

  const title = modalData?.title;
  const Content = modalData?.ContentComponent;
  const label = modalData?.label?.(isAdmin, selectedTeam.plan);
  const buttonType = modalData?.buttonType(isAdmin);
  const buttons = Array.isArray(buttonType) ? buttonType : [buttonType];

  return (
    <Modal
      isOpen={isOpen && isReady}
      onClose={onClose}
      size="xl"
      variant="modern"
    >
      <ModalOverlay zIndex={"toast"} />
      <ModalContent
        containerProps={{
          zIndex: "tooltip",
        }}
      >
        <ModalCloseButton data-testid="plan-limitation-close-modal-button" />
        <ModalBody>
          <VStack align="center" px={6} py={10} textAlign="center" spacing={0}>
            <Image
              mb={6}
              alt="limit icon"
              filter="drop-shadow(0px 0px 20px rgb(0 136 204)) drop-shadow(0px 0px 20px rgb(0 136 204 / 0.2))"
              src="/ui/team-billing-limit.svg"
            />

            <Heading
              pb={3}
              color="textPrimary"
              data-testid="plan-limitation-header"
              size="lg"
            >
              {title}
            </Heading>

            {!!Content && (
              <Content
                {...contentProps}
                isFreePlan={selectedTeam.isFreePlan}
                isAdmin={isAdmin}
              />
            )}

            {!!label && (
              <Text
                pb={8}
                color="textSecondary"
                data-testid="plan-limitation-text"
              >
                {label &&
                  label.split("\n").reduce((memo, str, idx) => {
                    if (idx > 0) memo.push(<br key={`${idx}-br`} />);
                    memo.push(str);
                    return memo;
                  }, [] as React.ReactNode[])}
              </Text>
            )}

            <VStack w="100%" spacing={4}>
              {buttons.includes("upgrade") && (
                <>
                  <Link
                    href={{
                      pathname: "/team",
                      query: {
                        tab: "plans",
                      },
                    }}
                  >
                    <Button
                      leftIcon={<Icon id="Ui/Upgrade" />}
                      size="lg"
                      onClick={onClose}
                      data-testid="plan-limitation-upgrade-button"
                    >
                      Upgrade Organization Plan
                    </Button>
                  </Link>
                  {!selectedTeam.isFreePlan &&
                    id !== "planTeamMembers" &&
                    id !== "parallelTrainings" && (
                      <Link
                        onClick={onClose}
                        href={{
                          pathname: "/team",
                          query: {
                            tab: "plans",
                            oscu: "true",
                          },
                        }}
                      >
                        <Text
                          textColor="primary.500"
                          _hover={{
                            textDecoration: "underline",
                          }}
                        >
                          You can also buy creative units to fulfill your
                          temporary needs
                        </Text>
                      </Link>
                    )}
                </>
              )}

              {buttons.includes("sales") && (
                <Button
                  variant={buttons.length > 1 ? "secondary" : undefined}
                  size="lg"
                  onClick={() => {
                    onClose();
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLScTW4rxKfm5XgMX0WyjP6dXin1qrrsDEUN8EL9BfG7WNx1Qig/viewform",
                      "_blank"
                    );
                  }}
                  data-testid="plan-limitation-contact-us-button"
                >
                  Contact Sales
                </Button>
              )}

              {buttons.includes("close") && (
                <Button size="lg" onClick={onClose}>
                  OK
                </Button>
              )}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
