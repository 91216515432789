import {
  gridSorts,
  GridSortValue,
} from "domains/file-manager/constants/GridSort";
import { MenuListSingleChoiceNeverUndefined } from "domains/ui/components/Menu/MenuListSingleChoice";

export interface GridSortDropdownProps<T extends GridSortValue[]> {
  options: T;
  value: T[number];
  onChange: (value: T[number]) => void;
}

export default function GridSortDropdown<T extends GridSortValue[]>({
  options,
  value,
  onChange,
}: GridSortDropdownProps<T>) {
  return (
    <MenuListSingleChoiceNeverUndefined
      placeholder="Sort by"
      options={options.map((key) => ({
        value: key,
        label: gridSorts[key].label,
        dataTestId: `file-manager-change-order-${key}`,
      }))}
      setSelectedValue={onChange}
      selectedValue={value}
      topText="Sort by"
      dataTestId="file-manager-change-order-dropdown"
      variant="ghost"
    />
  );
}
