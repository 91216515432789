import { useCallback } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { createTagUpdateMessage } from "domains/tags/utils";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useHandleApiError } from "infra/api/error";
import {
  GetModelsByModelIdApiResponse,
  usePutModelsTagsByModelIdMutation,
} from "infra/api/generated/api";

function useModelTagsManager() {
  const handleApiError = useHandleApiError();
  const { successToast } = useScenarioToast();
  const { selectedTeam } = useTeamContext();
  const [updateModelTags] = usePutModelsTagsByModelIdMutation();

  const addTags = useCallback(
    async (
      model: GetModelsByModelIdApiResponse["model"],
      tagsToAdd: string[],
      withToast = true
    ) => {
      const newTags = tagsToAdd.filter((tag) => !model.tags.includes(tag));

      if (newTags.length > 0) {
        try {
          await updateModelTags({
            modelId: model.id,
            teamId: selectedTeam.id,
            body: { add: newTags, delete: [] },
          }).unwrap();
          if (withToast)
            successToast({
              title: createTagUpdateMessage(newTags, []),
            });
        } catch (error) {
          handleApiError(
            error,
            "There was an error updating the tags",
            {},
            withToast
          );
          if (!withToast) {
            throw error;
          }
        }
      }
    },
    [updateModelTags, selectedTeam.id, successToast, handleApiError]
  );

  const updateTags = useCallback(
    async (
      model: GetModelsByModelIdApiResponse["model"],
      newTags: string[],
      withToast = true
    ) => {
      const tagsToAdd = newTags.filter((tag) => !model.tags.includes(tag));
      const tagsToRemove = model.tags.filter((tag) => !newTags.includes(tag));

      if (tagsToAdd.length === 0 && tagsToRemove.length === 0) {
        return;
      }

      try {
        await updateModelTags({
          modelId: model.id,
          teamId: selectedTeam.id,
          body: {
            add: tagsToAdd,
            delete: tagsToRemove,
          },
        }).unwrap();
        if (withToast)
          successToast({
            title: createTagUpdateMessage(tagsToAdd, tagsToRemove),
          });
      } catch (error) {
        handleApiError(
          error,
          "There was an error updating the tags",
          {},
          withToast
        );
        if (!withToast) {
          throw error;
        }
      }
    },
    [successToast, updateModelTags, selectedTeam.id, handleApiError]
  );

  const removeTag = useCallback(
    async (
      model: GetModelsByModelIdApiResponse["model"],
      tagToRemove: string,
      withToast = true
    ) => {
      if (model.tags.includes(tagToRemove)) {
        try {
          await updateModelTags({
            modelId: model.id,
            teamId: selectedTeam.id,
            body: { add: [], delete: [tagToRemove] },
          }).unwrap();
          if (withToast) {
            successToast({
              title: createTagUpdateMessage([], [tagToRemove]),
            });
          }
        } catch (error) {
          handleApiError(
            error,
            "There was an error updating the tags",
            {},
            withToast
          );
          if (!withToast) {
            throw error;
          }
        }
      }
    },
    [updateModelTags, selectedTeam.id, successToast, handleApiError]
  );
  return {
    addTags,
    removeTag,
    updateTags,
  };
}

export default useModelTagsManager;
