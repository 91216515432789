import ButtonSingleChoice, {
  ButtonSingleChoiceProps,
} from "./ButtonSingleChoice";

export interface MenuButtonMultipleChoiceProps {
  text: string;
  onRemove?: () => void;
  dataTestId?: string;
  hasValue: boolean;
  variant?: ButtonSingleChoiceProps["variant"];
}

export default function MenuButtonMultipleChoice({
  text,
  onRemove,
  dataTestId,
  hasValue,
  variant,
}: MenuButtonMultipleChoiceProps) {
  return (
    <ButtonSingleChoice
      asMenuButton
      text={text}
      onRemove={onRemove}
      dataTestId={dataTestId}
      hasValue={hasValue}
      variant={variant}
      {...(hasValue
        ? {
            textColor: "primary.500",
            bgColor: "primary.800",
            borderWidth: 0,
            _hover: {
              textColor: "primary.500",
              bgColor: "primary.700",
            },
          }
        : {})}
    />
  );
}
