import { useCallback, useEffect, useMemo, useState } from "react";
import { useAssetRemoveBackgroundCu } from "domains/assets/hooks/useAssetRemoveBackgroundCu";
import { useClipboardContext } from "domains/commons/contexts/ClipboardProvider";
import { FileImageType } from "domains/file-manager/interfaces";
import SelectLibraryAsset, {
  SelectLibraryAssetProps,
} from "domains/image/components/SelectLibraryAsset";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import useRemoveBackgroundFromTrainingImage from "domains/models/hooks/useRemoveBackgroundFromTrainingImage";
import { TrainImage } from "domains/models/interfaces/train";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { extraTheme } from "domains/theme";
import Button from "domains/ui/components/Button";
import { CuIndicator } from "domains/ui/components/ButtonWithCuIndicator";
import CompatibleImageUploader, {
  handleBlurFocusOnUploadClick,
} from "domains/ui/components/CompatibleImageUploader";
import Icon from "domains/ui/components/Icon";
import { PremiumButtonWrapper } from "domains/ui/components/PremiumBadge";
import PreviewModalImage from "domains/ui/components/PreviewModalImage";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import {
  GetModelsByModelIdApiResponse,
  useDeleteModelsTrainingImagesByModelIdAndTrainingImageIdMutation,
} from "infra/api/generated/api";
import { ImageType } from "react-images-uploading";

import {
  Flex,
  FlexProps,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import TrainImagesItem from "./ImagesItem";

export interface ModelTrainImagesProps {
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  initialAssets?: FileImageType[];
  maxTrainingImages: number;
  captionHidden?: boolean;
  lowResMax?: number;
  onImageUpload: (images: TrainImage[]) => void;
  onImageChange: () => void;
  onDeleteAll: () => void;
}

export default function ModelTrainImages({
  model,
  initialAssets,
  maxTrainingImages,
  captionHidden: isCaptionHidden,
  lowResMax = 800,
  onImageUpload,
  onImageChange,
  onDeleteAll,
}: ModelTrainImagesProps) {
  const { selectedTeam } = useTeamContext();
  const { addImagePasteListener, removeImagePasteListener } =
    useClipboardContext();
  const { successToast, errorToast, infoToast } = useScenarioToast();
  const { isDraggingHover, dragFunctions, onDrop } = useImageUploadDragDrop({});
  const [imagePreviewIndex, setImagePreviewIndex] = useState<
    number | undefined
  >();
  const [deleteTrigger] =
    useDeleteModelsTrainingImagesByModelIdAndTrainingImageIdMutation();
  const removeBackground = useRemoveBackgroundFromTrainingImage();
  const [isDeleteAllLoading, setIsDeleteAllLoading] = useState<boolean>(false);
  const [isInitialAssetsSelected, setIsInitialAssetsSelected] =
    useState<boolean>(false);
  const [isRemoveAllBackgroundsLoading, setIsRemoveAllBackgroundsLoading] =
    useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [animateRef] = useAutoAnimate();

  const trainingImages = useMemo(() => model?.trainingImages ?? [], [model]);
  const trainingImageUrls = useMemo(
    () =>
      trainingImages.map(
        (image) => `${image.downloadUrl}&format=jpeg&quality=80`
      ),
    [trainingImages]
  );
  const previewImageUrl = (() => {
    if (trainingImageUrls && imagePreviewIndex !== undefined) {
      return trainingImageUrls[imagePreviewIndex];
    } else {
      return undefined;
    }
  })();

  const { cuCost, hasEnoughCu, isCuLoading } = useAssetRemoveBackgroundCu(
    trainingImages.map(({ id }) => ({
      // TODO: get width and height from the image to be more efficient, not available now
      assetId: id,
    }))
  );

  const isUploadable =
    !isDeleteAllLoading && trainingImages.length < maxTrainingImages;

  // ----------------------------------

  const handleImageUploadingChange = useCallback(
    async (images: (ImageType & { assetId?: string })[]) => {
      try {
        setIsUploading(true);

        const remainingImages = maxTrainingImages - trainingImages.length;
        const slicedImages = images.slice(0, remainingImages);
        const imagesToUpload: TrainImage[] = [];

        if (images.length > remainingImages) {
          errorToast({
            title: "Maximum images reached",
            description: `Only the ${remainingImages} first images have been kept.`,
          });
        }

        for (const image of slicedImages) {
          imagesToUpload.push({
            original: {
              assetId: image.assetId,
              src: image.data_url,
            },
          });
        }

        onImageUpload(imagesToUpload);
      } finally {
        setTimeout(() => setIsUploading(false), 500);
      }
    },
    [onImageUpload, errorToast, maxTrainingImages, trainingImages.length]
  );

  const handleLibraryAssetSelect = useCallback<
    NonNullable<SelectLibraryAssetProps["onMultiSelect"]>
  >(
    async (assets) => {
      await handleImageUploadingChange(
        assets.map((asset) => ({
          assetId: asset.id,
          data_url: asset.thumbnail,
        }))
      );
    },
    [handleImageUploadingChange]
  );

  const handleDeleteAllClick = useCallback(async () => {
    if (!model || trainingImages.length === 0) return;
    try {
      setIsDeleteAllLoading(true);

      for (const image of trainingImages) {
        await deleteTrigger({
          teamId: selectedTeam.id,
          modelId: model.id,
          trainingImageId: image.id,
        }).unwrap();
      }

      Track(AnalyticsEvents.CreateModel.DeletedAllTrainingImages, {
        modelId: model.id,
      });
      successToast({
        title:
          trainingImages.length > 1
            ? `${trainingImages.length} images removed`
            : "Image removed",
      });
      onDeleteAll();
    } catch (err) {
      errorToast({
        title: "Error deleting image",
        description: "There was an error deleting the image, please try again",
      });
    } finally {
      setIsDeleteAllLoading(false);
    }
  }, [
    deleteTrigger,
    successToast,
    errorToast,
    setIsDeleteAllLoading,
    onDeleteAll,
    model,
    selectedTeam.id,
    trainingImages,
  ]);

  const handleRemoveBgAllClick = useCallback(async () => {
    if (!model || trainingImages.length === 0) return;
    try {
      infoToast({
        title: "Removing backgrounds",
      });
      setIsRemoveAllBackgroundsLoading(true);
      // reversed to keep order
      const trainingImagesReversed = [...trainingImages].reverse();
      for (const trainingImage of trainingImagesReversed) {
        await removeBackground({
          trainingImage,
          model,
          withToast: false,
          isBulk:
            trainingImage.id !==
            trainingImagesReversed[trainingImagesReversed.length - 1].id,
        });
      }
      successToast({
        title: "Backgrounds removed",
      });
      Track(AnalyticsEvents.CreateModel.AllTrainingImagesBackgroundRemoved, {
        modelId: model.id,
      });
    } catch (_) {
      // toast is handled by removeBackground
    } finally {
      onImageChange();
      setIsRemoveAllBackgroundsLoading(false);
    }
  }, [
    removeBackground,
    setIsRemoveAllBackgroundsLoading,
    onImageChange,
    trainingImages,
    model,
    successToast,
    infoToast,
  ]);

  const handleImagePreviewClick = useCallback(
    (index: number) => {
      setImagePreviewIndex(index);
    },
    [setImagePreviewIndex]
  );

  const handlePreviewModalImageClose = useCallback(() => {
    setImagePreviewIndex(undefined);
  }, [setImagePreviewIndex]);

  const handlePreviewModalImageChange = useCallback(
    (index: number) => {
      setImagePreviewIndex(index);
    },
    [setImagePreviewIndex]
  );

  // ----------------------------------

  useEffect(() => {
    const onImagePastedCallback = (images: string[]) => {
      onImageUpload(
        images.map((i) => {
          return {
            original: {
              assetId: undefined,
              src: i,
            },
          };
        })
      );

      successToast({
        title: "Image(s) imported from clipboard",
      });
    };

    addImagePasteListener(onImagePastedCallback);
    return () => {
      removeImagePasteListener(onImagePastedCallback);
    };
  }, [
    addImagePasteListener,
    removeImagePasteListener,
    onImageUpload,
    successToast,
  ]);

  useEffect(() => {
    if (!initialAssets || initialAssets.length === 0 || isInitialAssetsSelected)
      return;
    setIsInitialAssetsSelected(true);
    handleLibraryAssetSelect(initialAssets);
  }, [
    handleLibraryAssetSelect,
    setIsInitialAssetsSelected,
    isInitialAssetsSelected,
    initialAssets,
  ]);

  // ----------------------------------

  return (
    <>
      <VStack align="stretch" flex={1} overflow="auto" pb={6} spacing={0}>
        <HStack justify="space-between" px={6} py={5} spacing={0}>
          <Text py={1} size="title.sm">
            Training Images
          </Text>

          {trainingImages.length > 1 && (
            <HStack spacing={2}>
              <PremiumButtonWrapper
                shouldShowWrapper={selectedTeam.isFreePlan}
                tooltip="Subscribe to bulk remove backgrounds"
              >
                <Button
                  isDisabled={selectedTeam.isFreePlan || !hasEnoughCu}
                  variant="secondary"
                  isLoading={isRemoveAllBackgroundsLoading}
                  onClick={handleRemoveBgAllClick}
                  size="sm"
                >
                  <span>Remove All Backgrounds</span>
                  {!selectedTeam.isFreePlan && (
                    <CuIndicator
                      p={0}
                      mr={-1}
                      cuCost={cuCost}
                      isCuLoading={isCuLoading}
                    />
                  )}
                </Button>
              </PremiumButtonWrapper>

              <Button
                variant="secondary"
                isLoading={isDeleteAllLoading}
                onClick={handleDeleteAllClick}
                size="sm"
              >
                Delete All
              </Button>
            </HStack>
          )}
        </HStack>

        <HStack ref={animateRef} align="stretch" wrap="wrap" px={6} spacing={2}>
          <CompatibleImageUploader
            multiple
            value={[]}
            onChange={handleImageUploadingChange}
            dataURLKey="data_url"
            allowNonImageType={false}
            resolutionHeight={512}
            resolutionWidth={512}
          >
            {({ onImageUpload, dragProps: { onDrop: onDropCallback } }) => (
              <AddButton
                compact={isCaptionHidden}
                disabled={!isUploadable}
                loading={isUploading}
                isDraggingHover={isDraggingHover}
                onClick={() =>
                  handleBlurFocusOnUploadClick(onImageUpload, setIsUploading)
                }
                onMultiSelect={handleLibraryAssetSelect}
                onDrop={(e) => onDrop(e, onDropCallback)}
                {...dragFunctions}
              />
            )}
          </CompatibleImageUploader>

          {trainingImages.map((trainingImage, idx) => (
            <TrainImagesItem
              key={trainingImage.id}
              disabled={isDeleteAllLoading}
              captionHidden={isCaptionHidden}
              trainingImage={trainingImage}
              model={model!}
              lowResMax={lowResMax}
              onChange={onImageChange}
              onPreviewClick={() => handleImagePreviewClick(idx)}
              isTraining
            />
          ))}
        </HStack>
      </VStack>

      <PreviewModalImage
        isOpen={imagePreviewIndex !== undefined}
        onClose={handlePreviewModalImageClose}
        onChange={handlePreviewModalImageChange}
        imageSrc={previewImageUrl}
        images={trainingImageUrls}
      />
    </>
  );
}

// ------------------------------------

interface AddButtonProps {
  disabled?: boolean;
  compact?: boolean;
  loading?: boolean;
  isDraggingHover?: boolean;
  onClick: FlexProps["onClick"];
  onMultiSelect: SelectLibraryAssetProps["onMultiSelect"];
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
  onDragEnter: (event: React.DragEvent<HTMLElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLElement>) => void;
}

function AddButton({
  disabled: isDisabled,
  compact: isCompact,
  loading: isLoading,
  isDraggingHover,
  onClick,
  onMultiSelect,
  onDrop,
  onDragEnter,
  onDragLeave,
  onDragOver,
}: AddButtonProps) {
  return (
    <VStack
      align="stretch"
      w="204px"
      h={isCompact ? "204px" : "300px"}
      transition={extraTheme.transitions.fast}
      spacing={2}
    >
      <SelectLibraryAsset
        isDisabled={isDisabled || isLoading}
        onMultiSelect={onMultiSelect}
        libraryProps={{ assetType: "image" }}
      />

      <VStack
        as={Button}
        flex={1}
        borderWidth={1}
        borderColor={isDraggingHover ? "primary.500" : "border.500"}
        borderRadius="lg"
        _hover={{ bgColor: "backgroundTertiary.800" }}
        bgColor="backgroundTertiary.500"
        data-testid="model-add-images-button"
        isDisabled={isDisabled || isLoading}
        onClick={!isDisabled && !isLoading ? onClick : undefined}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        variant="unstyled"
      >
        <Flex
          align="center"
          justify="center"
          w="48px"
          h="48px"
          borderRadius="50%"
          bgColor="primary.500"
        >
          {isLoading ? <Spinner /> : <Icon id="Ui/PlusSm" h="18px" />}
        </Flex>

        <Text color="textSecondary" lineHeight="24px" size="body.md">
          <span>Drag & drop, paste</span>
          <br />
          <span>{" or "}</span>
          <Text as="span" color="primary.500">
            <b>
              <u>upload images</u>
            </b>
          </Text>
        </Text>
      </VStack>
    </VStack>
  );
}
