import React, { useCallback, useMemo, useState } from "react";
import { getImageFileName } from "domains/assets/utils";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import loadBase64ImageFromUrl from "domains/image/methods/loadBase64ImageFromUrl";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
  useLazyGetAssetsByAssetIdQuery,
  usePostDownloadAssetsMutation,
} from "infra/api/generated/api";
import _ from "lodash";

import { Menu, MenuButton, MenuList } from "@chakra-ui/react";

export interface ButtonDownloadProps {
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

type DOWNLOAD_FORMATS = "jpeg" | "png" | "webp" | "svg";

export default function ButtonDownload({
  model,
  inference,
  asset,
}: ButtonDownloadProps) {
  const { selectedTeam } = useTeamContext();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();
  const { successToast, errorToast } = useScenarioToast();
  const [isDownloading, setIsDownloading] = useState(false);
  const [getAssetById] = useLazyGetAssetsByAssetIdQuery();

  const handleDownload = useCallback(
    async (format: DOWNLOAD_FORMATS) => {
      if (!asset) return;
      setIsDownloading(true);
      let assetUrl = asset.url;
      if (!assetUrl.includes("/assets-transform")) {
        const transformedAsset = await getAssetById({
          teamId: selectedTeam.id,
          assetId: asset.id,
        });
        if (transformedAsset.data?.asset) {
          assetUrl = transformedAsset.data.asset.url;
        }
      }
      ["quality", "format", "width", "height"].forEach((key) => {
        const regex = new RegExp(`[?&]${key}=[^&]*`);
        assetUrl = assetUrl.replace(regex, "");
      });
      const newUrl = `${assetUrl}&format=${format}`;

      const base64 = await loadBase64ImageFromUrl(newUrl);
      if (base64) {
        const link = document.createElement("a");
        link.href = base64;
        link.download = getImageFileName({
          model,
          inference,
          asset,
          options: {
            format,
          },
        });
        link.click();
        Track(AnalyticsEvents.AssetZoom.Downloaded, {
          assetId: asset.id,
          format,
        });
      } else {
        errorToast({
          title: "Error downloading image",
        });
      }
      setIsDownloading(false);
    },
    [asset, getAssetById, selectedTeam.id, model, inference, errorToast]
  );

  const availableFormats: DOWNLOAD_FORMATS[] = useMemo(() => {
    if (!asset) {
      return [];
    }
    if (
      asset.mimeType === "image/svg+xml" ||
      asset.metadata.type === "vectorization"
    ) {
      return ["svg"];
    }
    if (asset.metadata.type === "background-removal") {
      return ["png"];
    }
    return ["jpeg", "png", "webp"];
  }, [asset]);

  const [triggerDownload] = usePostDownloadAssetsMutation();
  const downloadMaps = useCallback(async () => {
    if (!asset) return;
    setIsDownloading(true);
    try {
      await triggerDownload({
        teamId: selectedTeam.id,
        body: {
          query: {
            assetIds: [asset.id],
            modelIds: [],
            inferenceIds: [],
          },
          options: {
            flat: true,
            fileNameTemplate: "",
          },
        },
      }).unwrap();
      successToast({
        title: "Download initiated",
        description:
          "An email with your download link will be sent to you once available.",
      });
    } catch (error: any) {
      handleApiError(error, "There was an error initiating your download", {
        quota: () => {
          if (_.get(error, "data.details.remainingSeconds")) {
            showLimitModal("planCooldown", {
              timeout: error.data.details.remainingSeconds,
              type: "download",
            });
          }
        },
      });
    } finally {
      setIsDownloading(false);
    }
  }, [
    asset,
    handleApiError,
    selectedTeam.id,
    showLimitModal,
    successToast,
    triggerDownload,
  ]);

  useHotkeys(appShortcuts.assetPage.shortcuts.download.shortcut, () => {
    if (asset?.metadata.type === "texture") {
      void downloadMaps();
    } else {
      void handleDownload(availableFormats[0]);
    }
  });

  return asset?.metadata.type === "texture" ? (
    <Button
      variant="secondary"
      size="sm"
      isLoading={isDownloading}
      onClick={downloadMaps}
      leftIcon={<Icon id="Ui/Download" h="16px" />}
    >
      Download Maps
    </Button>
  ) : (
    <Menu>
      <MenuButton
        as={Button}
        p={2}
        isDisabled={!asset}
        isLoading={isDownloading}
        onClick={
          availableFormats.length === 1
            ? (e) => {
                e.preventDefault();
                void handleDownload(availableFormats[0]);
              }
            : undefined
        }
        size="sm"
        variant="secondary"
      >
        <Icon id="Ui/Download" h="18px" />
      </MenuButton>

      <MenuList>
        {availableFormats.includes("png") && (
          <MenuItem
            onClick={() => handleDownload("png")}
            text="PNG (Full resolution)"
            tooltipProps={{
              placement: "bottom",
            }}
          />
        )}

        {availableFormats.includes("jpeg") && (
          <MenuItem
            onClick={() => handleDownload("jpeg")}
            text="JPEG (85% compression)"
          />
        )}

        {availableFormats.includes("webp") && (
          <MenuItem
            onClick={() => handleDownload("webp")}
            text="WebP (Full resolution)"
            tooltipProps={{
              placement: "bottom",
            }}
          />
        )}
      </MenuList>
    </Menu>
  );
}
