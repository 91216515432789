import React, { useCallback, useEffect, useState } from "react";
import { MAX_BASE64_UPLOAD_SIZE } from "domains/assets/constants/upload";
import useAssetRemoveBackground from "domains/assets/hooks/useAssetRemoveBackground";
import { useAssetRemoveBackgroundCu } from "domains/assets/hooks/useAssetRemoveBackgroundCu";
import useGetUploadedAsset from "domains/assets/hooks/useGetUploadedAsset";
import { useScenarioAssetDrop } from "domains/assets/hooks/useScenarioAssetDrop";
import { getBase64Mime } from "domains/assets/utils";
import { compressImageForUpload } from "domains/assets/utils/compressImage";
import { loadImage } from "domains/canvas/components/sections/DrawingTools/mergeImage";
import formatDate from "domains/canvas/utils/formatDate";
import { useClipboardContext } from "domains/commons/contexts/ClipboardProvider";
import { getMultipartChunkCount } from "domains/commons/misc";
import { GuideKeys } from "domains/guide/constants/guides";
import ButtonDroppableUpload from "domains/image/components/ButtonDroppableUpload";
import { ModalLibraryAssetSelectProps } from "domains/image/components/ModalLibraryAssetSelect";
import SelectLibraryAsset from "domains/image/components/SelectLibraryAsset";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import ButtonSectionTopIcon from "domains/inference/components/InferenceGenerator/Sidebar/ButtonSectionTopIcon";
import SidebarSection from "domains/inference/components/InferenceGenerator/Sidebar/Section";
import { useSectionsContext } from "domains/inference/contexts/SectionsProvider";
import { ButtonWithModalMiniCanvas } from "domains/mini-canvas/components/MiniCanvas";
import { MiniCanvasProvider } from "domains/mini-canvas/contexts/MiniCanvasProvider";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import CompatibleImageUploader, {
  handleBlurFocusOnUploadClick,
  ImageListType,
} from "domains/ui/components/CompatibleImageUploader";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import ModalCrop, { ModalCropProps } from "domains/ui/components/ModalCrop";
import PreviewModalImage from "domains/ui/components/PreviewModalImage";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { removeCuFromType } from "infra/api/ExludeCU";
import {
  GetAssetsByAssetIdApiResponse,
  usePostAssetMutation,
  usePostPatchInferencesMutation,
  usePostUploadsActionByTeamIdMutation,
  usePostUploadsMutation,
} from "infra/api/generated/api";
import { useIntl } from "react-intl";

import {
  Box,
  BoxProps,
  Center,
  Flex,
  HStack,
  Image as ChakraImage,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  StackProps,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";

export interface SidebarSectionReferenceImageBaseProps {
  title: string;
  id: string;
  noCollapse?: boolean;
  image: string | undefined;
  assetId?: string;
  setImage: (
    image: string,
    assetId?: string,
    assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
  ) => void;
  withSketch?: "always" | "edit" | "never";
  sketchDimensions?: [number, number];
  isLoading?: boolean;
  imageTag?: string;
  modeMapContent?: React.ReactNode;
  additionalContent?: React.ReactNode;
  resize:
    | {
        trackingEvent: string;
        trackingExtraParams: Record<string, unknown>;
      }
    | undefined;
  removeBackgroundTrackingExtraParams: Record<string, unknown>;
  builderSectionHeaderStyle?: StackProps;
  builderSectionContentBoxStyle?: BoxProps;
  disablePaste?: boolean;
  guide?: GuideKeys;
  withRemoveBackground?: boolean;
  isImmutable?: boolean;
  preventCompression?: boolean;
  libraryProps?: ModalLibraryAssetSelectProps["libraryProps"];
}

export default function SidebarSectionReferenceImageBase({
  title,
  id,
  noCollapse,
  image,
  assetId,
  setImage,
  withSketch = "always",
  sketchDimensions,
  isLoading = false,
  imageTag,
  modeMapContent,
  additionalContent,
  resize,
  removeBackgroundTrackingExtraParams,
  builderSectionHeaderStyle,
  builderSectionContentBoxStyle,
  disablePaste,
  guide,
  withRemoveBackground = true,
  isImmutable = false,
  preventCompression = false,
  libraryProps,
}: SidebarSectionReferenceImageBaseProps) {
  const { formatMessage } = useIntl();
  const getUploadedAsset = useGetUploadedAsset();
  const [uploadFileTrigger] = usePostUploadsMutation();
  const [runActionOnUploadTrigger] = usePostUploadsActionByTeamIdMutation();
  const { selectedTeam } = useTeamContext();
  const { successToast, errorToast } = useScenarioToast();
  const { addImagePasteListener, removeImagePasteListener } =
    useClipboardContext();

  const { toggleCollapsedSection } = useSectionsContext();
  const [isPreviewModalImageOpen, setIsPreviewModalImageOpen] =
    useState<boolean>(false);
  const [imageToCrop, setImageToCrop] =
    useState<ModalCropProps["imageToCrop"]>();
  const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number | undefined>(
    undefined
  );

  const { handleAssetUrl, isLoading: isAssetUrlLoading } = useScenarioAssetDrop(
    {
      onAssetFound: async (asset) => {
        await handleSetImage(asset.url, asset.id, asset);
      },
    }
  );

  const { isDraggingHover, dragFunctions, onDrop } = useImageUploadDragDrop({
    onImageDrop: handleAssetUrl,
  });

  // ----------------------------------
  const [postAssetTrigger] = usePostAssetMutation();
  const handleSetImage = useCallback(
    async (
      image: string,
      assetId?: string,
      asset?: GetAssetsByAssetIdApiResponse["asset"]
    ) => {
      if (!image || assetId || !resize) {
        setImage(image, assetId, asset);
        return;
      }

      setIsImageUploadLoading(true);

      const imageArrBuffer = await (await fetch(image)).arrayBuffer();
      const imageBase64Size = imageArrBuffer.byteLength;
      const imageBase64Mime = getBase64Mime(image) ?? "image/jpg";

      try {
        if (preventCompression && imageBase64Size >= MAX_BASE64_UPLOAD_SIZE) {
          const chunksCount = getMultipartChunkCount(imageBase64Size);
          const chunkSize = Math.ceil(imageBase64Size / chunksCount);
          const uploadInfo = await uploadFileTrigger({
            teamId: selectedTeam.id,
            body: {
              kind: "asset",
              contentType: imageBase64Mime,
              parts: chunksCount,
            },
          }).unwrap();

          if (!uploadInfo.upload.parts) {
            throw new Error("Invalid upload response");
          }

          for (let i = 0; i < chunksCount; i++) {
            const chunk = imageArrBuffer.slice(
              i * chunkSize,
              chunkSize * (i + 1)
            );
            const chunkUrl = (uploadInfo.upload.parts ?? [])[i].url;

            await fetch(chunkUrl, { method: "PUT", body: chunk });
            setUploadProgress(Math.round(((i + 1) / chunksCount) * 80));
          }

          await runActionOnUploadTrigger({
            teamId: selectedTeam.id,
            uploadId: uploadInfo.upload.id,
            body: {
              action: "complete",
            },
          }).unwrap();

          const uploadedAsset = await getUploadedAsset(uploadInfo.upload.id);
          setImage(
            `${uploadedAsset.asset.url}&width=${Math.min(
              uploadedAsset.asset.metadata?.width ?? 2_048,
              2_048
            )}`,
            uploadedAsset.asset.id,
            uploadedAsset.asset
          );
        } else {
          Track(resize.trackingEvent, {
            size: imageBase64Size,
            resize: imageBase64Size > MAX_BASE64_UPLOAD_SIZE,
            ...resize.trackingExtraParams,
          });

          const resizedImage = await compressImageForUpload(image);
          if (!resizedImage) {
            throw new Error("Failed import");
          }

          const postAssetResponse = await postAssetTrigger({
            teamId: selectedTeam.id,
            body: {
              image: resizedImage.data,
              name: "reference-image-" + formatDate(Date.now()),
            },
          }).unwrap();
          setImage(
            postAssetResponse.asset.url,
            postAssetResponse.asset.id,
            postAssetResponse.asset
          );
        }

        toggleCollapsedSection(id, false);
      } catch (error) {
        errorToast({
          title: "Error importing image",
          description:
            "There was an error importing the image. Please try again.",
        });
      }

      setIsImageUploadLoading(false);
      setUploadProgress(undefined);
    },
    [
      setImage,
      errorToast,
      postAssetTrigger,
      runActionOnUploadTrigger,
      getUploadedAsset,
      uploadFileTrigger,
      toggleCollapsedSection,
      setIsImageUploadLoading,
      selectedTeam.id,
      id,
      resize,
      preventCompression,
    ]
  );

  const handleApiError = useHandleApiError();
  const [putImagePatchtrigger] = usePostPatchInferencesMutation();
  const handleSetImageForMiniCanvas = useCallback(
    async (newImage: string) => {
      if (!assetId || !image) {
        return handleSetImage(newImage);
      }

      setIsImageUploadLoading(true);

      const [imageA, imageB] = await Promise.all([
        loadImage(image),
        loadImage(newImage),
      ]);
      const patch = document.createElement("canvas");
      patch.width = imageA.width;
      patch.height = imageA.height;
      const ctx = patch.getContext("2d");
      if (!ctx) {
        return;
      }
      ctx.globalCompositeOperation = "difference";
      ctx.drawImage(imageA, 0, 0);
      ctx.globalCompositeOperation = "source-over";
      ctx.drawImage(imageB, 0, 0);
      const patchData = patch.toDataURL("image/png");

      try {
        const patchImage = await putImagePatchtrigger({
          teamId: selectedTeam.id,
          body: {
            image: assetId,
            patch: {
              mode: "override",
              image: patchData,
            },
          },
        })
          .unwrap()
          .then(removeCuFromType);

        if (patchImage.asset) {
          await handleSetImage(
            patchImage.asset.url,
            patchImage.asset.id,
            patchImage.asset
          );
        }
      } catch (error) {
        handleApiError(error, "Error uploading image");
      } finally {
        setIsImageUploadLoading(false);
      }
    },
    [
      assetId,
      handleApiError,
      handleSetImage,
      image,
      putImagePatchtrigger,
      selectedTeam.id,
    ]
  );

  const { handleRemoveBackgroundAsset, isRemoveBackgroundLoading } =
    useAssetRemoveBackground();

  const onRemoveBackground = useCallback(async () => {
    if (!assetId) {
      return;
    }
    const newAsset = await handleRemoveBackgroundAsset({
      assetId,
      trackingExtraParams: removeBackgroundTrackingExtraParams,
    });
    if (newAsset) {
      void handleSetImage(newAsset.url, newAsset.id, newAsset);
    }
  }, [
    assetId,
    handleRemoveBackgroundAsset,
    handleSetImage,
    removeBackgroundTrackingExtraParams,
  ]);

  const handleUploadInputChange = useCallback(
    async (imageList: ImageListType) => {
      if (imageList.length === 0) {
        return;
      }

      const dataUrl = imageList[0].dataURL;
      if (dataUrl === undefined) {
        return;
      }

      await handleSetImage(dataUrl);
    },
    [handleSetImage]
  );

  const handlePreviewModalImageClose = useCallback(() => {
    setIsPreviewModalImageOpen(false);
  }, [setIsPreviewModalImageOpen]);

  // ----------------------------------

  useEffect(() => {
    if (disablePaste) {
      return;
    }
    const onImagePastedCallback = async (images: string[]) => {
      if (images.length > 0) {
        await handleSetImage(images[0]);
        successToast({
          title: "Image imported from clipboard",
        });
      }
    };

    addImagePasteListener(onImagePastedCallback);

    return () => {
      removeImagePasteListener(onImagePastedCallback);
    };
  }, [
    addImagePasteListener,
    removeImagePasteListener,
    handleSetImage,
    successToast,
    disablePaste,
  ]);

  const { cuCost, isCuLoading } = useAssetRemoveBackgroundCu({
    assetId: withRemoveBackground ? assetId : undefined,
  });

  const handleCropCancel = useCallback(() => {
    setImageToCrop(undefined);
  }, [setImageToCrop]);

  const handleCropSubmit = useCallback(
    async (asset: GetAssetsByAssetIdApiResponse["asset"]) => {
      await handleSetImage(asset.url, asset.id, asset);
      setImageToCrop(undefined);
    },
    [handleSetImage, setImageToCrop]
  );

  return (
    <MiniCanvasProvider>
      <SidebarSection
        key={isLoading ? `${id}Loading` : id}
        headerProps={{
          id,
          title,
          noCollapse: isImmutable || noCollapse,
          topRight:
            image && !isImmutable ? (
              <HStack spacing={2}>
                <ButtonSectionTopIcon onClick={() => handleSetImage("")}>
                  <Icon id="Ui/TrashMd" h="14px" />
                </ButtonSectionTopIcon>

                {withSketch !== "never" && (
                  <ButtonWithModalMiniCanvas
                    defaultImage={image}
                    onAccept={handleSetImageForMiniCanvas}
                    canvasDimensions={sketchDimensions}
                    CustomButton={ButtonSectionTopIcon}
                  >
                    <Icon id="Ui/Pencil" h="14px" />
                  </ButtonWithModalMiniCanvas>
                )}

                <Menu placement="bottom-end">
                  <MenuButton
                    as={ButtonSectionTopIcon}
                    isLoading={isRemoveBackgroundLoading}
                  >
                    <Flex justify="center" w="100%">
                      <Icon id="Ui/Dots" />
                    </Flex>
                  </MenuButton>

                  <MenuList>
                    <MenuItem
                      onClick={() =>
                        setImageToCrop({
                          assetId,
                          src: image,
                        })
                      }
                      text={formatMessage({
                        id: "domains.inference.inferenceGenerator.cropImage",
                        defaultMessage: "Crop Image",
                      })}
                    />

                    {withRemoveBackground && (
                      <MenuItem
                        onClick={onRemoveBackground}
                        text={formatMessage({
                          id: "domains.inference.inferenceGenerator.removeBackground",
                          defaultMessage: "Remove Background",
                        })}
                        cuCost={{
                          cost: cuCost,
                          isLoading: isCuLoading,
                        }}
                      />
                    )}
                  </MenuList>
                </Menu>
              </HStack>
            ) : undefined,
          style: builderSectionHeaderStyle,
          guide,
        }}
        contentBoxStyle={builderSectionContentBoxStyle}
      >
        <Flex w="full" data-testid={`${id}-section`}>
          <CompatibleImageUploader
            value={[]}
            onChange={handleUploadInputChange}
            allowNonImageType={false}
            maxNumber={1}
            multiple={false}
          >
            {({ onImageUpload, dragProps: { onDrop: dragPropsOnDrop } }) => {
              if (image) {
                return (
                  <Flex align="center" direction="column" gap={4} w="100%">
                    <HStack spacing={2}>
                      {isImageUploadLoading ||
                      isLoading ||
                      isAssetUrlLoading ? (
                        <Center
                          w="160px"
                          h="160px"
                          borderRadius="md"
                          bgColor="backgroundQuaternary.500"
                        >
                          <Spinner />
                        </Center>
                      ) : (
                        <Box
                          {...dragFunctions}
                          pos="relative"
                          onDrop={(e) => onDrop(e, dragPropsOnDrop)}
                        >
                          <Link
                            onClick={() => setIsPreviewModalImageOpen(true)}
                          >
                            <ChakraImage
                              maxH="160px"
                              borderRadius="md"
                              alt="User uploaded image"
                              data-testid={`${id}-drag-and-drop-alt-image-area`}
                              fallback={
                                <Center
                                  w="160px"
                                  h="160px"
                                  borderRadius="md"
                                  bgColor="backgroundQuaternary.500"
                                >
                                  <Spinner />
                                </Center>
                              }
                              src={image}
                            />
                            {imageTag && (
                              <Box pos="absolute" bottom={2} left={2}>
                                <Tag variant="secondary">{imageTag}</Tag>
                              </Box>
                            )}
                          </Link>
                        </Box>
                      )}

                      {modeMapContent}
                    </HStack>

                    {additionalContent}
                  </Flex>
                );
              } else if (!isImmutable) {
                return (
                  <VStack w="100%" spacing={2}>
                    <SelectLibraryAsset
                      onSelect={(asset) => {
                        void handleSetImage(
                          asset.meta.url,
                          asset.id,
                          asset.meta
                        );
                      }}
                      libraryProps={libraryProps}
                    />

                    {withSketch === "always" && (
                      <ButtonWithModalMiniCanvas
                        onAccept={handleSetImage}
                        canvasDimensions={sketchDimensions}
                        variant="secondary"
                        size="sm"
                        w="100%"
                      >
                        Sketch
                      </ButtonWithModalMiniCanvas>
                    )}

                    <Divider label="OR" w="100%" />

                    <ButtonDroppableUpload
                      isLoading={
                        isImageUploadLoading ||
                        isLoading ||
                        isAssetUrlLoading ||
                        isUploading
                      }
                      loadingProgress={uploadProgress}
                      isDraggingHover={isDraggingHover}
                      onClick={() =>
                        handleBlurFocusOnUploadClick(
                          onImageUpload,
                          setIsUploading
                        )
                      }
                      onDrop={(e) => onDrop(e, dragPropsOnDrop)}
                      {...dragFunctions}
                    />
                  </VStack>
                );
              } else {
                return (
                  <Text color="textSecondary" size="body.sm">
                    -
                  </Text>
                );
              }
            }}
          </CompatibleImageUploader>
        </Flex>
      </SidebarSection>

      {!!image && (
        <>
          <PreviewModalImage
            isOpen={isPreviewModalImageOpen}
            imageSrc={image}
            onClose={handlePreviewModalImageClose}
          />

          <ModalCrop
            imageToCrop={imageToCrop}
            onSubmit={handleCropSubmit}
            onCancel={handleCropCancel}
          />
        </>
      )}
    </MiniCanvasProvider>
  );
}
