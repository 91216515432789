import { useCallback, useMemo } from "react";
import usePersistedState from "domains/commons/hooks/usePersistedState";
import { HeaderFilterAssetTypeProps } from "domains/file-manager/components/FileManagerImage/HeaderFilterAssetType";
import { FileManagerTopBarFilterAsset } from "domains/file-manager/components/FileManagerTopBar";
import {
  AssetType,
  DEFAULT_VALUES,
  FILTER_ASSET_TYPES,
  FilterAssetTypeKey,
  FilterAssetTypeMap,
  PERSISTED_KEYS,
} from "domains/file-manager/constants/AssetFilter";

export interface UseAssetFilterTypeWithAssetTypeArgs {
  assetType: AssetType;
  showVectorization?: boolean;
  forcedOptions?: undefined;
  avoidPersistedState?: boolean;
  forcedValues?: undefined;
}

export interface UseAssetFilterTypeWithForcedOptionsArgs {
  assetType?: undefined;
  showVectorization?: undefined;
  forcedOptions?: FilterAssetTypeKey[];
  avoidPersistedState?: undefined;
  forcedValues?: FilterAssetTypeKey[];
}

function isWithAssetType(
  args:
    | UseAssetFilterTypeWithAssetTypeArgs
    | UseAssetFilterTypeWithForcedOptionsArgs
): args is UseAssetFilterTypeWithAssetTypeArgs {
  return "assetType" in args;
}

export default function useAssetFilterType(
  props:
    | UseAssetFilterTypeWithAssetTypeArgs
    | UseAssetFilterTypeWithForcedOptionsArgs
) {
  const hasAssetType = isWithAssetType(props);

  const [types, setTypes] = usePersistedState<
    FilterAssetTypeKey[] | "all" | undefined
  >(
    hasAssetType &&
      !Array.isArray(props.assetType) &&
      !props.avoidPersistedState
      ? PERSISTED_KEYS[props.assetType]
      : undefined,
    {
      defaultValue:
        hasAssetType &&
        !Array.isArray(props.assetType) &&
        !props.avoidPersistedState
          ? DEFAULT_VALUES[props.assetType]
          : undefined,
    }
  );

  const options: Partial<FilterAssetTypeMap> = useMemo(() => {
    if (!hasAssetType) {
      return Object.entries(FILTER_ASSET_TYPES).reduce((acc, [key, value]) => {
        return (props.forcedValues ?? props.forcedOptions ?? []).includes(
          key as FilterAssetTypeKey
        )
          ? { ...acc, [key]: value }
          : acc;
      }, {});
    } else if (props.assetType !== "all") {
      return Object.entries(FILTER_ASSET_TYPES).reduce((acc, [key, value]) => {
        if (!props.showVectorization && key === "image:vectorization") {
          return acc;
        }

        if (Array.isArray(props.assetType)) {
          return props.assetType.some((type) => key.startsWith(`${type}:`))
            ? { ...acc, [key]: value }
            : acc;
        }

        return key.startsWith(`${props.assetType}:`)
          ? { ...acc, [key]: value }
          : acc;
      }, {});
    }
    if (!props.showVectorization) {
      return Object.entries(FILTER_ASSET_TYPES).reduce((acc, [key, value]) => {
        if (key === "image:vectorization") {
          return acc;
        }
        return { ...acc, [key]: value };
      }, {});
    }
    return FILTER_ASSET_TYPES;
  }, [
    hasAssetType,
    props.assetType,
    props.forcedOptions,
    props.forcedValues,
    props.showVectorization,
  ]);

  const optionsV2 = useMemo<HeaderFilterAssetTypeProps["options"]>(() => {
    if (!hasAssetType) {
      return Object.entries(FILTER_ASSET_TYPES).reduce((acc, [key, value]) => {
        return (props.forcedValues ?? props.forcedOptions ?? []).includes(
          key as FilterAssetTypeKey
        )
          ? { ...acc, [key]: value }
          : acc;
      }, {});
    } else if (props.assetType !== "all") {
      return Object.entries(FILTER_ASSET_TYPES).reduce((acc, [key, value]) => {
        if (!props.showVectorization && key === "image:vectorization") {
          return acc;
        }

        if (Array.isArray(props.assetType)) {
          return props.assetType.some((type) => key.startsWith(`${type}:`))
            ? { ...acc, [key]: value }
            : acc;
        }

        return key.startsWith(`${props.assetType}:`)
          ? { ...acc, [key]: value }
          : acc;
      }, {});
    }
    if (!props.showVectorization) {
      return Object.entries(FILTER_ASSET_TYPES).reduce((acc, [key, value]) => {
        if (key === "image:vectorization") {
          return acc;
        }
        return { ...acc, [key]: value };
      }, {});
    }
    return FILTER_ASSET_TYPES;
  }, [
    hasAssetType,
    props.assetType,
    props.forcedOptions,
    props.forcedValues,
    props.showVectorization,
  ]);

  const validTypes = useMemo(
    () =>
      Array.isArray(types) &&
      !props.showVectorization &&
      !props.forcedOptions?.includes("image:vectorization")
        ? types.filter((type) => type !== "image:vectorization")
        : types,
    [types, props.showVectorization, props.forcedOptions]
  );

  const selectedTypes = useMemo(() => {
    return !validTypes?.length || validTypes === "all"
      ? Object.values(options)
          .map((option) => option.value)
          .flat()
      : validTypes
          .map((type) => {
            return options[type]?.value;
          })
          .filter((type) => type !== undefined)
          .flat();
  }, [validTypes, options]);

  const fmHeaderFilterAssetProps = useMemo<
    HeaderFilterAssetTypeProps | undefined
  >(
    () =>
      props.forcedValues
        ? undefined
        : {
            values: (validTypes !== "all" && validTypes) || [],
            options: optionsV2,
            onChange: (value: typeof types) =>
              setTypes(value === undefined ? "all" : value),
          },
    [props.forcedValues, validTypes, optionsV2, setTypes]
  );

  const reset = useCallback(() => {
    if (!props.assetType) return;
    const assetTypeArr = Array.isArray(props.assetType)
      ? props.assetType
      : [props.assetType];
    const types = assetTypeArr.flatMap((type) => DEFAULT_VALUES[type]);
    setTypes(types.includes("all") ? "all" : (types as FilterAssetTypeKey[]));
  }, [setTypes, props.assetType]);

  return {
    allAssetsTypeArgs: {
      types: selectedTypes,
    },
    assetFileManagerTypeOptions: {
      filterAssetTypes: validTypes === "all" ? undefined : validTypes,
      filterAssetTypeOptions: options,
      onFilterAssetTypeChange: (value: typeof types) =>
        setTypes(value === undefined ? "all" : value),
    } as FileManagerTopBarFilterAsset,
    fmHeaderFilterAssetProps,
    reset,
  };
}
