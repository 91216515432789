import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { ASSET_TYPE_LABELS } from "domains/assets/constants/assetTypes";
import { useAssetParents } from "domains/assets/hooks/useAssetParent";
import { reverseStrength } from "domains/assets/utils";
import { assetTypeToAssetWithIpAdapterType } from "domains/assets/utils/assetTypeToAssetWithIpAdapterType";
import { assertNever } from "domains/commons/assertNever";
import { getImageThumbnail } from "domains/file-manager/interfaces";
import { getShouldHaveNsfwBlur } from "domains/file-manager/utils/getShouldHaveNsfwBlur";
import {
  getControlNetInfluenceFromString,
  getControlNetPresetFromString,
} from "domains/inference/enum/ControlNetPreset";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  AsideList,
  AsideListItem,
  AsideSection,
} from "domains/ui/components/Aside";
import { useUserContext } from "domains/user/contexts/UserProvider";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
  useGetAssetsByAssetIdQuery,
} from "infra/api/generated/api";

import {
  Box,
  Center,
  Image,
  ImageProps,
  SimpleGrid,
  Tag,
  VStack,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export interface AsideSectionReferenceProps {
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  withImageLinks?: boolean;
}

export function AsideSectionReference({
  inference,
  asset,
  withImageLinks = true,
}: AsideSectionReferenceProps) {
  const props = {
    inference,
    asset,
    withImageLinks,
  };
  if (asset?.metadata.type === "canvas-export") {
    return null;
  }

  const children = <SectionReference {...props} />;

  if (children.type(props) === null) return null;

  const title = (() => {
    if (asset?.metadata.type.startsWith("texture-")) {
      return "Texture Set";
    } else if (asset?.metadata.type === "upscale-skybox") {
      return "Preview";
    } else {
      return "Reference";
    }
  })();

  return (
    <AsideSection id="referenceImage" title={title}>
      {children}
    </AsideSection>
  );
}

export function SectionReference({
  inference,
  asset,
  withImageLinks = true,
}: AsideSectionReferenceProps) {
  const { selectedTeam } = useTeamContext();

  const referenceParents = useAssetParents({
    type: "reference",
    asset,
  });
  const controlParents = useAssetParents({
    type: "control",
    parentAssetId: inference?.parameters.controlImageId,
  });
  const { currentData: ipAdapterAsset } = useGetAssetsByAssetIdQuery(
    inference?.parameters.ipAdapterImageId ??
      inference?.parameters.ipAdapterImageIds?.[0]
      ? {
          assetId:
            inference.parameters.ipAdapterImageId ??
            inference.parameters.ipAdapterImageIds![0],
          teamId: selectedTeam.id,
        }
      : skipToken
  );

  // If there is no image to show
  if (!referenceParents && !ipAdapterAsset) return null;

  return (
    <>
      <VStack align="stretch" spacing={2}>
        {referenceParents && (
          <ImageGrid
            {...referenceParents}
            label="Reference"
            withLink={withImageLinks}
          />
        )}
        {ipAdapterAsset?.asset &&
          inference?.parameters.type === "txt2img_ip_adapter" && (
            <ImageGrid
              image={ipAdapterAsset.asset}
              label={(() => {
                if (inference.parameters.ipAdapterType === "character") {
                  return "Character";
                } else if (inference.parameters.ipAdapterType === "style") {
                  return "Style";
                }
                return "IP Adapter";
              })()}
              withLink={withImageLinks}
            />
          )}

        {asset?.metadata.type === "restyle" && (
          <AsideList variant="50-50">
            <AsideListItem
              label="Structure Strength"
              value={asset.metadata.structureFidelity ?? "-"}
              copyable
            />
          </AsideList>
        )}
      </VStack>

      {!!inference && (
        <AsideList>
          <AsideListItem
            label="Type"
            value={
              asset
                ? ASSET_TYPE_LABELS[
                    assetTypeToAssetWithIpAdapterType({
                      assetType: asset.metadata.type,
                      ipAdapterType: inference?.parameters.ipAdapterType,
                    })
                  ]
                : "-"
            }
          />

          {!controlParents &&
            inference.parameters.type.includes("controlnet") && (
              <AsideListItem
                label="ControlNet"
                value={
                  inference.parameters.modality
                    ? getControlNetPresetFromString(
                        inference?.parameters.modality
                      )?.label
                    : "Unknown"
                }
              />
            )}

          <AsideListItem
            label="Influence"
            value={(() => {
              if (inference.parameters.type.includes("reference")) {
                return Math.round(
                  (inference.parameters.styleFidelity ?? 0.1) * 100
                );
              } else if (
                !controlParents &&
                inference.parameters.type.includes("controlnet")
              ) {
                return Math.round(
                  getControlNetInfluenceFromString(
                    inference.parameters.modality ?? ""
                  ) * 100
                );
              } else if (inference.parameters.type === "txt2img_ip_adapter") {
                return Math.round(
                  ((inference.parameters.ipAdapterScale !== undefined
                    ? inference.parameters.ipAdapterScale
                    : inference.parameters.ipAdapterScales?.[0]) ?? 0) * 100
                );
              } else {
                return Math.round(
                  reverseStrength(inference.parameters.strength ?? 0) * 100
                );
              }
            })()}
          />

          {inference.parameters.type.includes("reference") && (
            <>
              <AsideListItem
                label="Ref Attn"
                value={inference.parameters.referenceAttn ? "Yes" : "No"}
              />
              <AsideListItem
                label="Ref AdaIN"
                value={inference.parameters.referenceAdain ? "Yes" : "No"}
              />
            </>
          )}

          {controlParents && (
            <>
              <ImageGrid
                {...controlParents}
                label="Control"
                withLink={withImageLinks}
              />
              <AsideListItem
                label="ControlNet"
                value={
                  inference.parameters.modality
                    ? getControlNetPresetFromString(
                        inference?.parameters.modality
                      )?.label
                    : "Unknown"
                }
              />
              <AsideListItem
                label="Influence"
                value={Math.round(
                  getControlNetInfluenceFromString(
                    inference.parameters.modality ?? ""
                  ) * 100
                )}
              />
            </>
          )}

          {inference.parameters.type !== "txt2img_ip_adapter" &&
            ipAdapterAsset?.asset && (
              <>
                <ImageGrid
                  image={ipAdapterAsset.asset}
                  label={(() => {
                    switch (inference.parameters.ipAdapterType) {
                      case "character":
                        return "Character";
                      case "style":
                        return "Style";
                      case undefined:
                        return "IP Adapter";
                      default:
                        assertNever(inference.parameters.ipAdapterType);
                    }
                  })()}
                  withLink={withImageLinks}
                />
                <AsideListItem
                  label="Influence"
                  value={
                    ((inference.parameters.ipAdapterScale !== undefined
                      ? inference.parameters.ipAdapterScale
                      : inference.parameters.ipAdapterScales?.[0]) ?? 0) * 100
                  }
                />
              </>
            )}
        </AsideList>
      )}
    </>
  );
}

// ------------------------------------

interface ImageGridProps {
  image: GetAssetsByAssetIdApiResponse["asset"];
  modeMap?: GetAssetsByAssetIdApiResponse["asset"];
  label: string;
  withLink?: boolean;
}

function ImageGrid({ image, modeMap, label, withLink = true }: ImageGridProps) {
  const router = useRouter();
  const { nsfwFilteredTypes } = useUserContext();

  return (
    <SimpleGrid gap={2} columns={2}>
      <ImagePreview
        variant="left"
        title={modeMap ? label : undefined}
        imgSrc={getImageThumbnail({
          url: image.url,
          type: image.metadata.type,
        })}
        internalLink={
          withLink
            ? {
                pathname: router.pathname,
                query: {
                  ...router.query,
                  openAssetId: image.id,
                },
              }
            : undefined
        }
        isBlurred={getShouldHaveNsfwBlur({
          nsfwFilteredTypes,
          nsfw: image.nsfw,
        })}
      />

      {!!modeMap && (
        <ImagePreview
          variant="right"
          title="Mode Map"
          imgSrc={getImageThumbnail({
            url: modeMap.url,
            type: modeMap.metadata.type,
          })}
          internalLink={
            withLink
              ? {
                  pathname: router.pathname,
                  query: {
                    ...router.query,
                    openAssetId: modeMap.id,
                  },
                }
              : undefined
          }
          isBlurred={getShouldHaveNsfwBlur({
            nsfwFilteredTypes,
            nsfw: modeMap.nsfw,
          })}
        />
      )}
    </SimpleGrid>
  );
}

// ------------------------------------

interface ImagePreviewProps {
  imgSrc?: ImageProps["src"];
  title?: React.ReactNode;
  internalLink?: LinkProps["href"];
  variant?: "left" | "right";
  isBlurred: boolean;
}

function ImagePreview({
  imgSrc,
  title,
  internalLink,
  variant = "left",
  isBlurred,
}: ImagePreviewProps) {
  return (
    <Center
      as={internalLink ? Link : undefined}
      pos="relative"
      overflow="hidden"
      w="100%"
      borderRadius="md"
      bgColor="backgroundQuaternary.500"
      href={internalLink}
    >
      <Image
        h="160px"
        borderRadius="md"
        objectFit="cover"
        alt="Image"
        fallback={<Box w="100%" h="160px" />}
        filter={isBlurred ? "blur(20px)" : undefined}
        src={imgSrc + "&height=256"}
      />

      {title && (
        <Box
          pos="absolute"
          right={variant === "right" ? 2 : undefined}
          bottom={2}
          left={variant === "left" ? 2 : undefined}
        >
          <Tag variant="secondary">{title}</Tag>
        </Box>
      )}
    </Center>
  );
}
