import { useRef, useState } from "react";
import React from "react";
import { useRouter } from "next/router";
import CreateCollectionModal from "domains/collections/components/CreateCollectionModal";
import useAllCollections from "domains/collections/hooks/useAllCollections";
import { useCreateNewModelModalContext } from "domains/models/contexts/CreateNewModelModalProvider";
import { useNavBarContext } from "domains/navigation/contexts/NavBarProvider";
import ModalCreateTeam from "domains/teams/components/ModalCreateTeam";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { extraTheme } from "domains/theme";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";
import { PremiumButtonWrapper } from "domains/ui/components/PremiumBadge";
import { useHover } from "domains/ui/hooks/useHover";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  VStack,
} from "@chakra-ui/react";

import NavBarItem from "./ElementNavBarItem";
import NavBarSubItem from "./ElementNavBarSubItem";
import SectionLogo from "./SectionLogo";

export default React.memo(function NavBar() {
  const { isMobile, width, isDrawerOpen, closeDrawer } = useNavBarContext();

  if (isMobile) {
    return (
      <>
        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer} placement="left">
          <DrawerOverlay />
          <DrawerContent w={width} maxW={width} h="100vh">
            <NavBarContent />
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return (
    <Box as="nav">
      <NavBarContent />
      <Box w={`${width}px`} h="100%" transition={extraTheme.transitions.fast} />
    </Box>
  );
});

// ------------------------------------

const NavBarContent = React.memo(function NavBarContent() {
  const { selectedTeam } = useTeamContext();
  const { isMobile, isCompact, width } = useNavBarContext();
  const router = useRouter();
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const { collections } = useAllCollections();
  const { open: openCreateModelModal, openUpload: openUploadModelModal } =
    useCreateNewModelModalContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isCreateCollectionModalOpen, setIsCreateCollectionModalOpen] =
    useState(false);

  const path = router.pathname;

  const hasReframeFlag = useUserContext()?.featureFlags?.includes("reframe");

  return (
    <>
      <Box ref={containerRef} />

      <VStack
        ref={hoverRef}
        pos="fixed"
        align="stretch"
        overflowX="hidden"
        overflowY="auto"
        w={`${width}px`}
        h="100vh"
        minH="100vh"
        maxH="100vh"
        pb={4}
        borderRightWidth="1px"
        borderRightColor="border.500"
        transition={extraTheme.transitions.fast}
        __css={{
          "&::-webkit-scrollbar": {
            width: "0",
          },
        }}
        bgColor="black"
        spacing={4}
      >
        <SectionLogo isHover={isHover} />

        <VStack align={isCompact ? "start" : "stretch"} px={2.5} spacing={0.5}>
          <NavBarItem
            id="home"
            text="Home"
            icon={<Icon id="Nav/Home/Outline" w="18px" />}
            iconActive={<Icon id="Nav/Home/Solid" w="18px" />}
            link="/"
            dataTestId="SideMenuLink-Home"
            isCompact={isCompact}
            isActive={path === "/"}
          />

          <NavBarItem
            id="models"
            text="Models"
            icon={<Icon id="Nav/Model/Outline" w="18px" />}
            iconActive={<Icon id="Nav/Model/Solid" w="18px" />}
            link="/models"
            dataTestId="SideMenuLink-Models"
            isCompact={isCompact}
            isActive={path.startsWith("/models") || path.startsWith("/compose")}
            onClick={() => {
              Track(AnalyticsEvents.Navigation.ClickedModels);
            }}
          >
            <NavBarSubItem
              text="New"
              icon={<Icon id="Ui/Plus" w="12px" />}
              isActive={["/models/new", "/compose/new"].includes(path)}
              onClick={openCreateModelModal}
            />

            <NavBarSubItem
              text="Gallery"
              icon={<Icon id="Domains/Assets/Grid/Fill/Outline" w="14px" />}
              link="/models"
              isActive={path === "/models"}
            />

            <PremiumButtonWrapper
              shouldShowWrapper={selectedTeam.isFreePlan}
              shouldShowBorder={false}
            >
              <NavBarSubItem
                text="Upload"
                icon={<Icon id="Ui/Upload" w="14px" />}
                onClick={openUploadModelModal}
                isDisabled={selectedTeam.isFreePlan}
              />
            </PremiumButtonWrapper>
          </NavBarItem>

          <NavBarItem
            id="images"
            text="Images"
            icon={<Icon id="Nav/Image/Outline" w="18px" />}
            iconActive={<Icon id="Nav/Image/Solid" w="18px" />}
            link="/images"
            dataTestId="SideMenuLink-Images"
            isCompact={isCompact}
            isActive={
              path.startsWith("/images") ||
              path === "/pixelate" ||
              path === "/background-remove" ||
              path === "/enhance" ||
              path === "/vectorize" ||
              path === "/expand"
            }
            onClick={() => {
              Track(AnalyticsEvents.Navigation.ClickedImages);
            }}
          >
            <NavBarSubItem
              text="Generate"
              icon={<Icon id="Ui/Plus" w="12px" />}
              isActive={path === "/images/new"}
              link="/images/new"
            />

            <NavBarSubItem
              text="Gallery"
              icon={<Icon id="Domains/Assets/Grid/Fill/Outline" w="14px" />}
              link="/images"
              isActive={path === "/images"}
            />

            <NavBarSubItem
              text="Enhance"
              icon={<Icon id="Nav/Upscale/Outline" w="14px" />}
              isActive={path === "/enhance"}
              onClick={() => {
                Track(AnalyticsEvents.Navigation.ClickedUpscale);
              }}
              link="/enhance"
            />

            <NavBarSubItem
              text="Restyle"
              icon={<Icon id="Nav/Restyle/Outline" w="14px" />}
              isActive={path === "/restyle"}
              onClick={() => {
                Track(AnalyticsEvents.Navigation.ClickedRestyle);
              }}
              link="/restyle"
            />

            {hasReframeFlag && (
              <NavBarSubItem
                text="Expand"
                icon={<Icon id="Nav/Expand/Outline" w="14px" />}
                isActive={path === "/expand"}
                onClick={() => {
                  Track(AnalyticsEvents.Navigation.ClickedReframe);
                }}
                link="/expand"
              />
            )}

            <NavBarSubItem
              text="Vectorize"
              icon={<Icon id="Nav/Vectorize/Outline" w="14px" />}
              isActive={path === "/vectorize"}
              onClick={() => {
                Track(AnalyticsEvents.Navigation.ClickedVectorize);
              }}
              link="/vectorize"
            />

            <NavBarSubItem
              text="Pixelate"
              icon={<Icon id="Nav/Pixelate/Outline" w="14px" />}
              isActive={path === "/pixelate"}
              onClick={() => {
                Track(AnalyticsEvents.Navigation.ClickedPixelate);
              }}
              link="/pixelate"
            />

            <NavBarSubItem
              text="Remove Background"
              icon={<Icon id="Nav/BgRemove/Outline" w="14px" />}
              isActive={path === "/background-remove"}
              onClick={() => {
                Track(AnalyticsEvents.Navigation.ClickedRemoveBackground);
              }}
              link="/background-remove"
            />
          </NavBarItem>

          {!isMobile && (
            <NavBarItem
              id="canvas"
              text="Canvas"
              icon={<Icon id="Nav/Canvas/Outline" w="18px" />}
              iconActive={<Icon id="Nav/Canvas/Solid" w="18px" />}
              link="/canvas"
              dataTestId="SideMenuLink-Canvas"
              isCompact={isCompact}
              isActive={path.startsWith("/canvas")}
              onClick={() => {
                Track(AnalyticsEvents.Navigation.ClickedCanvas);
              }}
            >
              <NavBarSubItem
                text="New"
                icon={<Icon id="Ui/Plus" w="12px" />}
                isActive={path === "/canvas/new"}
                link="/canvas/new"
              />
              <NavBarSubItem
                text="Live"
                icon={<Icon id="Ui/Plus" w="12px" />}
                isActive={path === "/canvas/new"}
                link={{
                  pathname: "/canvas/new",
                  query: {
                    live: "true",
                  },
                }}
              />

              <NavBarSubItem
                text="Gallery"
                icon={<Icon id="Domains/Assets/Grid/Fill/Outline" w="14px" />}
                link="/canvas"
                isActive={path === "/canvas"}
              />
            </NavBarItem>
          )}

          <NavBarItem
            id="skyboxes"
            text="Skyboxes"
            icon={<Icon id="Nav/Skybox/Outline" w="18px" />}
            iconActive={<Icon id="Nav/Skybox/Solid" w="18px" />}
            link="/skyboxes"
            dataTestId="SideMenuLink-Skyboxes"
            isCompact={isCompact}
            isActive={path.startsWith("/skyboxes")}
            onClick={() => {
              Track(AnalyticsEvents.Navigation.ClickedSkyboxes);
            }}
          >
            <NavBarSubItem
              text="New"
              icon={<Icon id="Ui/Plus" w="12px" />}
              isActive={path === "/skyboxes/new"}
              link="/skyboxes/new"
            />

            <NavBarSubItem
              text="Gallery"
              icon={<Icon id="Domains/Assets/Grid/Fill/Outline" w="14px" />}
              link="/skyboxes"
              isActive={path === "/skyboxes"}
            />

            <NavBarSubItem
              text="Enhance"
              icon={<Icon id="Nav/Upscale/Outline" w="14px" />}
              isActive={path === "/skyboxes/enhance"}
              onClick={() => {
                Track(AnalyticsEvents.Navigation.ClickedUpscaleSkybox);
              }}
              link="/skyboxes/enhance"
            />
          </NavBarItem>

          <NavBarItem
            id="textures"
            text="Textures"
            icon={<Icon id="Nav/Texture/Outline" w="18px" />}
            iconActive={<Icon id="Nav/Texture/Solid" w="18px" />}
            link="/textures"
            dataTestId="SideMenuLink-Textures"
            isCompact={isCompact}
            isActive={path.startsWith("/textures")}
            onClick={() => {
              Track(AnalyticsEvents.Navigation.ClickedTextures);
            }}
          >
            <NavBarSubItem
              text="New"
              icon={<Icon id="Ui/Plus" w="12px" />}
              isActive={path === "/textures/new"}
              link="/textures/new"
            />

            <NavBarSubItem
              text="Gallery"
              icon={<Icon id="Domains/Assets/Grid/Fill/Outline" w="14px" />}
              link="/textures"
              isActive={path === "/textures"}
            />

            <NavBarSubItem
              text="Enhance"
              icon={<Icon id="Nav/Upscale/Outline" w="14px" />}
              isActive={path === "/textures/enhance"}
              onClick={() => {
                Track(AnalyticsEvents.Navigation.ClickedUpscaleTexture);
              }}
              link="/textures/enhance"
            />
          </NavBarItem>
        </VStack>

        <Divider />
        <VStack
          align={isCompact ? "center" : "stretch"}
          px={isCompact ? 0 : 2}
          spacing={0.5}
        >
          <NavBarItem
            id="collections"
            text="Collections"
            icon={<Icon id="Nav/Collection/Outline" w="18px" />}
            dataTestId="SideMenuLink-Models"
            isCompact={isCompact}
          >
            <NavBarSubItem
              text="New"
              icon={<Icon id="Ui/Plus" w="12px" />}
              onClick={() => setIsCreateCollectionModalOpen(true)}
            />

            {collections.map((collection) => (
              <NavBarSubItem
                text={collection.name}
                key={`collection-${collection.id}`}
                link={{
                  pathname: "/collections/[id]",
                  query: { id: collection.id },
                }}
                isActive={
                  path === "/collections/[id]" &&
                  router.query.id === collection.id
                }
              />
            ))}
          </NavBarItem>
        </VStack>
      </VStack>

      <ModalCreateTeam />
      <CreateCollectionModal
        setIsOpen={setIsCreateCollectionModalOpen}
        isOpen={isCreateCollectionModalOpen}
      />
    </>
  );
});
