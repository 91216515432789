import { useMemo, useState } from "react";
import useAllCollections from "domains/collections/hooks/useAllCollections";

export default function useAssetFilterCollection() {
  const [collectionId, setCollectionId] = useState<string | undefined>();
  const { collections, isLoading } = useAllCollections();

  const options = useMemo(
    () =>
      collections.map((collection) => ({
        label: collection.name,
        value: collection.id,
      })),
    [collections]
  );

  return {
    allAssetsCollectionArgs: {
      collectionId,
    },
    assetFileManagerCollectionOptions: {
      filterAssetCollection: collectionId,
      filterAssetCollectionOptions: options,
      isFilterAssetCollectionLoading: isLoading,
      onFilterAssetCollectionChange: setCollectionId,
    },
    fmHeaderCollectionProps: {
      filterCollection: collectionId,
      filterCollectionOptions: options,
      isFilterCollectionLoading: isLoading,
      onFilterCollectionChange: setCollectionId,
    },
  };
}
