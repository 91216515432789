import { FilterAssetCollectionOption } from "domains/file-manager/constants/AssetFilter";
import MenuListSingleChoice from "domains/ui/components/Menu/MenuListSingleChoice";

export interface FilterAssetCollectionDropdownProps<
  T extends FilterAssetCollectionOption
> {
  options: T[];
  value?: T["value"];
  isLoading?: boolean;
  onChange: (value: T["value"] | undefined) => void;
}

export default function FilterAssetCollectionDropdown<
  T extends FilterAssetCollectionOption
>({
  options,
  value,
  isLoading,
  onChange,
}: FilterAssetCollectionDropdownProps<T>) {
  return (
    <MenuListSingleChoice
      placeholder="Collection"
      options={options.map((option) => ({
        value: option.value,
        label: option.label,
        dataTestId: `file-manager-asset-change-collection-${option.value}`,
      }))}
      setSelectedValue={onChange}
      selectedValue={value}
      topText="Collection"
      dataTestId="file-manager-asset-change-collection-dropdown"
      isButtonLoading={isLoading}
    />
  );
}
