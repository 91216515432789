import {
  getVectorizeParamsBody,
  UseAssetVectorizeReturnValue,
} from "domains/assets/hooks/useAssetVectorize";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  useGetRemoveBackgroundCostQuery,
  useGetVectorizationCostQuery,
} from "infra/store/apiSlice";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export function useAssetVectorizeCu(
  assetId: string | undefined,
  form: UseAssetVectorizeReturnValue["form"]
): {
  isCuLoading: boolean;
  cuCost?: number;
} {
  const { selectedTeam } = useTeamContext();
  const debouncedForm = useDebounce(form, 500);

  const { data: removeBgCuCostData, isLoading: removeBgCuCostLoading } =
    useGetRemoveBackgroundCostQuery(
      assetId && debouncedForm.isRemoveBackground
        ? {
            teamId: selectedTeam.id,
            body: {
              image: assetId,
            },
            cacheKeys: {
              assetId,
            },
          }
        : skipToken
    );

  const body = assetId
    ? getVectorizeParamsBody(assetId, debouncedForm)
    : undefined;

  const { data: cuCostData, isLoading: isCuLoading } =
    useGetVectorizationCostQuery(
      assetId && debouncedForm && body
        ? {
            teamId: selectedTeam.id,
            body,
          }
        : skipToken
    );

  let cuCost = undefined;
  if (!!cuCostData && assetId) {
    cuCost = cuCostData.creativeUnitsCost;
    if (debouncedForm.isRemoveBackground) {
      cuCost += removeBgCuCostData?.creativeUnitsCost ?? 0;
    }
  }

  return {
    isCuLoading:
      removeBgCuCostLoading ||
      isCuLoading ||
      (!!cuCostData && !_.isEqual(form, debouncedForm)),
    cuCost,
  };
}
