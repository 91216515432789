import { ComponentPropsWithRef } from "react";
import Slider from "domains/ui/components/Slider";
import WithLabelAndTooltip, {
  WithLabelAndTooltipProps,
} from "domains/ui/components/WithLabelAndTooltip";

interface SliderWithInputNumberProps
  extends ComponentPropsWithRef<typeof Slider> {
  title: string;
  tooltip?: React.ReactNode;
  value: number | undefined;
  setValue: (value: number | undefined) => void;
  min: number;
  max: number;
  step?: number;
  "data-testid"?: string;
  tooltipProps?: WithLabelAndTooltipProps["tooltipProps"];
}

export default function SliderWithInputNumber({
  title,
  tooltip,
  tooltipProps,
  value,
  min,
  setValue,
  ...props
}: SliderWithInputNumberProps) {
  return (
    <WithLabelAndTooltip
      label={title}
      tooltip={tooltip}
      tooltipProps={tooltipProps}
    >
      <Slider
        {...props}
        value={value ?? min}
        min={min}
        onChange={setValue}
        withNumberInput
      />
    </WithLabelAndTooltip>
  );
}
