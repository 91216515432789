import { useCallback, useEffect, useState } from "react";
import { useAssetUpload } from "domains/image/hooks/useAssetUpload";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import CompatibleImageUploader, {
  handleBlurFocusOnUploadClick,
  ImageListType,
} from "domains/ui/components/CompatibleImageUploader";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import { usePutAssetsByCollectionIdMutation } from "infra/api/generated/api";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

interface DropAssetModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  beforeUploading?: () => void;
  collectionId?: string;
  children?: React.ReactNode;
}

export default function DropAssetModal({
  showModal,
  setShowModal,
  beforeUploading,
  collectionId,
  children,
}: DropAssetModalProps) {
  const { selectedTeam } = useTeamContext();
  const { uploadImage } = useAssetUpload();
  const [addAssetToCollectionTrigger] = usePutAssetsByCollectionIdMutation();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDraggingHoverBody, setIsDraggingHoverBody] = useState(0);
  const [uploadedCount, setUploadedCount] = useState<
    | {
        total: number;
        current: number;
      }
    | undefined
  >();
  const {
    isDraggingHover: isDraggingHoverDropZone,
    dragFunctions,
    onDrop,
  } = useImageUploadDragDrop({
    allowEventFromApp: false,
  });

  // ----------------------------------

  const handleDropImages = useCallback(
    async (imageList: ImageListType) => {
      const images: string[] = [];
      for (const image of imageList) {
        if (image.dataURL) {
          images.push(image.dataURL);
        }
      }

      if (images.length > 0) {
        beforeUploading?.();
        setUploadedCount({
          total: images.length,
          current: 1,
        });
        for (const image of images) {
          const asset = await uploadImage({
            imageData: image,
            collectionIds: collectionId ? [collectionId] : undefined,
            name: "collection uploaded image",
            trackingEvent: AnalyticsEvents.ImageLibrary.UploadedImage,
            trackingProperties: {
              collectionsIds: [collectionId],
            },
          });
          setUploadedCount((prevState) => {
            if (prevState) {
              return {
                total: prevState.total,
                current:
                  prevState.current < prevState.total
                    ? prevState.current + 1
                    : prevState.current,
              };
            }
            return prevState;
          });
          if (
            asset &&
            collectionId &&
            !asset.meta.collectionIds.includes(collectionId)
          ) {
            await addAssetToCollectionTrigger({
              teamId: selectedTeam.id,
              collectionId,
              body: {
                assetIds: [asset.id],
              },
            });
          }
          await new Promise((r) => setTimeout(r, 100));
        }
        setUploadedCount(undefined);
        setShowModal(false);
      }
    },
    [
      uploadImage,
      collectionId,
      beforeUploading,
      setShowModal,
      addAssetToCollectionTrigger,
      selectedTeam.id,
    ]
  );

  // ----------------------------------

  useEffect(() => {
    const noopHandler = (event: any) => {
      if (!event.dataTransfer.types.includes("Files")) {
        return;
      }

      if (event.type === "dragenter") {
        setIsDraggingHoverBody((prevState) => prevState + 1);
      }
      if (event.type === "dragleave") {
        setIsDraggingHoverBody((prevState) => {
          if (prevState > 0) {
            return prevState - 1;
          }
          return prevState;
        });
      }
    };

    document.addEventListener("dragenter", noopHandler);
    document.addEventListener("dragleave", noopHandler);

    return () => {
      document.removeEventListener("dragenter", noopHandler);
      document.removeEventListener("dragleave", noopHandler);
    };
  }, [setIsDraggingHoverBody]);

  // ----------------------------------

  return (
    <>
      {children}
      <CompatibleImageUploader
        value={[]}
        onChange={handleDropImages}
        allowNonImageType={false}
        multiple
      >
        {({ onImageUpload, dragProps: { onDrop: dragPropsOnDrop } }) => {
          return (
            <Modal
              closeOnEsc={true}
              closeOnOverlayClick={true}
              isOpen={
                showModal ||
                isDraggingHoverDropZone ||
                isDraggingHoverBody > 0 ||
                uploadedCount !== undefined
              }
              onClose={() => setShowModal(false)}
              trapFocus={false}
              variant="modern"
            >
              <ModalContent
                borderColor={
                  isDraggingHoverDropZone ? "primary.500" : undefined
                }
                pointerEvents="all"
                containerProps={{
                  bg: "transparent",
                  pointerEvents: isDraggingHoverBody > 0 ? "none" : "all",
                }}
                filter="drop-shadow(0px 0px 20px rgb(0 0 0)) drop-shadow(0px 0px 20px rgb(0 0 0 / 0.2))"
                {...dragFunctions}
                pb={uploadedCount ? undefined : 0}
                onDrop={(e) =>
                  onDrop(e, (e) => {
                    dragPropsOnDrop(e);
                    setIsDraggingHoverBody(0);
                  })
                }
              >
                <ModalCloseButton />

                <ModalBody w="100%">
                  <VStack spacing={2}>
                    {uploadedCount ? (
                      <>
                        <Text size="body.xl">
                          Uploading {uploadedCount.current} of{" "}
                          {uploadedCount.total} image
                          {uploadedCount.total > 1 && "s"}
                        </Text>
                        <Spinner />
                      </>
                    ) : (
                      <>
                        <Text size="body.xl">Drop images here</Text>
                        <Text size="body.lg">or</Text>
                        <Button
                          isLoading={isUploading}
                          onClick={() =>
                            handleBlurFocusOnUploadClick(
                              onImageUpload,
                              setIsUploading
                            )
                          }
                          variant="secondary"
                        >
                          Upload from computer
                        </Button>
                        <Text
                          my={6}
                          textColor="textSecondary"
                          whiteSpace="nowrap"
                          size="body.sm"
                        >
                          Scenario handles potential duplicate uploads.
                        </Text>
                      </>
                    )}
                  </VStack>
                </ModalBody>
              </ModalContent>
            </Modal>
          );
        }}
      </CompatibleImageUploader>
    </>
  );
}
