export async function getImageDimensions(
  imageData: string | HTMLCanvasElement
) {
  if (imageData instanceof HTMLCanvasElement) {
    return {
      width: imageData.width,
      height: imageData.height,
    };
  }

  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
    image.onerror = reject;
    image.src = imageData;
  });
}
