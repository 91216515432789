import { clickElementById } from "domains/canvas/components/Hotkeys/clickElementById";
import {
  AppShortcutCategory,
  ShortcutItem,
} from "domains/shortcuts/components/appShortcuts";

export const canvasShortcuts = {
  undo: {
    shortcut: "mod+z",
    description: "Undo",
    action: () => clickElementById("undo"),
  },
  redo: {
    shortcut: ["mod+shift+z", "mod+y"],
    description: "Redo",
    action: () => clickElementById("redo"),
  },
  toggleLiveMode: {
    shortcut: "shift+l",
    description: "Toggle Live",
    action: () => clickElementById("toggle-live"),
  },
  penTool: {
    shortcut: "s",
    description: "Sketch",
    action: () => clickElementById("pen"),
  },
  eraserTool: {
    shortcut: "e",
    description: "Eraser",
    action: () => clickElementById("eraser"),
  },
  selectTool: {
    shortcut: "a",
    description: "Select",
    action: () => clickElementById("select"),
  },
  maskTool: {
    shortcut: "m",
    description: "Draw a mask",
    action: () => clickElementById("aiPen"),
  },
  aiSegmentationTool: {
    shortcut: "shift+m",
    description: "Smart mask",
    action: () => clickElementById("aiSegmentation"),
  },
  deleteMask: {
    shortcut: "esc",
    description: "Delete mask",
    action: () => clickElementById("delete-mask"),
  },
  aiLassoTool: {
    shortcut: "l",
    description: "Draw a mask (lasso)",
    action: () => clickElementById("aiLasso"),
  },
  import: {
    shortcut: "i",
    description: "Import",
    action: () => clickElementById("import-element-button"),
  },
  colorPicker: {
    shortcut: "c",
    description: "Color picker",
    action: () => clickElementById("color-picker-button"),
  },
  resize: {
    shortcut: "r",
    description: "Resize",
    action: () => clickElementById("resize-button"),
  },
  saveCanvas: {
    shortcut: "mod+s",
    description: "Save to gallery",
    action: () => clickElementById("save-button"),
    options: {
      preventDefault: true,
    },
  },
  shortcutHelp: {
    shortcut: "h",
    description: "Keyboard Shortcuts",
    action: () => {
      clickElementById("shortcut-button");
    },
  },
  resizeBrush: {
    shortcut: ["shift+mouse scroll"],
    description: "Resize brush",
    action: () => {},
  },
  aiEraserTool: {
    shortcut: "shift+e",
    description: "Fill",
    action: () => clickElementById("aiEraser"),
  },
  clearAllContent: {
    shortcut: ["mod+delete", "mod+backspace"],
    description: "Clear all content",
    action: () => {
      clickElementById("canvas-clear-all-content-button");
    },
    options: {
      preventDefault: true,
    },
  },
} satisfies Record<string, AppShortcutCategory | ShortcutItem>;
