import React, { useCallback, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import useAssetUpscale from "domains/assets/hooks/useAssetUpscale";
import { useAssetUpscaleCu } from "domains/assets/hooks/useAssetUpscaleCu";
import {
  isAssetInferenceTexture,
  isAssetUpscaledTexture,
} from "domains/assets/utils/isType";
import { useSectionsContext } from "domains/inference/contexts/SectionsProvider";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import ButtonWithCuIndicator from "domains/ui/components/ButtonWithCuIndicator";
import UpscaleParams from "domains/ui/components/UpscaleParams";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
  useGetJobIdQuery,
} from "infra/api/generated/api";

import { Text, VStack } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export interface DrawerUpscaleProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  onClose: (options: { avoidRedirect?: boolean }) => void;
}

export default function DrawerUpscale({ asset, onClose }: DrawerUpscaleProps) {
  const router = useRouter();
  const { openSection } = useSectionsContext();
  const { selectedTeam } = useTeamContext();
  const { errorToast } = useScenarioToast();
  const isTexture =
    isAssetInferenceTexture(asset) || isAssetUpscaledTexture(asset);
  const [upscalingJobToFetch, setUpscalingJobToFetch] = useState<
    GetJobIdApiResponse["job"] | undefined
  >();

  const { currentData: upscalingJob } = useGetJobIdQuery(
    upscalingJobToFetch
      ? {
          teamId: selectedTeam.id,
          jobId: upscalingJobToFetch.jobId,
        }
      : skipToken,
    {
      pollingInterval: 5_000,
    }
  );

  const { setValue, setForm, form, handleUpscaleJob, isUpscaleLoading } =
    useAssetUpscale(isTexture ? "texture" : "image", {
      onValueChange: openSection,
    });

  const handleSubmit = useCallback(async () => {
    const newJob = await handleUpscaleJob({
      assetId: asset.id,
      trackingExtraParams: {
        origin: "image edit",
      },
    });
    if (newJob) {
      setUpscalingJobToFetch(newJob);
    }
  }, [asset, handleUpscaleJob]);

  useEffect(() => {
    if (
      upscalingJob?.job &&
      upscalingJob.job.status === "success" &&
      upscalingJob.job.metadata.assetIds?.[0]
    ) {
      delete router.query.edit;
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: upscalingJob.job.metadata.assetIds[0],
        },
      });
      onClose({
        avoidRedirect: true,
      });
      setUpscalingJobToFetch(undefined);
    } else if (
      upscalingJob?.job &&
      (upscalingJob.job.status === "failure" ||
        upscalingJob.job.status === "canceled")
    ) {
      errorToast({
        title: "Enhance failed",
        description:
          "There was an error enhancing the image. Please try again.",
      });
      setUpscalingJobToFetch(undefined);
    }
  }, [errorToast, onClose, router, upscalingJob]);

  useEffect(() => {
    setUpscalingJobToFetch(undefined);
  }, [asset]);

  useEffect(() => {
    if (asset) {
      setForm((form) => ({
        ...form,
        prompt: asset.metadata.prompt ?? "",
        negativePrompt: asset.metadata.negativePrompt ?? "",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  const { isCuLoading, cuCost } = useAssetUpscaleCu({
    form,
    assetId: asset.id,
    type: isTexture ? "texture" : "image",
  });

  return (
    <VStack align="start" w="100%" spacing={5}>
      <UpscaleParams
        form={form}
        setValue={setValue}
        width={asset.metadata.width ?? 0}
        height={asset.metadata.height ?? 0}
        isAssetZoom
        type={isTexture ? "texture" : "image"}
      />
      <ButtonWithCuIndicator
        cuCost={cuCost}
        isCuLoading={isCuLoading}
        size="sm"
        onClick={handleSubmit}
        isLoading={isUpscaleLoading || !!upscalingJobToFetch}
        w="100%"
      >
        Enhance
      </ButtonWithCuIndicator>

      <Link
        href={{
          pathname: isTexture ? "/textures/enhance" : "/enhance",
          query: {
            importAssetId: asset.id,
          },
        }}
      >
        <Text textColor="primary.500" fontSize="sm" textDecoration="underline">
          More settings
        </Text>
      </Link>
    </VStack>
  );
}
