import { useRouter } from "next/router";
import { AsideEditAction } from "domains/assets/constants/Aside";
import {
  isAssetInferenceTexture,
  isAssetSkybox,
  isAssetUpscaledTexture,
} from "domains/assets/utils/isType";
import {
  AsideCard,
  AsideList,
  AsideSection,
} from "domains/ui/components/Aside";
import Icon from "domains/ui/components/Icon";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";
import _ from "lodash";

export interface AsideSectionEditProps {
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  onActionClick: (action: AsideEditAction) => void;
}

export default function AsideSectionEdit({
  asset,
  onActionClick,
}: AsideSectionEditProps) {
  const router = useRouter();
  const editCapabilities = asset?.editCapabilities;
  const isSkybox = isAssetSkybox(asset);
  const isTexture =
    isAssetInferenceTexture(asset) || isAssetUpscaledTexture(asset);

  const canRemoveBackground =
    editCapabilities?.includes("REMOVE_BACKGROUND") && !isSkybox && !isTexture;
  const canUpscale =
    _.intersection(editCapabilities, ["UPSCALE", "UPSCALE_360"]).length > 0 &&
    asset?.metadata.type !== "upscale-skybox" &&
    asset?.metadata.type !== "texture" &&
    !asset?.metadata.type.startsWith("texture-");
  const canPixelate =
    editCapabilities?.includes("PIXELATE") && !isSkybox && !isTexture;
  const canVectorize =
    editCapabilities?.includes("VECTORIZATION") && !isSkybox && !isTexture;
  const hasReframeFlag = useUserContext()?.featureFlags?.includes("reframe");
  const canReframe =
    editCapabilities?.includes("REFRAME") &&
    !isSkybox &&
    !isTexture &&
    hasReframeFlag;

  const canEdit =
    canRemoveBackground || canUpscale || canPixelate || canVectorize;

  if (!asset || !canEdit) {
    return null;
  }

  return (
    <AsideSection id="edit" title="Edit">
      <AsideList>
        {canReframe && (
          <AsideCard
            size="sm"
            icon={<Icon id="Nav/Expand/Outline" h="18px" mt="-2px" />}
            title="Expand"
            onClick={() => onActionClick("expand")}
            data-testid="edit-image-reframe"
          />
        )}

        {canRemoveBackground && (
          <AsideCard
            size="sm"
            icon={<Icon id="Nav/BgRemove/Outline" h="20px" />}
            title="Remove Background"
            onClick={() => onActionClick("removeBg")}
            data-testid="edit-image-remove-background"
          />
        )}

        {canUpscale && (
          <AsideCard
            size="sm"
            icon={<Icon id="Nav/Upscale/Outline" h="20px" />}
            title="Enhance"
            onClick={!isSkybox ? () => onActionClick("upscale") : undefined}
            internalLink={
              (isSkybox &&
                router.pathname === "/skyboxes/new" && {
                  pathname: "/skyboxes/new",
                  query: { enhanceAssetId: asset?.id },
                }) ||
              (isSkybox && {
                pathname: "/skyboxes/enhance",
                query: {
                  importAssetId: asset.id,
                },
              }) ||
              undefined
            }
            data-testid="edit-image-upscale"
          />
        )}

        {canPixelate && (
          <AsideCard
            size="sm"
            icon={<Icon id="Nav/Pixelate/Outline" h="18px" />}
            title="Pixelate"
            onClick={() => onActionClick("pixelate")}
            data-testid="edit-image-pixelate"
          />
        )}

        {canVectorize && (
          <AsideCard
            size="sm"
            icon={<Icon id="Domains/Tools/Vectorize" h="18px" mt="-2px" />}
            title="Vectorize"
            onClick={() => onActionClick("vectorize")}
            data-testid="edit-image-vectorize"
          />
        )}
      </AsideList>
    </AsideSection>
  );
}
