import React, { useCallback, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import useSkyboxUpscale from "domains/assets/hooks/useSkyboxUpscale";
import { useSkyboxUpscaleCu } from "domains/assets/hooks/useSkyboxUpscaleCu";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import ButtonWithCuIndicator from "domains/ui/components/ButtonWithCuIndicator";
import SkyboxUpscaleParams from "domains/ui/components/SkyboxUpscaleParams";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
  useGetJobIdQuery,
} from "infra/api/generated/api";

import { Text, VStack } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export interface DrawerSkyboxUpscaleProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  onClose: (options: { avoidRedirect?: boolean }) => void;
}

export default function DrawerSkyboxUpscale({
  asset,
  onClose,
}: DrawerSkyboxUpscaleProps) {
  const router = useRouter();
  const { selectedTeam } = useTeamContext();
  const { errorToast } = useScenarioToast();
  const { form, setReference, setValue, upscale, isUpscaleLoading } =
    useSkyboxUpscale();
  const [upscalingJobToFetch, setUpscalingJobToFetch] = useState<
    GetJobIdApiResponse["job"] | undefined
  >();
  const { currentData: upscalingJob } = useGetJobIdQuery(
    upscalingJobToFetch
      ? {
          teamId: selectedTeam.id,
          jobId: upscalingJobToFetch.jobId,
        }
      : skipToken,
    {
      pollingInterval: 5_000,
    }
  );

  const { isCuLoading, cuCost } = useSkyboxUpscaleCu(form);

  // ----------------------------------

  const handleSubmit = useCallback(async () => {
    const newJob = await upscale({
      trackingExtraParams: {
        origin: "image edit",
      },
    });
    if (newJob) {
      setUpscalingJobToFetch(newJob);
    }
  }, [upscale]);

  // ----------------------------------

  useEffect(() => {
    if (
      upscalingJob?.job &&
      upscalingJob.job.status === "success" &&
      upscalingJob.job.metadata.assetIds?.[0]
    ) {
      delete router.query.edit;
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: upscalingJob.job.metadata.assetIds[0],
        },
      });
      onClose({
        avoidRedirect: true,
      });
      setUpscalingJobToFetch(undefined);
    } else if (
      upscalingJob?.job &&
      (upscalingJob.job.status === "failure" ||
        upscalingJob.job.status === "canceled")
    ) {
      errorToast({
        title: "Enhance failed",
        description:
          "There was an error enhancing the skybox. Please try again.",
      });
      setUpscalingJobToFetch(undefined);
    }
  }, [errorToast, onClose, router, upscalingJob]);

  useEffect(() => {
    setUpscalingJobToFetch(undefined);
  }, [asset]);

  useEffect(() => {
    if (asset) {
      setReference(asset.url, asset.id, asset);
    }
  }, [asset, setReference]);

  // ----------------------------------

  return (
    <VStack align="start" w="100%" spacing={5}>
      <SkyboxUpscaleParams form={form} setValue={setValue} isAssetZoom />

      <ButtonWithCuIndicator
        cuCost={cuCost}
        isCuLoading={isCuLoading}
        size="sm"
        onClick={handleSubmit}
        isLoading={isUpscaleLoading || !!upscalingJobToFetch}
        w="100%"
      >
        Enhance
      </ButtonWithCuIndicator>

      <Link
        href={{
          pathname: "/skyboxes/enhance",
          query: {
            importAssetId: asset.id,
          },
        }}
      >
        <Text textColor="primary.500" fontSize="sm" textDecoration="underline">
          More settings
        </Text>
      </Link>
    </VStack>
  );
}
