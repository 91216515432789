import { useMemo } from "react";
import AssetZoom from "domains/assets/components/AssetZoom";
import { FileManagerProps } from "domains/file-manager/components/FileManagerV2";
import { FmVirtualFilesProps } from "domains/file-manager/components/FileManagerV2/FmVirtualFiles";
import { FileImageType } from "domains/file-manager/interfaces";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import { mapFmFileImagesToFileImages } from "domains/file-manager/utils/mapFmFileToFile";

export type VirtualFilesCustomProps = Pick<
  FileManagerProps,
  "hasMore" | "onEndReached"
> & {
  isWithoutAssetZoom?: boolean;
  isOriginalAssetsInAssetZoom?: boolean;
  assetZoomAdditionalFiles?: FmFileImage[];
  assetZoomPriorityLevel?: number;
  onDeleteClick: (files: FmFileImage[]) => void;
};

export type VirtualFilesProps = FmVirtualFilesProps<
  "image",
  VirtualFilesCustomProps
>;

export default function VirtualFiles({
  files,
  assetZoomAdditionalFiles,
  isWithoutAssetZoom = false,
  isOriginalAssetsInAssetZoom = false,
  assetZoomPriorityLevel = 0,
  hasMore = false,
  onEndReached,
  onDeleteClick,
}: VirtualFilesProps) {
  const assetZoomFiles = useMemo<FileImageType[]>(() => {
    return mapFmFileImagesToFileImages([
      ...(assetZoomAdditionalFiles ?? []).filter(
        (file) => !files.find((item) => item.id === file.id)
      ),
      ...files.filter((file) => file.status === "success"),
    ]);
  }, [assetZoomAdditionalFiles, files]);

  return (
    <>
      {!isWithoutAssetZoom && (
        <AssetZoom
          priorityLevel={assetZoomPriorityLevel}
          assets={assetZoomFiles}
          originalAssets={isOriginalAssetsInAssetZoom}
          hasMore={!!hasMore}
          loadMore={onEndReached ?? (() => {})}
          onDelete={onDeleteClick}
        />
      )}
    </>
  );
}
