import { UpscaleForm } from "domains/assets/constants/upscale";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useGetImageUpscaleCostQuery } from "infra/store/apiSlice";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/dist/query";

import { getUpscaleParamsBody, UPSCALE_DEFAULT_FORM } from "./useAssetUpscale";

type UseAssetUpscaleArgs =
  | {
      form?: UpscaleForm;
      assetId?: string;
      type: "image" | "texture";
      skip?: never;
    }
  | {
      form?: never;
      assetId?: never;
      type?: never;
      skip: true;
    };

export function useAssetUpscaleCu({
  form,
  assetId,
  type,
}: UseAssetUpscaleArgs): {
  isCuLoading: boolean;
  cuCost?: number;
} {
  const { selectedTeam } = useTeamContext();
  const debouncedForm = useDebounce(form, 500);

  const body = assetId
    ? getUpscaleParamsBody({
        assetId,
        form: debouncedForm ?? UPSCALE_DEFAULT_FORM,
        negativePrompt: "",
        prompt: "",
        type,
      })
    : undefined;

  const { data: cuCostData, isFetching: isCuLoading } =
    useGetImageUpscaleCostQuery(
      assetId && body ? { teamId: selectedTeam.id, body } : skipToken
    );

  return {
    cuCost: assetId ? cuCostData?.creativeUnitsCost : undefined,
    isCuLoading:
      isCuLoading || (!!cuCostData && !_.isEqual(form, debouncedForm)),
  };
}
