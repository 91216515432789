import { useCallback, useMemo } from "react";
import Button from "domains/ui/components/Button";
import ButtonSingleChoice, {
  ButtonSingleChoiceProps,
} from "domains/ui/components/Menu/ButtonSingleChoice";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  Box,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";

const RANGE_SHORTCUTS = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "This Week", value: "thisWeek" },
  { label: "This Month", value: "thisMonth" },
  { label: "This Quarter", value: "thisQuarter" },
];

type DatepickerRangeValue =
  | { start: string; end: string | undefined }
  | undefined;

export interface DatepickerRangeProps {
  placeholder?: string;
  value: DatepickerRangeValue;
  onChange: (value: DatepickerRangeValue) => void;
  variant?: ButtonSingleChoiceProps["variant"];
}

export default function DatepickerRange({
  placeholder,
  value,
  onChange,
  variant,
}: DatepickerRangeProps) {
  const startDate = useMemo(
    () =>
      value?.start ? moment.utc(value.start, "YYYY-MM-DD").toDate() : undefined,
    [value?.start]
  );
  const endDate = useMemo(
    () =>
      value?.end ? moment.utc(value.end, "YYYY-MM-DD").toDate() : undefined,
    [value?.end]
  );

  const handlePickerChange = useCallback(
    ([start, end]: [Date | undefined, Date | undefined]) => {
      onChange(
        start
          ? {
              start: moment(start).format("YYYY-MM-DD"),
              end: end ? moment(end).format("YYYY-MM-DD") : undefined,
            }
          : undefined
      );
    },
    [onChange]
  );

  const handleShortcutClick = useCallback(
    (type: string) => {
      const start = {
        today: moment().startOf("day"),
        yesterday: moment().startOf("day").subtract(1, "day"),
        thisWeek: moment().startOf("isoWeek"),
        thisMonth: moment().startOf("month"),
        thisQuarter: moment().startOf("quarter"),
        lastWeek: moment().startOf("isoWeek").subtract(1, "week"),
        lastMonth: moment().startOf("month").subtract(1, "month"),
        lastQuarter: moment().startOf("quarter").subtract(1, "quarter"),
      }[type];
      const end = {
        today: moment().startOf("day"),
        yesterday: moment().startOf("day").subtract(1, "day"),
        thisWeek: moment(),
        thisMonth: moment(),
        thisQuarter: moment(),
        lastWeek: moment().startOf("isoWeek").subtract(1, "day"),
        lastMonth: moment().startOf("month").subtract(1, "day"),
        lastQuarter: moment().startOf("quarter").subtract(1, "day"),
      }[type];
      if (!start || !end) return;

      onChange({
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
      });
    },
    [onChange]
  );

  const handleResetClick = useCallback(() => onChange(undefined), [onChange]);

  return (
    <Popover placement="bottom-start" variant="blurred">
      <PopoverTrigger>
        <ButtonSingleChoice
          variant={variant}
          hasValue
          text={
            startDate
              ? _.compact([
                  moment.utc(startDate).format("MMM DD, YY"),
                  endDate && moment.utc(endDate).format("MMM DD, YY"),
                ]).join(" - ")
              : placeholder ?? "Date"
          }
        />
      </PopoverTrigger>

      <PopoverContent w="430px">
        <PopoverBody px={3}>
          <HStack align="stretch" w="full">
            <VStack align="stretch" flex={1} spacing={0}>
              {RANGE_SHORTCUTS.map(({ label, value }) => (
                <Button
                  key={value}
                  size="sm"
                  variant="menuItem"
                  onClick={handleShortcutClick.bind(null, value)}
                >
                  {label}
                </Button>
              ))}

              <Box flex={1} minH="30px" />

              <Button size="sm" variant="menuItem" onClick={handleResetClick}>
                Reset
              </Button>
            </VStack>

            <Box w="1px" bgColor="borderAlpha.500" />

            <DatePicker
              calendarClassName="scenario-calendar"
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={(value) =>
                handlePickerChange([
                  value?.[0] ?? undefined,
                  value?.[1] ?? undefined,
                ])
              }
              maxDate={moment.utc().endOf("day").toDate()}
              minDate={moment.utc().subtract(3, "year").toDate()}
              selectsRange
              inline
            />
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
