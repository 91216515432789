import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import Button from "domains/ui/components/Button";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

interface ModalReviewCaptionProps {
  isOpen: boolean;
  onClose: () => void;
  onTrain: () => void;
}

export default function ModalReviewCaption({
  isOpen,
  onClose,
  onTrain,
}: ModalReviewCaptionProps) {
  const [shouldShowFirstCaptionModal, setIsShowingFirstCaptionModal] =
    usePersistedState(PersistedStateKey.TRAIN_SHOW_FIRST_REVIEW_CAPTION_MODAL, {
      defaultValue: true,
    });

  return (
    <Modal isOpen={isOpen} onClose={() => {}} variant={"modern"}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <VStack align="stretch" textAlign="center" spacing={8}>
            <Text size="body.lg">
              {shouldShowFirstCaptionModal
                ? "You didn't modify any captions. Remember to add or remove any details in the foregrounds or backgrounds."
                : "Are you sure you reviewed the captions?"}
            </Text>
            {shouldShowFirstCaptionModal ? (
              <Button
                variant={"primary"}
                onClick={() => {
                  onClose();
                  setIsShowingFirstCaptionModal(false);
                }}
              >
                OK
              </Button>
            ) : (
              <VStack align="stretch" spacing={4}>
                <Button
                  variant={"primary"}
                  onClick={() => {
                    onClose();
                    onTrain();
                  }}
                >
                  Yes, start training
                </Button>
                <Button variant={"secondary"} onClick={onClose}>
                  No, I need to review them
                </Button>
              </VStack>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
