import { useMemo } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { useQueries } from "infra/api/hooks/useQueries";
import { apiSlice } from "infra/store/apiSlice";

type UseAssetRemoveBackgroundCuArgs =
  | Array<{
      assetId: string;
      width?: number;
      height?: number;
    }>
  | {
      assetId?: string;
      width?: number;
      height?: number;
      skip?: never;
    }
  | {
      assetId?: never;
      width?: never;
      height?: never;
      skip: true;
    };

export function useAssetRemoveBackgroundCu(
  args: UseAssetRemoveBackgroundCuArgs
): {
  isCuLoading: boolean;
  cuCost?: number;
  hasEnoughCu: boolean;
  hasError: boolean;
} {
  const { selectedTeam } = useTeamContext();
  const { totalRemainingCreativeUnits } = usePlanContext();

  const assets = useMemo(() => {
    if (Array.isArray(args)) {
      return args;
    } else if (!args.skip) {
      return [args];
    } else {
      return undefined;
    }
  }, [args]);

  const getRemoveBackgroundCostQueries = useQueries(
    apiSlice.endpoints.getRemoveBackgroundCost,
    (assets ?? [])
      .filter(({ assetId }) => !!assetId)
      .map(({ assetId, width, height }) => ({
        teamId: selectedTeam.id,
        body: {
          image: assetId!,
          ...(width && height ? { width, height } : {}),
        },
        cacheKeys: width && height ? { width, height } : { assetId },
      }))
  );

  const cuCost =
    getRemoveBackgroundCostQueries.length > 0
      ? getRemoveBackgroundCostQueries
          .map((query) => query.data?.creativeUnitsCost || 0)
          .reduce((acc, cost) => acc + cost, 0)
      : undefined;
  const isLoading = getRemoveBackgroundCostQueries.some(
    (query) => query.isLoading
  );
  const hasError = getRemoveBackgroundCostQueries.some(
    (query) => query.isError
  );

  return {
    cuCost,
    hasError,
    hasEnoughCu:
      cuCost !== undefined &&
      totalRemainingCreativeUnits !== undefined &&
      cuCost <= totalRemainingCreativeUnits,

    isCuLoading: isLoading,
  };
}
