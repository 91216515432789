import React, {
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useMemo,
} from "react";
import { HandleSelect } from "domains/file-manager/components/FileManager";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { useOptimizedAssetUrl } from "domains/file-manager/hooks/useOptimizedAssetUrl";
import {
  FileHandler,
  FileImageType,
  FileType,
} from "domains/file-manager/interfaces";
import Icon from "domains/ui/components/Icon";
import { useHover } from "domains/ui/hooks/useHover";
import _ from "lodash";
import { isSafari } from "react-device-detect";

import {
  Box,
  BoxProps,
  Center,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  Fade,
  Flex,
  Grid,
  Image as ChakraImage,
  ImageProps,
  keyframes,
  Skeleton,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

export type FilePreviewProps<F extends FileType> = PropsWithChildren & {
  fileHandler: FileHandler<F>;

  file: F;

  onSelect?: HandleSelect;
  isSelected?: boolean;

  onDrag?: MouseEventHandler<HTMLDivElement>;

  canSelect?: boolean;

  selectModeEnabled?: boolean;

  isLoading?: boolean;

  gridView?: GridViewKey;
  showFileNames?: "always" | "hover" | "never";

  isRevealed?: boolean;
  onReveal?: (id: string) => void;
  isBlurred?: boolean;
  isDisabled?: boolean;

  cardWidth: number;
  cardMaxWidth?: number;
  imgProps?: ImageProps;
};

const FilePreview = React.memo(
  function FilePreview(props: FilePreviewProps<FileType>) {
    const {
      file,
      fileHandler,
      gridView,
      isLoading,
      selectModeEnabled,
      isSelected,
      onSelect,
      onDrag,
      canSelect,
      showFileNames,
      isBlurred,
      isDisabled,
      cardWidth,
      cardMaxWidth,
      imgProps = {},
      children,
    } = props;

    // const [imgHeight, setImgHeight] = useState<number[] | undefined>();
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    const isSuccess = !("status" in file) || file.status === "success";
    const { url: maxResUrl } = useOptimizedAssetUrl({
      file,
      cardWidth: cardMaxWidth ?? cardWidth,
    });
    const imgUrl = isSuccess ? maxResUrl : "";
    const lowResThumbnailUrl = isSuccess
      ? `${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/${file.id}`
      : undefined;

    const imgHeightRatio = Math.round((file.height / file.width) * 100);
    const cardMinSize = Math.min(cardWidth, (cardWidth * imgHeightRatio) / 100);

    const handleFileClick: MouseEventHandler<HTMLDivElement> = (event) => {
      if (selectModeEnabled && canSelect) {
        onSelect?.(file.id, { shiftPressed: event.shiftKey });
      } else if (event.shiftKey && canSelect) {
        onSelect?.(file.id, { shiftPressed: false });
      } else {
        fileHandler.onOpen?.(file);
      }

      event.stopPropagation();
    };

    const handleCheckboxChange: MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        onSelect?.(file.id, {
          shiftPressed: event.shiftKey,
        });

        event.stopPropagation();
      },
      [onSelect, file.id]
    );

    const handleFileDrag: MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        onDrag?.(event);
        event.stopPropagation();
      },
      [onDrag]
    );

    const gridViewCssProps: BoxProps = useMemo(() => {
      let cssProps: BoxProps =
        gridView !== "masonry"
          ? {
              position: "absolute",
              top: 0,
              objectFit: gridView === "fill" ? "cover" : "contain",
            }
          : {
              objectFit: "fill",
            };
      if (file.type === "image" && file.meta.metadata.type === "texture") {
        cssProps = {
          ...cssProps,
          objectFit: "cover",
          objectPosition: "50% 0",
        };
      }
      return cssProps;
    }, [file, gridView]);

    const imageFilters = useMemo(() => {
      const filters = [];
      if (isBlurred) {
        filters.push("blur(20px)");
      }
      if (isSelected) {
        filters.push("brightness(1.2)");
      }
      return filters.join(" ");
    }, [isBlurred, isSelected]);

    return (
      <>
        <Box
          ref={hoverRef}
          pos="relative"
          zIndex={0}
          w="100%"
          h={0}
          pt={gridView === "masonry" ? `${imgHeightRatio}%` : "100%"}
          data-outside-click-excluded={true}
          draggable
          {...(isSuccess && !isDisabled
            ? { onClick: handleFileClick, onDragEnd: handleFileDrag }
            : {})}
          userSelect="none"
        >
          {/* Image display */}
          <Grid
            pos="absolute"
            top={0}
            overflow="hidden"
            w="100%"
            h="100%"
            borderTopRadius="md"
            borderBottomRadius={showFileNames === "always" ? undefined : "md"}
            cursor={isSuccess && !isDisabled ? "pointer" : "default"}
            bgColor="backgroundSecondary.500"
          >
            <ChakraImage
              {...(!isSuccess
                ? {
                    display: "none",
                    position: "absolute",
                    pointerEvents: "none",
                  }
                : {})}
              pos="relative"
              w="100%"
              h="100%"
              borderTopRadius="md"
              borderBottomRadius={showFileNames === "always" ? undefined : "md"}
              transformOrigin="top left"
              transition="transform .1s"
              alt=""
              data-id="asset-image"
              data-testid="asset-gallery-preview-image"
              fallback={<Skeleton pos="relative" w="100%" h="100%" />}
              fallbackSrc={lowResThumbnailUrl}
              filter={imageFilters}
              loading={isSafari ? "eager" : "lazy"}
              src={imgUrl}
              {...gridViewCssProps}
              {...imgProps}
            />

            {!isSuccess && (
              <VStack
                borderRadius="md"
                {...gridViewCssProps}
                pos="relative"
                align="center"
                justify="center"
                w="100%"
                h="100%"
                bgColor="backgroundSecondary.500"
                spacing={3}
              >
                <DisplayIsNotSuccess file={file} cardWidth={cardMinSize} />
              </VStack>
            )}

            <Fade
              // Alway show on hover to make the checkbox visible on white images when its selectable
              in={
                isSuccess && canSelect && isHovered && showFileNames === "hover"
              }
              style={{
                position: "absolute",
                zIndex: 3,
                top: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
              unmountOnExit
            >
              <Flex
                align="end"
                direction="column"
                overflow="hidden"
                w="100%"
                h="100%"
                borderRadius="md"
                pointerEvents="none"
              >
                <Flex
                  className="bg-gradient-hover"
                  direction="column"
                  w="100%"
                  h="100%"
                >
                  {showFileNames === "hover" && isHovered && (
                    <Text
                      pos="absolute"
                      bottom={2}
                      left={2}
                      // overflow="hidden"
                      noOfLines={2}
                      size="body.sm"
                    >
                      {file.name}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Fade>

            {isSuccess && canSelect && (isHovered || selectModeEnabled) && (
              <Flex
                pos="absolute"
                zIndex={4}
                top={0}
                direction={"column"}
                display={["none", "none", "flex"]}
                p={2}
                data-testid="asset-gallery-preview-image-checkbox"
                // this is to prevent the file from being open when clicking on the checkbox, stopping the propagation on the on onChange doesn't work
                onClick={handleCheckboxChange}
              >
                {!isLoading && (
                  <Checkbox
                    pointerEvents="none"
                    colorScheme="primary"
                    isChecked={isSelected}
                    // Do not use the onChange as it doesn't transmit the shift key
                    size="xl"
                    variant="lightCircular"
                  />
                )}
              </Flex>
            )}
          </Grid>

          {showFileNames === "always" && (
            <Flex
              align="center"
              justify="center"
              w="100%"
              h="40px"
              mb="-40px"
              p={2}
              borderBottomRadius="md"
              bgColor={
                isDisabled || isHovered
                  ? "backgroundQuaternary.500"
                  : "backgroundSecondary.500"
              }
            >
              <Tooltip
                isDisabled={file.name.length <= cardWidth / 8.5}
                label={
                  file.name.length > cardWidth / 8.5 ? file.name : undefined
                }
              >
                <Text
                  textColor={isDisabled ? "textSecondary" : "textPrimary"}
                  textAlign="center"
                  isTruncated
                  size="body.md"
                >
                  {file.name}
                </Text>
              </Tooltip>
            </Flex>
          )}

          {children}

          {isDisabled && (
            <Box
              pos="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              borderTopRadius="md"
              borderBottomRadius={showFileNames === "always" ? undefined : "md"}
              pointerEvents="none"
              bgColor="rgba(100,100,100,0.6)"
            />
          )}

          {isHovered && !isDisabled && showFileNames === "always" && (
            <Box
              pos="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              borderTopRadius="md"
              pointerEvents="none"
              bgColor="rgba(255,255,255,0.1)"
            />
          )}
        </Box>

        {selectModeEnabled && isSelected && (
          <Box
            pos="absolute"
            zIndex={5}
            top={0}
            left={0}
            w="100%"
            h="100%"
            borderRadius="md"
            pointerEvents="none"
            outline="3px solid"
            outlineColor="primary.500"
            outlineOffset="-3px"
          />
        )}
      </>
    );
  },
  (
    prevProps: FilePreviewProps<FileType>,
    props: FilePreviewProps<FileType>
  ) => {
    return _.isEqual(prevProps, props);
  }
);

export default FilePreview;

// ------------------------------------

const animation = keyframes`
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

interface DisplayIsNotSuccessProps {
  file: FileImageType;
  cardWidth: number;
}

function DisplayIsNotSuccess({ file, cardWidth }: DisplayIsNotSuccessProps) {
  const isVerySmall = cardWidth < 100;

  if (file.status === "placeholder") {
    const placeholderText = file.meta.tags.includes("no-dimensions")
      ? "? x ?"
      : `${file.width} x ${file.height}`;
    return (
      <Text
        color="secondary.700"
        textAlign={"center"}
        size={isVerySmall ? "body.sm" : "body.md"}
      >
        {placeholderText}
      </Text>
    );
  }

  if (file.status === "queued" || file.status === "warming-up") {
    return (
      <>
        <Center
          pos="relative"
          w="60px"
          maxW={`${cardWidth / 2}px`}
          h="60px"
          maxH={`${cardWidth / 2}px`}
          bg="linear-gradient(315deg, #1A1A1A, #2F2F2F, #434343, #2F2F2F, #1A1A1A, #2F2F2F, #434343, #2F2F2F, #1A1A1A)"
          bgSize="200% 200%"
          borderRadius="full"
          animation={`${animation} 5s linear infinite`}
          data-testid="asset-gallery-grid-cell-queued-status"
        >
          <Icon
            id={file.status === "queued" ? "Ui/Hourglass" : "Ui/Warm"}
            color="textPrimary"
            h="22px"
            maxW="50%"
            maxH="50%"
          />
        </Center>
        {!isVerySmall && (
          <Text color="textPrimary" size="body.md">
            {file.status === "queued" ? "Queued" : "Initializing"}
          </Text>
        )}
      </>
    );
  }

  if (file.status === "processing") {
    return (
      <>
        {file.meta.metadata.progressPercent !== undefined ? (
          <CircularProgress
            color="textPrimary"
            data-testid="asset-gallery-grid-cell-progress-status"
            size={`calc(min(${cardWidth / 2}px, 60px) + ${
              isVerySmall ? "3px" : "5px"
            })`}
            thickness={isVerySmall ? "3px" : "5px"}
            trackColor="rgba(255, 255, 255, 0.2)"
            value={file.meta.metadata.progressPercent}
          >
            <CircularProgressLabel>
              {`${Math.round(file.meta.metadata.progressPercent)}%`}
            </CircularProgressLabel>
          </CircularProgress>
        ) : (
          <Spinner
            w="60px"
            maxW={`${cardWidth / 2}px`}
            h="60px"
            maxH={`${cardWidth / 2}px`}
            color="textPrimary"
            data-testid="asset-gallery-grid-cell-progress-status"
            emptyColor="rgba(255, 255, 255, 0.2)"
            speed="0.75s"
            thickness={isVerySmall ? "3px" : "5px"}
          />
        )}

        {!isVerySmall && <Text size="body.md">Generating</Text>}
      </>
    );
  }

  return (
    <>
      <Icon
        id={"Ui/AlertOctagon"}
        color={"textPrimary"}
        maxW="50%"
        maxH="50%"
      />
      {!isVerySmall && (
        <Text color="textPrimary" textAlign="center" size="body.md">
          Generation failed
        </Text>
      )}
    </>
  );
}
