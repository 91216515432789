import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { getBrushCircle } from "domains/canvas/components/sections/DrawingTools/getBrushCircle";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { srcToBase64 } from "domains/commons/srcToBase64";
import { useMiniCanvasContext } from "domains/mini-canvas/contexts/MiniCanvasProvider";
import {
  Action,
  MiniCanvasTool,
  Point,
  State,
} from "domains/mini-canvas/interfaces/miniCanvas";
import { canvasShortcuts } from "domains/shortcuts/components/canvasShortcuts";
import ColorPicker, { EyeDropper } from "domains/ui/components/ColorPicker";
import Icon from "domains/ui/components/Icon";
import { useWindowSize } from "domains/ui/hooks/useWindowSize";
import Konva from "konva";
import { Image, Layer, Line, Rect, Stage } from "react-konva";
import useImage from "use-image";

import {
  Box,
  Button,
  ButtonProps,
  Center,
  Divider,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Image as ChakraImage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";

const miniCanvasReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "CLEAR": {
      return {
        lines: [],
        undoStack: [],
        redoStack: [],
      };
    }
    case "UPDATE_CURRENT_LINE": {
      const updatedLines = [...state.lines];
      updatedLines[updatedLines.length - 1] = action.payload;
      return {
        ...state,
        lines: updatedLines,
      };
    }
    case "ADD_LINE": {
      return {
        ...state,
        lines: [...state.lines, action.payload],
      };
    }
    case "ADD_LINES_TO_UNDO_STACK": {
      return {
        ...state,
        undoStack: [...state.undoStack, [...state.lines]],
        redoStack: [],
      };
    }

    case "UNDO": {
      if (state.undoStack.length === 0) return state;
      const newUndoStack = [...state.undoStack];
      const previousLines = newUndoStack.pop() ?? [];
      return {
        ...state,
        lines: previousLines,
        undoStack: newUndoStack,
        redoStack: [...state.redoStack, state.lines],
      };
    }
    case "REDO": {
      if (state.redoStack.length === 0) return state;
      const newRedoStack = [...state.redoStack];
      const nextLines = newRedoStack.pop();
      return {
        ...state,
        lines: nextLines ?? [],
        undoStack: [...state.undoStack, state.lines],
        redoStack: newRedoStack,
      };
    }
    default:
      return state;
  }
};

export interface MiniCanvasProps {
  stageRef: React.RefObject<Konva.Stage>;
  defaultImage?: string;
  windowSize: { width: number; height: number };
  dimensions?: [number, number];
}

const MiniCanvas = React.memo(
  ({
    stageRef,
    defaultImage,
    windowSize,
    dimensions = [1_024, 1_024],
  }: MiniCanvasProps) => {
    const { miniCanvasSettings, setMiniCanvasSettings } =
      useMiniCanvasContext();
    const { color, width } = miniCanvasSettings;
    const [tool, setTool] = useState<MiniCanvasTool>("brush");

    const [size, setSize] = useState({
      width: dimensions[0],
      height: dimensions[1],
    });

    const scale = useMemo(() => {
      const modalVerticalPadding = 200;
      const modalHorizontalPadding = 50;
      const modalMaxWidth =
        (windowSize.width ?? 0) * 0.9 - modalHorizontalPadding;
      const modalMaxHeight =
        (windowSize.height ?? 0) * 0.9 - modalVerticalPadding;

      // Calculate scales for both dimensions
      const scaleWidth = modalMaxWidth / size.width;
      const scaleHeight = modalMaxHeight / size.height;

      // Use the smaller scale to ensure image fits in both dimensions
      return Math.min(scaleWidth, scaleHeight);
    }, [windowSize.width, windowSize.height, size.width, size.height]);

    const isDrawing = useRef(false);

    const [state, dispatch] = useReducer(miniCanvasReducer, {
      lines: [],
      undoStack: [],
      redoStack: [],
    });

    useEffect(() => {
      return () => {
        dispatch({ type: "CLEAR" });
      };
    }, []);

    const dist = (p1: Point, p2: Point) => {
      return ((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2) ** 0.5;
    };

    const handleMouseMove = useCallback(
      (event: MouseEvent | TouchEvent) => {
        // Only proceed if drawing is active and there is a last line
        if (!isDrawing.current || state.lines.length === 0) {
          return;
        }

        const bounds =
          stageRef.current?.attrs.container.getBoundingClientRect() ?? {
            left: 0,
            top: 0,
          };
        const correctEvent =
          window.TouchEvent && event instanceof TouchEvent
            ? event.touches[0]
            : (event as MouseEvent);
        const newPoint = {
          x: (correctEvent.clientX - bounds.left) / scale,
          y: (correctEvent.clientY - bounds.top) / scale,
        };

        const lastLine = state.lines[state.lines.length - 1];
        const lastPoint = {
          x: lastLine.points[0],
          y: lastLine.points[1],
        };

        // https://github.com/konvajs/konva/issues/1144
        if (dist(lastPoint, newPoint) > 4) {
          // Add point to the last line
          const newPoints = lastLine.points.concat([newPoint.x, newPoint.y]);
          const updatedLine = { ...lastLine, points: newPoints };
          dispatch({ type: "UPDATE_CURRENT_LINE", payload: updatedLine });
        }
      },
      [scale, state.lines, stageRef]
    );

    const handleMouseDown = (
      e: Konva.KonvaEventObject<MouseEvent | TouchEvent>
    ) => {
      const pos = e.target.getStage()?.getRelativePointerPosition();
      if (!pos) return;

      // Save the state before starting the new line
      if (isDrawing.current === false) {
        dispatch({ type: "ADD_LINES_TO_UNDO_STACK" });
      }

      isDrawing.current = true;
      const newLine = { tool, points: [pos.x, pos.y], stroke: color, width };
      dispatch({ type: "ADD_LINE", payload: newLine });
    };

    const handleMouseUp = useCallback(() => {
      isDrawing.current = false;
    }, []);

    useEffect(() => {
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchend", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("touchmove", handleMouseMove);
      return () => {
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchend", handleMouseUp);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("touchmove", handleMouseMove);
      };
    }, [handleMouseMove, handleMouseUp]);

    const handleUndo = () => {
      dispatch({ type: "UNDO" });
    };

    const handleRedo = () => {
      dispatch({ type: "REDO" });
    };

    const [imageB64, setImageB64] = useState<string | undefined>();
    useEffect(() => {
      if (!defaultImage) return;
      void srcToBase64(defaultImage).then((b64) => {
        setImageB64(b64);
      });
    }, [defaultImage]);

    const [image, imageState] = useImage(imageB64 ?? "", "anonymous");

    useEffect(() => {
      if (!image) return;
      setSize({
        width: image.width,
        height: image.height,
      });
    }, [image]);

    useHotkeys(
      canvasShortcuts.undo.shortcut,
      handleUndo,
      { preventDefault: true },
      [handleUndo]
    );

    useHotkeys(
      canvasShortcuts.redo.shortcut,
      handleRedo,
      {
        preventDefault: true,
      },
      [handleRedo]
    );

    return (
      <Flex align="center" direction="column" w="full" h="full">
        <Box w="full" h="full">
          <Stage
            ref={stageRef}
            width={size.width * scale}
            height={size.height * scale}
            scale={{ x: scale, y: scale }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            style={{
              margin: "auto",
              width: size.width * scale,
              height: size.height * scale,
              border: "1px solid #333333",
              cursor: `url(${getBrushCircle(width * scale)}) ${
                (width * scale) / 2
              } ${(width * scale) / 2}, auto`,
            }}
          >
            <Layer>
              <Rect width={size.width} height={size.height} fill="#ffffff" />
            </Layer>
            <Layer>
              <Image
                image={image}
                width={size.width}
                height={size.height}
                alt="your image"
              />
              {state.lines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke={line.stroke}
                  strokeWidth={line.width}
                  tension={0.8}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={
                    line.tool === "eraser" ? "destination-out" : "source-over"
                  }
                />
              ))}
            </Layer>
          </Stage>

          {imageState === "loading" && !!imageB64 && (
            <Center pos="absolute" top={0} left={0} w={`full`} h={`full`}>
              <Spinner color="black" size="xl" />
            </Center>
          )}
        </Box>
        <HStack align="center" justify="space-between" p={2}>
          <HStack>
            <IconButton
              aria-label="undo"
              icon={<Icon id="Domains/Canvas/Undo" />}
              isDisabled={state.undoStack.length === 0}
              onClick={handleUndo}
              size="sm"
              variant="secondary"
            />
            <IconButton
              aria-label="redo"
              icon={<Icon id="Domains/Canvas/Redo" />}
              isDisabled={state.redoStack.length === 0}
              onClick={handleRedo}
              size="sm"
              variant="secondary"
            />
          </HStack>
          <HStack
            gap="2"
            h="36px"
            bg="backgroundQuaternary.500"
            borderRadius="md"
          >
            <IconButton
              aria-label="brush"
              icon={<Icon id="Ui/Pencil" />}
              onClick={() => setTool("brush")}
              size="sm"
              variant={tool === "brush" ? "primary" : "secondary"}
            />

            <ChakraImage src={getBrushCircle(5)} />
            <FormControl
              // This component allow the slider to use the _group hover styles that disable transitions animation on the slider to not have a lag effect
              h="fit-content"
              m={0}
              p={0}
            >
              <Slider
                w="90px"
                colorScheme="white"
                defaultValue={width}
                max={70}
                min={1}
                onChange={(value) => {
                  setMiniCanvasSettings({
                    ...miniCanvasSettings,
                    width: value,
                  });
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb
                  //   Because the background is different from usual slider bg, we need the shadow to match the background to have that floating thumb effect that dont touch the track
                  bg="backgroundQuaternary.500"
                  shadow="0px 0px 0px 2px var(--chakra-colors-backgroundQuaternary-500)"
                />
              </Slider>
            </FormControl>
            <ChakraImage src={getBrushCircle(20)} />

            <Divider h={"60%"} orientation="vertical" />

            <EyeDropper
              color={color}
              onPickColor={(color) =>
                setMiniCanvasSettings({ ...miniCanvasSettings, color })
              }
            />

            <Popover isLazy placement="auto">
              <PopoverTrigger>
                <Box
                  className="checkerboard"
                  // White background to show alpha transparency
                  w={5}
                  h={5}
                  mr={2}
                  borderRadius="full"
                  cursor={"pointer"}
                  bgColor={"white"}
                >
                  <Box w={5} h={5} borderRadius="50%" bgColor={color} />
                </Box>
              </PopoverTrigger>
              <Portal>
                <PopoverContent w="228px" border={"none"}>
                  <ColorPicker
                    withAlpha
                    withEyeDropper
                    color={color}
                    onChange={(hex) => {
                      setMiniCanvasSettings({
                        ...miniCanvasSettings,
                        color: hex,
                      });
                    }}
                  />
                </PopoverContent>
              </Portal>
            </Popover>
          </HStack>
          <IconButton
            aria-label="eraser"
            icon={<Icon id="Domains/Canvas/Erase" />}
            onClick={() => setTool("eraser")}
            size="sm"
            variant={tool === "eraser" ? "primary" : "secondary"}
          />
        </HStack>
      </Flex>
    );
  }
);
MiniCanvas.displayName = "MiniCanvas";

export type ButtonWithModalMiniCanvasProps = {
  defaultImage?: string;
  children: ReactNode;
  canvasDimensions?: [number, number];
  onAccept: (image: string) => void;
  CustomButton?: React.ComponentType<ButtonProps>;
} & ButtonProps;

export const ButtonWithModalMiniCanvas = React.memo(
  ({
    defaultImage,
    children,
    canvasDimensions = [1_024, 1_024],
    onAccept,
    CustomButton = Button,
    ...buttonProps
  }: ButtonWithModalMiniCanvasProps) => {
    const initRef = useRef<any>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const stageRef = useRef<Konva.Stage>(null);
    const [isAcceptLoading, setIsAcceptLoading] = useState(false);

    const [image] = useImage(defaultImage ?? "", "anonymous");
    const handleOnAccept = useCallback(async () => {
      if (!stageRef.current) return;
      setIsAcceptLoading(true);

      await new Promise((resolve) => setTimeout(resolve, 0));

      const currentScale = stageRef.current.scale();
      const currentHeight = stageRef.current.height();
      const currentWidth = stageRef.current.width();

      stageRef.current.scale({
        x: 1,
        y: 1,
      });
      stageRef.current.width(image?.width ?? canvasDimensions[0]);
      stageRef.current.height(image?.height ?? canvasDimensions[1]);

      onAccept(stageRef.current.toDataURL());
      onClose();

      stageRef.current.scale(currentScale);
      stageRef.current.height(currentHeight);
      stageRef.current.width(currentWidth);

      setIsAcceptLoading(false);
    }, [image, canvasDimensions, onAccept, onClose]);

    return (
      <>
        <CustomButton onClick={onOpen} {...buttonProps}>
          {children}
        </CustomButton>

        <Modal
          initialFocusRef={initRef}
          isOpen={isOpen}
          onClose={onClose}
          variant="action"
        >
          <ModalOverlay />
          <ModalContent w="fit-content" maxW="unset" maxH="unset">
            <ModalHeader>Edit reference image</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <MiniCanvas
                dimensions={canvasDimensions}
                stageRef={stageRef}
                defaultImage={defaultImage}
                windowSize={{
                  width: windowWidth ?? 0,
                  height: windowHeight ?? 0,
                }}
              />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button onClick={onClose} size={"sm"} variant="secondary">
                  Cancel
                </Button>
                <Button
                  isLoading={isAcceptLoading}
                  onClick={handleOnAccept}
                  size={"sm"}
                  variant="primary"
                >
                  Apply
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

ButtonWithModalMiniCanvas.displayName = "ButtonWithModalMiniCanvas";

export default MiniCanvas;
