import { tagIdToLabel } from "domains/tags/utils";
import Tag from "domains/ui/components/Tag";

import { Button, Wrap, WrapItem } from "@chakra-ui/react";

interface TagDisplayWithRemoveProps {
  tags: string[];
  onRemove?: (tag: string) => void;
  maxTags?: number;
  onSeeMore?: () => void;
}

const TagDisplayWithRemove: React.FC<TagDisplayWithRemoveProps> = ({
  tags,
  onRemove,
  maxTags,
  onSeeMore,
}) => {
  const visibleTags = maxTags ? tags.slice(0, maxTags) : tags;
  const showMore = maxTags && tags.length > maxTags;

  const canRemove = !!onRemove;

  if (!tags.length) {
    return null;
  }
  return (
    <Wrap>
      {visibleTags.map((tag) => (
        <WrapItem key={tag}>
          <Tag
            canEdit={canRemove}
            label={tagIdToLabel(tag)}
            onRemoveClick={() => onRemove?.(tag)}
          />
        </WrapItem>
      ))}
      {showMore && (
        <Button
          onClick={onSeeMore}
          size="xs"
          textDecor="underline"
          variant="ghost"
        >
          View More
        </Button>
      )}
    </Wrap>
  );
};

export default TagDisplayWithRemove;
