import isBreakpointMobile from "domains/commons/isBreakpointMobile";
import { useNavBarContext } from "domains/navigation/contexts/NavBarProvider";
import { extraTheme } from "domains/theme";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";

import { Flex, HStack, IconButton, useBreakpoint } from "@chakra-ui/react";

import BackgroundTasksDropdown from "./BackgroundTasksDropdown";
import HelpDropdown from "./HelpDropdown";
import LinkDropdown from "./LinkDropdown";
import OrganizationDropdown from "./OrganizationDropdown";
import ProfileDropdown from "./ProfileDropdown";
import SearchButton from "./SearchButton";
import SubscriptionWarning from "./SubscriptionWarning";

export const TOP_BAR_HEIGHT = 56;

export default function TopBar() {
  const { toggleDrawer, absoluteWidth: navBarWidth } = useNavBarContext();
  const breakpoint = useBreakpoint({ ssr: true });
  const isMobile = isBreakpointMobile(breakpoint);

  return (
    <Flex
      pos="fixed"
      zIndex="banner"
      align="center"
      justify="space-between"
      gap={4}
      w={isMobile ? "100%" : `calc(100% - ${navBarWidth}px)`}
      h={`${TOP_BAR_HEIGHT}px`}
      px={isMobile ? 2 : 4}
      borderBottomWidth="1px"
      borderBottomColor="border.500"
      transition={extraTheme.transitions.fast}
      bgColor="black.500"
    >
      {/* Left side */}
      <Flex w="100%">
        {isMobile && (
          <IconButton
            aria-label="Open menu"
            onClick={() => toggleDrawer()}
            size="md"
            variant="unstyled"
          >
            <Icon id="Layout/MobileMenu" h="16px" />
          </IconButton>
        )}

        <OrganizationDropdown />
      </Flex>

      {/* Right side */}
      <HStack align="center" spacing={2}>
        <SubscriptionWarning />

        <SearchButton />

        <Divider mx={1} dividerProps={{ w: "1px", h: "14px" }} />

        <BackgroundTasksDropdown />
        <HelpDropdown />
        <LinkDropdown />

        <Divider mx={1} dividerProps={{ w: "1px", h: "14px" }} />

        <ProfileDropdown />
      </HStack>
    </Flex>
  );
}
