import { useCallback, useRef, useState } from "react";
import useGetImageDimensions from "domains/commons/hooks/useGetImageDimensions";
import ImageCropper from "domains/image/components/ImageCropper";
import { TrainImage } from "domains/models/interfaces/train";
import Icon from "domains/ui/components/Icon";
import { ReactCropperElement } from "react-cropper";

import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Text,
  VStack,
} from "@chakra-ui/react";

import ElementLowResWarning from "./ElementLowResWarning";
import TopIconButton from "./ElementTopIconButton";

export interface ModalCropProps {
  images: TrainImage[];
  lowResMax?: number;
  uploadedImagesCount: number | undefined;
  onSubmit: (images: TrainImage[]) => void;
  onChange: (images: TrainImage[]) => void;
}

export default function ModalCrop({
  images,
  lowResMax = 1_000,
  uploadedImagesCount,
  onSubmit,
  onChange,
}: ModalCropProps) {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [imageToCrop, setImageToCrop] = useState<number | undefined>();
  const cropperRef = useRef<ReactCropperElement>(null);

  const step = (() => {
    if (imageToCrop !== undefined) return "crop";
    else if (isUploading) return "upload";
    else return "list";
  })();

  // ----------------------------------

  const handleRemoveClick = useCallback(
    (idx: number) => {
      const newImages = [...images];
      newImages.splice(idx, 1);
      onChange(newImages);
    },
    [onChange, images]
  );

  const handleCancelClick = useCallback(() => {
    onChange([]);
    setIsUploading(false);
    setIsCropping(false);
    setImageToCrop(undefined);
  }, [onChange, setIsUploading, setIsCropping, setImageToCrop]);

  const handleSubmitClick = useCallback(async () => {
    try {
      setIsUploading(true);
      await onSubmit(images);
    } finally {
      setIsUploading(false);
    }
  }, [onSubmit, setIsUploading, images]);

  const handleCancelCropClick = useCallback(() => {
    setImageToCrop(undefined);
  }, [setImageToCrop]);

  const handleAdjustCropClick = useCallback(
    (idx: number) => {
      setImageToCrop(idx);
    },
    [setImageToCrop]
  );

  const handleConfirmCropClick = useCallback(async () => {
    if (imageToCrop === undefined) return;
    try {
      setIsCropping(true);

      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;
      const cropMetadata = cropper.getData();
      const width = Math.floor(cropper.getData().width - 1);
      const height = Math.floor(cropper.getData().height - 1);

      cropper.setData({ width, height });
      const result = cropper.getCroppedCanvas().toDataURL("image/png", 1);

      const prevImage = images[imageToCrop];
      const newImage: TrainImage = {
        ...prevImage,
        cropped: {
          src: result,
          metadata: { ...cropMetadata },
        },
      };

      const newImages = [...images];
      newImages.splice(imageToCrop, 1, newImage);
      onChange(newImages);
      setImageToCrop(undefined);
    } finally {
      setIsCropping(false);
    }
  }, [onChange, setIsCropping, images, imageToCrop]);

  // ---------------------------------

  return (
    <Modal isOpen={images.length > 0} onClose={() => {}} variant="action">
      <ModalOverlay data-outside-click-excluded="true" />
      <ModalContent
        p={0}
        // eslint-disable-next-line
        // @ts-ignore
        containerProps={{ "data-outside-click-excluded": true }}
      >
        <ModalHeader>
          <HStack w="100%" spacing={2}>
            {step === "crop" && (
              <IconButton
                aria-label="Back"
                colorScheme="white"
                onClick={handleCancelCropClick}
                size="xs"
                variant="ghost"
              >
                <Icon id="Ui/ChevronLeft" h="12px" />
              </IconButton>
            )}

            <Text flex={1} textAlign="left" size="body.bold.lg">
              {(() => {
                if (step === "crop") return "Adjust Crop";
                else if (step === "list") return "Crop Images";
                else if (step === "upload") return "Uploading Images...";
                else return "";
              })()}
            </Text>

            {step !== "upload" && (
              <IconButton
                aria-label="Close"
                colorScheme="white"
                onClick={handleCancelClick}
                size="xs"
                variant="ghost"
              >
                <Icon id="Ui/Cross" h="12px" />
              </IconButton>
            )}
          </HStack>
        </ModalHeader>

        <ModalBody w="100%">
          {step === "crop" && (
            <Flex justify="center" w="100%" p={6}>
              <ImageCropper
                imageData={
                  imageToCrop !== undefined
                    ? images[imageToCrop].original.src
                    : ""
                }
                cropMetadata={
                  imageToCrop !== undefined
                    ? images[imageToCrop].cropped?.metadata
                    : undefined
                }
                cropperRef={cropperRef}
              />
            </Flex>
          )}

          {step === "list" && (
            <ImageList
              images={images}
              lowResMax={lowResMax}
              onAdjustCropClick={handleAdjustCropClick}
              onRemoveClick={handleRemoveClick}
            />
          )}

          {step === "upload" && (
            <VStack p={10} spacing={2}>
              <Text color="textSecondary" size="body.md">
                {`${uploadedImagesCount ?? 0} of ${images.length} ${
                  images.length > 1 ? "images" : "image"
                } uploaded`}
              </Text>

              <Slider
                pointerEvents="none"
                max={images.length}
                min={0}
                value={uploadedImagesCount ?? 0}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
              </Slider>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={step === "upload"}
            onClick={
              (step === "crop" && handleCancelCropClick) ||
              (step === "list" && handleCancelClick) ||
              undefined
            }
            size="sm"
            variant="secondary"
          >
            Cancel
          </Button>

          <Button
            isLoading={isCropping || isUploading}
            onClick={
              (step === "crop" && handleConfirmCropClick) ||
              (step === "list" && handleSubmitClick) ||
              undefined
            }
            size="sm"
            variant="primary"
          >
            <Text>{step === "crop" ? "Confirm" : "Upload Images"}</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

// ------------------------------------

interface ImageListProps {
  images: TrainImage[];
  lowResMax?: number;
  onAdjustCropClick: (idx: number) => void;
  onRemoveClick: (idx: number) => void;
}

function ImageList({
  images,
  lowResMax = 1_000,
  onAdjustCropClick,
  onRemoveClick,
}: ImageListProps) {
  const columnCount = Math.min(3, images.length);
  return (
    <Box overflow="auto" maxH="450px">
      <SimpleGrid
        maxW={`${columnCount * 300}px`}
        mx="auto"
        p={4}
        columns={columnCount}
        spacing={2}
      >
        {images.map((image, idx) => (
          <ImageListItem
            key={`${idx}-${image.original.src.slice(-10) || "unknown"}`}
            idx={idx}
            image={image}
            lowResMax={lowResMax}
            onAdjustCropClick={onAdjustCropClick}
            onRemoveClick={onRemoveClick}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}

// ------------------------------------

interface ImageListItemProps {
  image: TrainImage;
  idx: number;
  lowResMax: number;
  onAdjustCropClick: (idx: number) => void;
  onRemoveClick: (idx: number) => void;
}

function ImageListItem({
  image,
  idx,
  lowResMax,
  onAdjustCropClick,
  onRemoveClick,
}: ImageListItemProps) {
  const { width } = useGetImageDimensions(
    image.cropped?.src ?? image.original.src
  );
  const isLowRes = width !== undefined && width < lowResMax;

  return (
    <Box
      pos="relative"
      overflow="hidden"
      w="100%"
      pt="100%"
      borderRadius="md"
      bgColor="background.500"
      data-group
    >
      <Image
        pos="absolute"
        top={0}
        left={0}
        w="100%"
        h="100%"
        objectFit="contain"
        alt="image to crop"
        src={image.cropped?.src ?? image.original.src}
      />

      <VStack
        pos="absolute"
        top={0}
        left={0}
        justify="center"
        w="100%"
        h="100%"
        _groupHover={{ visibility: "visible" }}
        visibility="hidden"
        spacing={2}
      >
        <Button
          onClick={() => onAdjustCropClick(idx)}
          size="sm"
          variant="secondaryAlt"
        >
          Adjust Crop
        </Button>
      </VStack>

      <HStack
        pos="absolute"
        top={2}
        right={2}
        _groupHover={{ visibility: "visible" }}
        visibility="hidden"
        spacing={2}
      >
        <TopIconButton onClick={() => onRemoveClick(idx)}>
          <Icon id="Ui/TrashMd" h="14px" />
        </TopIconButton>
      </HStack>

      {isLowRes && (
        <Box pos="absolute" top={0} left={0} p={2}>
          <ElementLowResWarning lowResMax={lowResMax} />
        </Box>
      )}
    </Box>
  );
}
