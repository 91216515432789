import { useCallback, useMemo, useState } from "react";
import { PIXELATE_DEFAULT_VALUES } from "domains/assets/constants/pixelate";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { ColorPalette } from "domains/ui/components/ColorPicker/defaultColorPalettes";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { ExcludeCu, removeCuFromType } from "infra/api/ExludeCU";
import {
  GetAssetsByAssetIdApiResponse,
  PostPixelateInferencesApiArg,
  PostPixelateInferencesApiResponse,
  useLazyGetAssetsByAssetIdQuery,
  usePostPixelateInferencesMutation,
} from "infra/api/generated/api";
import _ from "lodash";

export const PIXEL_GRID_SIZES = [16, 32, 64, 128, 256];
export type PixelGridSize = (typeof PIXEL_GRID_SIZES)[number];

export interface PixelateForm {
  shouldRemoveNoise: boolean;
  shouldRemoveBackground: boolean;
  pixelGridSize: PixelGridSize;
  shouldUseNewColorPalette: boolean;
  newColorPalette: ColorPalette;
  selectedColorPalette: ColorPalette | undefined;
  colorPaletteSize: number | undefined;
  reference:
    | {
        src: string;
        assetId?: string;
      }
    | undefined;
}

export interface UseAssetPixelateReturnValue {
  form: PixelateForm;
  setValue: <K extends keyof PixelateForm>(
    key: K,
    value: PixelateForm[K]
  ) => void;
  setReference: (
    image: string,
    assetId?: string,
    assetMeta?: GetAssetsByAssetIdApiResponse["asset"]
  ) => void;
  resetForm: () => void;
  handlePixelateJob: (props: {
    assetId?: string;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<
    ExcludeCu<PostPixelateInferencesApiResponse>["job"] | undefined
  >;
  handlePixelateAsset: (props: {
    assetId?: string;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<GetAssetsByAssetIdApiResponse["asset"] | undefined>;
  isPixelateLoading: boolean;
}

export default function useAssetPixelate(): UseAssetPixelateReturnValue {
  const { selectedTeam } = useTeamContext();
  const [getAssetByAssetId] = useLazyGetAssetsByAssetIdQuery();
  const [triggerImagePixelate] = usePostPixelateInferencesMutation();
  const { infoToast, successToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();
  const [form, setForm] = useState<PixelateForm>({
    ...PIXELATE_DEFAULT_VALUES,
  });
  const [isPixelateLoading, setIsPixelateLoading] = useState<boolean>(false);

  // ----------------------------------

  const handlePixelate = useCallback(
    async ({
      assetId: initialAssetId,
      trackingExtraParams,
    }: {
      assetId?: string;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      try {
        infoToast({
          title: "Pixelating image",
        });

        const assetId = initialAssetId ?? form.reference?.assetId;
        if (!assetId) {
          throw new Error("No asset");
        }

        const body = getPixelateParamsBody(assetId, form);
        const pixelateResponse = await triggerImagePixelate({
          teamId: selectedTeam.id,
          originalAssets: "true",
          body,
        })
          .unwrap()
          .then(removeCuFromType);

        Track(AnalyticsEvents.ImageLibrary.PixelatedImage, {
          ...body,
          ...trackingExtraParams,
        });

        return pixelateResponse;
      } catch (error: any) {
        handleApiError(error, "Error pixelating image", {
          quota: () => {
            if (_.get(error, "data.details.remainingSeconds")) {
              showLimitModal("planCooldown", {
                timeout: error.data.details.remainingSeconds,
                type: "pixelate",
              });
            } else if (
              _.get(error, "data.reason").includes(
                "You have reached your plan's limit."
              )
            ) {
              showLimitModal("notEnoughCreativeUnits");
            } else {
              errorToast({
                title: "Error generating images",
                description: _.get(error, "data.reason"),
              });
            }
          },
        });
      }
    },
    [
      infoToast,
      form,
      triggerImagePixelate,
      selectedTeam.id,
      errorToast,
      handleApiError,
      showLimitModal,
    ]
  );

  const handlePixelateJob = useCallback(
    async ({
      assetId: initialAssetId,
      trackingExtraParams,
    }: {
      assetId?: string;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsPixelateLoading(true);
      const pixelateResponse = await handlePixelate({
        assetId: initialAssetId,
        trackingExtraParams,
      });
      setIsPixelateLoading(false);
      return pixelateResponse?.job;
    },
    [handlePixelate]
  );

  const handlePixelateAsset = useCallback(
    async ({
      assetId: initialAssetId,
      trackingExtraParams,
    }: {
      assetId?: string;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsPixelateLoading(true);

      const pixelateResponse = await handlePixelate({
        assetId: initialAssetId,
        trackingExtraParams,
      });

      if (!pixelateResponse?.asset) {
        return;
      }

      let pixelatedAsset: GetAssetsByAssetIdApiResponse | undefined =
        await getAssetByAssetId({
          teamId: selectedTeam.id,
          originalAssets: "true",
          assetId: pixelateResponse.asset.id,
        }).unwrap();

      while (
        pixelatedAsset?.asset &&
        pixelatedAsset.asset.status === "pending"
      ) {
        await new Promise((resolve) => setTimeout(resolve, 2_000));
        try {
          pixelatedAsset = await getAssetByAssetId({
            teamId: selectedTeam.id,
            originalAssets: "true",
            assetId: pixelatedAsset.asset.id,
          }).unwrap();
        } catch (_) {
          pixelatedAsset = undefined;
        }
      }

      if (!pixelatedAsset?.asset || pixelatedAsset.asset.status !== "success") {
        errorToast({
          title: "Error pixelating image",
        });
      }

      successToast({
        title: "The image has been pixelated",
      });
      setIsPixelateLoading(false);

      return pixelatedAsset?.asset;
    },
    [
      errorToast,
      getAssetByAssetId,
      handlePixelate,
      selectedTeam.id,
      successToast,
    ]
  );

  const setValue = useCallback(
    <K extends keyof PixelateForm>(key: K, value: PixelateForm[K]) => {
      setForm((form) => ({
        ...form,
        [key]: value,
      }));
    },
    [setForm]
  );

  const setReference = useCallback(
    async (image: string, assetId?: string) => {
      setForm((form) => ({
        ...form,
        reference: {
          src: image,
          assetId,
        },
      }));
    },
    [setForm]
  );

  const resetForm = useCallback(() => {
    setForm({ ...PIXELATE_DEFAULT_VALUES });
  }, []);

  // ----------------------------------

  return useMemo(
    () => ({
      form,
      setValue,
      setReference,
      resetForm,
      handlePixelateJob,
      handlePixelateAsset,
      isPixelateLoading,
    }),
    [
      form,
      setValue,
      setReference,
      resetForm,
      handlePixelateJob,
      handlePixelateAsset,
      isPixelateLoading,
    ]
  );
}

// ----------------------------------

export function getPixelateParamsBody(
  assetId: string,
  form: PixelateForm
): PostPixelateInferencesApiArg["body"] {
  let colorPalette = undefined;
  if (form.shouldUseNewColorPalette) {
    colorPalette = form.newColorPalette.colors.map((color) => {
      return [
        parseInt(color.slice(1, 3), 16),
        parseInt(color.slice(3, 5), 16),
        parseInt(color.slice(5, 7), 16),
      ];
    });
  } else if (form.selectedColorPalette) {
    colorPalette = form.selectedColorPalette.colors.map((color) => {
      return [
        parseInt(color.slice(1, 3), 16),
        parseInt(color.slice(3, 5), 16),
        parseInt(color.slice(5, 7), 16),
      ];
    });
  }

  return {
    image: assetId,
    pixelGridSize: form.pixelGridSize,
    removeNoise: form.shouldRemoveNoise,
    removeBackground: form.shouldRemoveBackground,
    colorPalette,
    colorPaletteSize: !colorPalette ? form.colorPaletteSize : undefined,
  };
}
