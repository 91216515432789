import React, { PropsWithChildren } from "react";
import { FmFile, FmFileType } from "domains/file-manager/interfacesV2";
import Button from "domains/ui/components/Button";

import {
  Card,
  Checkbox,
  Divider,
  HStack,
  Slide,
  Text,
  useToken,
} from "@chakra-ui/react";

export type FmSelectionActionsProps<
  T extends FmFileType,
  P extends object = object
> = {
  selectedFiles: FmFile<T>[];
  isDisplayed: boolean;
} & P;

export interface FmSelectionActionProps<T extends FmFileType> {
  selectedFiles: FmFile<T>[];
  isDisplayed: boolean;
}

// ------------------------------------

export interface FmSelectionActionsContainerProps extends PropsWithChildren {
  totalCount: number;
  selectionCount: number;
  selectionMax?: number;
  onSelectAll: () => void;
  onDeselectAll: () => void;
}

export const FmSelectionActionsContainer = React.memo(
  function FmSelectionActionsContainer({
    totalCount,
    selectionCount,
    selectionMax,

    onSelectAll,
    onDeselectAll,

    children,
  }: FmSelectionActionsContainerProps) {
    const selectionBarZIndex = useToken("zIndices", "popover");

    return (
      <Slide
        direction="bottom"
        in={!!selectionCount}
        style={{ zIndex: selectionBarZIndex }}
      >
        <Card
          py={5}
          borderBottomColor="transparent"
          borderX={0}
          borderRadius={0}
          data-outside-click-excluded={true}
          data-testid="file-manager-selection-bar"
        >
          <HStack
            align="center"
            justify="space-between"
            w="100%"
            h="100%"
            px={6}
            spacing={4}
          >
            <SectionLeft
              count={selectionCount}
              max={selectionMax}
              onClearClick={selectionCount ? onDeselectAll : undefined}
              onSelectAllClick={
                selectionCount < totalCount ? onSelectAll : undefined
              }
            />

            <HStack align="center" justify="center">
              {children}
            </HStack>
          </HStack>
        </Card>
      </Slide>
    );
  }
);

// ------------------------------------

interface SectionLeftProps {
  count: number;
  max?: number;
  onSelectAllClick?: () => void;
  onClearClick?: () => void;
}

const SectionLeft = React.memo(function SectionLeft({
  count,
  max,
  onSelectAllClick,
  onClearClick,
}: SectionLeftProps) {
  return (
    <HStack wrap="nowrap" spacing={3}>
      <Checkbox
        pointerEvents="none"
        colorScheme="primary"
        isChecked={true}
        size="xl"
        variant="lightCircular"
      />

      <Text
        textColor="textPrimary"
        whiteSpace="nowrap"
        data-testid="file-manager-number-images-selected"
        size="body.bold.lg"
      >
        {`${count} selected`}
      </Text>

      {max !== undefined && (
        <Text textColor="textSecondary" whiteSpace="nowrap" size="body.md">
          {`(${max} max)`}
        </Text>
      )}

      {(onSelectAllClick || onClearClick) && (
        <Divider h={6} orientation="vertical" />
      )}

      {onSelectAllClick && (
        <Button
          variant="secondary"
          onClick={onSelectAllClick}
          data-testid="file-manager-select-all-button"
        >
          Select All
        </Button>
      )}

      {onClearClick && (
        <Button
          variant="secondary"
          onClick={onClearClick}
          isDisabled={count === 0}
          data-testid="file-manager-clear-button"
        >
          Clear
        </Button>
      )}
    </HStack>
  );
});
