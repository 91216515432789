import { SkyboxForm } from "domains/skyboxes/interfaces/Skybox";
import { GetModelsByModelIdApiResponse } from "infra/api/generated/api";

export type SkyboxStyleCategory = {
  [key: string]: { label: string };
};

export const SKYBOX_STYLE_CATEGORIES = {
  realistic: {
    label: "Realistic & Photographic",
  },
  traditional: {
    label: "Traditional Art",
  },
  stylized: {
    label: "Stylized & Illustration",
  },
  fantasy: {
    label: "SciFi & Fantasy",
  },
  digital: {
    label: "Digital & 3D",
  },
} as const satisfies SkyboxStyleCategory;

export type SkyboxStyleCategoryKey = keyof typeof SKYBOX_STYLE_CATEGORIES;

export const SKYBOX_STYLE_CATEGORY_KEYS = Object.keys(
  SKYBOX_STYLE_CATEGORIES
) as SkyboxStyleCategoryKey[];

// ------------------------------------

export type SkyboxStyle = {
  [key: string]: {
    label: string;
    category: SkyboxStyleCategoryKey;
    thumbnail: string;
  };
};

export const SKYBOX_STYLES = {
  photo: {
    label: "Photo",
    category: "realistic",
    thumbnail: "/skybox-styles/realistic-photo.jpg",
  },
  cinematic: {
    label: "Cinematic",
    category: "realistic",
    thumbnail: "/skybox-styles/realistic-cinematic.jpg",
  },
  "cloud-skydome": {
    label: "Skydome",
    category: "realistic",
    thumbnail: "/skybox-styles/realistic-skydome.jpg",
  },
  space: {
    label: "Space",
    category: "realistic",
    thumbnail: "/skybox-styles/realistic-space.jpg",
  },

  "oil-painting": {
    label: "Oil Painting",
    category: "traditional",
    thumbnail: "/skybox-styles/traditional-oil-painting.jpg",
  },
  pastel: {
    label: "Pastel",
    category: "traditional",
    thumbnail: "/skybox-styles/traditional-pastel.jpg",
  },
  ink: {
    label: "Pen & Ink",
    category: "traditional",
    thumbnail: "/skybox-styles/traditional-pen-ink.jpg",
  },
  claymation: {
    label: "Claymation",
    category: "traditional",
    thumbnail: "/skybox-styles/traditional-claymation.jpg",
  },

  comic: {
    label: "Comic",
    category: "stylized",
    thumbnail: "/skybox-styles/stylized-comic.jpg",
  },

  cartoon: {
    label: "Cartoon",
    category: "stylized",
    thumbnail: "/skybox-styles/stylized-cartoon.jpg",
  },
  manga: {
    label: "Manga",
    category: "stylized",
    thumbnail: "/skybox-styles/stylized-manga.jpg",
  },
  "manga-color": {
    label: "Manga Color",
    category: "stylized",
    thumbnail: "/skybox-styles/stylized-manga-color.jpg",
  },
  "retro-fantasy": {
    label: "Retro Fantasy",
    category: "stylized",
    thumbnail: "/skybox-styles/stylized-retro-fantasy.jpg",
  },
  whimsical: {
    label: "Whimsical",
    category: "stylized",
    thumbnail: "/skybox-styles/stylized-whimsical.jpg",
  },
  psychedelic: {
    label: "Psychedelic",
    category: "stylized",
    thumbnail: "/skybox-styles/stylized-psychedelic.jpg",
  },

  "scifi-concept-art": {
    label: "SciFi",
    category: "fantasy",
    thumbnail: "/skybox-styles/fantasy-scifi.jpg",
  },
  fantasy: {
    label: "Fantasy",
    category: "fantasy",
    thumbnail: "/skybox-styles/fantasy-fantasy.jpg",
  },
  cyberpunk: {
    label: "Cyberpunk",
    category: "fantasy",
    thumbnail: "/skybox-styles/fantasy-cyberpunk.jpg",
  },
  "neon-tron": {
    label: "Neon/Tron",
    category: "fantasy",
    thumbnail: "/skybox-styles/fantasy-neon.jpg",
  },
  enchanted: {
    label: "Enchanted",
    category: "fantasy",
    thumbnail: "/skybox-styles/fantasy-enchanted.jpg",
  },

  "3d-cartoon": {
    label: "3D Cartoon",
    category: "digital",
    thumbnail: "/skybox-styles/digital-3d-cartoon.jpg",
  },
} as const satisfies SkyboxStyle;

export type SkyboxStyleKey = keyof typeof SKYBOX_STYLES;

export const SKYBOX_STYLE_KEYS = Object.keys(SKYBOX_STYLES) as SkyboxStyleKey[];

export const FIRST_SKYBOX_STYLE_KEY =
  SKYBOX_STYLE_KEYS.find((styleKey) => {
    const style = SKYBOX_STYLES[styleKey];
    return style.category === SKYBOX_STYLE_CATEGORY_KEYS[0];
  }) ?? SKYBOX_STYLE_KEYS[0];

export const LAST_SKYBOX_STYLE_KEY =
  [...SKYBOX_STYLE_KEYS].reverse().find((styleKey) => {
    const style = SKYBOX_STYLES[styleKey];
    return (
      style.category ===
      SKYBOX_STYLE_CATEGORY_KEYS[SKYBOX_STYLE_CATEGORY_KEYS.length - 1]
    );
  }) ?? SKYBOX_STYLE_KEYS[SKYBOX_STYLE_KEYS.length - 1];

// ------------------------------------

export const DEFAULT_SKYBOX_FORM: SkyboxForm = {
  prompt: "",
  style: undefined,
  geometryEnforcement: 0,
  outputCount: 4,
  samplingSteps: 30,
  referenceImage: undefined,
  referenceImageId: undefined,
  referenceModeMap: undefined,
  shouldDetectReference: true,
  referenceFidelity: 0.15,
  referenceType: "img2img",
  seed: undefined,
};

// ------------------------------------

export const SKYBOX_MAGIC_PROMPT_MODEL = {
  id: "skyboxes",
} as GetModelsByModelIdApiResponse["model"];
