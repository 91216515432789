import { useEffect, useState } from "react";
import { getImageDimensions } from "domains/commons/utils/getImageDimensions";

type Dimension = {
  width: number | undefined;
  height: number | undefined;
};

export default function useGetImageDimensions(
  imageData: string | undefined,
  { isRestyle }: { isRestyle?: boolean } = {}
) {
  const [dimensions, setDimensions] = useState<Dimension>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    void (async () => {
      if (imageData) {
        const dimensions = await getImageDimensions(imageData);
        if (isRestyle) {
          setDimensions(convertToRestyleDimension(dimensions));
        } else {
          setDimensions(dimensions);
        }
      } else {
        setDimensions({ width: undefined, height: undefined });
      }
    })();
  }, [imageData, isRestyle]);

  return {
    width: dimensions.width,
    height: dimensions.height,
  };
}

export function convertToRestyleDimension({ width, height }: Dimension) {
  if (width === undefined || height === undefined) {
    return { width, height };
  }

  const scale: number = Math.sqrt(780_000 / (width * height));
  let newWidth: number = Math.floor(width * scale);

  if (newWidth % 64 !== 0) {
    newWidth = (Math.floor(newWidth / 64) + 1) * 64;
  }

  const newHeight: number = Math.floor((newWidth / width) * height);
  return {
    width: newWidth,
    height: newHeight,
  };
}
