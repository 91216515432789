import React, {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRouter } from "next/router";
import { AssetZoomBottomButtons } from "domains/assets/components/AssetZoom/Content";
import useAssetZoomCompareBefore from "domains/assets/hooks/useAssetZoomCompareBefore";
import { isAssetUpscaledTexture } from "domains/assets/utils/isType";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { capitalizeFirstWord } from "domains/commons/misc";
import ButtonSectionTopIcon from "domains/inference/components/InferenceGenerator/Sidebar/ButtonSectionTopIcon";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { AsideProvider } from "domains/ui/components/Aside/context";
import AsideSection from "domains/ui/components/Aside/Section";
import ButtonWithCuIndicator from "domains/ui/components/ButtonWithCuIndicator";
import ControlSwitch from "domains/ui/components/ControlSwitch";
import Icon from "domains/ui/components/Icon";
import Slider from "domains/ui/components/Slider";
import SliderWithInputNumber from "domains/ui/components/SliderWithInputNumber";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";
import { useHandleApiError } from "infra/api/error";
import { removeCuFromType } from "infra/api/ExludeCU";
import {
  GetAssetsByAssetIdApiResponse,
  GetJobIdApiResponse,
  useGetAssetsByAssetIdQuery,
  useGetJobIdQuery,
  usePostTextureInferencesMutation,
} from "infra/api/generated/api";
import { apiSlice, useGetTextureMapsCostQuery } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import store from "infra/store/store";
import _ from "lodash";
import * as THREE from "three";
import type { OrbitControls as OrbitControlsImpl } from "three-stdlib";

import {
  Box,
  Center,
  HStack,
  Image,
  Select,
  Spinner,
  Text,
  usePrevious,
  VStack,
} from "@chakra-ui/react";
import {
  Environment,
  EnvironmentProps,
  Html,
  OrbitControls,
} from "@react-three/drei";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  computeAO,
  computeHeight,
  computeMetallic,
  computeNormal,
  computeSmoothness,
  getParameters,
  roundEdgedBox,
} from "@scenario/textures";

const ENV_MAPS = [
  "apartment",
  "city",
  "dawn",
  "forest",
  "lobby",
  "night",
  "park",
  "studio",
  "sunset",
  "warehouse",
];

type TextureSettings = {
  raised: number;
  shiny: number;
  polished: number;
  angular: number;
  invert: boolean;
  repeat: number;
  geometry: "sphere" | "box" | "plane" | "cylinder";
  metalness: number;
  roughness: number;
  envMapIntensity: number;
  aoMapIntensity: number;
  displacementScale: number;
  withBackground: boolean;
  envMap: (typeof ENV_MAPS)[number];
};

const DEFAULT_TEXTURE_SETTINGS: TextureSettings = {
  raised: 0.25,
  shiny: 0.5,
  polished: 0.5,
  angular: 0.5,
  repeat: 2,
  invert: false,
  geometry: "box",
  metalness: 0.25,
  roughness: 0.5,
  envMapIntensity: 0.5,
  aoMapIntensity: 0.5,
  displacementScale: 40,
  withBackground: false,
  envMap: "forest",
};

interface AssetZoomTextureViewerProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  isComparing: boolean;
  onToggleComparing: () => void;
}

export default function AssetZoomTextureViewer({
  asset,
  isComparing,
  onToggleComparing,
}: AssetZoomTextureViewerProps) {
  const router = useRouter();
  const { selectedTeam } = useTeamContext();
  const handleApiError = useHandleApiError();
  const { infoToast, errorToast } = useScenarioToast();
  const isTextureType = asset.metadata.type === "texture";
  const [notDebouncedSettings, setNotDebouncedSettings] =
    usePersistedState<TextureSettings>(
      PersistedStateKey.TEXTURE_VIEWER_SETTINGS,
      {
        defaultValue: DEFAULT_TEXTURE_SETTINGS,
      }
    );
  const settings = useDebounce(notDebouncedSettings, 1_000);
  const [isHovering, setIsHovering] = useState(false);
  const [autoRotate, setAutoRotate] = usePersistedState<boolean>(
    PersistedStateKey.TEXTURE_AUTO_ROTATE,
    {
      defaultValue: true,
    }
  );

  const { data: cuCostData, isFetching: isCuLoading } =
    useGetTextureMapsCostQuery(
      isTextureType
        ? skipToken
        : {
            teamId: selectedTeam.id,
            body: {
              defaultParameters: true,
              saveFlipbook: true,
              texture: asset.id,
            },
          }
    );

  const [isSavingTexture, setIsSavingTexture] = useState(false);
  const [jobToFetch, setJobToFetch] = useState<
    GetJobIdApiResponse["job"] | undefined
  >();
  const { currentData: textureJob } = useGetJobIdQuery(
    jobToFetch
      ? {
          teamId: selectedTeam.id,
          jobId: jobToFetch.jobId,
        }
      : skipToken,
    {
      pollingInterval: 5_000,
    }
  );
  const [saveTextureTrigger] = usePostTextureInferencesMutation();
  const handleSave = useCallback(async () => {
    setIsSavingTexture(true);
    try {
      const newJob = await saveTextureTrigger({
        teamId: selectedTeam.id,
        body: {
          defaultParameters: false,
          polished: settings.polished,
          angular: settings.angular,
          saveFlipbook: true,
          texture: asset.id,
          raised: settings.raised,
          shiny: settings.shiny,
          invert: settings.invert,
        },
      })
        .unwrap()
        .then(removeCuFromType);
      infoToast({
        title: "Generating texture maps",
      });
      setJobToFetch(newJob.job);
    } catch (error) {
      handleApiError(error, "There was an error saving the texture");
    } finally {
      setIsSavingTexture(false);
    }
  }, [
    infoToast,
    asset,
    saveTextureTrigger,
    selectedTeam.id,
    settings.polished,
    settings.angular,
    settings.raised,
    settings.shiny,
    settings.invert,
    handleApiError,
  ]);

  useEffect(() => {
    if (
      textureJob?.job &&
      textureJob.job.status === "success" &&
      textureJob.job.metadata.assetIds?.[0]
    ) {
      delete router.query.edit;
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId:
            textureJob.job.metadata.assetIds[
              textureJob.job.metadata.assetIds.length - 1
            ],
        },
      });
      setJobToFetch(undefined);
      const jobInput = (textureJob.job.metadata.input ?? {}) as {
        texture?: string;
      };
      if (jobInput.texture) {
        store.dispatch(
          apiSlice.util.invalidateTags([
            {
              type: API_TAGS.asset,
              id: `assetId:${jobInput.texture}`,
            },
          ])
        );
      }
    } else if (
      textureJob?.job &&
      (textureJob.job.status === "failure" ||
        textureJob.job.status === "canceled")
    ) {
      errorToast({
        title: "Failed to generate texture maps",
        description: "There was an error saving the texture. Please try again.",
      });
      setJobToFetch(undefined);
    }
  }, [errorToast, router, textureJob]);

  useEffect(() => {
    setJobToFetch(undefined);
  }, [asset]);

  const handleSliderChange = (key: string) => (value: number | undefined) => {
    if (value === undefined) return;
    setNotDebouncedSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsHovering(false);
      }, 5_000);
    };
    resetTimer();
    const onMouseMove = () => {
      setIsHovering(true);
      resetTimer();
    };
    const refNode = containerRef.current;
    refNode?.addEventListener("mousemove", onMouseMove);
    return () => {
      clearTimeout(timer);
      refNode?.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  const { currentData: textureAssetData } = useGetAssetsByAssetIdQuery(
    isTextureType && asset.metadata.texture
      ? {
          teamId: selectedTeam.id,
          assetId: asset.metadata.texture,
        }
      : skipToken
  );

  useEffect(() => {
    if (asset && asset.metadata.invert !== undefined) {
      setNotDebouncedSettings((prevSettings) => ({
        ...prevSettings,
        invert: asset.metadata.invert!,
      }));
    }
  }, [asset, setNotDebouncedSettings]);

  const resetDisplaySettings = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setNotDebouncedSettings((prevSettings) => ({
        ...DEFAULT_TEXTURE_SETTINGS,
        shiny: prevSettings.shiny,
        raised: prevSettings.raised,
        polished: prevSettings.polished,
        angular: prevSettings.angular,
        invert: prevSettings.invert,
      }));
    },
    [setNotDebouncedSettings]
  );

  const resetTextureMapSettings = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setNotDebouncedSettings((prevSettings) => ({
        ...prevSettings,
        shiny: DEFAULT_TEXTURE_SETTINGS.shiny,
        raised: DEFAULT_TEXTURE_SETTINGS.raised,
        polished: DEFAULT_TEXTURE_SETTINGS.polished,
        angular: DEFAULT_TEXTURE_SETTINGS.angular,
        invert: DEFAULT_TEXTURE_SETTINGS.invert,
      }));
    },
    [setNotDebouncedSettings]
  );

  const beforeAsset = useAssetZoomCompareBefore({
    inference: undefined,
    asset,
  });

  return (
    <Box pos="relative" w="100%" h="100%">
      <Box pos="absolute" w="100%" h="100%">
        <HStack w="full" h="full" bgColor="black" spacing={0}>
          <VStack
            align="stretch"
            flexShrink={0}
            overflow="auto"
            w="300px"
            h="100%"
            bg="backgroundTertiary.500"
            borderRightWidth={1}
            spacing={0}
          >
            <AsideProvider prefix="texture">
              <AsideSection
                id="displaySettings"
                title="Display Settings"
                topRightComponent={
                  <ButtonSectionTopIcon onClick={resetDisplaySettings}>
                    <Icon id="Ui/Refresh" h="14px" />
                  </ButtonSectionTopIcon>
                }
              >
                <VStack align="stretch" spacing={2}>
                  <Text color="textSecondary" size="body.xs">
                    Configure visual rendering and environment properties for
                    the texture on the Scenario interface.
                  </Text>
                  <SliderWithInputNumber
                    title="Tiling"
                    value={notDebouncedSettings.repeat}
                    setValue={handleSliderChange("repeat")}
                    min={1}
                    max={10}
                    step={1}
                  />
                  <ControlSwitch
                    title={"Auto spin"}
                    isChecked={autoRotate}
                    setIsChecked={setAutoRotate}
                  />
                  <WithLabelAndTooltip
                    label="Geometry"
                    tooltip="Select a shape to display the texture on"
                  >
                    <Select
                      w="fit-content"
                      mr={-1}
                      ml="auto"
                      onChange={(e) =>
                        setNotDebouncedSettings((prevSettings) => ({
                          ...prevSettings,
                          geometry: e.target
                            .value as TextureSettings["geometry"],
                        }))
                      }
                      value={notDebouncedSettings.geometry}
                    >
                      <option value="box">Box</option>
                      <option value="sphere">Sphere</option>
                      <option value="plane">Plane</option>
                      <option value="cylinder">Cylinder</option>
                    </Select>
                  </WithLabelAndTooltip>
                  <SliderWithInputNumber
                    title="Metalness"
                    tooltip="Adjust the reflectiveness of the material as if it were metallic"
                    value={notDebouncedSettings.metalness}
                    setValue={handleSliderChange("metalness")}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                  <SliderWithInputNumber
                    title="Roughness"
                    tooltip="Adjust how surface irregularities affect the reflection of light"
                    value={notDebouncedSettings.roughness}
                    setValue={handleSliderChange("roughness")}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                  <SliderWithInputNumber
                    title="Env Intensity"
                    tooltip="Adjust the intensity of the environment lighting"
                    value={notDebouncedSettings.envMapIntensity}
                    setValue={handleSliderChange("envMapIntensity")}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                  <SliderWithInputNumber
                    title="AO Intensity"
                    tooltip="Adjust the ambient occlusion intensity"
                    value={notDebouncedSettings.aoMapIntensity}
                    setValue={handleSliderChange("aoMapIntensity")}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                  <SliderWithInputNumber
                    title="Displacement"
                    tooltip="Adjust the displacement of the texture vertices"
                    value={notDebouncedSettings.displacementScale}
                    setValue={handleSliderChange("displacementScale")}
                    min={0}
                    max={100}
                    step={1}
                  />
                  <ControlSwitch
                    title={"Background"}
                    tooltip="Turn on/off the display of the environment"
                    isChecked={settings.withBackground}
                    setIsChecked={() => {
                      setNotDebouncedSettings((prevSettings) => ({
                        ...prevSettings,
                        withBackground: !prevSettings.withBackground,
                      }));
                    }}
                  />
                  <WithLabelAndTooltip
                    label="Environment"
                    tooltip="Select an environment to display in the background"
                  >
                    <Select
                      w="fit-content"
                      mr={-1}
                      ml="auto"
                      onChange={(e) =>
                        setNotDebouncedSettings((prevSettings) => ({
                          ...prevSettings,
                          envMap: e.target.value as TextureSettings["envMap"],
                        }))
                      }
                      value={notDebouncedSettings.envMap ?? "forest"}
                    >
                      {ENV_MAPS.map((item) => (
                        <option key={item} value={item}>
                          {capitalizeFirstWord(item)}
                        </option>
                      ))}
                    </Select>
                  </WithLabelAndTooltip>
                </VStack>
              </AsideSection>
              {!isTextureType && (
                <AsideSection
                  id="textureMapSettings"
                  title="Texture Map Settings"
                  topRightComponent={
                    <ButtonSectionTopIcon onClick={resetTextureMapSettings}>
                      <Icon id="Ui/Refresh" h="14px" />
                    </ButtonSectionTopIcon>
                  }
                >
                  <VStack align="stretch" spacing={2}>
                    <Text color="textSecondary" size="body.xs">
                      Adjust parameters to generate PBR texture maps (height,
                      normal, smoothness, metalness, edge, AO).
                    </Text>
                    <SettingSlider
                      settingKey="raised"
                      value={notDebouncedSettings.raised}
                      setValue={handleSliderChange("raised")}
                    />
                    <SettingSlider
                      settingKey="shiny"
                      value={notDebouncedSettings.shiny}
                      setValue={handleSliderChange("shiny")}
                    />
                    <SettingSlider
                      settingKey="polished"
                      value={notDebouncedSettings.polished}
                      setValue={handleSliderChange("polished")}
                    />
                    <SettingSlider
                      settingKey="angular"
                      value={notDebouncedSettings.angular}
                      setValue={handleSliderChange("angular")}
                    />
                    <ControlSwitch
                      title={"Invert"}
                      isChecked={notDebouncedSettings.invert}
                      setIsChecked={(value) => {
                        setNotDebouncedSettings((prevSettings) => ({
                          ...prevSettings,
                          invert: value,
                        }));
                      }}
                    />
                    <ButtonWithCuIndicator
                      cuCost={cuCostData?.creativeUnitsCost}
                      isCuLoading={isCuLoading}
                      onClick={handleSave}
                      isLoading={isSavingTexture || !!jobToFetch}
                      size="sm"
                      flexShrink={0}
                    >
                      Generate Maps
                    </ButtonWithCuIndicator>
                  </VStack>
                </AsideSection>
              )}
            </AsideProvider>
          </VStack>

          <Box
            ref={containerRef}
            pos="relative"
            overflow="hidden"
            w="full"
            h="full"
            cursor={"move"}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <Canvas camera={{ position: [0, 0, 1_000], far: 10_000 }}>
              <Suspense fallback={<Loader />}>
                {!isTextureType && (
                  <TextureViewer
                    textureUrl={asset.url}
                    autoRotate={!isHovering && autoRotate}
                    settings={settings}
                  />
                )}
                {isTextureType && textureAssetData?.asset && (
                  <TextureViewer
                    textureUrl={textureAssetData.asset.url}
                    autoRotate={!isHovering && autoRotate}
                    settings={{
                      ...settings,
                      ..._.omitBy(
                        {
                          raised: asset.metadata.raised,
                          shiny: asset.metadata.shiny,
                          polished: asset.metadata.polished,
                          angular: asset.metadata.angular,
                        },
                        _.isUndefined
                      ),
                    }}
                  />
                )}
                {isTextureType && !textureAssetData?.asset && <Loader />}
              </Suspense>
            </Canvas>
            {isAssetUpscaledTexture(asset) && beforeAsset && (
              <AssetZoomBottomButtons
                compare={{ isComparing, onToggleComparing }}
              />
            )}
          </Box>
        </HStack>
      </Box>
    </Box>
  );
}

type TextureViewerProps = {
  textureUrl: string;
  autoRotate?: boolean;
  settings: TextureSettings;
};

const TextureViewer = memo(function TextureViewer({
  textureUrl,
  autoRotate,
  settings,
}: TextureViewerProps) {
  const { gl: renderer } = useThree();
  const orbitRef = useRef<OrbitControlsImpl>(null);

  const simulateWheel = useCallback((deltaY: number) => {
    const evt = document.createEvent("MouseEvents");
    evt.initEvent("wheel", true, true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    evt.deltaY = deltaY;
    const els = document.getElementsByTagName("canvas");
    if (els.length > 0) {
      els[0].dispatchEvent(evt);
    }
  }, []);

  useHotkeys(
    appShortcuts.assetPage.shortcuts.resetZoom.shortcut,
    () => orbitRef.current?.reset(),
    []
  );
  useHotkeys(
    appShortcuts.assetPage.shortcuts.zoomInTextureViewer.shortcut,
    () => simulateWheel(-100),
    []
  );
  useHotkeys(
    appShortcuts.assetPage.shortcuts.zoomOutTextureViewer.shortcut,
    () => simulateWheel(100),
    []
  );

  const texture = useLoader(THREE.TextureLoader, textureUrl);
  texture.encoding = THREE.sRGBEncoding;

  const parameters = useMemo(
    () =>
      getParameters({
        raised: settings.raised,
        shiny: settings.shiny,
        polished: settings.polished,
        angular: settings.angular,
        invert: settings.invert,
      }),
    [
      settings.angular,
      settings.invert,
      settings.polished,
      settings.raised,
      settings.shiny,
    ]
  );

  const {
    heightMapTarget,
    normalMapTarget,
    metallicMapTarget,
    smoothnessMapTarget,
    aoMapTarget,
  } = useMemo(() => {
    if (!texture || !renderer) {
      return {
        heightMapTarget: undefined,
        normalMapTarget: undefined,
        metallicMapTarget: undefined,
        smoothnessMapTarget: undefined,
        aoMapTarget: undefined,
      };
    }

    const heightMapTarget = computeHeight({
      texture,
      renderer,
      ...parameters.height,
    });

    const normalMapTarget = computeNormal({
      heightMap: heightMapTarget.texture,
      renderer,
      ...parameters.normal,
    });

    const metallicMapTarget = computeMetallic({
      texture,
      renderer,
      ...parameters.metallic,
    });

    const smoothnessMapTarget = computeSmoothness({
      texture,
      metallicMap: metallicMapTarget.texture,
      renderer,
      ...parameters.smoothness,
    });

    const aoMapTarget = computeAO({
      renderer,
      normalMap: normalMapTarget.texture,
      heightMap: heightMapTarget.texture,
      ...parameters.ao,
    });

    let repeatX = settings.repeat;
    let repeatY = settings.repeat;
    let positionX = 0;
    let positionY = 0;

    if (settings.geometry === "sphere" || settings.geometry === "cylinder") {
      repeatX = settings.repeat * 4;
      repeatY = settings.repeat * 2;
      positionY = 0.5;
      positionX = 0.5;
    }

    texture.repeat.set(repeatX, repeatY);
    heightMapTarget.texture.repeat.set(repeatX, repeatY);
    normalMapTarget.texture.repeat.set(repeatX, repeatY);
    metallicMapTarget.texture.repeat.set(repeatX, repeatY);
    smoothnessMapTarget.texture.repeat.set(repeatX, repeatY);
    aoMapTarget.texture.repeat.set(repeatX, repeatY);

    texture.offset.set(positionX, positionY);
    heightMapTarget.texture.offset.set(positionX, positionY);
    normalMapTarget.texture.offset.set(positionX, positionY);
    metallicMapTarget.texture.offset.set(positionX, positionY);
    smoothnessMapTarget.texture.offset.set(positionX, positionY);
    aoMapTarget.texture.offset.set(positionX, positionY);

    renderer.setRenderTarget(null);

    return {
      heightMapTarget,
      normalMapTarget,
      metallicMapTarget,
      smoothnessMapTarget,
      aoMapTarget,
    };
  }, [
    texture,
    renderer,
    parameters.height,
    parameters.normal,
    parameters.metallic,
    parameters.smoothness,
    parameters.ao,
    settings.repeat,
    settings.geometry,
  ]);

  const material = useMemo(() => {
    if (
      !texture ||
      !heightMapTarget ||
      !normalMapTarget ||
      !smoothnessMapTarget ||
      !metallicMapTarget ||
      !aoMapTarget
    )
      return undefined;

    return new THREE.MeshStandardMaterial({
      map: texture,
      displacementMap: heightMapTarget.texture,
      displacementScale: settings.displacementScale,
      normalMap: normalMapTarget.texture,
      normalScale: new THREE.Vector2(0.1, -0.1),
      roughness: settings.roughness,
      roughnessMap: (-smoothnessMapTarget + 1) as unknown as THREE.Texture,
      metalnessMap: metallicMapTarget.texture,
      metalness: settings.metalness,
      aoMap: aoMapTarget.texture,
      aoMapIntensity: settings.aoMapIntensity,
      envMapIntensity: settings.envMapIntensity,
    });
  }, [
    texture,
    heightMapTarget,
    normalMapTarget,
    smoothnessMapTarget,
    metallicMapTarget,
    aoMapTarget,
    settings.displacementScale,
    settings.roughness,
    settings.metalness,
    settings.aoMapIntensity,
    settings.envMapIntensity,
  ]);

  const geometry = useMemo(() => {
    if (settings.geometry === "sphere") {
      return new THREE.SphereGeometry(400, 1_000, 1_000);
    } else if (settings.geometry === "plane") {
      return new THREE.PlaneGeometry(750, 750, 1_000, 1_000);
    } else if (settings.geometry === "cylinder") {
      return new THREE.CylinderGeometry(300, 300, 700, 1_000, 1_000);
    }
    return roundEdgedBox(700, 700, 700, 120, 100, 100, 100, 200);
  }, [settings.geometry]);

  const previousMaterial = usePrevious(material);
  const previousGeometry = usePrevious(geometry);

  if (!material || !geometry) return <Loader />;

  renderer.renderLists.dispose();
  previousMaterial?.dispose();
  previousGeometry?.dispose();

  return (
    <>
      <Environment
        preset={(settings.envMap ?? "forest") as EnvironmentProps["preset"]}
        background={settings.withBackground}
      />
      <ambientLight intensity={2} color={0xff_ff_ff} />
      <mesh geometry={geometry} material={material} />
      <OrbitControls
        ref={orbitRef}
        autoRotate={autoRotate}
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 2}
        minDistance={500}
        maxDistance={1_000}
      />
    </>
  );
});

export function Loader() {
  return (
    <Html center>
      <Spinner w="25px" h="25px" />
    </Html>
  );
}

function SettingSlider({
  settingKey,
  value,
  setValue,
}: {
  settingKey: "raised" | "shiny" | "polished" | "angular";
  value: number;
  setValue: (value: number) => void;
}) {
  const texts = {
    raised: ["Mud", "Rock"],
    shiny: ["Rock", "Lead ball"],
    polished: ["Rock", "Mirror"],
    angular: ["Soft", "Hard"],
  };

  return (
    <SliderWithInputNumber
      title={capitalizeFirstWord(settingKey)}
      value={value}
      setValue={(newValue) => {
        if (newValue !== undefined) {
          setValue(newValue);
        }
      }}
      min={0}
      max={1}
      step={0.01}
      tooltip={
        <SettingTooltip
          title={`How ${settingKey} is the surface?`}
          value={value}
          leftText={texts[settingKey][0]}
          rightText={texts[settingKey][1]}
          imageKey={settingKey}
        />
      }
      tooltipProps={{
        offset: [100, 10],
      }}
    />
  );
}

function SettingTooltip({
  title,
  value,
  leftText,
  rightText,
  imageKey,
}: {
  title: string;
  value: number;
  leftText: string;
  rightText: string;
  imageKey: string;
}) {
  return (
    <VStack align="stretch" w="300px" spacing={1}>
      <Text textColor="textPrimary" size="body.md">
        {title}
      </Text>
      <HStack spacing={4}>
        <VStack spacing={0}>
          <Text
            h="20px"
            textColor="textSecondary"
            whiteSpace="nowrap"
            size="body.md"
          >
            {leftText}
          </Text>
          <Image
            h="25px"
            mb="5px"
            alt=""
            src={`/ui/texture/${imageKey}/0.png`}
          />
        </VStack>
        <VStack align="strech" w="100%" spacing={0}>
          <Center minH="20px">
            <Slider
              hideValue
              value={value}
              onChange={() => {}}
              mx={0}
              min={0}
              max={1}
              step={0.01}
            />
          </Center>
          <HStack justify="center">
            <Image h="30px" alt="" src={`/ui/texture/${imageKey}/1.png`} />
          </HStack>
        </VStack>
        <VStack spacing={0}>
          <Text
            h="20px"
            textColor="textSecondary"
            whiteSpace="nowrap"
            size="body.md"
          >
            {rightText}
          </Text>
          <Image
            h="25px"
            mb="5px"
            alt=""
            src={`/ui/texture/${imageKey}/2.png`}
          />
        </VStack>
      </HStack>
    </VStack>
  );
}
