import { useCallback, useEffect, useState } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { useLazyGetTeamsSubscriptionsByTeamIdQuery } from "infra/api/generated/api";

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

export default function ModalFailedPayment() {
  const [getSubscriptionTrigger] = useLazyGetTeamsSubscriptionsByTeamIdQuery();
  const { selectedTeam, selectedTeamDetails } = useTeamContext();
  const [customerPortalUrl, setCustomerPortalUrl] = useState<
    string | undefined
  >();
  const [isHidden, setIsHidden] = useState(false);

  const isOpen = selectedTeamDetails?.paymentStatus === "failed" && !isHidden;
  const isAdmin = selectedTeam.userRole === "admin";

  const handleClose = useCallback(() => {
    setIsHidden(true);
  }, [setIsHidden]);

  // Reset hidden state when payment status changes
  useEffect(() => {
    if (selectedTeamDetails?.paymentStatus === "failed") {
      setIsHidden(false);
    }
  }, [selectedTeamDetails?.paymentStatus]);

  // ----------------------------------

  useEffect(() => {
    if (!(isOpen && isAdmin)) return;
    const timeout = setTimeout(async () => {
      const { data: subscription } = await getSubscriptionTrigger({
        teamId: selectedTeam.id,
        paymentProvider: "stripe",
      });
      setCustomerPortalUrl(subscription?.customerPortalUrl);
    }, 1);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [
    getSubscriptionTrigger,
    setCustomerPortalUrl,
    selectedTeam.id,
    isAdmin,
    isOpen,
  ]);
  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" variant="modern">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Icon
              id="Ui/Warning"
              h="52px"
              color="danger.500"
              filter="drop-shadow(0px 0px 25px var(--chakra-colors-danger-500))"
            />
            <VStack spacing={2}>
              <Text color="textPrimary" size="title.lg">
                Last Payment Failed
              </Text>
              <Text color="textSecondary" size="body.lg">
                {isAdmin
                  ? "Please update the organization payment method as the latest payment attempt was unsuccessful. This will ensure uninterrupted access to Scenario."
                  : "The most recent payment was unsuccessful. To ensure uninterrupted access to Scenario, please ask an admin for assistance."}
              </Text>
            </VStack>
          </VStack>
        </ModalBody>
        {isAdmin && (
          <ModalFooter mt={6}>
            <Box>
              <Button
                variant="primary"
                colorScheme="danger"
                size="lg"
                externalLink={customerPortalUrl}
                isLoading={!customerPortalUrl}
              >
                Update Payment Method
              </Button>
            </Box>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
