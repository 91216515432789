import { useMemo } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import MenuListSingleChoice, {
  MenuListSingleChoiceProps,
} from "domains/ui/components/Menu/MenuListSingleChoice";
import { useUserContext } from "domains/user/contexts/UserProvider";

export type FilterAuthor = "me" | "all" | string;

export const FILTER_AUTHOR_OPTIONS: MenuListSingleChoiceProps<FilterAuthor>["options"] =
  [
    { value: "me" as const, label: "Me" },
    { value: "all" as const, label: "All" },
  ];

type FilterAuthorOption = (typeof FILTER_AUTHOR_OPTIONS)[number];

export interface HeaderFilterAuthorProps {
  value: NonNullable<FilterAuthorOption["value"]>;
  isLoading?: boolean;
  onChange: (value: NonNullable<FilterAuthorOption["value"]>) => void;
  variant?: "secondary" | "primary";
}

export default function HeaderFilterAuthor({
  value,
  isLoading,
  onChange,
  variant = "primary",
}: HeaderFilterAuthorProps) {
  const { user } = useUserContext();
  const { selectedTeamDetails, isLoadingTeamDetails } = useTeamContext();

  const teamMembers = useMemo(
    () =>
      (selectedTeamDetails?.users ?? []).filter(
        (option) => option.id !== user?.id
      ),
    [selectedTeamDetails?.users, user?.id]
  );

  const options = useMemo<
    MenuListSingleChoiceProps<FilterAuthor>["options"]
  >(() => {
    return [
      ...FILTER_AUTHOR_OPTIONS,
      ...(teamMembers.length ? [{ isDivider: true as const }] : []),
      ...teamMembers.map((user) => ({
        label: user.email,
        value: user.id,
      })),
    ];
  }, [teamMembers]);

  return (
    <MenuListSingleChoice
      isPrimary={variant === "primary"}
      variant={variant === "secondary" ? "secondaryAlt" : undefined}
      cannotBeUndefined
      placeholder="Author"
      options={options}
      setSelectedValue={onChange}
      selectedValue={value}
      topText="Author"
      isButtonLoading={isLoading || isLoadingTeamDetails}
    />
  );
}
