import { MouseEventHandler, useCallback, useMemo, useRef } from "react";
import React from "react";
import FmCard, {
  FmCardProps,
} from "domains/file-manager/components/FileManagerV2/FmCard";

import { ImageProps } from "@chakra-ui/react";

interface CardContainerProps extends Omit<FmCardProps<"image">, "type"> {
  variant: "image" | "skybox" | "texture";
}

export default React.memo(function CardContainer({
  isBlurred,
  variant,
  children,
  imgProps,
  ...props
}: CardContainerProps) {
  const textureAnimationRef = useRef<
    { entryOffset: number; value: number } | undefined
  >();

  // ----------------------------------

  const handleSkyboxMouseMove: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const yDiff = Math.round(
        (e.nativeEvent.offsetY / e.currentTarget.offsetHeight) * 33.333
      );
      const xDiff = Math.round(
        (e.nativeEvent.offsetX / e.currentTarget.offsetWidth) * 33.333
      );
      e.currentTarget.style.transform = `scale(1.5) translate(-${xDiff}%, -${yDiff}%)`;
    },
    []
  );

  const handleSkyboxMouseLeave: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.currentTarget.style.transform = `unset`;
    },
    []
  );

  const handleTextureMouseEnter: MouseEventHandler<HTMLDivElement> =
    useCallback((e) => {
      textureAnimationRef.current = {
        entryOffset: e.clientX,
        value: 50,
      };
    }, []);

  const handleTextureMouseMove: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (textureAnimationRef.current === undefined) return;
      const steps = [0, 16.666_7, 33.333_3, 50, 66.666_7, 83.333_3, 100];
      const offset = e.clientX - textureAnimationRef.current.entryOffset;
      const partWidth = e.currentTarget.clientWidth / 7;

      if (Math.abs(offset) >= partWidth) {
        const nextIndex =
          steps.indexOf(textureAnimationRef.current.value) +
          (offset > 0 ? -1 : 1);
        const nextStep = steps[(nextIndex + steps.length) % steps.length];
        e.currentTarget.style.objectPosition = `${nextStep}% 0`;
        textureAnimationRef.current.entryOffset = e.clientX;
        textureAnimationRef.current.value = nextStep;
      }
    },
    []
  );

  const handleTextureMouseLeave: MouseEventHandler<HTMLDivElement> =
    useCallback((e) => {
      textureAnimationRef.current = undefined;
      e.currentTarget.style.objectPosition = "50% 0";
    }, []);

  // ----------------------------------

  const onImgMouseEnter =
    (variant === "skybox" && handleSkyboxMouseMove) ||
    (variant === "texture" && handleTextureMouseEnter) ||
    undefined;

  const onImgMouseMove =
    (variant === "skybox" && handleSkyboxMouseMove) ||
    (variant === "texture" && handleTextureMouseMove) ||
    undefined;

  const onImgMouseLeave =
    (variant === "skybox" && handleSkyboxMouseLeave) ||
    (variant === "texture" && handleTextureMouseLeave) ||
    undefined;

  const newImgProps = useMemo(
    () =>
      ({
        ...imgProps,
        position: "absolute",
        top: 0,
        objectFit: "cover",
        transformOrigin: "top left",
        transition: "transform .1s",
        onMouseEnter: onImgMouseEnter,
        onMouseMove: onImgMouseMove,
        onMouseLeave: onImgMouseLeave,
      } satisfies ImageProps),
    [imgProps, onImgMouseEnter, onImgMouseLeave, onImgMouseMove]
  );

  return (
    <FmCard
      type="image"
      isBlurred={isBlurred}
      {...props}
      imgProps={newImgProps}
    >
      {children}
    </FmCard>
  );
});
