import React, { useCallback, useMemo, useState } from "react";
import Icon from "domains/ui/components/Icon";
import MenuButtonMultipleChoice from "domains/ui/components/Menu/MenuButtonMultipleChoice";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import ScenarioInput from "domains/ui/components/ScenarioInput";

import {
  Box,
  Flex,
  Menu,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

interface CategoryOption {
  category: string;
  items: string[];
}

interface MenuListCategoryMultipleChoiceProps {
  options: CategoryOption[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  selectedCategories: string[];
  setSelectedCategories: (categories: string[]) => void;
  renderLabel: (value: string) => string;
  menuButtonText: string;
  hasValueEnabled?: boolean;
  closeOnSelect?: boolean;
}

const MenuListCategoryMultipleChoice: React.FC<
  MenuListCategoryMultipleChoiceProps
> = ({
  options,
  selectedValues,
  setSelectedValues,
  selectedCategories,
  setSelectedCategories,
  renderLabel,
  menuButtonText,
  hasValueEnabled = true,
  closeOnSelect = false,
}) => {
  const [search, setSearch] = useState("");

  const handleItemClick = useCallback(
    (item: string) => {
      const isSelected = selectedValues.includes(item);
      setSelectedValues(
        isSelected
          ? selectedValues.filter((x) => x !== item)
          : [...selectedValues, item]
      );
    },
    [selectedValues, setSelectedValues]
  );

  const handleCategoryClick = useCallback(
    (category: string) => {
      const isSelected = selectedCategories.includes(category);
      setSelectedCategories(
        isSelected
          ? selectedCategories.filter((c) => c !== category)
          : [...selectedCategories, category]
      );
    },
    [selectedCategories, setSelectedCategories]
  );

  const normalizedSearch = useMemo(
    () => search.trim().toLowerCase().replace("-", " "),
    [search]
  );

  return (
    <Menu autoSelect={false} closeOnSelect={closeOnSelect}>
      <MenuButtonMultipleChoice
        text={menuButtonText}
        hasValue={
          (selectedValues.length > 0 || selectedCategories.length > 0) &&
          hasValueEnabled
        }
        onRemove={() => {
          setSelectedValues([]);
          setSelectedCategories([]);
        }}
      />
      <Portal>
        <MenuList zIndex="tooltip" data-outside-click-excluded={true}>
          {options.map(({ category, items }) => (
            <Box key={category}>
              <Popover
                closeOnBlur={false}
                isOpen={items.length === 0 ? false : undefined}
                placement="right"
                trigger="hover"
              >
                <PopoverTrigger>
                  <Box>
                    <MenuItem
                      onClick={() => handleCategoryClick(category)}
                      rightIcon={
                        items.length === 0 ? undefined : (
                          <Icon id="Ui/ChevronRight" color={"textTertiary"} />
                        )
                      }
                      text={
                        <Flex gap={1}>
                          <Box>
                            {renderLabel ? renderLabel(category) : category}
                          </Box>
                          {selectedCategories.includes(category) ? (
                            <Icon
                              color="primary.500"
                              id="Ui/Check"
                              alignSelf="center"
                            />
                          ) : null}
                        </Flex>
                      }
                    />
                  </Box>
                </PopoverTrigger>
                <PopoverContent
                  overflowX="hidden"
                  overflowY="auto"
                  w="auto"
                  h="200px"
                  p={2}
                  bg="background.500"
                  borderRadius="xl"
                >
                  <Flex>
                    <ScenarioInput
                      value={search}
                      setValue={setSearch}
                      placeholder="Search"
                      leftComponent={
                        <Icon
                          mb={0.5}
                          id={"Ui/Search"}
                          color={"textTertiary"}
                        />
                      }
                      containerProps={{ mb: 1.5 }}
                    />
                  </Flex>
                  {items.map((item) => {
                    const isHidden = !renderLabel(item)
                      .toLowerCase()
                      .includes(normalizedSearch);
                    return (
                      <MenuItem
                        key={item}
                        check="checkbox"
                        text={renderLabel ? renderLabel(item) : item}
                        onClick={() => handleItemClick(item)}
                        isSelected={selectedValues.includes(item)}
                        isHidden={isHidden}
                      />
                    );
                  })}
                </PopoverContent>
              </Popover>
            </Box>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default MenuListCategoryMultipleChoice;
