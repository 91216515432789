import HeaderFilterAuthor, {
  HeaderFilterAuthorProps,
} from "domains/file-manager/components/FileManagerImage/HeaderFilterAuthor";
import HeaderFilterDate, {
  HeaderFilterDateProps,
} from "domains/file-manager/components/FileManagerImage/HeaderFilterDate";
import {
  FilterAssetCollectionOption,
  FilterAssetCollectionValue,
  FilterAssetType,
  FilterAssetTypeKey,
} from "domains/file-manager/constants/AssetFilter";
import { GridSortValue } from "domains/file-manager/constants/GridSort";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { TOP_BAR_HEIGHT } from "domains/navigation/constants/TopBar";

import { Flex, FlexProps, HStack, Text } from "@chakra-ui/react";

import FilterAssetCollectionDropdown from "./FilterAssetCollectionDropdown";
import FilterAssetTypeDropdown from "./FilterAssetTypeDropdown";
import GridColumnNumberInput from "./GridColumnNumberInput";
import GridSortDropdown from "./GridSortDropdown";
import GridViewDropdown from "./GridViewDropdown";

export interface FileManagerTopBarFilterAsset {
  filterAssetTypes?: FilterAssetTypeKey[];
  filterAssetTypeOptions?: {
    [key in FilterAssetTypeKey]: FilterAssetType;
  };
  onFilterAssetTypeChange?: (value: FilterAssetTypeKey[] | undefined) => void;
}

export interface FileManagerTopBarProps extends FileManagerTopBarFilterAsset {
  styleProps?: FlexProps;

  numberOfColumns: number;
  onNumberOfColumnsChange: (numberOfColumns: number) => void;
  canChangeNumberOfColumns?: boolean;

  gridView: Exclude<GridViewKey, "jobs">;
  onGridViewChange: (gridView: Exclude<GridViewKey, "jobs">) => void;
  canChangeView?: boolean;

  sort?: GridSortValue;
  onSortChange?: (value: GridSortValue) => void;
  sortOptions?: GridSortValue[];

  filterAssetCollection?: FilterAssetCollectionValue;
  onFilterAssetCollectionChange?: (
    value: FilterAssetCollectionValue | undefined
  ) => void;
  filterAssetCollectionOptions?: FilterAssetCollectionOption[];
  isFilterAssetCollectionLoading?: boolean;

  headerFilterDateProps?: HeaderFilterDateProps;
  headerFilterAuthorProps?: HeaderFilterAuthorProps;

  title?: string;

  leftContent?: React.ReactNode;
}

function FileManagerTopBar({
  styleProps,

  numberOfColumns,
  onNumberOfColumnsChange,
  canChangeNumberOfColumns,

  gridView,
  onGridViewChange,
  canChangeView,

  sort,
  onSortChange,
  sortOptions,

  filterAssetTypes,
  filterAssetTypeOptions,
  onFilterAssetTypeChange,

  filterAssetCollection,
  onFilterAssetCollectionChange,
  filterAssetCollectionOptions,
  isFilterAssetCollectionLoading,

  headerFilterDateProps,
  headerFilterAuthorProps,

  title,

  leftContent,
}: FileManagerTopBarProps) {
  return (
    <Flex
      pos="sticky"
      zIndex="sticky"
      top={`${TOP_BAR_HEIGHT}px`}
      align="center"
      justify="space-between"
      flexGrow={1}
      bg="background.500"
      {...styleProps}
    >
      <Flex>
        {title ? <Text size="body.bold.lg">{title}</Text> : leftContent}
      </Flex>

      <HStack flex={1} spacing={2}>
        {filterAssetTypeOptions && onFilterAssetTypeChange && (
          <FilterAssetTypeDropdown
            values={filterAssetTypes ?? []}
            options={filterAssetTypeOptions}
            onChange={onFilterAssetTypeChange}
          />
        )}

        {!!headerFilterAuthorProps && (
          <HeaderFilterAuthor {...headerFilterAuthorProps} />
        )}

        {!!headerFilterDateProps && (
          <HeaderFilterDate {...headerFilterDateProps} />
        )}

        {filterAssetCollectionOptions && onFilterAssetCollectionChange && (
          <FilterAssetCollectionDropdown
            value={filterAssetCollection}
            options={filterAssetCollectionOptions}
            isLoading={isFilterAssetCollectionLoading}
            onChange={onFilterAssetCollectionChange}
          />
        )}
      </HStack>

      <HStack my={2} spacing={1}>
        {canChangeNumberOfColumns && (
          <GridColumnNumberInput
            value={numberOfColumns}
            onChange={onNumberOfColumnsChange}
          />
        )}
        {sortOptions && sortOptions.length > 0 && onSortChange && (
          <GridSortDropdown
            value={sort ?? sortOptions[0]}
            options={sortOptions}
            onChange={onSortChange}
          />
        )}
        {canChangeView && (
          <GridViewDropdown value={gridView} onChange={onGridViewChange} />
        )}
      </HStack>
    </Flex>
  );
}

export default FileManagerTopBar;
