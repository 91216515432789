import { useCallback } from "react";
import useAssetRemoveBackground from "domains/assets/hooks/useAssetRemoveBackground";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useHandleApiError } from "infra/api/error";
import {
  GetModelsByModelIdApiResponse,
  useDeleteModelsTrainingImagesByModelIdAndTrainingImageIdMutation,
  usePostModelsTrainingImagesByModelIdMutation,
  usePutAssetByAssetIdMutation,
} from "infra/api/generated/api";

export default function useRemoveBackgroundFromTrainingImage() {
  const { selectedTeam } = useTeamContext();
  const handleApiError = useHandleApiError();
  const { handleRemoveBackgroundAsset } = useAssetRemoveBackground();
  const [addTrainingImageTrigger] =
    usePostModelsTrainingImagesByModelIdMutation();
  const [deleteTrainingImageTrigger] =
    useDeleteModelsTrainingImagesByModelIdAndTrainingImageIdMutation();
  const [updateAssetTrigger] = usePutAssetByAssetIdMutation();

  return useCallback(
    async ({
      trainingImage,
      model,
      withToast = true,
      isBulk = false,
    }: {
      trainingImage: NonNullable<
        GetModelsByModelIdApiResponse["model"]["trainingImages"]
      >[number];
      model: GetModelsByModelIdApiResponse["model"];
      withToast?: boolean;
      isBulk?: boolean;
    }) => {
      const newAsset = await handleRemoveBackgroundAsset({
        assetId: trainingImage.id,
        trackingExtraParams: {
          origin: "create model",
        },
        withToast,
        isBulk,
      });
      if (!newAsset) {
        throw new Error("FAILED TO REMOVE BACKGROUND");
      }

      try {
        if (trainingImage.description !== trainingImage.automaticCaptioning) {
          await updateAssetTrigger({
            teamId: selectedTeam.id,
            assetId: newAsset.id,
            body: {
              description: trainingImage.description,
            },
          }).unwrap();
        }

        await deleteTrainingImageTrigger({
          teamId: selectedTeam.id,
          modelId: model.id,
          trainingImageId: trainingImage.id,
        }).unwrap();

        await addTrainingImageTrigger({
          teamId: selectedTeam.id,
          modelId: model.id,
          body: {
            assetId: newAsset.id,
          },
        }).unwrap();
      } catch (error) {
        handleApiError(error, "Error removing the background");
        throw error;
      }
    },
    [
      handleRemoveBackgroundAsset,
      deleteTrainingImageTrigger,
      selectedTeam.id,
      addTrainingImageTrigger,
      updateAssetTrigger,
      handleApiError,
    ]
  );
}
