import { useDebounce } from "domains/commons/hooks/useDebounce";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { PostReframeInferencesApiArg } from "infra/api/generated/api";
import { useGetReframeCostQuery } from "infra/store/apiSlice";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/query";

export function useAssetReframeCu(body?: PostReframeInferencesApiArg["body"]): {
  isCuLoading: boolean;
  cuCost?: number;
} {
  const { selectedTeam } = useTeamContext();

  const debouncedBody = useDebounce(body, 500);

  const { data: cuCostData, isFetching: isCuLoading } = useGetReframeCostQuery(
    debouncedBody
      ? {
          teamId: selectedTeam.id,
          body: debouncedBody,
        }
      : skipToken
  );

  return {
    cuCost: cuCostData?.creativeUnitsCost,
    isCuLoading: isCuLoading || !_.isEqual(body, debouncedBody),
  };
}
