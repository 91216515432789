import { useMemo } from "react";
import { LinkProps } from "next/link";
import { useRouter } from "next/router";
import {
  isAssetInferenceTexture,
  isAssetUpscaledTexture,
} from "domains/assets/utils/isType";
import {
  getIsModelFluxDev,
  getIsModelFluxPro,
  getIsModelFluxPro1_1,
  getIsModelFluxPro1_1Ultra,
} from "domains/models/utils";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
} from "infra/api/generated/api";

export function useAssetReloadParametersLink(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined,
  { modelId, inferenceId }: { modelId?: string; inferenceId?: string } = {}
): LinkProps["href"] | undefined {
  const isTextureInference = isAssetInferenceTexture(asset);
  const isTextureUpscale = isAssetUpscaledTexture(asset);
  const isInference =
    (asset?.metadata.type ?? "").includes("inference") && !isTextureInference;
  const isUpscale = asset?.metadata.type === "upscale";
  const isSkyboxBase = asset?.metadata.type === "skybox-base-360";
  const isSkyboxUpscale = asset?.metadata.type === "upscale-skybox";
  const isRestyle = asset?.metadata.type === "restyle";
  const isReframe = asset?.metadata.type === "reframe";

  const linkAssetId = asset?.id;
  const linkModelId = modelId ?? asset?.metadata.modelId;
  const linkInferenceId = inferenceId ?? asset?.metadata.inferenceId;

  return useMemo(() => {
    if (isSkyboxBase && linkAssetId) {
      return {
        pathname: "/skyboxes/new",
        query: {
          importAssetId: linkAssetId,
        },
      };
    }

    if (isSkyboxUpscale && linkAssetId) {
      return {
        pathname: "/skyboxes/enhance",
        query: {
          restartEnhance: linkAssetId,
        },
      };
    }

    if (isInference && linkModelId && linkInferenceId) {
      return {
        pathname: "/images/new",
        query: {
          modelId: linkModelId,
          importAssetId: `${linkModelId}|${linkInferenceId}`,
        },
      };
    }

    if (isUpscale && linkAssetId) {
      return {
        pathname: "/enhance",
        query: {
          restartEnhance: linkAssetId,
        },
      };
    }

    if (isTextureInference && linkModelId && linkInferenceId) {
      return {
        pathname: "/textures/new",
        query: {
          modelId: linkModelId,
          importAssetId: `${linkModelId}|${linkInferenceId}`,
        },
      };
    }

    if (isTextureUpscale && linkAssetId) {
      return {
        pathname: "/textures/enhance",
        query: {
          restartEnhance: linkAssetId,
        },
      };
    }

    if (isRestyle && linkAssetId) {
      return {
        pathname: "/restyle",
        query: {
          restartRestyle: linkAssetId,
        },
      };
    }

    if (isReframe && linkAssetId) {
      return {
        pathname: "/expand",
        query: {
          restartExpand: linkAssetId,
        },
      };
    }

    return undefined;
  }, [
    isInference,
    isReframe,
    isRestyle,
    isSkyboxBase,
    isSkyboxUpscale,
    isTextureInference,
    isTextureUpscale,
    isUpscale,
    linkAssetId,
    linkInferenceId,
    linkModelId,
  ]);
}

// ------------------------------------

export function useAssetReloadPromptLink(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined
): LinkProps["href"] | undefined {
  const hasPrompt = !!asset?.metadata?.prompt;
  const isTextureInference = isAssetInferenceTexture(asset);
  const isTextureUpscale = isAssetUpscaledTexture(asset);
  const isInference =
    (asset?.metadata.type ?? "").includes("inference") && !isTextureInference;
  const isUpscale = asset?.metadata.type === "upscale";
  const isSkybox = asset?.metadata.type === "skybox-base-360";
  const isRestyle = asset?.metadata.type === "restyle";
  const isReframe = asset?.metadata.type === "reframe";

  const linkAssetId = asset?.id;

  return useMemo(() => {
    if (!hasPrompt) {
      return undefined;
    }

    if (isSkybox) {
      return {
        pathname: "/skyboxes/new",
        query: {
          importPromptFromAssetId: linkAssetId,
        },
      };
    }

    if (isInference) {
      return {
        pathname: "/images/new",
        query: {
          importPromptFromAssetId: linkAssetId,
        },
      };
    }

    if (isUpscale) {
      return {
        pathname: "/enhance",
        query: {
          importPromptFromAssetId: linkAssetId,
        },
      };
    }

    if (isTextureInference) {
      return {
        pathname: "/textures/new",
        query: {
          importPromptFromAssetId: linkAssetId,
        },
      };
    }

    if (isTextureUpscale) {
      return {
        pathname: "/textures/enhance",
        query: {
          importPromptFromAssetId: linkAssetId,
        },
      };
    }

    if (isRestyle) {
      return {
        pathname: "/restyle",
        query: {
          importPromptFromAssetId: linkAssetId,
        },
      };
    }

    if (isReframe) {
      return {
        pathname: "/expand",
        query: {
          importPromptFromAssetId: linkAssetId,
        },
      };
    }

    return undefined;
  }, [
    hasPrompt,
    isInference,
    isReframe,
    isRestyle,
    isSkybox,
    isTextureInference,
    isTextureUpscale,
    isUpscale,
    linkAssetId,
  ]);
}

// ------------------------------------

export function useAssetReloadModelsLink(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined,
  { modelId }: { modelId?: string } = {}
): LinkProps["href"] | undefined {
  const isTextureInference = isAssetInferenceTexture(asset);
  const isInference =
    (asset?.metadata.type ?? "").includes("inference") && !isTextureInference;

  const linkModelId = modelId ?? asset?.metadata.modelId;

  return useMemo(() => {
    if (isInference) {
      return {
        pathname: "/images/new",
        query: {
          modelId: linkModelId,
        },
      };
    }

    if (isTextureInference) {
      return {
        pathname: "/textures/new",
        query: {
          modelId: linkModelId,
        },
      };
    }

    return undefined;
  }, [isInference, isTextureInference, linkModelId]);
}

// ------------------------------------

export function useAssetRefineLink(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined,
  { modelId }: { modelId?: string } = {}
): LinkProps["href"] | undefined {
  const isSkyboxBase = asset?.metadata.type === "skybox-base-360";
  const isSkyboxUpscale = asset?.metadata.type === "upscale-skybox";
  const isDisabled =
    !asset ||
    isSkyboxBase ||
    !(asset.editCapabilities ?? []).includes("REFINE");
  const isTexture =
    isAssetInferenceTexture(asset) || isAssetUpscaledTexture(asset);

  const linkModelId = modelId ?? asset?.metadata.modelId;

  return useMemo(() => {
    if (isDisabled) {
      return undefined;
    }

    if (isSkyboxUpscale) {
      return {
        pathname: "/skyboxes/enhance",
        query: {
          importAssetId: asset?.id,
        },
      };
    } else if (isTexture) {
      return {
        pathname: "/textures/new",
        query: {
          importAssetId: asset?.id,
        },
      };
    } else {
      return {
        pathname: "/images/new",
        query: {
          modelId: linkModelId,
          importAssetId: asset?.id,
        },
      };
    }
  }, [asset?.id, isDisabled, isSkyboxUpscale, isTexture, linkModelId]);
}

// ------------------------------------

export function useAssetUpscaleLink(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined
): LinkProps["href"] | undefined {
  const router = useRouter();
  const isDisabled =
    !asset ||
    asset?.metadata.type === "texture" ||
    (asset?.metadata.type ?? "").startsWith("texture-") ||
    ["upscale-skybox"].includes(asset?.metadata.type ?? "") ||
    (asset && !(asset.editCapabilities ?? []).includes("UPSCALE"));
  const isSkybox = asset?.metadata.type === "skybox-base-360";
  const isTexture =
    isAssetInferenceTexture(asset) || isAssetUpscaledTexture(asset);

  return useMemo(() => {
    if (isDisabled) {
      return undefined;
    }

    if (isSkybox && router.pathname === "/skyboxes/new") {
      return {
        pathname: "/skyboxes/new",
        query: { enhanceAssetId: asset?.id },
      };
    } else if (isSkybox) {
      return {
        pathname: "/skyboxes/enhance",
        query: {
          importAssetId: asset?.id,
        },
      };
    } else if (isTexture) {
      return {
        pathname: "/textures/enhance",
        query: {
          importAssetId: asset?.id,
        },
      };
    } else {
      return {
        pathname: "/enhance",
        query: {
          importAssetId: asset?.id,
        },
      };
    }
  }, [asset?.id, isDisabled, isSkybox, isTexture, router.pathname]);
}

// ------------------------------------

export function getModelIdForCanvas(
  modelId: string,
  { isLive = false }: { isLive?: boolean } = {}
) {
  const model = {
    id: modelId,
  } as GetModelsByModelIdApiResponse["model"];
  if (
    getIsModelFluxPro(model) ||
    getIsModelFluxPro1_1(model) ||
    getIsModelFluxPro1_1Ultra(model)
  ) {
    return isLive ? "flux.1-schnell" : "flux.1-dev";
  }
  if (getIsModelFluxDev(model) && isLive) {
    return "flux.1-schnell";
  }
  return modelId;
}

export function useAssetCanvasLink(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined,
  { isLive = false }: { isLive?: boolean } = {}
): LinkProps["href"] | undefined {
  const isDisabled = !asset;

  const modelId = asset?.metadata.modelId
    ? getModelIdForCanvas(asset.metadata.modelId, { isLive })
    : undefined;

  return useMemo(() => {
    if (isDisabled) {
      return undefined;
    }

    return {
      pathname: "/canvas/new",
      query: {
        importAssetId: asset?.id,
        ...(isLive ? { live: "true" } : {}),
        ...(modelId ? { modelId } : {}),
      },
    };
  }, [asset?.id, isDisabled, isLive, modelId]);
}

export function useAssetExpandLink(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined
): LinkProps["href"] | undefined {
  const isDisabled =
    !asset || !(asset.editCapabilities ?? []).includes("REFRAME");

  return useMemo(() => {
    if (isDisabled) {
      return undefined;
    }

    return {
      pathname: "/expand",
      query: {
        importAssetId: asset?.id,
      },
    };
  }, [asset?.id, isDisabled]);
}
