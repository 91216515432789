import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FmGridProps } from "domains/file-manager/components/FileManagerV2/FmGrid";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { useAvoidFlicker } from "domains/file-manager/hooks/useAvoidFlicker";
import { useOptimizedAssetUrl } from "domains/file-manager/hooks/useOptimizedAssetUrl";
import { FmFileImage } from "domains/file-manager/interfacesV2";
import { getShouldHaveNsfwBlur } from "domains/file-manager/utils/getShouldHaveNsfwBlur";
import { useHover } from "domains/ui/hooks/useHover";
import { useWindowSize } from "domains/ui/hooks/useWindowSize";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { noop } from "lodash";
import {
  useContainerPosition,
  useInfiniteLoader,
  useMasonry,
  usePositioner,
  useResizeObserver,
} from "masonic";
import { useScroller } from "mini-virtual-list";

import { Box, BoxProps } from "@chakra-ui/react";

import { ActionType, FmImageCardActionsProps } from "./Card/Actions";
import CardContainer from "./Card/Container";
import { CardContentProps } from "./Card/Content";
import Card from "./Card";

export type GridCustomProps<C extends object = object> = {
  variant?: "image" | "skybox" | "texture";
  view: Exclude<GridViewKey, "jobs">;
  isVaryEnabled?: boolean;

  pinnedFileIds?: string[];
  onCardPinClick?: CardContentProps["onPinClick"];

  isCardWithoutActions?: boolean;
  cardActionsProps: C;
  CardActionsComponent?: React.FunctionComponent<FmImageCardActionsProps<C>>;
  onActionClick?: (type: ActionType) => void;
};

export type GridProps<C extends object = object> = FmGridProps<
  "image",
  GridCustomProps<C>
>;

export default function Grid<C extends object = object>({
  files,

  variant = "image",
  view,
  columnsCount,
  isVaryEnabled = false,

  isSelectable = false,
  isSelectionForced = false,
  selection,
  isSelectionMaxReached,
  onSelect,

  onCardClick,
  onEndReached,

  scrollRef,
  containerRef,

  pinnedFileIds,
  onCardPinClick,

  isCardWithoutActions = false,
  cardActionsProps,
  CardActionsComponent,
  onActionClick,
}: GridProps<C>) {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { offset, width: itemWidth } = useContainerPosition(containerRef, [
    windowWidth,
    windowHeight,
  ]);
  const { scrollTop } = useScroller(scrollRef ?? window, {
    offset,
  });
  const [initialCardWidth, setInitialCardWidth] = useState<
    number | undefined
  >();
  const [maxCardWidth, setMaxCardWidth] = useState<number | undefined>();

  // check if files[0] has changed
  const fileZero = files[0];
  const [firstFile, setFirstFile] = useState(fileZero);
  const willChangeOnlyIfFirstFileChangedDimensions = useMemo(() => {
    if (
      fileZero.width !== firstFile.width ||
      fileZero.height !== firstFile.height
    ) {
      return {};
    }
    setFirstFile(fileZero);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileZero]);

  const [fileLength, setFileLength] = useState(files.length);
  /** Used to reset positions of already existing files, when we delete files, but not when new files are loaded */
  const willChangeOnlyIfLessFilesThanBefore = useMemo(() => {
    if (files.length < fileLength) {
      return {};
    }
    setFileLength(files.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files.length]);

  const positioner = usePositioner(
    {
      width: itemWidth,
      columnGutter: 8,
      columnCount: columnsCount,
      rowGutter: 8,
    },
    [
      columnsCount,
      view,
      willChangeOnlyIfLessFilesThanBefore,
      willChangeOnlyIfFirstFileChangedDimensions,
      itemWidth,
    ]
  );
  const resizeObserver = useResizeObserver(positioner);

  const MasonryCardWithProps = useCallback(
    (props: Omit<MasonryCardProps, keyof MasonryCardCommonProps>) => (
      <MasonryCard
        view={view}
        variant={variant}
        isVaryEnabled={isVaryEnabled}
        onClick={onCardClick}
        containerRef={containerRef}
        onPinClick={onCardPinClick}
        isWithoutActions={isCardWithoutActions}
        actionsProps={cardActionsProps}
        ActionsComponent={CardActionsComponent}
        onActionClick={onActionClick}
        {...props}
      />
    ),
    [
      CardActionsComponent,
      cardActionsProps,
      isCardWithoutActions,
      onCardPinClick,
      containerRef,
      onCardClick,
      onActionClick,
      variant,
      view,
      isVaryEnabled,
    ]
  );

  const masonryItems = useMemo(
    () =>
      files.map((file): MasonryCardProps["data"] => {
        return {
          file,
          isDisabled: !!isSelectionMaxReached && !selection.includes(file.id),
          isPinned: pinnedFileIds?.includes(file.id),
          cardWidth: positioner.columnWidth,
          cardMaxWidth:
            initialCardWidth && maxCardWidth ? maxCardWidth : undefined,
          cardHeight:
            file && view === "masonry"
              ? (file.height || positioner.columnWidth) *
                (positioner.columnWidth /
                  (file.width || positioner.columnWidth))
              : positioner.columnWidth,
          isSelected: selection.includes(file.id),
          isShiftSelectable: isSelectionForced || !!selection.length,
          isSelectable,
          onSelect,
        };
      }),
    [
      files,
      view,
      positioner.columnWidth,
      pinnedFileIds,
      selection,
      isSelectionForced,
      isSelectionMaxReached,
      isSelectable,
      onSelect,
      initialCardWidth,
      maxCardWidth,
    ]
  );

  // ----------------------------------

  const handleEndReachCheck = useInfiniteLoader(async () => onEndReached?.(), {
    isItemLoaded: (index, items) => !!items[index],
  });

  // ----------------------------------

  useEffect(() => {
    if (positioner.columnWidth < 40) return;

    if (!maxCardWidth || positioner.columnWidth > maxCardWidth) {
      setMaxCardWidth(positioner.columnWidth);
    }

    if (!initialCardWidth) {
      setInitialCardWidth(positioner.columnWidth);
    }
  }, [positioner.columnWidth, initialCardWidth, maxCardWidth]);

  // ----------------------------------

  const masonry = useAvoidFlicker(
    useMasonry({
      scrollTop,
      positioner,
      resizeObserver,
      items: masonryItems,
      onRender: handleEndReachCheck,
      className: "masonic",
      itemKey: (data) => data.file.id,
      overscanBy: 2,
      height: windowHeight || 0,
      render: MasonryCardWithProps,
    })
  );

  return <Box sx={{ ".masonic": { outline: "none" } }}>{masonry}</Box>;
}

type MasonryCardCommonProps<C extends object = object> = {
  view?: GridViewKey;
  variant: "image" | "skybox" | "texture";
  onClick?: (file: FmFileImage) => void;
  containerRef: RefObject<HTMLDivElement>;
  isVaryEnabled?: boolean;

  onPinClick?: CardContentProps["onPinClick"];
  isWithoutActions?: boolean;
  actionsProps: C;
  ActionsComponent?: React.FunctionComponent<FmImageCardActionsProps<C>>;
  onActionClick?: (type: ActionType) => void;
};

type MasonryCardProps<C extends object = object> = MasonryCardCommonProps<C> & {
  width: number;
  index: number;
  data: {
    file: FmFileImage;
    isDisabled: boolean;
    cardHeight: number;
    cardWidth: number;
    cardMaxWidth?: number;
    isSelected: boolean;
    isShiftSelectable: boolean;
    isSelectable?: boolean;
    isPinned?: boolean;
    onSelect: FmGridProps["onSelect"];
  };
};

const MasonryCardWithoutMemo = function MasonryCard<C extends object = object>({
  view,
  variant,
  isVaryEnabled = false,
  onClick,
  containerRef,
  onPinClick,
  isWithoutActions = false,
  actionsProps,
  ActionsComponent,
  onActionClick,
  data: {
    file,
    isDisabled,
    cardWidth,
    cardMaxWidth,
    cardHeight,
    isSelected = false,
    isShiftSelectable = false,
    isSelectable = false,
    isPinned = false,
    onSelect,
  },
  index,
}: MasonryCardProps<C>) {
  const { nsfwFilteredTypes, nsfwRevealedAssetIds } = useUserContext();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const { url: maxResUrl } = useOptimizedAssetUrl({
    imageFile: file,
    cardWidth: cardMaxWidth ?? cardWidth,
  });

  const isSuccess = file.status === "success";
  const thumbnailUrl = (isSuccess && maxResUrl) || "";
  const lowResThumbnailUrl = isSuccess
    ? `${process.env.NEXT_PUBLIC_CDN_URL}/thumbnails/${file.id}`
    : undefined;
  const imgHeightRatio = Math.max(
    Math.round((file.height / file.width) * 100),
    20
  );
  const imgProps: BoxProps = useMemo(() => {
    let props: BoxProps =
      view !== "masonry"
        ? {
            position: "absolute",
            top: 0,
            objectFit: view === "fill" ? "cover" : "contain",
          }
        : {
            objectFit: "fill",
          };
    if (file.meta.metadata.type === "texture") {
      props = {
        ...props,
        objectFit: "cover",
        objectPosition: "50% 0",
      };
    }
    return props;
  }, [file, view]);

  const isBlurred =
    getShouldHaveNsfwBlur({
      nsfwFilteredTypes,
      nsfw: file.meta.nsfw,
    }) && !nsfwRevealedAssetIds.includes(file.id);

  const containerProps: BoxProps = useMemo(() => {
    return {
      pt: view === "masonry" ? `${imgHeightRatio}%` : "100%",
    };
  }, [view, imgHeightRatio]);

  return (
    <Box
      ref={hoverRef}
      pos="relative"
      h={view === "masonry" ? `${cardHeight}px` : `${cardWidth}px`}
      minH="30px"
      cursor={isDisabled ? "default" : "pointer"}
      data-id={file.id}
      data-key={index}
      data-testid="asset-gallery-grid-cell"
    >
      <CardContainer
        variant={variant}
        file={file}
        thumbnail={thumbnailUrl}
        lowResThumbnail={lowResThumbnailUrl}
        isHovered={isHovered}
        isDisabled={isDisabled}
        isBlurred={isBlurred}
        isSelectable={isSuccess && isSelectable && !isDisabled}
        isShiftSelectable={isShiftSelectable}
        isSelected={isSelected}
        onSelect={isDisabled ? noop : onSelect}
        onClick={isSuccess ? onClick : undefined}
        containerProps={containerProps}
        imgProps={imgProps}
      >
        <Card
          file={file}
          variant={variant}
          isVaryEnabled={isVaryEnabled}
          cardWidth={cardWidth}
          containerRef={containerRef}
          isCancelable
          isHovered={isHovered}
          isBlurred={isBlurred}
          onClick={isSuccess ? onClick : undefined}
          isPinned={isPinned}
          onPinClick={onPinClick}
          isWithoutActions={isWithoutActions}
          actionsProps={actionsProps}
          ActionsComponent={ActionsComponent}
          onActionClick={onActionClick}
        />
      </CardContainer>
    </Box>
  );
};

const MasonryCard = React.memo(
  MasonryCardWithoutMemo
) as typeof MasonryCardWithoutMemo;
