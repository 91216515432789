import { ReactNode } from "react";
import defaultColorPalettes, {
  ColorPalette,
} from "domains/ui/components/ColorPicker/defaultColorPalettes";
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "domains/ui/components/Tabs";

import {
  Box,
  Flex,
  Grid,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

import ColorPaletteThumbnail from "./ElementColorPaletteThumbnail";

const MODAL_ID = "pixelateSelectColorPalette";

interface ModalColorPaletteSelectorProps {
  imgSrc: string | undefined;
  setSelectedColorPalette: (colorPalette: ColorPalette | undefined) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setShouldUseNewColorPalette: (value: boolean) => void;
  savedColorPalettes: ColorPalette[];
}

export default function ModalColorPaletteSelector({
  imgSrc,
  setSelectedColorPalette,
  isOpen,
  setIsOpen,
  setShouldUseNewColorPalette,
  savedColorPalettes,
}: ModalColorPaletteSelectorProps) {
  const handleColorPaletteClick = (colorPalette: ColorPalette | undefined) => {
    setSelectedColorPalette(colorPalette);
    setIsOpen(false);
    setShouldUseNewColorPalette(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} variant={"modern"}>
      <ModalOverlay />
      <ModalContent w={"90vw"} maxW={"unset"} h={"80vh"} p={0}>
        <ModalCloseButton />
        <ModalBody w={"100%"} id={MODAL_ID}>
          <Flex direction={"column"} w={"100%"} pb={3}>
            <Text
              px={5}
              py={5}
              textAlign={"left"}
              borderBottomWidth={1}
              size={"body.bold.lg"}
            >
              Choose color palette
            </Text>
            <Box pt={4} px={5}>
              <Tabs w="100%">
                <TabList>
                  <Tab>Your Palettes</Tab>
                  <Tab>Platform Palettes</Tab>
                </TabList>
                <TabIndicator />
                <TabPanels mt={5}>
                  <TabPanel>
                    <ModalColorPaletteSelectorTab
                      colorPalettes={savedColorPalettes}
                      handleColorPaletteClick={handleColorPaletteClick}
                      additionalChildren={[
                        <Box
                          key={"color-palette-none"}
                          w={"200px"}
                          h={"200px"}
                          cursor={"pointer"}
                          onClick={() => handleColorPaletteClick(undefined)}
                        >
                          <Box
                            w={"100%"}
                            h={"100%"}
                            bgColor="backgroundQuaternary.500"
                          >
                            {imgSrc && (
                              <Image
                                w={"100%"}
                                h={"100%"}
                                objectFit={"contain"}
                                alt={"image"}
                                src={imgSrc}
                              />
                            )}
                          </Box>
                        </Box>,
                      ]}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ModalColorPaletteSelectorTab
                      colorPalettes={defaultColorPalettes}
                      handleColorPaletteClick={handleColorPaletteClick}
                      showNames={true}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

// ------------------------------------

interface ModalColorPaletteSelectorTabProps {
  colorPalettes: ColorPalette[];
  handleColorPaletteClick: (colorPalette: ColorPalette | undefined) => void;
  additionalChildren?: ReactNode[];
  showNames?: boolean;
}

function ModalColorPaletteSelectorTab({
  colorPalettes,
  handleColorPaletteClick,
  additionalChildren,
  showNames,
}: ModalColorPaletteSelectorTabProps) {
  return (
    <Grid
      justifyContent={"space-between"}
      rowGap={5}
      columnGap={3}
      templateColumns={["repeat(auto-fill, 200px)"]}
    >
      {additionalChildren && additionalChildren.map((child) => child)}
      {colorPalettes.map((colorPalette) => {
        return (
          <VStack
            key={`color-palette-${colorPalette.id}`}
            cursor={"pointer"}
            onClick={() => handleColorPaletteClick(colorPalette)}
            spacing={0}
          >
            <HStack w={"200px"} h={"200px"} spacing={0}>
              <ColorPaletteThumbnail colorPalette={colorPalette} />
            </HStack>
            {showNames && (
              <Flex align={"center"} justify={"center"} w={"100%"} h={"40px"}>
                <Text
                  overflow={"hidden"}
                  w={"100%"}
                  textColor={"textPrimary"}
                  textOverflow={"ellipsis"}
                  size={"body.sm"}
                >
                  {colorPalette.name}
                </Text>
              </Flex>
            )}
          </VStack>
        );
      })}
    </Grid>
  );
}
