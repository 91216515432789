import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import AsideDrawer from "domains/assets/components/AssetZoom/Drawer";
import {
  AsideEditAction,
  AsideEditActions,
} from "domains/assets/constants/Aside";
import { useHotkeys } from "domains/commons/contexts/HotkeysProvider";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { Shortcut } from "domains/shortcuts/components/Shorcut";
import Aside from "domains/ui/components/Aside";
import Icon from "domains/ui/components/Icon";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";

import AsideSectionConcepts from "./SectionConcepts";
import AsideSectionDerived from "./SectionDerived";
import AsideSectionEdit from "./SectionEdit";
import AsideSectionParameters from "./SectionParameters";
import { AsideSectionReference } from "./SectionReference";
import AsideSectionRefs from "./SectionRefs";
import AsideSectionTags from "./SectionTags";
import AsideSectionTop from "./SectionTop";

export interface AssetZoomAsideProps {
  model?: GetModelsByModelIdApiResponse["model"];
  inference?: GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"];
  asset?: GetAssetsByAssetIdApiResponse["asset"];
  isOpen: boolean;
  onSetIsOpen: (isOpen: boolean) => void;
}

export default function AssetZoomAside({
  model,
  inference,
  asset,
  isOpen,
  onSetIsOpen,
}: AssetZoomAsideProps) {
  const router = useRouter();
  const [drawer, setDrawer] = useState<AsideEditAction | undefined>();
  const isInference = asset?.metadata.type.includes("inference");

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const editAction = router.query.edit;

    if (editAction && AsideEditActions.includes(editAction as any)) {
      setDrawer(editAction as AsideEditAction);
      onSetIsOpen(true);
    }
  }, [onSetIsOpen, router.query.edit, setDrawer, router.isReady]);

  const handleEditActionClick = useCallback(
    (action: AsideEditAction) => {
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          edit: action,
        },
      });
      setDrawer(action);
    },
    [setDrawer, router]
  );

  const handleDrawerClose = useCallback(
    ({ avoidRedirect }: { avoidRedirect?: boolean }) => {
      if (!avoidRedirect) {
        delete router.query.edit;
        void router.push({
          pathname: router.pathname,
          query: router.query,
        });
      }
      setDrawer(undefined);
    },
    [setDrawer, router]
  );

  const handleOpenToggle = useCallback(() => {
    onSetIsOpen(!isOpen);
  }, [onSetIsOpen, isOpen]);

  useHotkeys(appShortcuts.assetPage.shortcuts.info.shortcut, handleOpenToggle, [
    handleOpenToggle,
  ]);

  return (
    <Aside
      id="assetZoom"
      title="Info"
      icon={<Icon id="Ui/Info" h="22px" />}
      open={isOpen}
      buttonTestId="panel-info-button"
      onOpenToggle={handleOpenToggle}
      tooltip={<Shortcut {...appShortcuts.assetPage.shortcuts.info} />}
    >
      <AsideSectionTop
        model={model}
        inference={isInference ? inference : undefined}
        asset={asset}
      />

      <AsideSectionEdit asset={asset} onActionClick={handleEditActionClick} />

      <AsideSectionTags asset={asset} />

      <AsideSectionParameters
        model={model}
        inference={isInference ? inference : undefined}
        asset={asset}
      />

      <AsideSectionReference
        inference={isInference ? inference : undefined}
        asset={asset}
      />

      <AsideSectionRefs asset={asset} />

      <AsideSectionDerived asset={asset} />

      <AsideSectionConcepts inference={isInference ? inference : undefined} />

      {asset && (
        <AsideDrawer
          asset={asset}
          action={drawer}
          onClose={handleDrawerClose}
        />
      )}
    </Aside>
  );
}
