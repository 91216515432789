import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ReferenceImage } from "domains/inference/interfaces/InferenceGenerator";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { removeCuFromType } from "infra/api/ExludeCU";
import {
  GetJobIdApiResponse,
  PostSkyboxUpscale360InferencesApiArg,
  usePostSkyboxUpscale360InferencesMutation,
} from "infra/api/generated/api";
import _ from "lodash";

export type SkyboxUpscaleForm = {
  reference:
    | {
        image: string;
        assetId: string;
        width?: number;
      }
    | undefined;
  prompt: string;
  negativePrompt: string;
  seed: string | undefined;
  scalingFactor: number;
  targetWidth: number | undefined;
  styleImages: ReferenceImage[];
  styleFidelity: number;
  detailsLevel: number;
};

export const DEFAULT_SKYBOX_UPSCALE_FORM: SkyboxUpscaleForm = {
  reference: undefined,
  prompt: "",
  negativePrompt: "",
  seed: undefined,
  scalingFactor: 2,
  targetWidth: undefined,
  styleImages: [],
  styleFidelity: 25,
  detailsLevel: 15,
};

// ------------------------------------

interface SkyboxUpscaleContextValue {
  form: SkyboxUpscaleForm;
  setForm: React.Dispatch<React.SetStateAction<SkyboxUpscaleForm>>;
  setFormValue: <K extends keyof SkyboxUpscaleForm>(
    key: K,
    value: SkyboxUpscaleForm[K]
  ) => void;
  createUpscaleJob: (options: {
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<GetJobIdApiResponse["job"] | undefined>;
  isCreatingUpscaleJob: boolean;
}

export const SkyboxUpscaleContext = createContext<SkyboxUpscaleContextValue>({
  form: DEFAULT_SKYBOX_UPSCALE_FORM,
  setForm: () => {},
  setFormValue: () => {},
  createUpscaleJob:
    (async () => {}) as SkyboxUpscaleContextValue["createUpscaleJob"],
  isCreatingUpscaleJob: false,
});

export function SkyboxUpscaleProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const { selectedTeam } = useTeamContext();
  const [triggerSkyboxUpscale] = usePostSkyboxUpscale360InferencesMutation();
  const { infoToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();

  const [form, setForm] = useState<SkyboxUpscaleForm>({
    ...DEFAULT_SKYBOX_UPSCALE_FORM,
  });
  const [isCreatingUpscaleJob, setIsCreatingUpscaleJob] =
    useState<boolean>(false);

  // ----------------------------------

  const setFormValue = useCallback(
    <K extends keyof SkyboxUpscaleForm>(
      key: K,
      value: SkyboxUpscaleForm[K]
    ) => {
      setForm((form) => {
        return { ...form, [key]: value };
      });
    },
    []
  );

  const createUpscaleJob = useCallback(
    async ({
      trackingExtraParams,
    }: {
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsCreatingUpscaleJob(true);
      infoToast({
        title: "Enhancing skybox",
      });

      try {
        const body = getSkyboxUpscaleParamsBody({
          form,
        });
        if (!body) return;

        const upscaleResponse = await triggerSkyboxUpscale({
          teamId: selectedTeam.id,
          body,
        })
          .unwrap()
          .then(removeCuFromType);

        Track(AnalyticsEvents.Skybox.UpscaledSkybox, {
          ...body,
          ...trackingExtraParams,
        });

        return upscaleResponse.job;
      } catch (error: any) {
        handleApiError(error, "Error enhancing skybox", {
          quota: () => {
            if (_.get(error, "data.details.remainingSeconds")) {
              showLimitModal("planCooldown", {
                timeout: error.data.details.remainingSeconds,
                type: "upscale",
              });
            } else if (
              _.get(error, "data.reason").includes(
                "You have reached your plan's limit."
              )
            ) {
              showLimitModal("notEnoughCreativeUnits");
            } else {
              errorToast({
                title: "Error enhancing skybox",
                description: _.get(error, "data.reason"),
              });
            }
          },
        });
      } finally {
        setIsCreatingUpscaleJob(false);
      }
    },
    [
      infoToast,
      form,
      triggerSkyboxUpscale,
      selectedTeam.id,
      errorToast,
      handleApiError,
      showLimitModal,
    ]
  );

  const contextValue = useMemo(
    () => ({
      form,
      setForm,
      setFormValue,
      createUpscaleJob,
      isCreatingUpscaleJob,
    }),
    [form, setForm, setFormValue, createUpscaleJob, isCreatingUpscaleJob]
  );

  // ----------------------------------
  return (
    <SkyboxUpscaleContext.Provider value={contextValue}>
      {children}
    </SkyboxUpscaleContext.Provider>
  );
}

export function useSkyboxUpscaleContext() {
  return useContext<SkyboxUpscaleContextValue>(SkyboxUpscaleContext);
}

export function getSkyboxUpscaleParamsBody({
  form,
}: {
  form: SkyboxUpscaleForm;
}): PostSkyboxUpscale360InferencesApiArg["body"] | undefined {
  if (!form.reference) return;
  const referenceWidth = form.reference?.width ?? 1_024;
  const styleImagesIds = form.styleImages.map(
    (styleImage) => styleImage.assetId
  );

  return {
    image: form.reference.assetId,
    prompt: form.prompt,
    negativePrompt: form.negativePrompt,
    seed: form.seed ? parseInt(form.seed) : undefined,
    targetWidth: form.targetWidth ?? referenceWidth * form.scalingFactor,
    ...(styleImagesIds.length > 0 && {
      styleImages: styleImagesIds,
      styleFidelity: form.styleFidelity,
    }),
    detailsLevel: form.detailsLevel,
  };
}
