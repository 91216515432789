import React, { useMemo } from "react";
import { NextSeo } from "next-seo";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetJobFilterAuthor from "domains/assets/hooks/useAssetAuthor";
import useAssetFilterType from "domains/assets/hooks/useAssetFilterType";
import useAssetSort from "domains/assets/hooks/useAssetSort";
import useAssetView from "domains/assets/hooks/useAssetView";
import FileManagerImage, {
  FileManagerImageProps,
} from "domains/file-manager/components/FileManagerImage";
import { FmImageCardActionsProps } from "domains/file-manager/components/FileManagerImage/Card/Actions";
import { EmptyMessageTexture } from "domains/file-manager/components/FileManagerImage/EmptyMessage";
import { TOP_BAR_HEIGHT } from "domains/navigation/constants/TopBar";
import Button from "domains/ui/components/Button";
import Header from "domains/ui/components/Header";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";

import { Box } from "@chakra-ui/react";

export default function TexturesPage() {
  return (
    <>
      <NextSeo title="Textures" />

      <Header title="Textures">
        <Button
          leftIcon={<Icon id="Ui/Plus" />}
          data-testid="new-texture-button"
          internalLink="/textures/new"
          size="sm"
        >
          New Texture
        </Button>
      </Header>

      <Box mx={9}>
        <ListTextures />
      </Box>
    </>
  );
}

// ------------------------------------

export type ListTexturesProps = {
  collectionId?: string;
  EmptyStateComponent?: FileManagerImageProps["EmptyStateComponent"];
};

export function ListTextures({
  collectionId,
  EmptyStateComponent,
}: ListTexturesProps) {
  const fmViewProps = useAssetView({ variant: "texture" });
  const { allAssetsTypeArgs, fmHeaderFilterAssetProps } = useAssetFilterType({
    assetType: "texture",
  });
  const { authorQueryArgs, fmHeaderFilterAuthorProps } =
    useAssetJobFilterAuthor();
  const { sortQueryArgs, fmHeaderSortProps } = useAssetSort();
  const result = useAllAssets({
    collectionId,
    ...sortQueryArgs,
    ...allAssetsTypeArgs,
    ...(collectionId ? undefined : authorQueryArgs),
  });

  // ----------------------------------

  const cardActionsProps = useMemo(() => ({}), []);

  // ----------------------------------

  return (
    <FileManagerImage
      variant="texture"
      files={fmViewProps.view === "jobs" ? undefined : result.files}
      jobs={fmViewProps.view === "jobs" ? result.jobs : undefined}
      isLoading={result.isLoading}
      hasMore={result.hasMore}
      onEndReached={result.loadMore}
      isSelectable
      {...fmViewProps}
      CardActionsComponent={CardActions}
      cardActionsProps={cardActionsProps}
      headerFilterAssetProps={fmHeaderFilterAssetProps}
      headerFilterAuthorProps={
        collectionId ? undefined : fmHeaderFilterAuthorProps
      }
      headerSortProps={fmHeaderSortProps}
      EmptyStateComponent={EmptyStateComponent ?? EmptyMessageTexture}
      styleProps={{
        header: {
          top: `${TOP_BAR_HEIGHT}px`,
        },
      }}
    />
  );
}

// ------------------------------------

export type CardActionsCustomProps = {
  _never?: never;
};

export type CardActionsProps = FmImageCardActionsProps<CardActionsCustomProps>;

export function CardActions({ asset }: CardActionsProps) {
  const assetId = asset?.id;
  const modelId = asset?.metadata.modelId;
  const hasPrompt = !!asset?.metadata.prompt;
  const canReuseImage = !["texture"].includes(asset?.metadata.type ?? "");

  if (!modelId && !canReuseImage && !hasPrompt) {
    return null;
  }

  return (
    <>
      {modelId && (
        <MenuItem
          w="150px"
          iconId="Nav/Model/Outline"
          iconH="12px"
          internalLink={{
            pathname: "/textures/new",
            query: {
              modelId,
            },
          }}
          text="Model"
        />
      )}

      {canReuseImage && (
        <MenuItem
          w="150px"
          iconId="Nav/Image/Outline"
          iconH="12px"
          internalLink={{
            pathname: "/textures/new",
            query: {
              importAssetId: assetId,
            },
          }}
          text="Image"
        />
      )}

      {hasPrompt && (
        <MenuItem
          w="150px"
          iconId="Ui/Prompt"
          iconH="12px"
          internalLink={{
            pathname: "/textures/new",
            query: {
              importPromptFromAssetId: assetId,
            },
          }}
          text="Prompt"
        />
      )}
    </>
  );
}
